module.exports = [
    {
        name: "Changelly",
        address: "0x96fC4553a00C117C5b0bED950Dd625d1c16Dc894",
        type: "exchange",
    },
    {
        name: "Shapeshift",
        address: "0x70faa28A6B8d6829a4b1E649d26eC9a2a39ba413",
        type: "exchange",
    },
    {
        name: "ENS-Registrar",
        address: "0x6090A6e47849629b7245Dfa1Ca21D94cd15878Ef",
    },
    {
        name: "Poloniex ColdWallet",
        address: "0xb794F5eA0ba39494cE839613fffBA74279579268",
        type: "exchange",
    },
    {
        name: "Gemini",
        address: "0xd24400ae8bfebb18ca49be86258a3c749cf46853",
        type: "exchange",
    },
    {
        name: "LooneyLottery",
        address: "0x2ef76694fBfD691141d83F921A5ba710525De9B0",
    },
    {
        name: "EOS-Owner",
        address: "0x9937dbb2128b55c44d8af7bf36fd76796a814cf4",
    },
    {
        name: "liqui.io",
        address: "0x8271B2E8CBe29396e9563229030c89679B9470db",
        type: "exchange",
    },
    {
        name: "Bitfinex_Wallet1",
        address: "0x1151314c646Ce4E0eFD76d1aF4760aE66a9Fe30F",
        type: "exchange",
    },
    {
        name: "Bitfinex_Wallet2",
        address: "0x7727E5113D1d161373623e5f49FD568B4F543a9E",
        type: "exchange",
    },
    {
        name: "Bitfinex_Wallet3",
        address: "0x4fdd5Eb2FB260149A3903859043e962Ab89D8ED4",
        type: "exchange",
    },
    {
        name: "Bitfinex_Wallet4",
        address: "0x876EabF441B2EE5B5b0554Fd502a8E0600950cFa",
        type: "exchange",
    },
    {
        name: "Bittrex",
        address: "0xfbb1b73c4f0bda4f67dca266ce6ef42f520fbb98",
        type: "exchange",
    },
    {
        name: "EthDev",
        address: "0xde0b295669a9fd93d5f28d9ec85e40f4cb697bae",
    },
    {
        name: "DigixCrowdSale",
        address: "0xf0160428a8552ac9bb7e050d90eeade4ddd52843",
    },
    {
        address: "0x7da82c7ab4771ff031b66538d2fb9b0b047f6cf9",
        name: "GolemMultisig",
    },
    {
        address: "0x3bfc20f0b9afcace800d73d2191166ff16540258",
        name: "Polkadot-Multisig",
    },
    {
        address: "0xcafe1a77e84698c83ca8931f54a755176ef75f2c",
        name: "Aragon_Multisig",
    },
    {
        address: "0xbf4ed7b27f1d666546e30d74d50d173d20bca754",
        name: "WithdrawDAO",
    },
    {
        address: "0xc78310231aa53bd3d0fea2f8c705c67730929d8f",
        name: "SingularFunds",
    },
    {
        address: "0x851b7f3ab81bd8df354f0d7640efcd7288553419",
        name: "Gnosis-AuctionWallet",
    },
    {
        address: "0x3f5ce5fbfe3e9af3971dd833d26ba9b5c936f0be",
        name: "BinanceWallet",
        type: "exchange",
    },
    {
        name: "Kraken_4",
        address: "0x267be1C1D684F78cb4F6a176C4911b741E4Ffdc0",
        type: "exchange",
    },
    {
        name: "Kraken_3",
        address: "0xe853c56864a2ebe4576a807d26fdc4a0ada51919",
        type: "exchange",
    },
    {
        address: "0x0a869d79a7052c7f1b55a8ebabbea3420f0d1e13",
        name: "Kraken_2",
        type: "exchange",
    },
    {
        name: "Kraken_1",
        address: "0x2910543Af39abA0Cd09dBb2D50200b3E800A63D2",
        type: "exchange",
    },
    {
        address: "0x376c3e5547c68bc26240d8dcc6729fff665a4448",
        name: "Iconomi-Multisig",
    },
    {
        address: "0x4fdd5eb2fb260149a3903859043e962ab89d8ed4",
        name: "Bitfinex_Wallet3",
        type: "exchange",
    },
    {
        address: "0x52bc44d5378309EE2abF1539BF71dE1b7d7bE3b5",
        name: "Nanopool",
        type: "Mining",
    },
    {
        address: "0xB3764761E297D6f121e79C32A65829Cd1dDb4D32",
        name: "MultisigExploit-Hacker",
        type: "hacker",
    },
    {
        address: "0x5e8f0e63e7614c47079a41ad4c37be7def06df5a",
        name: "Dao-Hacker",
        type: "hacker",
    },
    {
        address: "0xD91E45416bfbBEc6e2D1ae4aC83b788A21Acf583",
        name: "Etheroll",
    },
    {
        address: "0X69DF5ED3363cfC4c92ac96C6C5f2A42fCCBF85",
        name: "Brother Five",
        url:
            "https://www.longhash.com/news/the-3-million-winner-of-fomo3d-is-still-playing-to-win",
        description: "Brother five was the first $3 Million Winner of Fomo3D",
    },
];
