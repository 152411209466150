function formatCurrency(amount, decimals = 2, currency = "EUR") {
    if (decimals === 0) {
        let string = parseFloat(Math.round(amount)).toLocaleString("us-US", {
            style: "currency",
            currency: currency,
        });
        return string.replace("US", "");
    }
    let string = parseFloat(amount).toLocaleString("us-US", {
        style: "currency",
        currency: currency,
        maximumFractionDigits: decimals,
    });
    return string.replace("US", "");
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
}

function hexToDecimal(hex) {
    return parseInt(hex, 16);
}

function formatSource(source) {
    source = replaceAll(source, /;/, ";<br/>");
    source = replaceAll(source, /\/\*/, "<br/>/*");
    source = replaceAll(source, /event/, "<br/>event");
    source = replaceAll(source, /{/, "{<br/>");
    source = replaceAll(source, /}/, "}");
    source = replaceAll(source, /contract/, "<br/><br/>contract");
    source = replaceAll(source, /function/, "<br/><br/>function");
    return source;
}

function formatNumber(amount, decimals = 2) {
    let s = parseFloat(amount).toLocaleString("us-US", {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
    return s;
}

function dotProduct(x1, x2, y1, y2) {
    let dot = x1 * x2 + y1 * y2; // dot product between [x1, y1] and [x2, y2]
    let det = x1 * y2 - y1 * x2; // determinant
    return Math.atan2(det, dot); // atan2(y, x) or atan2(sin, cos)
}

function multiply(x1, x2, y1, y2) {
    let x = x1 * x2; // dot product between [x1, y1] and [x2, y2]
    let y = y1 * y2; // determinant
    return { x: x, y: y }; // atan2(y, x) or atan2(sin, cos)
}

function angle(x, y, angle) {
    return {
        x: x * Math.cos(angle) - y * Math.sin(angle),
        y: x * Math.sin(angle) + y * Math.cos(angle),
    }; // atan2(y, x) or atan2(sin, cos)
}

// Converts from degrees to radians.
function radians(degrees) {
    return (degrees * Math.PI) / 180;
}

// Converts from radians to degrees.
function degrees(radians) {
    return (radians * 180) / Math.PI;
}

//["0x6090A6e47849629b7245Dfa1Ca21D94cd15878Ef","0x3FA6D806609bCD5B86BE4614e97976152eC78a99","0xb794F5eA0ba39494cE839613fffBA74279579268","0x29be8158ffd14625f303c9937909d74be6585a35","0x900d0881a2e85a8e4076412ad1cefbe2d39c566c","0xfA129DCe2215E3f16aA9b1bD31601873206157C5","0xAb5801a7D398351b8bE11C439e05C5B3259aeC9B","0xb3764761e297d6f121e79c32a65829cd1ddb4d32","0xD74Ed380f96cb20E6EfC735d4B654Bf420123923","0x87e722173f2d28ce10ce1fa9bd862912fe7e24e6","0xbb9bc244d798123fde783fcc1c72d3bb8c189413","0xa62142888aba8370742be823c1782d17a0389da1","0x00789b1c3d583e3057c264888afc2c38e4aff5b7","0x42d6622dece394b54999fbd73d108123806f6a18","0x06012c8cf97BEaD5deAe237070F9587f8E7A266d","0x981e983f7ea0486195bce0a0460ba23e572d87ec"]

function getTag(node) {
    let result = tags["address"];
    if (node.tags)
        for (let t of node.tags) {
            if (tags[t] && result && tags[t].priority > result.priority) {
                result = tags[t];
            }
        }
    return result;
}

function tagExists(tag) {
    return tags[tag] !== undefined;
}

function hasTag(tag, node) {
    return node.tags.indexOf(tag) > -1;
}

function renderIcon(node) {
    let tag = getTag(node);
    return tag.icon.emoji; //tags[node.type].icon.emoji;
}

function getColors(node) {
    let tag = getTag(node);
    return tag.colors;
}

function addTag(tag, node) {
    if (tagExists(tag) && node.tags.indexOf(tag) === -1) node.tags.push(tag);
    if (tag === "contract") removeTag("address", node);
}

function removeTag(tag, node) {
    if (node.tags.indexOf(tag) > -1)
        node.tags.splice(node.tags.indexOf(tag), 1);
}

const tags = {
    unknown: {
        name: "unknown",
        icon: {
            emoji: "",
            offset: [0, 0],
        },
        colors: {
            nodeColor: "#d4d4d4",
            textColor: "black",
            pillColor: "#000",
        },
        priority: 1,
    },
    empty: {
        name: "empty",
        icon: {
            emoji: "",
            offset: [0, 0],
        },
        description: "Address has no ether",
        colors: {
            nodeColor: "#f0f0f0",
            textColor: "black",
            pillColor: "#000",
        },
        priority: 0,
    },
    address: {
        name: "address",
        icon: {
            emoji: "",
            offset: [0, 0],
        },
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#A5BBB8",
        },
        priority: -1,
    },
    named: {
        name: "named",
        icon: {
            emoji: "🏷️",
            offset: [0, 0],
        },
        colors: {
            nodeColor: "#64A0F7",
            textColor: "#64A0F7",
            pillColor: "#64A0F7",
        },
        priority: 50,
    },
    token: {
        name: "token", //get token name
        icon: {
            emoji: "🏅",
            offset: [0, 0],
        },
        description: "Contract is a token",
        colors: {
            nodeColor: "#f60",
            textColor: "#f60",
            pillColor: "#f60",
        },
        priority: 30,
    },
    logo: {
        name: "logo", //get token name
        icon: {
            emoji: " ",
            offset: [0, 0],
        },
        description: "Contract has a logo",
        colors: {
            nodeColor: "#fff",
            textColor: "#05ffa1",
            pillColor: "#999",
        },
        priority: 2,
    },
    ERC20: {
        name: "ERC-20",
        icon: {
            emoji: "🏅",
            offset: [0, 0],
        },
        description: "Contract implements the ERC-20 interface",
        colors: {
            nodeColor: "#fff",
            textColor: "#05ffa1",
            pillColor: "#00e68e",
        },
        priority: 20,
    },
    ERC721: {
        name: "ERC-721",
        icon: {
            emoji: "🐱",
            offset: [0, 0],
        },
        description: "Contract implements the ERC-712 interface",
        colors: {
            nodeColor: "#fff",
            textColor: "#05ffa1",
            pillColor: "#00e68e",
        },
        priority: 20,
    },
    contract: {
        name: "contract",
        icon: {
            emoji: "📟",
            offset: [0, 0],
        },
        description: "Address is a contract",
        colors: {
            nodeColor: "#00e68e",
            textColor: "#05ffa1",
            pillColor: "#00e68e",
        },
        priority: 25,
    },
    ens: {
        name: "ENS",
        icon: {
            emoji: "🏷️",
            offset: [0, 0],
        },
        description: "Address has an ENS domain name",
        colors: {
            nodeColor: "#64A0F7",
            textColor: "#64A0F7",
            pillColor: "#64A0F7",
        },
        priority: 60,
    },
    scam: {
        name: "scam",
        icon: {
            emoji: "🚨",
            offset: [0, 0],
        },
        description: "Address is known to belong to a scam",
        colors: {
            nodeColor: "#fe0000",
            textColor: "#fe0000",
            pillColor: "#fe0000",
        },
        priority: 80,
    },
    hacker: {
        name: "hacker",
        icon: {
            emoji: "☠️",
            offset: [0, 0],
        },
        description: "Address is known to belong to a hacker",
        colors: {
            nodeColor: "#fe0000",
            textColor: "#fe0000",
            pillColor: "#fe0000",
        },
        priority: 90,
    },
    exchange: {
        name: "exchange",
        icon: {
            emoji: "🏦",
            offset: [0, 0],
        },
        description: "Address belongs to a known exchange",
        colors: {
            nodeColor: "#b967ff",
            textColor: "#b967ff",
            pillColor: "#b967ff",
        },
        priority: 20,
    },
    miner: {
        name: "miner",
        icon: {
            emoji: "⚒️",
            offset: [0, 0],
        },
        description: "Address has mined Ethereum blocks",
        colors: {
            nodeColor: "#01cdfe",
            textColor: "#000",
            pillColor: "#01cdfe",
        },
        priority: 30,
    },
    wallet: {
        name: "wallet",
        icon: {
            emoji: "👛",
            offset: [0, 0],
        },
        description: "Address is a wallet contract",
        colors: {
            nodeColor: "white",
            textColor: "black",
            pillColor: "white",
        },
        priority: 10,
    },
    bluewhale: {
        name: "whale",
        icon: {
            emoji: "🐳",
            offset: [0, 0],
        },
        description: "Address has more than 100.000 Ether",
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#19e6c4",
        },
        priority: 1,
    },
    whale: {
        name: "whale",
        icon: {
            emoji: "🐋",
            offset: [0, 0],
        },
        description: "Address has more than 10.000 Ether",
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#19e6c4",
        },
        priority: 1,
    },
    kraken: {
        name: "kraken",
        icon: {
            emoji: "🦑",
            offset: [0, 0],
        },
        description: "Address has more than 1000 Ether",
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#19e6c4",
        },
        priority: 1,
    },
    shark: {
        name: "shark",
        icon: {
            emoji: "🦈",
            offset: [0, 0],
        },
        description: "Address has more than 200 Ether",
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#19e6c4",
        },
        priority: 1,
    },
    dolphin: {
        name: "dolphin",
        icon: {
            emoji: "🐬",
            offset: [0, 0],
        },
        description: "Address has more than 50 Ether",
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#19e6c4",
        },
        priority: 1,
    },
    fish: {
        name: "fish",
        icon: {
            emoji: "🐠",
            offset: [0, 0],
        },
        description: "Address has more than 20 Ether",
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#19e6c4",
        },
        priority: 1,
    },
    smallfish: {
        name: "small fish",
        icon: {
            emoji: "🐟",
            offset: [0, 0],
        },
        description: "Address has more than 10 Ether",
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#19e6c4",
        },
        priority: 1,
    },
    shrimp: {
        name: "shrimp",
        icon: {
            emoji: "🦐",
            offset: [0, 0],
        },
        description: "Address has more than 5 Ether",
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#19e6c4",
        },
        priority: 1,
    },
    hodler: {
        name: "hodler",
        icon: {
            emoji: "",
            offset: [0, 0],
        },
        description: "Address has not made a transfer in 90 days",
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#19e6c4",
        },
        priority: 1,
    },
    active: {
        name: "active",
        icon: {
            emoji: "",
            offset: [0, 0],
        },
        description: "Address has made a transfer in the past 90 days",
        colors: {
            nodeColor: "#bafff4",
            textColor: "black",
            pillColor: "#19e6c4",
        },
        priority: 1,
    },
};

module.exports = {
    formatCurrency: formatCurrency,
    formatNumber: formatNumber,
    renderIcon: renderIcon,
    getColors: getColors,
    dotProduct: dotProduct,
    multiply: multiply,
    angle: angle,
    degrees: degrees,
    radians: radians,
    tags: tags,
    getTag: getTag,
    tagExists: tagExists,
    addTag: addTag,
    hasTag: hasTag,
    removeTag: removeTag,
    replaceAll: replaceAll,
    formatSource: formatSource,
    hexToDecimal: hexToDecimal,
};
