import React, { Component } from "react";
import Graph from "./Graph.js";
import Top from "./Top.js";
import AccountInfo from "./AccountInfo.js";
import Ethtective from "../utils/ethtective";
import KeyHandler, { KEYPRESS } from "react-key-handler";
import "../css/App.css";

const ethtective = new Ethtective({});
let hoveredNode = null;
let hoveredLink = null;

class App extends Component {
    state = {
        data: {
            nodes: [],
            links: []
        },
        selectedAddress: "",
        selected: null,
        hoveredNode: null,
        loading: false,
        started: false,
        isScanning: false
    };

    componentDidMount = () => {
        ethtective.setOnDirty(this.onDirty);
        //get address params
        if (this.props.match.params.search) {
            let query = this.props.match.params.search;
            if (ethtective.isAddress(query)) {
                this.setState({ selectedAddress: query });
                this.onScan(query);
            } else if (ethtective.isTransaction(query)) {
                this.scanTx(query);
            } else if (ethtective.isEns(query)) {
                this.setState({ selectedAddress: query });
                this.onScan(query);
            }
        }
        if (this.props.match.params.address) {
            let search = this.props.match.params.address;
            for (let s of search.split("&")) {
                if (s !== "") {
                    this.setState({ selectedAddress: s });
                    this.onScan(s);
                }
            }
            let marked = this.props.match.params.marked;
            if (marked)
                for (let s of marked.split("&")) {
                    if (s !== "") {
                        let account = ethtective.getAccount(s);
                        account.marked = true;
                    }
                }
        } else if (this.props.match.params.txhash) {
            let hash = this.props.match.params.txhash;
            this.scanTx(hash);
        }
    };

    scanTx = hash => {
        ///should validate input here
        this.setState({
            started: true,
            isScanning: true,
            selectedAddress: hash
        });
        ethtective.scanTx(hash).then(address => {
            console.log(address);
            this.onRefresh();
            let link = ethtective.getLinkByTxHash(hash);
            this.selectLink(link);
            this.setState({
                isScanning: false
            });
            // this.onScan(address, true);
        });
        console.log(hash);
    };

    handleClick = e => {
        if (e.button === 2 && hoveredNode) {
            e.preventDefault();
            this.onMark(hoveredNode.address);
        }
    };

    onRefresh() {
        this.setState({
            data: {
                nodes: ethtective.accounts,
                links: ethtective.links
            }
        });
    }

    onSetAddress = (address) => {
        console.warn(address);
        this.setState({ selectedAddress: address });
    }

    onDirty = () => {
        this.onSelect(this.state.selectedAddress);
    };

    onClear = () => {
        this.setState({ selectedAddress: "" });
        ethtective.clear();
        this.onRefresh();
        this.refreshUrl();
    };

    onSearch = address => {
        if (address === undefined || address === "") return;
        this.onClear();
        if (address.length === 66) this.scanTx(address);
        else this.onScan(address, true);
    };

    onAdd = address => {
        if (address === undefined || address === "") return;
        if (address.length === 66) this.scanTx(address);
        else this.onScan(address, true);
    };

    onScan = (address, fromSearch = false) => {
        console.log(address);
        this.setState({ started: true, isScanning: true });
        if (this.state.data.nodes.length === 0) {
            //@TODO center on the first node to make sure user sees what's going on
        }
        if (address.length === 66) {
            this.scanTx(address);
            return;
        }
        this.onSelect(address);
        ethtective.scan(address).then(response => {
            if (!response) return;
            this.onSelect(response.address);
            this.setState({
                data: {
                    nodes: ethtective.accounts,
                    links: ethtective.links
                },
                isScanning: false
            });
            this.refreshUrl();
        });
    };

    setScanning = () => {
        this.setState({ started: true, isScanning: true });
    };

    refreshUrl = () => {
        this.props.history.replace("");
        let url = "address/";
        let marked = "";
        ethtective.accounts.forEach(account => {
            if (account.transactionsScanned) url += account.address + "&";
            if (account.marked) marked += account.address + "&";
        });
        this.props.history.replace(
            url + (marked !== "" ? "/marked/" + marked : "")
        );
    };

    onHoverNode = node => {
        hoveredNode = node;
        var element = document.getElementById("body");
        if (hoveredNode) element.classList.add("hovered");
        else element.classList.remove("hovered");
    };

    onHoverLink = link => {
        hoveredLink = link;
        var element = document.getElementById("body");
        if (hoveredLink) element.classList.add("hovered");
        else element.classList.remove("hovered");
    };

    centerOnNode = address => {
        let node = ethtective.getAccount(address);
        this.graph.centerOnNode(node);
    };

    onTyping = address => {
        this.setState({selectedAddress: address});
    }

    onSelect = address => {
        this.setState({
            selectedAddress: address,
            selected: ethtective.getAccount(address, false)
        });
    };

    selectLink = link => {
        console.log(link);
        this.setState({
            selected: link
        });
    };

    onMark = address => {
        let node = ethtective.getAccount(address);
        node.marked = !node.marked;
        this.refreshUrl();
    };

    onSetName = name => {
        console.log(name + ' ' + this.state.selectedAddress);
        if (this.state.selected) {
            let node = this.state.selected;
            node.name = name;
            console.log('yes');
        }
    };

    onDelete = address => {
        this.setState({ selectedAddress: "" });
        ethtective.removeAccount(address);
        this.onRefresh();
    };

    render() {
        return (
            <div className={`App ${hoveredNode ? "hovered" : ""}`}>
                <KeyHandler
                    keyEventName={KEYPRESS}
                    keyValue=" "
                    onKeyHandle={() =>
                        this.centerOnNode(this.state.selectedAddress)
                    }
                />
                <KeyHandler
                    keyEventName={KEYPRESS}
                    keyValue="m"
                    onKeyHandle={() => this.onMark(this.state.selectedAddress)}
                />
                <KeyHandler
                    keyEventName={KEYPRESS}
                    keyValue="d"
                    onKeyHandle={() =>
                        this.onDelete(this.state.selectedAddress)
                    }
                />
                <Graph
                    ref={el => {
                        this.graph = el;
                    }}
                    selectedAddress={this.state.selectedAddress}
                    selected={this.state.selected}
                    ethtective={ethtective}
                    scan={this.onScan}
                    data={this.state.data}
                    select={this.onSelect}
                    selectLink={this.selectLink}
                    onClick={this.handleClick}
                    onHoverNode={node => this.onHoverNode(node)}
                    onHoverLink={link => this.onHoverLink(link)}
                />
                <Top
                    ref={el => {
                        this.top = el;
                    }}
                    selectedAddress={this.state.selectedAddress}
                    search={this.onSearch}
                    select={this.onSelect}
                    setName={this.onSetName}
                    clear={this.onClear}
                    started={this.state.started}
                    setAddress={this.onSetAddress}
                    isScanning={this.state.isScanning}
                    startScanning={this.setScanning}
                    onTyping={this.onTyping}
                    add={this.onAdd}
                    nodes={this.state.data.nodes}
                />
                <AccountInfo
                    ref={el => {
                        this.accountInfo = el;
                    }}
                    selectedAddress={this.state.selectedAddress}
                    selected={this.state.selected}
                    ethtective={ethtective}
                    scan={this.onScan}
                    data={this.state.data}
                    select={this.onSelect}
                    className={"handle"}
                />
            </div>
        );
    }
}

export default App;
