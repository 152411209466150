import React from "react";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "../css/Donations.css";
import { Jazzicon } from '@ukstv/jazzicon-react';
import styled from '@emotion/styled';

const ModifiedJazzicon = styled(Jazzicon)({
  width: 13,
  height: 13,
});

const styles = theme => ({
  lightTooltip: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[2],
    fontFamily: "Hack",
    fontSize: 9,
  },
  absolute: {
    position: "absolute",
    top: "3px",
  },
});

class Donations extends React.Component {
  state = {
    donationAmount: 0.01,
    askForDonations: false,
    account: null,
    personalAddress: this.props.address
  };

  static getDerivedStateFromProps(props, state) {
    if (state.personalAddress !== props.address) {
        return {personalAddress: props.address};
    }
    return null;
  }

  changeDonationAmount = e => {
    console.log("change");
    this.setState({ donationAmount: e.target.value });
  };

  // handleClick = () => {
  //   var user_address = web3.eth.accounts[0];
  //   console.log(user_address);
  //   let donation = this.state.donationAmount;
  //   web3.eth.sendTransaction(
  //     {
  //       to: "0x09ca59e18c58f25b092a0f2670928f5d0656a331",
  //       from: this.state.account.address,
  //       value: web3.toWei(donation, "ether"),
  //     },
  //     function(err, transactionHash) {
  //       if (err) return console.log("Darn!: " + err.message);

  //       // If you get a transactionHash, you can assume it was sent,
  //       // or if you want to guarantee it was received, you can poll
  //       // for that transaction to be mined first.
  //       console.log("Thank you for your contribution to Ethtective!");
  //     },
  //   );
  // };

  donateWithMetaMask() {
    // needs input amount option
    return (
      <div className={this.state.askForDonations ? "showDonations" : ""}>
        <span id='connectedWallet'>{this.state.personalAddress}</span>
        <span role="img" aria-labelledby="unicorn">
          🦄
        </span>{" "}
        <Input
          type="number"
          value={this.state.donationAmount.toFixed(3)}
          onChange={this.changeDonationAmount}
          endAdornment={<InputAdornment position="end">Ξ</InputAdornment>}
        />
        &nbsp;
        <Button className="btn btn-default" onClick={this.handleClick}>
          Donate with MetaMask
        </Button>
        {/* &nbsp;
        <span role="img" aria-labelledby="heart">
          <a href="http://cafe.ethtective.com">❤️</a>
        </span> */}
      </div>
    );
  }

  donateWithoutMetaMask() {
    const address = this.state.personalAddress !== "" ? <><div onClick={() => this.props.web3()} id='connectedWallet'><div className ='walletText'>{this.state.personalAddress}</div><ModifiedJazzicon address={'0xBAc675C310721717Cd4A37F6cbeA1F081b1C2a07'} /></div></> : <></>;
    return (
      <div className="donations">
        {address}        
        <span role="img">
          <a
            href="https://canary.ethtective.com/address/0x09ca59e18c58f25b092a0f2670928f5d0656a331"
            alt="Ethtective Canary Mode"
          >
            <span role="img" aria-label="unicorn">🦄</span>
          </a>
        </span>
        {/* <a
          href="http://cafe.ethtective.com"
          alt="Metadata Cafe"
          target="_blank"
        >
          ❤️
        </a> */}
      </div>
    );
  }

  componentDidMount = async () => {
    setInterval(() => {
      this.setState({ askForDonations: true });
    }, 5000);
    setInterval(() => {
      let donation = parseFloat(this.state.donationAmount) + 0.01;
      this.setState({
        donationAmount: donation,
      });
    }, 180000);
  };

  render() {
    const donationType = this.donateWithoutMetaMask();
    return <><div id="donations">{donationType}</div></>;
  }
}

Donations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Donations);
