import Eth from "ethjs";
import abi from "./ABI_LuckyList.json";
const eth = new Eth(
    new Eth.HttpProvider(
        `https://eth-mainnet.alchemyapi.io/v2/${process.env.react_app_alchemy_api}`,
    ),
);

const used = [];
const cache = [];

export default class LuckyList {
    constructor() {
        this.contract_address = "0x470e5d1a483a004a947a059f69c4b60499188b3d";
        this.contract = eth.contract(abi).at(this.contract_address);
        //precache some lucky addresses
        this.fillCache();
    }

    async getRandom() {
        if (cache.length > 0) {
            let add = cache.shift();
            used.push(add);
            return add;
        } else {
            this.fillCache();
            return this.getLucky();
        }
    }

    async getLucky(cache = false) {
        const current_index = await this.contract.getIndex();
        // console.log(current_index[0].toNumber());
        const randomIndex = Math.floor(
            Math.random() * (current_index[0].toNumber() - 1),
        );
        // console.log(randomIndex);

        return this.contract
            .getLucky(randomIndex)
            .then(result => {
                // console.log(result);
                if (result[0]) {
                    if (!cache) used.push(result[0]);
                    return result[0];
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    async fillCache() {
        for (let i = 0; i < 2; i++) {
            await this.addCache();
        }
    }

    async addCache() {
        const newAddress = await this.getLucky(true);
        if (used.indexOf(newAddress) === -1 && cache.indexOf(newAddress) === -1)
            cache.push(newAddress);
    }
}