import fromKapsule from "react-kapsule";
import ForceGraph2DKapsule from "force-graph";
import { formatNumber, renderIcon, getTag, hasTag } from "../utils/Utils";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import React, { Component } from "react";
import "../css/App.css";

TimeAgo.locale(en);
const timeAgo = new TimeAgo("en-US");

const ForceGraph2D = fromKapsule(
    ForceGraph2DKapsule,
    undefined,
    ["d3Force", "stopAnimation", "centerAt", "zoom"], // bind methods
);

let biggestamount = 0;
let biggestlink = 0;
let selectedNode = null;
let selected = null;
let hoveredNode = null;
let hoveredLink = null;
let zoomLevel = 7;
let canvas = null;
let opacity = 0;
let linkopacity = 0;
let tokenopacity = 0;
let currentFrame = 0;
let time = 0;

function timer(timestamp) {
    time += 0.004;
    window.requestAnimationFrame(timer);
}

timer(0);

class Graph extends Component {
    state = {
        address: "",
    };

    static getDerivedStateFromProps(props, state) {
    let newstate = {};
    if (state.address !== props.selectedAddress) {
        selectedNode = props.ethtective.getAccount(
        props.selectedAddress,
        false,
        );
        newstate = {...newstate, address: props.selectedAddress };
        // this.graph.centerAt(node.x, node.y, 1200);
    }
    if (selected !== props.selected) {
        selected = props.selected;
    }
    // Return null to indicate no change to state.
    return newstate;
    }

    setCanvasClickHandler(ctx) {
        canvas = ctx;
        canvas.addEventListener(
            "contextmenu",
            function(e) {
                e.preventDefault();
            },
            false,
        );
        canvas.addEventListener("mousedown", e => {
            this.props.onClick(e);
        });
    }

    _handleLinkHover = (link, prevlink) => {
        if (link) {
            hoveredLink = link;
        } else hoveredLink = null;
        this.props.onHoverLink(hoveredLink);
    };

    _handleLinkClick = link => {
        this.props.selectLink(link);
    };

    _handleHover = (node, prevnode) => {
        hoveredNode = null;
        if (node) {
            hoveredNode = node;
            node.isHovered = true;
        }
        if (prevnode) prevnode.isHovered = false;
        this.props.onHoverNode(hoveredNode);
    };

    _handleClick = node => {
        if (!node.transactionsScanned) {
            node.scanningTransactions = true;
            this.props.scan(node.address);
        }
        this.props.select(node.address);
        console.log(node);
    };

    centerOnNode = node => {
        if (node) this.graph.centerAt(node.x, node.y, 500);
    };

    componentDidMount() {
        if (this.graph) {
            this.graph.zoom(3.4, 0);
            this.setCanvasClickHandler(
                document
                    .getElementById("graphContainer")
                    .getElementsByTagName("canvas")[0],
            );
        }
    }

    nodeToolTip = node => {
        let tag = getTag(node);
        return `<div class='account ${
            node.name ? "named" : ""
        }'><p class='balance'>${
            node.hasTag("unknown")
                ? "Unknown | Click to load"
                : node.amount
                    ? "Ξ " + this.formatTooltip(node.amount, 4)
                    : "Ξ 0"
        } ${tag.icon.emoji}</p><p class='title ${
            node.name ? "named" : ""
        }' style='color:${tag.colors.textColor}'>${
            node.name ? node.name : node.address
        }</p><p class='type ${getTag(node).name}' style='background:${
            tag.colors.pillColor
        }; color:white'>${tag.name}</p></div>`;
    };

    nodeSize(node) {
        return node.amount
            ? node.amount > 0 || node.type === "contract"
                ? Math.log(
                      Math.max(
                          node.type === "contract" ? 10 : 1,
                          node.amount / 2,
                      ),
                  ) + 2
                : 2
            : 2;
    }

    linkSize(link) {
        let size = Math.min(Math.log(Math.max(1, link.amount * 3)) + 3, 20);
        return size;
    }

    linkLength(link) {
        let t = link.target;
        let s = link.source;
        let dist = Math.sqrt(Math.pow(s.x - t.x, 2) + Math.pow(s.y - t.y, 2));
        return dist;
    }

    linkMarked(link) {
        return link.source.marked && link.target.marked;
    }

    linkColor(link) {
        let opacity = Math.max(0.3, 1 - link.getAgeDelta());
        if (this.linkMarked(link)) return "#ffa8c7";
        if (link === hoveredLink) return "#14ffd6";
        if (link === selected && link.isSuccess()) return "#14ffd6";
        if (link.isPending()) return "#f8f8f8";
        if (link.isSuccess()) return `rgba(230,230,230,${opacity})`;
        else return `rgba(240,200,200,${opacity})`;
    }

    arrowColor(link) {
        return link.source.marked && link.target.marked
            ? "#ef6898"
            : link.isPending()
                ? "#14ffd6"
                : link === hoveredLink || link === selected
                    ? "#12e8c2"
                    : "#d3d3d3";
    }

    linkArrowSize(link) {
        let sin = Math.sin(time * 15) + 1;
        return link === hoveredLink || this.linkMarked(link)
            ? 4.2 + sin * 0.1
            : 4; //size;
    }

    linkArrowPos(link) {
        let sin = ((time / this.linkLength(link)) * 24) % 1;
        return link === hoveredLink ||
            link === selected ||
            link.isPending() ||
            this.linkMarked(link)
            ? sin
            : 1; //size;
    }

    formatTooltip = (value, dec = 4) => {
        let s = formatNumber(value, dec);
        dec += 1;
        if (value >= 1)
            return (
                '<span className="amt">' +
                s.slice(0, s.length - dec) +
                '<span class="fraction">' +
                s.slice(-dec) +
                "</span></span>"
            );
        else
            return (
                '<span className="amt">' +
                '<span class="fraction">' +
                s.slice(0, s.length - dec) +
                "</span>" +
                s.slice(-dec) +
                "</span>"
            );
    };

    linkToolTip(link) {
        let transactions = "";
        for (let tx of link.transactions) {
            let date = new Date(tx.timestamp * 1000);
            let name =
                tx.denomination.length > 5
                    ? tx.denomination.substring(0, 5) + "..."
                    : tx.denomination.substring(0, 5);
            transactions += `<tr class="transaction"><td class="denomination">${this.formatTooltip(
                tx.amount,
            )} ${name}</td><td>(${timeAgo.format(
                date,
            )}, ${date.toLocaleString()})</td></tr>`;
        }
        return `<div class='transactions shadow'><table>${transactions}</table></div>`;
    }

    drawTextAlongArc(context, str, centerX, centerY, radius, angle) {
        var len = str.length,
            s;
        context.save();
        context.translate(centerX, centerY);
        context.rotate((-1 * angle) / 2);
        context.rotate((-1 * (angle / len)) / 2);
        for (var n = 0; n < len; n++) {
            context.rotate(angle / len);
            context.save();
            context.translate(0, -1 * radius);
            s = str[n];
            context.fillText(s, 0, 0);
            context.restore();
        }
        context.restore();
    }

    render() {
        const { data } = this.props;
        for (let i = 0; i < data.nodes.length; i++) {
            if (data.nodes[i].amount > biggestamount)
                biggestamount = data.nodes[i].amount;
        }
        for (let i = 0; i < data.links.length; i++) {
            if (data.links[i].amount > biggestlink)
                biggestlink = data.links[i].amount;
        }
        return (
            <div id="graphContainer">
                <div
                    id="graph"
                    className={
                        hoveredNode !== null || hoveredLink !== null
                            ? "hovered"
                            : "hovered"
                    }
                />
                <ForceGraph2D
                    ref={el => {
                        this.graph = el;
                    }}
                    graphData={data}
                    nodeRelSize={4}
                    nodeVal={node => this.nodeSize(node)}
                    onNodeClick={this._handleClick}
                    onNodeHover={(node, prevnode) =>
                        this._handleHover(node, prevnode)
                    }
                    onLinkClick={this._handleLinkClick}
                    onLinkHover={this._handleLinkHover}
                    nodeLabel={node => this.nodeToolTip(node)}
                    linkLabel={link => this.linkToolTip(link)}
                    zoom={(11, 1000)}
                    linkCurvature={0.32}
                    linkDirectionalArrowLength={link =>
                        this.linkArrowSize(link)
                    }
                    linkDirectionalArrowRelPos={link => this.linkArrowPos(link)}
                    linkDirectionalArrowColor={link => this.arrowColor(link)}
                    linkOpacity={1}
                    linkColor={link => this.linkColor(link)}
                    linkWidth={link => this.linkSize(link)}
                    nodeCanvasObject={(node, ctx, globalScale) => {
                        if (!ctx) this.setCanvasClickHandler(ctx);
                        zoomLevel = globalScale;
                        let color = getTag(node).colors.nodeColor;
                        if (
                            hasTag("logo", node) &&
                            !hasTag("scam", node) &&
                            !hasTag("hacker", node)
                        )
                            color = "#fff";
                        if (hoveredNode === node) color = "#14ffd6";
                        if (node.marked) color = "#ef6898";
                        ctx.beginPath();
                        ctx.fillStyle = color;
                        let size = this.nodeSize(node);
                        ctx.arc(node.x, node.y, size, 0, 2 * Math.PI, false);
                        ctx.fill();
                        ctx.lineWidth = selectedNode === node ? 0.1 : 0.25;
                        ctx.strokeStyle =
                            selectedNode === node ? "#848484" : "white";
                        ctx.stroke();
                        ctx.closePath();

                        // loading spinner
                        if (
                            node.scanningTransactions &&
                            !node.transactionsScanned
                        ) {
                            window.requestAnimationFrame(timestamp => {
                                currentFrame = timestamp;
                            });
                            ctx.beginPath();
                            ctx.strokeStyle = "rgba(20, 255, 216, .5)";
                            ctx.arc(
                                node.x,
                                node.y,
                                size + 1.3,
                                ((currentFrame * 0.0036 + 0.3) % 2) * Math.PI,
                                ((currentFrame * 0.0048 + 1.9) % 2) * Math.PI,
                                true,
                            );
                            ctx.lineWidth = 0.5;
                            ctx.stroke();
                            ctx.closePath();
                        } else if (
                            selectedNode === node ||
                            hoveredNode === node
                        ) {
                            ctx.beginPath();
                            ctx.strokeStyle = color;
                            let s =
                                hoveredNode === node
                                    ? size + 1.3 + Math.sin(time * 7) / 1.5
                                    : size + 1.3;
                            ctx.arc(node.x, node.y, s, 0, 2 * Math.PI, true);
                            ctx.lineWidth = 0.5;
                            ctx.stroke();
                            ctx.closePath();
                        }

                        // emoji icon
                        let icon = renderIcon(node);
                        ctx.textAlign = "left";
                        ctx.textBaseline = "middle";

                        //@TODO look at this buggy centering
                        let fontSize = 0.5;
                        ctx.font = `${fontSize}px Source Code Pro`;
                        const iconSize = ctx.measureText(icon);
                        ctx.font = `${size}px Source Code Pro`;
                        if (node.image) {
                            let imgSize = size * 1.4;
                            if (hoveredNode === node)
                                ctx.globalCompositeOperation = "darken";
                            else ctx.globalCompositeOperation = "source-over";
                            ctx.drawImage(
                                node.image,
                                node.x - imgSize / 2,
                                node.y - imgSize / 2,
                                imgSize,
                                imgSize,
                            );
                            ctx.globalCompositeOperation = "source-over";
                        } else {
                            ctx.fillText(
                                renderIcon(node),
                                node.x - (iconSize.width * size) / 2,
                                node.y + (iconSize.width * size) / 6,
                            );
                        }

                        if (zoomLevel > 0) {
                            // amount text
                            fontSize = 3;
                            // let opacity = Math.min(6, zoomLevel - 3.8);
                            // opacity = zoomLevel > 3.8 ? opacity / 6 : 0;
                            opacity = Math.max(
                                Math.min(
                                    0.7,
                                    zoomLevel > 2
                                        ? opacity + 0.008
                                        : opacity - 0.008,
                                ),
                                0,
                            );
                            ctx.fillStyle = `rgba(0,0,0,${opacity})`;
                            ctx.font = `${fontSize}px Hack`;

                            if (node.amount > 0.0001)
                                ctx.fillText(
                                    node.amount && node.amount > 0
                                        ? `Ξ ${formatNumber(node.amount, 4)}`
                                        : "",
                                    node.x + size + 2.5,
                                    node.y + (node.name ? 1.55 : 0),
                                );
                            ctx.font = `${fontSize}px Source Code Pro`;
                            if (node.name)
                                ctx.fillText(
                                    node.name,
                                    node.x + size + 2.5,
                                    node.y - (node.amount > 0.0001 ? 1.55 : 0),
                                );

                            linkopacity = Math.max(
                                Math.min(
                                    0.7,
                                    zoomLevel > 3.5
                                        ? linkopacity + 0.008
                                        : linkopacity - 0.008,
                                ),
                                0,
                            );

                            tokenopacity = Math.max(
                                Math.min(
                                    0.7,
                                    zoomLevel > 5.5
                                        ? tokenopacity + 0.008
                                        : tokenopacity - 0.008,
                                ),
                                0,
                            );
                            ctx.fillStyle = `rgba(0,0,0,${linkopacity})`;
                            ctx.font = `${1.5}px Hack`;
                            let txTextHeight = -4;
                            for (let link of data.links) {
                                if (link.target.address !== node.address)
                                    continue;

                                let t = link.target;
                                let s = link.source;
                                let x = (t.x + s.x) / 2;
                                let y = (t.y + s.y) / 2;

                                let dir = { x: t.x - s.x, y: t.y - s.y };
                                let tx_buffer = [];
                                for (
                                    let i = 1;
                                    i < link.transactions.length + 1;
                                    i++
                                ) {
                                    let tx = link.transactions[i - 1];
                                    let text = tx.amount
                                        ? `${tx.denomination} ${formatNumber(
                                              tx.amount,
                                              4,
                                          )}`
                                        : "";
                                    if (link.transactions.length > 1)
                                        txTextHeight += 4;
                                    const width = ctx.measureText(text).width;
                                    tx_buffer.push({
                                        text: text,
                                        dir: dir,
                                        x: x,
                                        y: y,
                                        width: width,
                                        den:
                                            tx.denomination === "Ξ"
                                                ? true
                                                : false,
                                    });
                                }
                                for (let i = 1; i < tx_buffer.length + 1; i++) {
                                    let tx = tx_buffer[i - 1];
                                    let offset_y =
                                        (txTextHeight / 2 - (i - 1) * 4) / 2;
                                    ctx.fillStyle = `rgba(0,0,0,${
                                        tx.den
                                            ? linkopacity
                                            : tokenopacity * 0.35
                                    })`;
                                    if (link === hoveredLink)
                                        ctx.fillStyle = `rgba(0,0,0,${linkopacity *
                                            2}`;
                                    ctx.fillText(
                                        tx.text,
                                        tx.x - -tx.dir.y * 0.15 - tx.width / 2,
                                        tx.y - tx.dir.x * 0.15 - offset_y,
                                    );
                                }
                            }
                        }
                    }}
                />
            </div>
        );
    }
}

export default Graph;
