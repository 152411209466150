import axios from "axios";
import Eth from "ethjs";
import BigNumber from "bignumber.js";
import contractNames from "./contractNames.js";
import Metadata from "./metadata.js";
import ensReverse from "./ENSReverseLookup.js";
import coinlist from "./coinlist.js";
import LuckyList from "./LuckyList.js";
import { addTag, removeTag, hexToDecimal } from "./Utils.js";

const ensLookup = new ensReverse();
const metadata = new Metadata();
const ethplorer_api = process.env.react_app_ethpl_api;
const etherscan_api = process.env.react_app_escan_api;

export default class Ethtective {
  constructor() {
    this.accounts = [];
    this.transactions = [];
    this.links = [];
    this.luckyList = LuckyList;
    this.etherPrice = 0;
    this.gasPrice = null;
    this.getGasPrice();
    this.getPrice();
  }

  async getGasPrice() {
    return {};
  }

  estimateGasPrice(tx) {
    if (this.gasPrice === {}) {
      console.logWarning("No gas price available for duration estimation");
      return -1;
    }
    // console.log(tx);
  }

  isAddress(addresses) {
    return Eth.isAddress(addresses);
  }

  isTransaction(hash) {
    return hash.match(/^0x([A-Fa-f0-9]{64})$/);
  }

  isEns(addresses) {
    return addresses.match(/^.*\.eth$/);
  }

  async getPrice() {
    return axios
      .get(
        `https://api.etherscan.io/api?module=stats&ohai&action=ethprice&apikey=${etherscan_api}`,
      )
      .then(response => {
        this.etherPrice = parseFloat(response.data.result.ethusd);
        for (let n of this.accounts) {
          n.etherPrice = this.etherPrice;
        }
      });
  }

  //TODO refactor scan function to accept address / ens / transaction

  async scan(address) {
    if (address === undefined) return;
    let ensName = null;
    if (address.toLowerCase().endsWith(".eth")) {
      let res = await ensLookup.lookup(address);
      if (!res || res.length !== 42) return null;
      ensName = address;
      address = res;
    }
    let account = await this.getAccount(address);
    if (!account) return;
    if (account.transactionsScanned) {
      return account;
    }
    return this.findAccount(address).then(() => {
      account.scanningTransactions = true;

      //FIX this is a bit fugly to do here, we do it here because if there is no ENS resolver we still want the ENS address to show
      if (ensName !== null) {
        account.name = ensName;
        addTag("ens", account);
      }

      return this.scanTransactions(account).then(response => {
        return this.scanTokenTransactions(account).then(response => {
          return account;
        });
      });
    });
  }

  async scanTx(hash) {
    return axios
      .get(`https://api.ethplorer.io/getTxInfo/${hash}?apiKey=${ethplorer_api}`)
      .then(response => {
        console.log(Object.assign({}, response.data));
        let tx = [response.data];
        // this.scan(response.data.to);
        // this.scan(response.data.from);
        if (response.data.blockNumber === null) {
          return this.getRawTransaction(hash);
        }
        return this.saveTransactions(tx);
      });
  }

  getRawTransaction = async hash => {
    let d = await axios.get(
      `https://api.etherscan.io/api?module=proxy&action=eth_getTransactionByHash&txhash=${hash}&apikey=${etherscan_api}`,
    );
    console.log(d.data.result);
    let gasCost = hexToDecimal(d.data.result.gasPrice) / 1e9;
    let estimate = await axios.get(
      `https://gasprice-oevudroezf.now.sh/t/${gasCost}`,
    );
    console.log(estimate);
    // let safeLowWait = this.gasPrice.safeLowWait;
    // let safeLow = this.gasPrice.safeLow / 10;
    // console.log(safeLowWait, safeLow, gasCost);
    // let wait = (safeLow / safeLowWait) * gasCost;
    // eth.fromWei(gasCost, "ether");
    // return {
    //   wait: wait,
    //   gas: gasCost,
    //   safeLowWait: safeLowWait,
    //   safeLow: safeLow,
    //   raw: d,
    // };
  };

  async clear() {
    this.accounts = [];
    this.transactions = [];
    this.links = [];
  }

  setOnDirty(fn) {
    this.onDirtyFN = fn;
  }

  async findAccount(address) {
    return axios
      .get(
        `https://api.ethplorer.io/getAddressInfo/${address}?apiKey=${ethplorer_api}`,
      )
      .then(response => {
        let data = response.data;
        let account = this.getAccount(address);
        account.amount = parseFloat(data.ETH.balance);
        account.tokens = data.tokens;
        removeTag("unknown", account);
        return account.scan();
      });
  }

  async scanTransactions(account) {
    return axios
      .get(
        "https://api.ethplorer.io/getAddressTransactions/" +
          account.address +
          "?apiKey=" +
          ethplorer_api,
      )
      .then(response => {
        account.transactionsScanned = true;
        return this.saveTransactions(response.data);
      })
      .catch(error => {
        // console.error(error);
        return error;
      });
  }

  async scanTransactionDetails(tx) {
    if (tx.data === undefined)
      return axios
        .get(
          `https://api.ethplorer.io/getTxInfo/${
            tx.hash
          }?apiKey=${ethplorer_api}`,
        )
        .then(response => {
          // console.log(response.data);
          tx.data = response.data;
          console.log(response.data);
        })
        .catch(err => {});
  }

  async scanTokenTransactions(account) {
    return axios
      .get(
        "https://api.ethplorer.io/getAddressHistory/" +
          account.address +
          "?apiKey=" +
          ethplorer_api,
      )
      .then(response => {
        account.transactionsScanned = true;
        return this.saveTransactions(response.data.operations);
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  async saveTransactions(transactions) {
    let processed = [];
    for (let tx of transactions) {
      if (tx.value == null) continue;
      // console.log(tx);
      tx.amount = parseFloat(tx.value);

      // APIs have different nomenclature for hashes:
      let hash = tx.tokenInfo ? tx.transactionHash : tx.hash;
      if (!this.transactionExists(hash)) {
        let source =
          tx.from === "0x0000000000000000000000000000000000000000"
            ? this.addAccountByAddress(tx.tokenInfo.address)
            : this.addAccountByAddress(tx.from);
        let target = this.addAccountByAddress(tx.to);
        let amount = tx.amount < 0.000001 ? 0 : Number(tx.amount);
        let denomination = "Ξ";
        if (tx.tokenInfo && tx.type !== "approve") {
          let tokenBalance = new BigNumber(tx.amount);
          amount = Number(
            tokenBalance.shiftedBy(-parseInt(tx.tokenInfo.decimals, 10)),
          );
          denomination = tx.tokenInfo.symbol;
        } else {
          // console.log(tx);
        }
        // unsigned int hack
        if (amount === 1.157920892373162e77) amount = 0;
        let linkdata = new Transaction(
          hash,
          amount,
          denomination,
          source,
          target,
        );
        let transaction = Object.assign(tx, linkdata);
        if (!source || !target) return;
        source.transactions.push(transaction);
        target.transactions.push(transaction);
        this.transactions.push(transaction);
        if (transaction.blockNumber === null) {
          // console.log("tx pending");
          // this.getRawTransaction(transaction.hash).then(data => {
          //   console.log(data);
          // });
        }
        processed.push(transaction);
        // console.log(transaction);
      }
    }
    return new Promise((resolve, reject) => {
      if (processed.length > 0) this.mergeTransactions(processed);
      resolve("resolved");
    });
  }

  mergeTransactions(transactions) {
    for (let transaction of transactions) {
      let link = this.getLink(transaction);
      this.addTransactionToLink(link, transaction);
    }
  }

  addLink(transaction) {
    if (!this.linkExists(transaction)) {
      let link = new Link(
        this.links.length + 1,
        transaction.source,
        transaction.target,
      );
      this.addTransactionToLink(link, transaction);
      this.links.push(link);
      return link;
    } else {
      let link = this.getLink(transaction);
      this.addTransactionToLink(link, transaction);
      return link;
    }
  }

  addTransactionToLink(link, transaction) {
    for (let tx of link.transactions) {
      if (tx.hash === transaction.hash) {
        return;
      }
    }
    link.transactions.push(transaction);
    link.size += transaction.amount;
    link.amount = 0;
    for (let tx of link.transactions) {
      link.amount += parseFloat(tx.amount);
    }
    link.name = link.amount.toString();
  }

  getLink(transaction) {
    for (let link of this.links) {
      if (
        link.source === transaction.source &&
        link.target === transaction.target
      ) {
        return link;
      }
    }
    return this.addLink(transaction);
  }

  getLinkByTxHash(hash) {
    for (let link of this.links) {
      for (let tx of link.transactions) {
        if (tx.hash.toLowerCase() === hash.toLowerCase()) return link;
      }
    }
  }

  linkExists(transaction) {
    for (let tx of this.transactions) {
      if (
        tx.source === transaction.source.address &&
        tx.target === transaction.target
      ) {
        return true;
      }
    }
    return false;
  }

  transactionExists(txid) {
    for (let tx of this.transactions) {
      if (tx.hash === txid) return true;
    }
    return false;
  }

  addAccountByAddress(address) {
    if (!Eth.isAddress(address)) return;
    if (this.accountExists(address) === false) {
      let account = new Node(address, this.onDirtyFN, this.etherPrice);
      this.accounts.push(account);
      return account;
    }
    return this.getAccount(address);
  }

  accountExists(address) {
    if (!Eth.isAddress(address)) return;
    for (let account of this.accounts) {
      if (account.address.toUpperCase() === address.toUpperCase()) return true;
    }
    return false;
  }

  getAccount(address, autocreate = true) {
    if (!address) return;
    if (!Eth.isAddress(address)) return;
    for (let account of this.accounts) {
      if (account.address.toUpperCase() === address.toUpperCase())
        return account;
    }
    if (autocreate) return this.addAccountByAddress(address);
  }

  removeAccount(address) {
    let account = this.getAccount(address);
    let unscannedSiblings = [];
    let orphanedTransactions = [];
    // need to do a recursive lookup here if deleting this specific node doesn't break other nodes
    for (let t of account.transactions) {
      if (t.target.address !== address && !t.target.transactionsScanned) {
        unscannedSiblings.push(t.target);
        orphanedTransactions.push(t);
      }
      if (t.source.address !== address && !t.source.transactionsScanned) {
        unscannedSiblings.push(t.source);
        orphanedTransactions.push(t);
      }
    }
    this.links = this.links.filter((link, index, arr) => {
      for (let tx of orphanedTransactions) {
        if (link.transactions.indexOf(tx) > -1) return false;
      }
      return true;
    });
    this.transactions = this.transactions.filter((tx, index, arr) => {
      return orphanedTransactions.indexOf((tx, index, arr)) === -1;
    });
    this.accounts = this.accounts.filter((s, index, arr) => {
      return unscannedSiblings.indexOf(s) === -1 && s.transactions.length !== 0;
    });
    account.tags = ["address", "unknown"];
    account.transactionsScanned = false;
    account.scanningTransactions = false;
    account.transactions = account.transactions.filter(tx => {
      return orphanedTransactions.indexOf(tx) === -1;
    });
    console.log(account);
  }
}

class Node {
  constructor(_address, _dirtyFN, _etherPrice = 0) {
    this.id = _address;
    this.address = _address;
    this.key = _address;
    this.name = "";
    this.amount = 0;
    this.x = 0;
    this.y = 0;
    this.size = 12;
    this.depth = 1;
    this.start = 0;
    this.transactions = [];
    this.tags = ["address", "unknown"];
    this.onDirtyFN = _dirtyFN;
    this.etherPrice = _etherPrice;
  }

  getTotal() {
    let total = 0;
    total += this.amount * this.etherPrice;
    if (this.tokens)
      for (let t of this.tokens) {
        if (!t.tokenInfo.price) continue;
        let tokenBalance = new BigNumber(t.balance);
        let amt = tokenBalance.shiftedBy(-parseInt(t.tokenInfo.decimals, 10));
        total += amt * parseFloat(t.tokenInfo.price.rate);
      }
    return total;
  }

  async scan() {
    return axios
      .get(
        `https://api.ethplorer.io/getAddressInfo/${
          this.address
        }?apiKey=${ethplorer_api}`,
      )
      .then(response => {
        let data = response.data;
        this.amount = parseFloat(data.ETH.balance);
        removeTag("unknown", this);
        this.isContract();
        this.getContractSource();
        this.isMiner();
        this.isWhale();
        this.isScam();
        this.isEns();
        this.hasMetadata();
        this.isKnownAddress();
        // console.log(this, "scanned");
        return this;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  hasTag(tag) {
    return this.tags.indexOf(tag) > -1;
  }

  async isEns() {
    return ensLookup.resolve(this.address).then(ensname => {
      if (ensname) {
        console.log(ensname);
        this.name = ensname;
        addTag("ens", this);
      }
      if (this.onDirtyFN) {
        this.onDirtyFN();
      }
    });
  }

  async isScam() {
    return axios
      .get(`https://etherscamdb.info/api/check/${this.address}`)
      .then(response => {
        if (response.data.result === "neutral") return;
        this.scam = response.data.entries;
        this.name = "SCAM: " + response.data.entries["0"].description;
        addTag("scam", this);
        if (this.onDirtyFN) {
          this.onDirtyFN();
        }
      });
  }

  async hasMetadata() {
    metadata
      .getAddress(this.address)
      .then(result => {
        if (
          result &&
          result.address.toUpperCase() === this.address.toUpperCase()
        ) {
          this.parseMetadata(result);
        }
      })
      .catch(err => {});
    metadata.getRopsten(this.address).then(result => {
      if (
        result &&
        result.address.toUpperCase() === this.address.toUpperCase()
      ) {
        this.parseMetadata(result);
      }
    });
  }

  async parseMetadata(result) {
    this.name = result.data.metadata.name;
    if (result.data.metadata.logo) {
      let image = new Image();
      image.src = result.data.metadata.logo;
      this.image = image;
      addTag("logo", this);
    }
    if (
      result.data.metadata.reputation.status.toLowerCase() === "blocked" ||
      result.data.metadata.reputation.category.toLowerCase() === "scam"
    ) {
      addTag("scam", this);
    }
    if (result.data.metadata.reputation.category.toLowerCase() === "exchange") {
      addTag("exchange", this);
    }
    if (result.data.metadata.reputation.category.toLowerCase() === "hacker") {
      addTag("hacker", this);
    }
    this.metadata = result.data.metadata;
    if (this.onDirtyFN) {
      this.onDirtyFN();
    }
  }

  async isContract() {
    return axios
      .get(
        `https://api.ethplorer.io/getAddressInfo/${
          this.address
        }?apiKey=${ethplorer_api}`,
      )
      .then(response => {
        if (
          response.data.tokenInfo !== undefined &&
          this.metadata === undefined
        ) {
          this.tokenInfo = response.data.tokenInfo;
          this.name = this.tokenInfo.name;
          addTag("token", this);

          //find additional data in coinlist
          if (
            coinlist.Data[this.tokenInfo.symbol] &&
            coinlist.Data[this.tokenInfo.symbol].ImageUrl
          ) {
            // console.log(coinlist.Data[this.tokenInfo.symbol]);
            this.image = new Image();
            addTag("logo", this);
            this.image.src =
              "https://www.cryptocompare.com" +
              coinlist.Data[this.tokenInfo.symbol].ImageUrl;
            this.name = coinlist.Data[this.tokenInfo.symbol].CoinName;
          }
        }
        if (response.data.contractInfo !== undefined) {
          this.contractInfo = response.data.contractInfo;
          addTag("contract", this);
        }
        if (this.onDirtyFN) {
          this.onDirtyFN();
        }
      });
  }

  async isMiner() {
    return axios
      .get(
        `https://api.etherscan.io/api?module=account&action=getminedblocks&address=${
          this.address
        }&apikey=${etherscan_api}`,
      )
      .then(response => {
        if (response.data.result && response.data.result.length > 0) {
          addTag("miner", this);
        }
        if (this.onDirtyFN) {
          this.onDirtyFN();
        }
      });
  }

  async isWhale() {
    let tag = "";
    if (this.amount < 0.0001) addTag("empty", this);
    if (this.amount >= 100000) tag = "bluewhale";
    //"🐳";
    else if (this.amount >= 10000) tag = "whale";
    //"🐋";
    else if (this.amount >= 1000) tag = "kraken";
    //"🦑";
    else if (this.amount >= 200) tag = "shark";
    //"🦈";
    else if (this.amount >= 50) tag = "dolphin";
    //"🐬";
    else if (this.amount >= 20) tag = "fish";
    //"🐠";
    else if (this.amount >= 10) tag = "smallfish";
    //"🐟";
    else if (this.amount >= 5) tag = "shrimp";
    if (tag !== "") addTag(tag, this);
  }

  async isHodler() {
    if (this.amount >= 0.0001) {
      let recentTransactions = false;
      for (let t of this.transactions) {
        let timestamp = new Date().getTime() - 90 * 24 * 60 * 60 * 1000;
        if (t.timestamp * 1000 > timestamp) {
          recentTransactions = true;
        }
      }
      if (!recentTransactions) addTag("hodler", this);
      if (recentTransactions) addTag("active", this);
    }
  }

  async isKnownAddress() {
    for (let c of contractNames) {
      if (this.address.toUpperCase() === c.address.toUpperCase()) {
        this.name = c.name;
        if (c.type === "scam") {
          addTag("scam", this);
        }
        if (c.type === "hacker") {
          addTag("hacker", this);
        }
        if (c.type === "exchange") {
          addTag("exchange", this);
        }
        return;
      }
    }
  }

  async getContractSource() {
    return axios
      .get(
        `https://api.etherscan.io/api?module=contract&action=getsourcecode&address=${
          this.address
        }&apikey=${etherscan_api}`,
      )
      .then(response => {
        if (
          response.data.result.length > 0 &&
          response.data.result[0].ABI !== "Contract source code not verified"
        ) {
          addTag("contract", this);
          if (!this.contractInfo) this.contractInfo = {};
          this.contractInfo.source = response.data.result[0];
          if (this.metadata === undefined && this.name.length === 0)
            this.name = `${this.contractInfo.source.ContractName} contract`;

          if (this.contractInfo.source.SourceCode)
          {

            let ercType = this.contractInfo.source.SourceCode.match(
            /(CONTRACT ERC[0-9]*)/i,
            );
            if (ercType) {
              let type = ercType[0].match(/(ERC[0-9]*)/i);
              addTag(type[0], this);
            }
            
            ercType = this.contractInfo.source.SourceCode.match(/(ERC\w[0-9]*)/i);
            if (ercType) {
              for (let t of ercType) {
                addTag(t, this);
              }
            }
          }
        }
        // console.log(response);
      });
  }
}

class Transaction {
  constructor(_key, _amount, _denomination, _source, _target) {
    this.key = _key;
    this.id = _key;
    this.size = 0;
    this.amount = parseFloat(_amount);
    this.denomination = _denomination;
    this.value = 0;
    this.source = _source;
    this.target = _target;
    this.x = 0;
    this.y = 0;
    this.hash = _key;
  }
}

class Link {
  constructor(_key, _source, _target) {
    this.key = _key;
    this.id = _key;
    this.size = 0;
    this.amount = 0;
    this.value = 0;
    this.source = _source;
    this.target = _target;
    this.x = 0;
    this.y = 0;
    this.transactions = [];
  }

  isSuccess() {
    for (let tx of this.transactions) {
      if ((tx.data && tx.data.success === false) || tx.blockNumber === null)
        return false;
    }
    return true;
  }

  isPending() {
    for (let tx of this.transactions) {
      if (tx.blockNumber === null) return true;
    }
    return false;
  }

  // returns a delta, between now and 1 year old
  getAgeDelta() {
    const year = 24 * 60 * 60 * 1000 * 100;
    let mostRecent = 0;
    for (let tx of this.transactions) {
      if (tx.timestamp > mostRecent) mostRecent = tx.timestamp;
    }
    let diff = (new Date().getTime() - mostRecent * 1000) / year;
    // console.log(diff);
    return diff;
  }
}
