import React, { Component } from "react";
import Donations from "./Donations.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import { edit, fingerprint, tx, ens } from "./Icons";
import LuckyList from "../utils/LuckyList";
import Eth from "ethjs";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal from "web3modal";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        1: `https://eth-mainnet.alchemyapi.io/v2/${process.env.react_app_alchemy_api}`,
      },
    }
  }
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions // required
});

let provider = null; //await web3Modal.connect();

let web3 = null; //new Web3(provider);

const luckyList = new LuckyList();

const theme = createMuiTheme({
  palette: {
    primary: { light: "#00ffd9", main: "#00ffd9", dark: "#00ffd9" } // Purple and green play nicely together.
  }
});

class Top extends Component {
  state = {
    address: "",
    searchIcon: fingerprint,
    luckyText: "I'm feeling lucky",
    personalAddress: "",
    handleIcon: this.handleIcon
  };

  componentDidMount = async () => {
    // if (window.ethereum) {
    //   this.checkNewMask();
    // }
    if (window.ethereum && provider) {
      this.getAccounts();
      window.ethereum.on('accountsChanged', (accounts) => {
        this.getAccounts();
      });
      return;
    }
  }

  connectWeb3 = async () => {
    if (provider)
    {
      console.log("existing provider");
      console.log(web3Modal);
      console.log(provider);
    }

    web3Modal.clearCachedProvider();
    provider = await web3Modal.connect();
    web3 = new Web3(provider);

    if (web3) {
      console.log("web3 enabled");
      console.log(web3);
      const accounts = await web3.eth.getAccounts();
      console.log(accounts);
      this.setState({ personalAddress: accounts[0], address: accounts[0]});
      this.props.setAddress(accounts[0]);
      return accounts[0];
    }
    // if (window.ethereum) {
    //   console.log(window.ethereum);
    // }
    return await this.checkNewMask();
  };

  getAccounts = async () => {
    const newAccounts = await window.ethereum.enable();
    this.setState({ personalAddress: newAccounts[0], address: newAccounts[0] });
    this.props.setAddress(newAccounts[0]);
    console.log(this.state.address);
  }

  checkNewMask = async () => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        this.getAccounts();
      });
      return;
    }
    //   console.log('window ethereum');
    //   console.log(ethereum);
    //   console.log(window.web3);
    //   const accounts = await window.web3.eth.getAccounts();
    //   console.log(accounts);
    //   this.setState({ personalAddress: ethereum[0] });
    // }
    if (!web3) return "";

    const accounts = await web3.eth.getAccounts();
    console.log(accounts);
    this.setState({ personalAddress: accounts[0] });

    return accounts[0];
  };

  isAddressAvailable() {
    if (!web3) return false;
    return !web3.eth.accounts;
  }

  static getDerivedStateFromProps(props, state) {
    let newState = state.handleIcon(props.selectedAddress, state);
    newState = { address: props.selectedAddress, ...newState };
    return newState;
  }

  handleKeyPress = e => {
    if (e.key === "Enter") {
      // console.log(this.state.address);

      // Check if entered string is an address or a piece of text
      if (this.state.address.toLowerCase().indexOf("0x") === 0) {
        this.handleSearch(this.state.address);
      } else if (this.state.address.endsWith(".eth")) {
        this.handleSearch(this.state.address);
      } else {
        // name node accordingly
        this.props.setName(this.state.address);
      }
    }
  };

  handleChange = (e, value) => {
    // console.log(e.target.value);
    let newState = this.handleIcon(e.target.value, this.state);
    this.props.onTyping(e.target.value);
    this.setState({ address: e.target.value, ...newState });
  };

  handleSearch = async e => {
    //@TODO add checksum and check if valid address
    if (
      (this.state.address.toLowerCase().indexOf("0x") === 0 &&
      Eth.isAddress(this.state.address)) || this.state.address.endsWith(".eth") ||
      (this.state.address.toLowerCase().indexOf("0x") === 0 &&
      this.state.address.length === 66)
    ) {
      if(this.props.nodes.length === 0)
      {
        this.props.search(this.state.address);
      }
      else {
        this.props.add(this.state.address);
      }
    }
  };

  handleIcon(newAddress, state) {
    let newState = {};
    let address = newAddress ? newAddress : state.address;
    if (!address) return newState;
    if (address.toLowerCase().indexOf("0x") === 0) {
      if (Eth.isAddress(address)) {
        newState = {...newState, searchIcon: fingerprint, iconColor: "#00ffd9" };
      } else if (address.length === 66) {
        newState = {...newState, searchIcon: tx, iconColor: "#00ffd9" };
      } else {
        newState = {...newState, iconColor: "#f60" };
      }
    } else if (address.endsWith(".eth")) {
      newState = {...newState, searchIcon: ens };
      newState = {...newState, iconColor: "#00ffd9" };
    } else if (address.length > 1) {
      newState = {...newState, searchIcon: edit };
    }
    return newState;
  }

  openMe = async e => {
    if (this.state.personalAddress !== "") {
      return this.props.search(this.state.personalAddress);  
    }
    let address = await this.connectWeb3();
    return this.props.search(address);
  };

  openAdd = async e => {
    return this.props.add(this.state.address);
  };

  openLucky = async e => {
    this.props.startScanning();
    let lucky = await luckyList.getRandom();
    return this.props.search(lucky);
  };

  openEtherscan = async e => {
    window.open("https://etherscan.io/address/" + this.state.address, "_blank");
  };

  openGoogle = async e => {
    window.open(
      "https://www.google.com/search?q=" + this.state.address,
      "_blank"
    );
  };

  render() {
    let showSpinner = this.props.isScanning ? (
      <MuiThemeProvider theme={theme}>
        <CircularProgress size={40} id="spinner" />
      </MuiThemeProvider>
    ) : (
      ""
    );

    return (
      <>
      <div id="Top" className={this.props.started ? "searched" : ""}>
        <a className="logo" href="/" style={{ pointerEvents: "auto" }}>
          <div id="logo">TECTIVE</div>
        </a>
        <a className="logo" href="/" style={{ pointerEvents: "auto" }}>
          <div id="MiniLogo">TECTIVE</div>
        </a>
        <div id="inputbox">
          <MuiThemeProvider theme={theme}>
            <Input
              id="name-simple"
              value={this.state.address}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              spellCheck="false"
              inputProps={{
                "aria-label": "Description"
              }}
              autoFocus
              placeholder=""
              autocomplete="off"
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  {showSpinner}
                  <IconButton onClick={this.handleSearch}>
                    <SvgIcon
                      style={{ fontSize: 20, color: this.state.iconColor }}
                    >
                      {this.state.searchIcon}
                    </SvgIcon>
                  </IconButton>
                </InputAdornment>
              }
            />
          </MuiThemeProvider>
          <div id="toolbar">
            <Button size="small" onClick={this.openMe}>
              {window.ethereum && this.state.personalAddress !== "" ? "Wallet Address" : "Connect Wallet"}
            </Button>
            <Button size="small" onClick={this.openLucky}>
              {this.state.luckyText}
            </Button>
            <Button size="small" onClick={this.props.clear}>
              Reset
            </Button>
            <Button size="small" onClick={this.openAdd}>
              Add
            </Button>
          </div>
          <div className={"guide" + (this.props.started ? " searched" : "")}>
            <p>
              <b>
                Ethtective 2.4.0 | Alchemy & Walletconnect
              </b>
            </p>
          </div>
        </div>
      </div>
      <Donations web3={this.connectWeb3} address={this.state.personalAddress} />
      </>
    );
  }
}

export default Top;
