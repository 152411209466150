import Eth from "ethjs";
import abi from "../abi/metadata.json";
import ropsten_abi from "../abi/metadata.json";
import IPFS from "ipfs-mini";

const eth = new Eth(
    new Eth.HttpProvider(
        `https://eth-mainnet.alchemyapi.io/v2/${process.env.react_app_alchemy_api}`,
    ),
);
const ropsten = new Eth(
    new Eth.HttpProvider(
        `https://eth-ropsten.alchemyapi.io/v2/${process.env.react_app_alchemy_ropsten}`,
    ),
);

const ipfs = new IPFS({
    host: "ipfs.infura.io",
    port: 5001,
    protocol: "https",
});

export default class MetaDataContract {
    constructor() {
        this.contract_address = "0x201be2022c9b58428d6a5743f2bd4cb8934547df";
        this.ropsten_address = "0xfa91455977911e46f48b0c362174f52176ed49b6";
        this.contract = eth.contract(abi).at(this.contract_address);
        this.ropsten_contract = ropsten
            .contract(ropsten_abi)
            .at(this.ropsten_address);
    }

    async getAddress(address) {
        return this.contract
            .getByAddress(address)
            .then(result => {
                //TODO: check if valid IPFS link
                if (
                    result[0] === "0x" ||
                    result[0] === "0x0000000000000000000000000000000000000000"
                )
                    return null;
                return this.lookUp(result[2]).then(ipfs => {
                    return {
                        address: result[0],
                        name: result[1],
                        data: ipfs,
                    };
                });
            })
            .catch(err => {});
    }

    async getRopsten(address) {
        return this.ropsten_contract.getByAddress(address).then(result => {
            //TODO: check if valid IPFS link
            if (
                result[0] === "0x" ||
                result[0] === "0x0000000000000000000000000000000000000000"
            ) {
                return null;
            }
            return this.lookUp(result[2])
                .then(ipfs => {
                    return {
                        address: result[0],
                        name: result[1],
                        data: ipfs,
                    };
                })
                .catch(err => {});
        });
    }

    async lookUp(address) {
        return new Promise((resolve, reject) => {
            ipfs.catJSON(address, (err, result) => {
                if (err) reject(err);
                resolve(result);
            });
        });
    }
}
