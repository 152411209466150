import React from "react";
import BigNumber from "bignumber.js";
import { Rnd } from "react-rnd";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  formatNumber,
  formatCurrency,
  formatSource,
  getTag,
  tags
} from "../utils/Utils";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import Tooltip from "@material-ui/core/Tooltip";
import SourceCode from "./SourceCode";
import "../css/AccountInfo.css";

TimeAgo.locale(en);
const timeAgo = new TimeAgo("en-US");

const styles = theme => ({
  popper: {
    opacity: 1
  },
  lightTooltip: {
    background: "#fefefe",
    color: theme.palette.text.primary,
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 3px 2px 0px rgba(0, 0, 0, 0.01), 0px 3px 1px -2px rgba(0, 0, 0, 0.01)",
    fontFamily: "Hack, Source Code Pro",
    fontSize: 9,
    opacity: 1
  },
  absolute: {
    position: "absolute",
    top: "3px"
  }
});

class AccountInfo extends React.Component {
  state = {
    node: {},
    modalOpen: false,
    code: "",
    showAll: false
  };

  componentWillReceiveProps(nextProps) {
    this.rnd.updateSize({
      width: this.calcWidthOffset().width,
      height: "auto"
    });
    if (nextProps.selected && nextProps.selected.source) {
      for (let tx of nextProps.selected.transactions) {
        this.props.ethtective.scanTransactionDetails(tx).then(r => {
          this.setState({ update: true });
        });
      }
    }
  }

  openEtherscan = async e => {
    window.open(
      "https://etherscan.io/address/" + this.props.selected.address,
      "_blank"
    );
  };

  viewSource = async node => {
    let wnd = window.open("about:blank", "", "_blank");
    let code = node.contractInfo.source.SourceCode;
    wnd.document.write(formatSource(code));
  };

  onRefresh = () => {
    this.forceUpdate();
  };

  calcWidthOffset = () => {
    let calc = {
      width: window.innerWidth * 0.37,
      x: (window.innerWidth - window.innerWidth * 0.37) / 2
    };
    if (window.innerWidth < 1400) {
      calc = {
        width: window.innerWidth * 0.75,
        x: (window.innerWidth - window.innerWidth * 0.75) / 2
      };
    }
    return calc;
  };

  format = (value, dec = 4) => {
    let s = formatNumber(value, dec);
    dec += 1;
    if (value >= 1)
      return (
        <span className="amt">
          {s.slice(0, s.length - dec)}
          <span className="fraction">{s.slice(-dec)}</span>
        </span>
      );
    else
      return (
        <span className="amt">
          <span className="fraction">{s.slice(0, s.length - dec)}</span>
          {s.slice(-dec)}
        </span>
      );
  };

  renderTransaction = link => {
    const { classes } = this.props;

    let transactions = link.transactions.map(key => {
      let date = new Date(key.timestamp * 1000);
      let name = key.denomination;
      return (
        <tr className="transaction" key={key.key}>
          <Tooltip
            title={
              key.data && key.data.success
                ? key.data.confirmations + " confirmations"
                : ""
            }
            placement="top"
            classes={{
              tooltip: classes.lightTooltip,
              popper: classes.popper
            }}
            enterDelay={200}
            leaveDelay={200}
          >
            {key.data ? (
              <td
                className={
                  "_10 " +
                  (key.data.success
                    ? "tx_success"
                    : key.data.success === null
                      ? "tx_pending"
                      : "tx_failed")
                }
              >
                {key.data.success
                  ? "Success"
                  : key.data.success === null
                    ? "Pending"
                    : "Failed"}
              </td>
            ) : (
              <td className={"_10 tx_unknown"}>Unknown</td>
            )}
          </Tooltip>
          <td className="_10 amount">{this.format(key.amount)}</td>
          <td className="_5 denomination">{name}</td>
          {key.timestamp ? (
            <>
              <td className="_10">{timeAgo.format(date)}</td>
              <td className="_20">{date.toLocaleString()}</td>
            </>
          ) : (
            <td className="_30">
              {key.timestamp
                ? date.toLocaleString()
                : "Waiting to be confirmed"}
              {this.props.ethtective.estimateGasPrice(key)}
            </td>
          )}
          <td className="txhash">
            <a href={`https://etherscan.io/tx/${key.hash}`} target="_blank"
            rel="noopener noreferrer">
              {key.hash}
            </a>
          </td>
        </tr>
      );
    });
    return (
      <div className={"link "}>
        <div className="header">
          <div className="sourcetarget">
            <p className="source">
              <a
                href={`https://etherscan.io/tx/${link.source.address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.source.address}
              </a>
            </p>
            &nbsp;➡️
            <p className="target">
              <a
                href={`https://etherscan.io/tx/${link.target.address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.target.address}
              </a>
            </p>
          </div>
        </div>
        <div className="info_table">
          <table>
            <tbody>{transactions}</tbody>
          </table>
        </div>
      </div>
    );
  };

  renderRow = (
    _key,
    _address,
    _alert,
    _symbol,
    _amount,
    _price,
    _name,
    _details
  ) => {
    return (
      <tr className="token" key={_key}>
        <td className="_10">
          <a href={"https://etherscan.io/address/" + _address} target="_blank" rel="noopener noreferrer">
            {_alert}
            <span className="denomination">{_symbol}</span>
          </a>
        </td>
        <td className="_20 amount">{this.format(parseFloat(_amount))}</td>
        <td className="_20 price">
          <span className="price">
            {_price
              ? formatCurrency(
                  parseFloat(_amount) * parseFloat(_price),
                  2,
                  "USD"
                )
              : ""}
          </span>
        </td>
        <td className="_30">
          <span className="tokenName">{_name}</span>
        </td>
        <td className="_30">
          <span className={"details "}>{_details}</span>
        </td>
      </tr>
    );
  };

  renderEth = node => {
    return this.renderRow(
      "Eth",
      node.address,
      "",
      "Ξ",
      node.amount,
      node.etherPrice,
      "Ether",
      ""
    );
  };

  renderTotal = node => {
    return (
      <tr className="total" key={"total"}>
        <td className="_10 " />
        <td className="_10 " />
        <td className="_20 amount">
          {formatCurrency(parseFloat(node.getTotal()), 2, "USD")}
        </td>
      </tr>
    );
  };

  shouldRenderToken = (token, balance) => {
    if (token.tokenInfo && !this.state.showAll) {
      if (!token.tokenInfo.price) return false;
      if (token.tokenInfo.price && token.tokenInfo.price.rate * balance < 0.05)
        return false;
    }
    return true;
  };

  renderToken = key => {
    let tokenBalance = new BigNumber(key.balance);
    let balance = 0;
    try {
      balance = tokenBalance.shiftedBy(-parseInt(key.tokenInfo.decimals, 10));
    } catch {
      console.log("token illegal");
    }
    if (!this.shouldRenderToken(key, balance)) return "";
    return this.renderRow(
      key.tokenInfo.address,
      key.tokenInfo.address,
      key.tokenInfo.alert ? "⚠️" : "",
      key.tokenInfo.symbol,
      balance,
      key.tokenInfo.price.rate,
      key.tokenInfo.name +
        (key.tokenInfo.alert ? "(" + key.tokenInfo.alert + ")" : ""),
      ""
    );
  };

  renderAccount() {
    const { classes } = this.props;
    let node = this.props.ethtective.getAccount(
      this.props.selected.address,
      false
    );
    if (!node || !node.transactionsScanned) return <div />;
    let tokens =
      node.tokens !== undefined ? (
        node.tokens
          .sort((a, b) => {
            return a.tokenInfo !== undefined && a.tokenInfo.symbol !== undefined && b.tokenInfo !== undefined && b.tokenInfo.symbol !== undefined? a.tokenInfo.symbol.toUpperCase() >
              b.tokenInfo.symbol.toUpperCase()
              ? 1
              : -1 : -1;
          })
          .map(key => {
            if (key.tokenInfo && key.tokenInfo.symbol && key.tokenInfo.symbol.length > 0) {
              return this.renderToken(key);
            }
            return <React.Fragment />;
          })
      ) : (
        <tr />
      );
    if (tokens.filter)
    {
      tokens = tokens.filter(x => x !== "");
    }
    let viewSource =
      node.contractInfo &&
      node.contractInfo.source &&
      node.contractInfo.source.SourceCode ? (
        <button onClick={() => this.viewSource(node)}>
          contract source
        </button>
      ) : (
        <React.Fragment />
      );
    let name = node.name
      ? node.name
      : node.contractInfo !== undefined
        ? node.tokenInfo !== undefined
          ? node.tokenInfo.name
          : node.contractInfo.name !== undefined
            ? node.contractInfo.name
            : "Contract"
        : "";
    let url =
      node.metadata &&
      node.metadata.reputation.status !== "Blocked" &&
      node.metadata.url ? (
        <Tooltip
          title={"Open the website associated with this address"}
          placement="top"
          classes={{
            tooltip: classes.lightTooltip,
            popper: classes.popper
          }}
          enterDelay={200}
          leaveDelay={200}
        >
          <a href={node.metadata.url} target="_blank" rel="noopener noreferrer">
            website
          </a>
        </Tooltip>
      ) : (
        <React.Fragment />
      );
    return (
      <div className={"node"}>
        <div
          className="header"
          style={{ background: getTag(node).colors.pillColor }}
        >
          <div className="address">
            <span className={"name" + (name !== "" ? "" : " hidden")}>
              {name}
            </span>{" "}
            <a href={"https://www.ethtective.com/" + node.address}>
              {node.address}
            </a>{" "}
          </div>
        </div>
        <div className="toolbar">
          {node.tags
            .sort((a, b) => {
              return tags[a].priority > tags[b].priority;
            })
            .map(_tag => {
              let tag = tags[_tag];
              return (
                <Tooltip
                  key={tag.name}
                  title={tag.description ? tag.description : ""}
                  placement="top"
                  classes={{
                    tooltip: classes.lightTooltip,
                    popper: classes.popper
                  }}
                  enterDelay={200}
                  leaveDelay={200}
                >
                  <p
                    className="tag"
                    style={{ background: tag.colors.pillColor, color: "white" }}
                  >
                    {tag.name}
                  </p>
                </Tooltip>
              );
            })}

          <p className="tools">
            <Tooltip
              title={"Edit Metadata for this address"}
              placement="top"
              classes={{
                tooltip: classes.lightTooltip,
                popper: classes.popper
              }}
              enterDelay={200}
              leaveDelay={200}
            >
              <a
                href={
                  "https://beta.ethregistry.org/edit/" +
                  this.props.selected.address
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: "#888", color: "white" }}
              >
                edit
              </a>
            </Tooltip>
            <Tooltip
              title={"Show or filter low value Tokens"}
              placement="top"
              classes={{
                tooltip: classes.lightTooltip,
                popper: classes.popper
              }}
              enterDelay={200}
              leaveDelay={200}
            >
              <button
                onClick={() => {
                  this.setState({ showAll: !this.state.showAll });
                }}
                style={{ backgroundColor: "#888", color: "white" }}
              >
                {this.state.showAll ? "filter tokens" : "show all tokens"}
              </button>
            </Tooltip>
            {url}
            <Tooltip
              title={"Open address in Etherscan"}
              placement="top"
              classes={{
                tooltip: classes.lightTooltip,
                popper: classes.popper
              }}
              enterDelay={200}
              leaveDelay={200}
            >
              <a
                href={
                  "https://etherscan.io/address/" + this.props.selected.address
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                etherscan
              </a>
            </Tooltip>
            <Tooltip
              title={"Search address with Google"}
              placement="top"
              classes={{
                tooltip: classes.lightTooltip,
                popper: classes.popper
              }}
              enterDelay={200}
              leaveDelay={200}
            >
              <a
                href={
                  "https://www.google.com/search?q=" +
                  this.props.selected.address
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                google
              </a>
            </Tooltip>
            {viewSource}
          </p>
        </div>
        <div className="info_table">
          <table>
            <tbody>
              {this.renderTotal(node)}
              {this.renderEth(node)}
              {tokens}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    let content = <p />;
    if (this.props.selected && this.props.selected.address) {
      content = this.renderAccount();
    } else if (this.props.selected && this.props.selected.source) {
      content = this.renderTransaction(this.props.selected);
    }
    let enabled =
      this.props.selected &&
      ((this.props.ethtective.getAccount(this.props.selected.address, false) &&
        this.props.ethtective.getAccount(this.props.selected.address, false)
          .transactionsScanned) ||
        this.props.selected.source);
    return (
      <Rnd
        ref={c => {
          this.rnd = c;
        }}
        default={{
          x: this.calcWidthOffset().x,
          y: window.innerHeight * 0.8,
          width: this.calcWidthOffset().width,
          height: "auto"
        }}
      >
        <div
          id="AccountInfo"
          className={"shadow_big" + (enabled ? " enabled" : "")}
        >
          {content}
          <SourceCode code={this.state.code} open={this.state.modalOpen} />
        </div>
      </Rnd>
    );
  }
}

AccountInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountInfo);
