import ENS from "ethereum-ens";
import Eth from "ethjs";

const provider = new Eth.HttpProvider(
    `https://eth-mainnet.alchemyapi.io/v2/${process.env.react_app_alchemy_api}`,
);

export default class ENSReverseLookup {
    constructor() {
        try {
            this.ens = new ENS(provider);
        } catch (e) {
            console.error("Ens Provider not found");
        }
    }

    async resolve(address) {
        try {
            let test = await this.ens.reverse(address).name();
            return test;
        } catch (e) {
            // console.log(e);
        }
    }

    async lookup(address) {
        console.log(address);
        try {
            console.log(address);
            let addr = await this.ens.resolver(address).addr();
            if (addr) return addr;
        } catch (e) {}
    }
}
