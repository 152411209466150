export default {
    Data: {
        BTC: {
            ImageUrl: "/media/19633/btc.png",
            Symbol: "BTC",
            CoinName: "Bitcoin",
        },
        ETH: {
            ImageUrl: "/media/20646/eth_logo.png",
            Symbol: "ETH",
            CoinName: "Ethereum",
        },
        LTC: {
            ImageUrl: "/media/19782/litecoin-logo.png",
            Symbol: "LTC",
            CoinName: "Litecoin",
        },
        DASH: {
            ImageUrl: "/media/33842920/dash.png",
            Symbol: "DASH",
            CoinName: "Dash",
        },
        XMR: {
            ImageUrl: "/media/19969/xmr.png",
            Symbol: "XMR",
            CoinName: "Monero",
        },
        NXT: {
            ImageUrl: "/media/20627/nxt.png",
            Symbol: "NXT",
            CoinName: "Nxt",
        },
        ETC: {
            ImageUrl: "/media/33752295/etc_new.png",
            Symbol: "ETC",
            CoinName: "Ethereum Classic",
        },
        DOGE: {
            ImageUrl: "/media/19684/doge.png",
            Symbol: "DOGE",
            CoinName: "Dogecoin",
        },
        ZEC: {
            ImageUrl: "/media/351360/zec.png",
            Symbol: "ZEC",
            CoinName: "ZCash",
        },
        BTS: {
            ImageUrl: "/media/20705/bts.png",
            Symbol: "BTS",
            CoinName: "Bitshares",
        },
        DGB: {
            ImageUrl: "/media/12318264/7638-nty_400x400.jpg",
            Symbol: "DGB",
            CoinName: "DigiByte",
        },
        BTCD: {
            ImageUrl: "/media/19630/btcd_1.png",
            Symbol: "BTCD",
            CoinName: "BitcoinDark",
        },
        PPC: {
            ImageUrl: "/media/19864/peercoin-logo.png",
            Symbol: "PPC",
            CoinName: "PeerCoin",
        },
        CRAIG: {
            ImageUrl: "/media/20022/craig.png",
            Symbol: "CRAIG",
            CoinName: "CraigsCoin",
        },
        XBS: {
            ImageUrl: "/media/351060/xbs_1.png",
            Symbol: "XBS",
            CoinName: "Bitstake",
        },
        XPY: {
            ImageUrl: "/media/20076/xpy_1.png",
            Symbol: "XPY",
            CoinName: "PayCoin",
        },
        PRC: {
            ImageUrl: "/media/20393/prc.png",
            Symbol: "PRC",
            CoinName: "ProsperCoin",
        },
        YBC: {
            ImageUrl: "/media/19975/ybc.png",
            Symbol: "YBC",
            CoinName: "YbCoin",
        },
        DANK: {
            ImageUrl: "/media/20247/dank.png",
            Symbol: "DANK",
            CoinName: "DarkKush",
        },
        GIVE: {
            ImageUrl: "/media/20297/give.png",
            Symbol: "GIVE",
            CoinName: "GiveCoin",
        },
        KOBO: {
            ImageUrl: "/media/20329/kobo.png",
            Symbol: "KOBO",
            CoinName: "KoboCoin",
        },
        DT: {
            ImageUrl: "/media/20031/dt.png",
            Symbol: "DT",
            CoinName: "DarkToken",
        },
        CETI: {
            ImageUrl: "/media/20228/ceti.png",
            Symbol: "CETI",
            CoinName: "CETUS Coin",
        },
        SUP: {
            ImageUrl: "/media/20442/sup.png",
            Symbol: "SUP",
            CoinName: "Supcoin",
        },
        XPD: {
            ImageUrl: "/media/20162/xpd.png",
            Symbol: "XPD",
            CoinName: "PetroDollar",
        },
        GEO: {
            ImageUrl: "/media/20292/geo.png",
            Symbol: "GEO",
            CoinName: "GeoCoin",
        },
        CHASH: {
            ImageUrl: "/media/20231/chash.png",
            Symbol: "CHASH",
            CoinName: "CleverHash",
        },
        SPR: {
            ImageUrl: "/media/20438/spr.png",
            Symbol: "SPR",
            CoinName: "Spreadcoin",
        },
        NXTI: {
            ImageUrl: "/media/20376/nxti.png",
            Symbol: "NXTI",
            CoinName: "NXTI",
        },
        WOLF: {
            ImageUrl: "/media/20559/wolf.png",
            Symbol: "WOLF",
            CoinName: "Insanity Coin",
        },
        XDP: {
            ImageUrl: "/media/20560/xdp.png",
            Symbol: "XDP",
            CoinName: "DogeParty",
        },
        "2015": {
            ImageUrl: "/media/20180/2015.png",
            Symbol: "2015",
            CoinName: "2015 coin",
        },
        "42": {
            ImageUrl: "/media/12318415/42.png",
            Symbol: "42",
            CoinName: "42 Coin",
        },
        AC: {
            ImageUrl: "/media/19593/ac.png",
            Symbol: "AC",
            CoinName: "Asia Coin",
        },
        ACOIN: {
            ImageUrl: "/media/20079/acoin.png",
            Symbol: "ACOIN",
            CoinName: "ACoin",
        },
        AERO: {
            ImageUrl: "/media/19594/aero.png",
            Symbol: "AERO",
            CoinName: "Aero Coin",
        },
        ALF: {
            ImageUrl: "/media/19600/alf.png",
            Symbol: "ALF",
            CoinName: "AlphaCoin",
        },
        AGS: {
            ImageUrl: "/media/19595/ags.png",
            Symbol: "AGS",
            CoinName: "Aegis",
        },
        AMC: {
            ImageUrl: "/media/19601/amc.png",
            Symbol: "AMC",
            CoinName: "AmericanCoin",
        },
        ALN: {
            ImageUrl: "/media/20080/aln.png",
            Symbol: "ALN",
            CoinName: "AlienCoin",
        },
        APEX: {
            ImageUrl: "/media/19599/apex.png",
            Symbol: "APEX",
            CoinName: "ApexCoin",
        },
        ARCH: {
            ImageUrl: "/media/20085/arch.png",
            Symbol: "ARCH",
            CoinName: "ArchCoin",
        },
        ARG: {
            ImageUrl: "/media/19602/arg.png",
            Symbol: "ARG",
            CoinName: "Argentum",
        },
        ARI: {
            ImageUrl: "/media/20082/ari.png",
            Symbol: "ARI",
            CoinName: "AriCoin",
        },
        AUR: {
            ImageUrl: "/media/19608/aur.png",
            Symbol: "AUR",
            CoinName: "Aurora Coin",
        },
        AXR: {
            ImageUrl: "/media/20086/axr.png",
            Symbol: "AXR",
            CoinName: "AXRON",
        },
        BCX: {
            ImageUrl: "/media/19620/bcx.png",
            Symbol: "BCX",
            CoinName: "BattleCoin",
        },
        "BEN*": {
            ImageUrl: "/media/19617/ben.png",
            Symbol: "BEN*",
            CoinName: "Benjamins",
        },
        BET: {
            ImageUrl: "/media/19621/bet.png",
            Symbol: "BET",
            CoinName: "BetaCoin",
        },
        BITB: {
            ImageUrl: "/media/350879/bitb.png",
            Symbol: "BITB",
            CoinName: "BitBean",
        },
        BLU: {
            ImageUrl: "/media/19624/blu.png",
            Symbol: "BLU",
            CoinName: "BlueCoin",
        },
        BLK: {
            ImageUrl: "/media/351795/blk.png",
            Symbol: "BLK",
            CoinName: "BlackCoin",
        },
        BOST: {
            ImageUrl: "/media/19626/bost.png",
            Symbol: "BOST",
            CoinName: "BoostCoin",
        },
        BQC: {
            ImageUrl: "/media/19631/bqc.png",
            Symbol: "BQC",
            CoinName: "BQCoin",
        },
        XMY: {
            ImageUrl: "/media/19815/myr.png",
            Symbol: "XMY",
            CoinName: "MyriadCoin",
        },
        MOON: {
            ImageUrl: "/media/19802/moon.png",
            Symbol: "MOON",
            CoinName: "MoonCoin",
        },
        ZET: {
            ImageUrl: "/media/19993/zet.png",
            Symbol: "ZET",
            CoinName: "ZetaCoin",
        },
        SXC: {
            ImageUrl: "/media/19924/sxc.png",
            Symbol: "SXC",
            CoinName: "SexCoin",
        },
        QTL: {
            ImageUrl: "/media/19879/qtl.png",
            Symbol: "QTL",
            CoinName: "Quatloo",
        },
        ENRG: {
            ImageUrl: "/media/19697/enrg.png",
            Symbol: "ENRG",
            CoinName: "EnergyCoin",
        },
        QRK: {
            ImageUrl: "/media/19882/qrk.png",
            Symbol: "QRK",
            CoinName: "QuarkCoin",
        },
        RIC: {
            ImageUrl: "/media/19888/ric.jpg",
            Symbol: "RIC",
            CoinName: "Riecoin",
        },
        DGC: {
            ImageUrl: "/media/19676/dgc.png",
            Symbol: "DGC",
            CoinName: "DigiCoin",
        },
        LIMX: {
            ImageUrl: "/media/19769/limx.png",
            Symbol: "LIMX",
            CoinName: "LimeCoinX",
        },
        BTB: {
            ImageUrl: "/media/20083/bitb.png",
            Symbol: "BTB",
            CoinName: "BitBar",
        },
        CAIX: {
            ImageUrl: "/media/20226/caix.png",
            Symbol: "CAIX",
            CoinName: "CAIx",
        },
        BTE: {
            ImageUrl: "/media/19632/bte.png",
            Symbol: "BTE",
            CoinName: "ByteCoin",
        },
        "BTG*": {
            ImageUrl: "/media/19634/btg.png",
            Symbol: "BTG*",
            CoinName: "BitGem",
        },
        BUK: {
            ImageUrl: "/media/19637/buk.png",
            Symbol: "BUK",
            CoinName: "CryptoBuk",
        },
        CACH: {
            ImageUrl: "/media/19642/cach.png",
            Symbol: "CACH",
            CoinName: "Cachecoin",
        },
        CANN: {
            ImageUrl: "/media/20015/cann.png",
            Symbol: "CANN",
            CoinName: "CannabisCoin",
        },
        CAP: {
            ImageUrl: "/media/20017/cap.png",
            Symbol: "CAP",
            CoinName: "BottleCaps",
        },
        CASH: {
            ImageUrl: "/media/20016/cash.png",
            Symbol: "CASH",
            CoinName: "CashCoin",
        },
        CAT1: {
            ImageUrl: "/media/19644/cat.png",
            Symbol: "CAT1",
            CoinName: "Catcoin",
        },
        CBX: {
            ImageUrl: "/media/30001996/cbx.png",
            Symbol: "CBX",
            CoinName: "CryptoBullion",
        },
        CCN: {
            ImageUrl: "/media/19643/ccn.png",
            Symbol: "CCN",
            CoinName: "CannaCoin",
        },
        CIN: {
            ImageUrl: "/media/20698/cinder.png",
            Symbol: "CIN",
            CoinName: "CinderCoin",
        },
        CINNI: {
            ImageUrl: "/media/19651/cinni.jpeg",
            Symbol: "CINNI",
            CoinName: "CINNICOIN",
        },
        CXC: {
            ImageUrl: "/media/20246/cxc.png",
            Symbol: "CXC",
            CoinName: "CheckCoin",
        },
        CLAM: {
            ImageUrl: "/media/20020/clam.png",
            Symbol: "CLAM",
            CoinName: "CLAMS",
        },
        CLOAK: {
            ImageUrl: "/media/19994/cloak.png",
            Symbol: "CLOAK",
            CoinName: "CloakCoin",
        },
        CLR: {
            ImageUrl: "/media/19657/clr.png",
            Symbol: "CLR",
            CoinName: "CopperLark",
        },
        CMC: {
            ImageUrl: "/media/20019/cmc.png",
            Symbol: "CMC",
            CoinName: "CosmosCoin",
        },
        CNC: {
            ImageUrl: "/media/20021/cnc.png",
            Symbol: "CNC",
            CoinName: "ChinaCoin",
        },
        CNL: {
            ImageUrl: "/media/20024/cnl.png",
            Symbol: "CNL",
            CoinName: "ConcealCoin",
        },
        COMM: {
            ImageUrl: "/media/19661/comm.png",
            Symbol: "COMM",
            CoinName: "Community Coin",
        },
        COOL: {
            ImageUrl: "/media/19658/cool.png",
            Symbol: "COOL",
            CoinName: "CoolCoin",
        },
        CRACK: {
            ImageUrl: "/media/20023/crack.png",
            Symbol: "CRACK",
            CoinName: "CrackCoin",
        },
        "CRC*": {
            ImageUrl: "/media/19665/crc.png",
            Symbol: "CRC*",
            CoinName: "CraftCoin",
        },
        CRYPT: {
            ImageUrl: "/media/19664/crypt.png",
            Symbol: "CRYPT",
            CoinName: "CryptCoin",
        },
        CSC: {
            ImageUrl: "/media/19667/csc.png",
            Symbol: "CSC",
            CoinName: "CasinoCoin",
        },
        DEM: {
            ImageUrl: "/media/20028/dem.png",
            Symbol: "DEM",
            CoinName: "eMark",
        },
        DMD: {
            ImageUrl: "/media/19680/dmd.png",
            Symbol: "DMD",
            CoinName: "Diamond",
        },
        XVG: {
            ImageUrl: "/media/12318032/xvg.png",
            Symbol: "XVG",
            CoinName: "Verge",
        },
        DRKC: {
            ImageUrl: "/media/20027/drkc.png",
            Symbol: "DRKC",
            CoinName: "DarkCash",
        },
        DSB: {
            ImageUrl: "/media/20034/dsb.png",
            Symbol: "DSB",
            CoinName: "DarkShibe",
        },
        DVC: {
            ImageUrl: "/media/20563/dvc.png",
            Symbol: "DVC",
            CoinName: "DevCoin",
        },
        EAC: {
            ImageUrl: "/media/19690/eac.png",
            Symbol: "EAC",
            CoinName: "EarthCoin",
        },
        EFL: {
            ImageUrl: "/media/19692/efl.png",
            Symbol: "EFL",
            CoinName: "E-Gulden",
        },
        ELC: {
            ImageUrl: "/media/19694/elc.png",
            Symbol: "ELC",
            CoinName: "Elacoin",
        },
        EMC2: {
            ImageUrl: "/media/20033/emc2.png",
            Symbol: "EMC2",
            CoinName: "Einsteinium",
        },
        EMD: {
            ImageUrl: "/media/20278/emd.png",
            Symbol: "EMD",
            CoinName: "Emerald",
        },
        EXCL: {
            ImageUrl: "/media/20035/excl.png",
            Symbol: "EXCL",
            CoinName: "Exclusive Coin",
        },
        EXE: {
            ImageUrl: "/media/19700/exe.png",
            Symbol: "EXE",
            CoinName: "ExeCoin",
        },
        EZC: {
            ImageUrl: "/media/19702/ezc.png",
            Symbol: "EZC",
            CoinName: "EZCoin",
        },
        FLAP: {
            ImageUrl: "/media/20032/flap.png",
            Symbol: "FLAP",
            CoinName: "Flappy Coin",
        },
        FC2: {
            ImageUrl: "/media/19719/fuel.png",
            Symbol: "FC2",
            CoinName: "Fuel2Coin",
        },
        FFC: {
            ImageUrl: "/media/19706/ffc.png",
            Symbol: "FFC",
            CoinName: "FireflyCoin",
        },
        FIBRE: {
            ImageUrl: "/media/20030/fibre.png",
            Symbol: "FIBRE",
            CoinName: "FIBRE",
        },
        FRC: {
            ImageUrl: "/media/1382629/frc.png",
            Symbol: "FRC",
            CoinName: "FireRoosterCoin",
        },
        FLT: {
            ImageUrl: "/media/19709/flt.png",
            Symbol: "FLT",
            CoinName: "FlutterCoin",
        },
        FRK: {
            ImageUrl: "/media/19712/frk.png",
            Symbol: "FRK",
            CoinName: "Franko",
        },
        FRAC: {
            ImageUrl: "/media/19710/frac.png",
            Symbol: "FRAC",
            CoinName: "FractalCoin",
        },
        FST: {
            ImageUrl: "/media/19720/fst.png",
            Symbol: "FST",
            CoinName: "FastCoin",
        },
        FTC: {
            ImageUrl: "/media/19718/ftc.png",
            Symbol: "FTC",
            CoinName: "FeatherCoin",
        },
        GDC: {
            ImageUrl: "/media/20054/gdc.png",
            Symbol: "GDC",
            CoinName: "GrandCoin",
        },
        GLC: {
            ImageUrl: "/media/19724/glc.png",
            Symbol: "GLC",
            CoinName: "GlobalCoin",
        },
        GLD: {
            ImageUrl: "/media/19723/gld.png",
            Symbol: "GLD",
            CoinName: "GoldCoin",
        },
        GLX: {
            ImageUrl: "/media/19728/glx.png",
            Symbol: "GLX",
            CoinName: "GalaxyCoin",
        },
        GLYPH: {
            ImageUrl: "/media/19725/glyph.png",
            Symbol: "GLYPH",
            CoinName: "GlyphCoin",
        },
        GML: {
            ImageUrl: "/media/19726/gml.png",
            Symbol: "GML",
            CoinName: "GameLeagueCoin",
        },
        GUE: {
            ImageUrl: "/media/19732/gue.png",
            Symbol: "GUE",
            CoinName: "GuerillaCoin",
        },
        HAL: {
            ImageUrl: "/media/20036/hal.png",
            Symbol: "HAL",
            CoinName: "Halcyon",
        },
        HBN: {
            ImageUrl: "/media/19735/hbn.png",
            Symbol: "HBN",
            CoinName: "HoboNickels",
        },
        HUC: {
            ImageUrl: "/media/20037/hun.png",
            Symbol: "HUC",
            CoinName: "HunterCoin",
        },
        HVC: {
            ImageUrl: "/media/19745/hvc.png",
            Symbol: "HVC",
            CoinName: "HeavyCoin",
        },
        HYP: {
            ImageUrl: "/media/20624/hyp.png",
            Symbol: "HYP",
            CoinName: "Hyperstake",
        },
        ICB: {
            ImageUrl: "/media/19747/icb.png",
            Symbol: "ICB",
            CoinName: "IceBergCoin",
        },
        IFC: {
            ImageUrl: "/media/19754/ifc.png",
            Symbol: "IFC",
            CoinName: "Infinite Coin",
        },
        IOC: {
            ImageUrl: "/media/20042/ioc.png",
            Symbol: "IOC",
            CoinName: "IOCoin",
        },
        IXC: {
            ImageUrl: "/media/19761/ixc.png",
            Symbol: "IXC",
            CoinName: "IXcoin",
        },
        JBS: {
            ImageUrl: "/media/20044/jbs.png",
            Symbol: "JBS",
            CoinName: "JumBucks Coin",
        },
        JKC: {
            ImageUrl: "/media/19757/jkc.png",
            Symbol: "JKC",
            CoinName: "JunkCoin",
        },
        JUDGE: {
            ImageUrl: "/media/20038/judge.png",
            Symbol: "JUDGE",
            CoinName: "JudgeCoin",
        },
        KDC: {
            ImageUrl: "/media/19766/kdc.png",
            Symbol: "KDC",
            CoinName: "Klondike Coin",
        },
        "KEY*": {
            ImageUrl: "/media/20331/key.png",
            Symbol: "KEY*",
            CoinName: "KeyCoin",
        },
        KGC: {
            ImageUrl: "/media/19763/kgc.png",
            Symbol: "KGC",
            CoinName: "KrugerCoin",
        },
        "LAB*": {
            ImageUrl: "/media/20040/lab.png",
            Symbol: "LAB*",
            CoinName: "CoinWorksCoin",
        },
        "LGD*": {
            ImageUrl: "/media/19770/lgd.png",
            Symbol: "LGD*",
            CoinName: "Legendary Coin",
        },
        LK7: {
            ImageUrl: "/media/19776/lk7.png",
            Symbol: "LK7",
            CoinName: "Lucky7Coin",
        },
        LKY: {
            ImageUrl: "/media/19774/lky.png",
            Symbol: "LKY",
            CoinName: "LuckyCoin",
        },
        LSD: {
            ImageUrl: "/media/20041/lsd.png",
            Symbol: "LSD",
            CoinName: "LightSpeedCoin",
        },
        LTB: {
            ImageUrl: "/media/20336/ltb.png",
            Symbol: "LTB",
            CoinName: "Litebar ",
        },
        LTCD: {
            ImageUrl: "/media/20043/ltcd.png",
            Symbol: "LTCD",
            CoinName: "LitecoinDark",
        },
        LTCX: {
            ImageUrl: "/media/19779/ltcx.png",
            Symbol: "LTCX",
            CoinName: "LitecoinX",
        },
        LXC: {
            ImageUrl: "/media/20045/lxc.png",
            Symbol: "LXC",
            CoinName: "LibrexCoin",
        },
        LYC: {
            ImageUrl: "/media/19785/lyc.png",
            Symbol: "LYC",
            CoinName: "LycanCoin",
        },
        MAX: {
            ImageUrl: "/media/19786/max.png",
            Symbol: "MAX",
            CoinName: "MaxCoin",
        },
        MEC: {
            ImageUrl: "/media/19789/mec.png",
            Symbol: "MEC",
            CoinName: "MegaCoin",
        },
        MED: {
            ImageUrl: "/media/20046/med.png",
            Symbol: "MED",
            CoinName: "MediterraneanCoin",
        },
        MIN: {
            ImageUrl: "/media/19793/min.png",
            Symbol: "MIN",
            CoinName: "Minerals Coin",
        },
        MINT: {
            ImageUrl: "/media/19797/mint.png",
            Symbol: "MINT",
            CoinName: "MintCoin",
        },
        MN: {
            ImageUrl: "/media/19796/mn1.png",
            Symbol: "MN",
            CoinName: "Cryptsy Mining Contract",
        },
        MNC: {
            ImageUrl: "/media/19805/mincoin.png",
            Symbol: "MNC",
            CoinName: "MinCoin",
        },
        MRY: {
            ImageUrl: "/media/19807/mry.jpg",
            Symbol: "MRY",
            CoinName: "MurrayCoin",
        },
        "MYST*": {
            ImageUrl: "/media/20053/myst.png",
            Symbol: "MYST*",
            CoinName: "MysteryCoin",
        },
        MZC: {
            ImageUrl: "/media/19816/mzc.png",
            Symbol: "MZC",
            CoinName: "MazaCoin",
        },
        NAN: {
            ImageUrl: "/media/19821/nan.png",
            Symbol: "NAN",
            CoinName: "NanoToken",
        },
        NAUT: {
            ImageUrl: "/media/19822/naut.png",
            Symbol: "NAUT",
            CoinName: "Nautilus Coin",
        },
        NAV: {
            ImageUrl: "/media/351431/nav.png",
            Symbol: "NAV",
            CoinName: "NavCoin",
        },
        NBL: {
            ImageUrl: "/media/19825/nbl.png",
            Symbol: "NBL",
            CoinName: "Nybble",
        },
        NEC: {
            ImageUrl: "/media/19824/nec.png",
            Symbol: "NEC",
            CoinName: "NeoCoin",
        },
        NET: {
            ImageUrl: "/media/19826/net.png",
            Symbol: "NET",
            CoinName: "NetCoin",
        },
        NMB: {
            ImageUrl: "/media/20049/nmb.png",
            Symbol: "NMB",
            CoinName: "Nimbus Coin",
        },
        NRB: {
            ImageUrl: "/media/19839/nrb.png",
            Symbol: "NRB",
            CoinName: "NoirBits",
        },
        NOBL: {
            ImageUrl: "/media/19833/nobl.png",
            Symbol: "NOBL",
            CoinName: "NobleCoin",
        },
        NRS: {
            ImageUrl: "/media/19834/nrs.png",
            Symbol: "NRS",
            CoinName: "NoirShares",
        },
        NVC: {
            ImageUrl: "/media/20713/nvc.png",
            Symbol: "NVC",
            CoinName: "NovaCoin",
        },
        NMC: {
            ImageUrl: "/media/19830/nmc.png",
            Symbol: "NMC",
            CoinName: "Namecoin",
        },
        NYAN: {
            ImageUrl: "/media/19842/nyan.png",
            Symbol: "NYAN",
            CoinName: "NyanCoin",
        },
        OPAL: {
            ImageUrl: "/media/20050/opal.png",
            Symbol: "OPAL",
            CoinName: "OpalCoin",
        },
        ORB: {
            ImageUrl: "/media/19845/orb.png",
            Symbol: "ORB",
            CoinName: "Orbitcoin",
        },
        OSC: {
            ImageUrl: "/media/19847/osc.png",
            Symbol: "OSC",
            CoinName: "OpenSourceCoin",
        },
        PHS: {
            ImageUrl: "/media/19857/phs.png",
            Symbol: "PHS",
            CoinName: "PhilosophersStone",
        },
        POINTS: {
            ImageUrl: "/media/19863/points.png",
            Symbol: "POINTS",
            CoinName: "Cryptsy Points",
        },
        POT: {
            ImageUrl: "/media/19865/pot.png",
            Symbol: "POT",
            CoinName: "PotCoin",
        },
        PSEUD: {
            ImageUrl: "/media/20052/pseud.png",
            Symbol: "PSEUD",
            CoinName: "PseudoCash",
        },
        "PTS*": {
            ImageUrl: "/media/19869/pts.png",
            Symbol: "PTS*",
            CoinName: "Protoshares",
        },
        PXC: {
            ImageUrl: "/media/20058/pxc.png",
            Symbol: "PXC",
            CoinName: "PhoenixCoin",
        },
        PYC: {
            ImageUrl: "/media/19878/pyc.png",
            Symbol: "PYC",
            CoinName: "PayCoin",
        },
        RDD: {
            ImageUrl: "/media/19887/rdd.png",
            Symbol: "RDD",
            CoinName: "Reddcoin",
        },
        RIPO: {
            ImageUrl: "/media/20051/ripo.png",
            Symbol: "RIPO",
            CoinName: "RipOffCoin",
        },
        RPC: {
            ImageUrl: "/media/19895/rpc-2.png",
            Symbol: "RPC",
            CoinName: "RonPaulCoin",
        },
        RT2: {
            ImageUrl: "/media/19896/rt2.png",
            Symbol: "RT2",
            CoinName: "RotoCoin",
        },
        RYC: {
            ImageUrl: "/media/19898/ryc.png",
            Symbol: "RYC",
            CoinName: "RoyalCoin",
        },
        RZR: {
            ImageUrl: "/media/20055/rzr.png",
            Symbol: "RZR",
            CoinName: "RazorCoin",
        },
        SAT2: {
            ImageUrl: "/media/19897/sat2.png",
            Symbol: "SAT2",
            CoinName: "Saturn2Coin",
        },
        SBC: {
            ImageUrl: "/media/19900/sbc.png",
            Symbol: "SBC",
            CoinName: "StableCoin",
        },
        SDC: {
            ImageUrl: "/media/20419/sdc.png",
            Symbol: "SDC",
            CoinName: "ShadowCash",
        },
        SFR: {
            ImageUrl: "/media/19903/sfr.png",
            Symbol: "SFR",
            CoinName: "SaffronCoin",
        },
        SHADE: {
            ImageUrl: "/media/20056/shade.png",
            Symbol: "SHADE",
            CoinName: "ShadeCoin",
        },
        SHLD: {
            ImageUrl: "/media/19904/shld.png",
            Symbol: "SHLD",
            CoinName: "ShieldCoin",
        },
        SILK: {
            ImageUrl: "/media/20057/silk.png",
            Symbol: "SILK",
            CoinName: "SilkCoin",
        },
        SLG: {
            ImageUrl: "/media/20428/slg.png",
            Symbol: "SLG",
            CoinName: "SterlingCoin",
        },
        SMC: {
            ImageUrl: "/media/20059/smc.png",
            Symbol: "SMC",
            CoinName: "SmartCoin",
        },
        SOLE: {
            ImageUrl: "/media/20431/sole.png",
            Symbol: "SOLE",
            CoinName: "SoleCoin",
        },
        SPA: {
            ImageUrl: "/media/19911/spa.png",
            Symbol: "SPA",
            CoinName: "SpainCoin",
        },
        SPT: {
            ImageUrl: "/media/19917/spt.png",
            Symbol: "SPT",
            CoinName: "Spots",
        },
        SRC: {
            ImageUrl: "/media/19918/src.png",
            Symbol: "SRC",
            CoinName: "SecureCoin",
        },
        SSV: {
            ImageUrl: "/media/20060/ssv.png",
            Symbol: "SSV",
            CoinName: "SSVCoin",
        },
        XLM: {
            ImageUrl: "/media/20696/str.png",
            Symbol: "XLM",
            CoinName: "Stellar",
        },
        SUPER: {
            ImageUrl: "/media/20061/super.png",
            Symbol: "SUPER",
            CoinName: "SuperCoin",
        },
        SWIFT: {
            ImageUrl: "/media/20446/swift.png",
            Symbol: "SWIFT",
            CoinName: "BitSwift",
        },
        SYNC: {
            ImageUrl: "/media/19922/sync.png",
            Symbol: "SYNC",
            CoinName: "SyncCoin",
        },
        SYS: {
            ImageUrl: "/media/25792583/sys.png",
            Symbol: "SYS",
            CoinName: "SysCoin",
        },
        TAG: {
            ImageUrl: "/media/19925/tag.png",
            Symbol: "TAG",
            CoinName: "TagCoin",
        },
        TAK: {
            ImageUrl: "/media/19928/tak.png",
            Symbol: "TAK",
            CoinName: "TakCoin",
        },
        TES: {
            ImageUrl: "/media/19927/tes.png",
            Symbol: "TES",
            CoinName: "TeslaCoin",
        },
        TGC: {
            ImageUrl: "/media/19930/tgc.png",
            Symbol: "TGC",
            CoinName: "TigerCoin",
        },
        TIT: {
            ImageUrl: "/media/20069/tit.png",
            Symbol: "TIT",
            CoinName: "TitCoin",
        },
        TOR: {
            ImageUrl: "/media/19934/tor.png",
            Symbol: "TOR",
            CoinName: "TorCoin",
        },
        TRC: {
            ImageUrl: "/media/19938/terracoin.png",
            Symbol: "TRC",
            CoinName: "TerraCoin",
        },
        TTC: {
            ImageUrl: "/media/20064/ttc.png",
            Symbol: "TTC",
            CoinName: "TittieCoin",
        },
        ULTC: {
            ImageUrl: "/media/20063/ultc.png",
            Symbol: "ULTC",
            CoinName: "Umbrella",
        },
        UNB: {
            ImageUrl: "/media/19940/unb.png",
            Symbol: "UNB",
            CoinName: "UnbreakableCoin",
        },
        UNO: {
            ImageUrl: "/media/20065/uno.png",
            Symbol: "UNO",
            CoinName: "Unobtanium",
        },
        URO: {
            ImageUrl: "/media/19937/uro.png",
            Symbol: "URO",
            CoinName: "UroCoin",
        },
        USDE: {
            ImageUrl: "/media/20465/usde.png",
            Symbol: "USDE",
            CoinName: "UnitaryStatus Dollar",
        },
        UTC: {
            ImageUrl: "/media/19942/utc.png",
            Symbol: "UTC",
            CoinName: "UltraCoin",
        },
        UTIL: {
            ImageUrl: "/media/20067/util.png",
            Symbol: "UTIL",
            CoinName: "Utility Coin",
        },
        VDO: {
            ImageUrl: "/media/20066/vdo.png",
            Symbol: "VDO",
            CoinName: "VidioCoin",
        },
        VIA: {
            ImageUrl: "/media/20070/via.png",
            Symbol: "VIA",
            CoinName: "ViaCoin",
        },
        VOOT: {
            ImageUrl: "/media/19946/voot.png",
            Symbol: "VOOT",
            CoinName: "VootCoin",
        },
        VRC: {
            ImageUrl: "/media/20068/vrc.png",
            Symbol: "VRC",
            CoinName: "VeriCoin",
        },
        VTC: {
            ImageUrl: "/media/19945/vtc.png",
            Symbol: "VTC",
            CoinName: "Vertcoin",
        },
        WC: {
            ImageUrl: "/media/19948/wc.png",
            Symbol: "WC",
            CoinName: "WhiteCoin",
        },
        WDC: {
            ImageUrl: "/media/19949/wdc.png",
            Symbol: "WDC",
            CoinName: "WorldCoin",
        },
        XAI: {
            ImageUrl: "/media/20071/xai.png",
            Symbol: "XAI",
            CoinName: "SapienceCoin",
        },
        XBOT: {
            ImageUrl: "/media/20073/xbot.png",
            Symbol: "XBOT",
            CoinName: "SocialXbotCoin",
        },
        XC: {
            ImageUrl: "/media/19956/xc.png",
            Symbol: "XC",
            CoinName: "X11 Coin",
        },
        XCASH: {
            ImageUrl: "/media/20075/xcash.png",
            Symbol: "XCASH",
            CoinName: "Xcash",
        },
        XCR: {
            ImageUrl: "/media/19710/frac.png",
            Symbol: "XCR",
            CoinName: "Crypti",
        },
        XJO: {
            ImageUrl: "/media/19962/xjo.png",
            Symbol: "XJO",
            CoinName: "JouleCoin",
        },
        XLB: {
            ImageUrl: "/media/19966/xlb.png",
            Symbol: "XLB",
            CoinName: "LibertyCoin",
        },
        XPM: {
            ImageUrl: "/media/19970/xpm.png",
            Symbol: "XPM",
            CoinName: "PrimeCoin",
        },
        XST: {
            ImageUrl: "/media/20077/xst.png",
            Symbol: "XST",
            CoinName: "StealthCoin",
        },
        XXX: {
            ImageUrl: "/media/350617/xxx.png",
            Symbol: "XXX",
            CoinName: "XXXCoin",
        },
        YAC: {
            ImageUrl: "/media/19976/yac.png",
            Symbol: "YAC",
            CoinName: "YAcCoin",
        },
        ZCC: {
            ImageUrl: "/media/19979/zcc.png",
            Symbol: "ZCC",
            CoinName: "ZCC Coin",
        },
        ZED: {
            ImageUrl: "/media/19981/zed.png",
            Symbol: "ZED",
            CoinName: "ZedCoins",
        },
        "ZRC*": {
            ImageUrl: "/media/20078/zrc.png",
            Symbol: "ZRC*",
            CoinName: "ZiftrCoin",
        },
        BCN: {
            ImageUrl: "/media/12318404/bcn.png",
            Symbol: "BCN",
            CoinName: "ByteCoin",
        },
        EKN: {
            ImageUrl: "/media/20270/ekn.png",
            Symbol: "EKN",
            CoinName: "Elektron",
        },
        XDN: {
            ImageUrl: "/media/19957/xdn.jpg",
            Symbol: "XDN",
            CoinName: "DigitalNote ",
        },
        XAU: {
            ImageUrl: "/media/20479/xau.png",
            Symbol: "XAU",
            CoinName: "XauCoin",
        },
        TMC: {
            ImageUrl: "/media/20451/tmc.png",
            Symbol: "TMC",
            CoinName: "TimesCoin",
        },
        XEM: {
            ImageUrl: "/media/20490/xem.png",
            Symbol: "XEM",
            CoinName: "NEM",
        },
        BURST: {
            ImageUrl: "/media/16746623/burst.png",
            Symbol: "BURST",
            CoinName: "BurstCoin",
        },
        NBT: {
            ImageUrl: "/media/20363/nbt.png",
            Symbol: "NBT",
            CoinName: "NuBits",
        },
        SJCX: {
            ImageUrl: "/media/20422/sjcx.png",
            Symbol: "SJCX",
            CoinName: "StorjCoin",
        },
        START: {
            ImageUrl: "/media/19916/start.png",
            Symbol: "START",
            CoinName: "StartCoin",
        },
        HUGE: {
            ImageUrl: "/media/20318/huge.png",
            Symbol: "HUGE",
            CoinName: "BigCoin",
        },
        XCP: {
            ImageUrl: "/media/19960/xcp.png",
            Symbol: "XCP",
            CoinName: "CounterParty",
        },
        MAID: {
            ImageUrl: "/media/352247/maid.png",
            Symbol: "MAID",
            CoinName: "MaidSafe Coin",
        },
        "007": {
            ImageUrl: "/media/350595/007.png",
            Symbol: "007",
            CoinName: "007 coin",
        },
        NSR: {
            ImageUrl: "/media/20378/nsr.png",
            Symbol: "NSR",
            CoinName: "NuShares",
        },
        MONA: {
            ImageUrl: "/media/19801/mona.png",
            Symbol: "MONA",
            CoinName: "MonaCoin",
        },
        CELL: {
            ImageUrl: "/media/20227/cell.png",
            Symbol: "CELL",
            CoinName: "SolarFarm",
        },
        TEK: {
            ImageUrl: "/media/19929/tek.png",
            Symbol: "TEK",
            CoinName: "TekCoin",
        },
        TRON: {
            ImageUrl: "/media/20459/tron.png",
            Symbol: "TRON",
            CoinName: "Positron",
        },
        BAY: {
            ImageUrl: "/media/1383137/bay1.png",
            Symbol: "BAY",
            CoinName: "BitBay",
        },
        NTRN: {
            ImageUrl: "/media/12318281/ntrn.png",
            Symbol: "NTRN",
            CoinName: "Neutron",
        },
        SLING: {
            ImageUrl: "/media/20425/sling.png",
            Symbol: "SLING",
            CoinName: "Sling Coin",
        },
        XVC: {
            ImageUrl: "/media/350813/xvc.png",
            Symbol: "XVC",
            CoinName: "Vcash",
        },
        CRAVE: {
            ImageUrl: "/media/20242/crave.png",
            Symbol: "CRAVE",
            CoinName: "CraveCoin",
        },
        BLOCK: {
            ImageUrl: "/media/20204/block.png",
            Symbol: "BLOCK",
            CoinName: "BlockNet",
        },
        XSI: {
            ImageUrl: "/media/20165/xsi.png",
            Symbol: "XSI",
            CoinName: "Stability Shares",
        },
        "GHS*": {
            ImageUrl: "/media/19565/cexio.png",
            Symbol: "GHS*",
            CoinName: "Giga Hash",
        },
        BYC: {
            ImageUrl: "/media/20217/byc.png",
            Symbol: "BYC",
            CoinName: "ByteCent",
        },
        GRC: {
            ImageUrl: "/media/20307/grc.png",
            Symbol: "GRC",
            CoinName: "GridCoin",
        },
        GEMZ: {
            ImageUrl: "/media/19710/frac.png",
            Symbol: "GEMZ",
            CoinName: "Gemz Social",
        },
        KTK: {
            ImageUrl: "/media/19771/ktk.png",
            Symbol: "KTK",
            CoinName: "KryptCoin",
        },
        HZ: {
            ImageUrl: "/media/20320/hz.png",
            Symbol: "HZ",
            CoinName: "Horizon",
        },
        FAIR: {
            ImageUrl: "/media/20287/fair.png",
            Symbol: "FAIR",
            CoinName: "FairCoin",
        },
        QORA: {
            ImageUrl: "/media/19876/qora.png",
            Symbol: "QORA",
            CoinName: "QoraCoin",
        },
        RBY: {
            ImageUrl: "/media/351279/rby.png",
            Symbol: "RBY",
            CoinName: "RubyCoin",
        },
        PTC: {
            ImageUrl: "/media/19868/ptc.png",
            Symbol: "PTC",
            CoinName: "PesetaCoin",
        },
        KORE: {
            ImageUrl: "/media/14543972/kore.png",
            Symbol: "KORE",
            CoinName: "Kore",
        },
        WBB: {
            ImageUrl: "/media/20477/wbb.png",
            Symbol: "WBB",
            CoinName: "Wild Beast Coin",
        },
        SSD: {
            ImageUrl: "/media/20443/ssd.png",
            Symbol: "SSD",
            CoinName: "Sonic Screw Driver Coin",
        },
        XTC: {
            ImageUrl: "/media/20167/xtc.png",
            Symbol: "XTC",
            CoinName: "TileCoin",
        },
        NOTE: {
            ImageUrl: "/media/19832/note.png",
            Symbol: "NOTE",
            CoinName: "Dnotes",
        },
        "GRID*": {
            ImageUrl: "/media/20313/grid.png",
            Symbol: "GRID*",
            CoinName: "GridPay",
        },
        FLO: {
            ImageUrl: "/media/1383331/flo1.png",
            Symbol: "FLO",
            CoinName: "FlorinCoin",
        },
        MMXIV: {
            ImageUrl: "/media/19798/mmxiv.png",
            Symbol: "MMXIV",
            CoinName: "MaieutiCoin",
        },
        "8BIT": {
            ImageUrl: "/media/20176/8bit.png",
            Symbol: "8BIT",
            CoinName: "8BIT Coin",
        },
        STV: {
            ImageUrl: "/media/20444/stv.png",
            Symbol: "STV",
            CoinName: "Sativa Coin",
        },
        EBS: {
            ImageUrl: "/media/20267/ebs.png",
            Symbol: "EBS",
            CoinName: "EbolaShare",
        },
        AM: {
            ImageUrl: "/media/20191/am.png",
            Symbol: "AM",
            CoinName: "AeroMe",
        },
        XMG: {
            ImageUrl: "/media/20154/xmg.png",
            Symbol: "XMG",
            CoinName: "Coin Magi",
        },
        AMBER: {
            ImageUrl: "/media/20187/amber.png",
            Symbol: "AMBER",
            CoinName: "AmberCoin",
        },
        "JPC*": {
            ImageUrl: "/media/19759/jpc.png",
            Symbol: "JPC*",
            CoinName: "JackPotCoin",
        },
        NKT: {
            ImageUrl: "/media/20371/nkt.png",
            Symbol: "NKT",
            CoinName: "NakomotoDark",
        },
        J: {
            ImageUrl: "/media/20350/j.png",
            Symbol: "J",
            CoinName: "JoinCoin",
        },
        GHC: {
            ImageUrl: "/media/19721/ghc.png",
            Symbol: "GHC",
            CoinName: "GhostCoin",
        },
        "DTC*": {
            ImageUrl: "/media/19688/dtc.png",
            Symbol: "DTC*",
            CoinName: "DayTrader Coin",
        },
        ABY: {
            ImageUrl: "/media/1383379/aby.png",
            Symbol: "ABY",
            CoinName: "ArtByte",
        },
        LDOGE: {
            ImageUrl: "/media/20332/ldoge.png",
            Symbol: "LDOGE",
            CoinName: "LiteDoge",
        },
        MTR: {
            ImageUrl: "/media/19710/frac.png",
            Symbol: "MTR",
            CoinName: "MasterTraderCoin",
        },
        TRI: {
            ImageUrl: "/media/350568/tri.png",
            Symbol: "TRI",
            CoinName: "Triangles Coin",
        },
        SWARM: {
            ImageUrl: "/media/20445/swarm.png",
            Symbol: "SWARM",
            CoinName: "SwarmCoin",
        },
        BBR: {
            ImageUrl: "/media/19609/bbr.png",
            Symbol: "BBR",
            CoinName: "Boolberry",
        },
        BTCRY: {
            ImageUrl: "/media/20210/btcry.png",
            Symbol: "BTCRY",
            CoinName: "BitCrystal",
        },
        BCR: {
            ImageUrl: "/media/20198/bcr.png",
            Symbol: "BCR",
            CoinName: "BitCredit",
        },
        XPB: {
            ImageUrl: "/media/20158/xpb.png",
            Symbol: "XPB",
            CoinName: "Pebble Coin",
        },
        XDQ: {
            ImageUrl: "/media/19959/xdq.png",
            Symbol: "XDQ",
            CoinName: "Dirac Coin",
        },
        FLDC: {
            ImageUrl: "/media/20284/fldc.png",
            Symbol: "FLDC",
            CoinName: "Folding Coin",
        },
        SLR: {
            ImageUrl: "/media/20699/slr.png",
            Symbol: "SLR",
            CoinName: "SolarCoin",
        },
        SMAC: {
            ImageUrl: "/media/20427/smac.png",
            Symbol: "SMAC",
            CoinName: "Social Media Coin",
        },
        TRK: {
            ImageUrl: "/media/20460/trk.png",
            Symbol: "TRK",
            CoinName: "TruckCoin",
        },
        U: {
            ImageUrl: "/media/351629/u.jpg",
            Symbol: "U",
            CoinName: "Ucoin",
        },
        UIS: {
            ImageUrl: "/media/20455/uis.png",
            Symbol: "UIS",
            CoinName: "Unitus",
        },
        CYP: {
            ImageUrl: "/media/20248/cyp.png",
            Symbol: "CYP",
            CoinName: "CypherPunkCoin",
        },
        UFO: {
            ImageUrl: "/media/12318167/ufo1.png",
            Symbol: "UFO",
            CoinName: "UFO Coin",
        },
        ASN: {
            ImageUrl: "/media/20192/asn.png",
            Symbol: "ASN",
            CoinName: "Ascension Coin",
        },
        OC: {
            ImageUrl: "/media/19843/oc.png",
            Symbol: "OC",
            CoinName: "OrangeCoin",
        },
        GSM: {
            ImageUrl: "/media/20316/gsm.png",
            Symbol: "GSM",
            CoinName: "GSM Coin",
        },
        FSC: {
            ImageUrl: "/media/30001859/fsc.jpg",
            Symbol: "FSC",
            CoinName: "FriendshipCoin",
        },
        NXTTY: {
            ImageUrl: "/media/20379/nxtty.png",
            Symbol: "NXTTY",
            CoinName: "NXTTY",
        },
        QBK: {
            ImageUrl: "/media/20400/qbk.png",
            Symbol: "QBK",
            CoinName: "QuBuck Coin",
        },
        BLC: {
            ImageUrl: "/media/19623/blc.png",
            Symbol: "BLC",
            CoinName: "BlakeCoin",
        },
        MARYJ: {
            ImageUrl: "/media/20343/maryj.png",
            Symbol: "MARYJ",
            CoinName: "MaryJane Coin",
        },
        OMC: {
            ImageUrl: "/media/1381967/omc.png",
            Symbol: "OMC",
            CoinName: "OmniCron",
        },
        GIG: {
            ImageUrl: "/media/20294/gig.png",
            Symbol: "GIG",
            CoinName: "GigCoin",
        },
        CC: {
            ImageUrl: "/media/20225/cc.png",
            Symbol: "CC",
            CoinName: "CyberCoin",
        },
        BITS: {
            ImageUrl: "/media/19622/bits.png",
            Symbol: "BITS",
            CoinName: "BitstarCoin",
        },
        LTBC: {
            ImageUrl: "/media/20336/ltb.png",
            Symbol: "LTBC",
            CoinName: "LTBCoin",
        },
        NEOS: {
            ImageUrl: "/media/1382788/neos1.png",
            Symbol: "NEOS",
            CoinName: "NeosCoin",
        },
        HYPER: {
            ImageUrl: "/media/19744/hyper.png",
            Symbol: "HYPER",
            CoinName: "HyperCoin",
        },
        VTR: {
            ImageUrl: "/media/20471/vtr.png",
            Symbol: "VTR",
            CoinName: "Vtorrent",
        },
        METAL: {
            ImageUrl: "/media/20359/metal.png",
            Symbol: "METAL",
            CoinName: "MetalCoin",
        },
        PINK: {
            ImageUrl: "/media/350588/pinkcoin-logo.png",
            Symbol: "PINK",
            CoinName: "PinkCoin",
        },
        GRE: {
            ImageUrl: "/media/1382396/grn.png",
            Symbol: "GRE",
            CoinName: "GreenCoin",
        },
        XG: {
            ImageUrl: "/media/20156/xg.png",
            Symbol: "XG",
            CoinName: "XG Sports",
        },
        CHILD: {
            ImageUrl: "/media/20233/child.png",
            Symbol: "CHILD",
            CoinName: "ChildCoin",
        },
        BOOM: {
            ImageUrl: "/media/20208/boom.png",
            Symbol: "BOOM",
            CoinName: "BOOM Coin",
        },
        MINE: {
            ImageUrl: "/media/20356/mine.png",
            Symbol: "MINE",
            CoinName: "Instamine Nuggets",
        },
        ROS: {
            Symbol: "ROS",
            CoinName: "ROS Coin",
        },
        UNAT: {
            ImageUrl: "/media/20456/unat.png",
            Symbol: "UNAT",
            CoinName: "Unattanium",
        },
        SLM: {
            ImageUrl: "/media/20426/slm.png",
            Symbol: "SLM",
            CoinName: "SlimCoin",
        },
        GAIA: {
            ImageUrl: "/media/20290/gaia.png",
            Symbol: "GAIA",
            CoinName: "GAIA Platform",
        },
        TRUST: {
            ImageUrl: "/media/19935/trust.png",
            Symbol: "TRUST",
            CoinName: "TrustPlus",
        },
        FCN: {
            ImageUrl: "/media/20282/fcn.png",
            Symbol: "FCN",
            CoinName: "FantomCoin ",
        },
        XCN: {
            ImageUrl: "/media/20483/xcn.png",
            Symbol: "XCN",
            CoinName: "Cryptonite",
        },
        CURE: {
            ImageUrl: "/media/1383812/cure.png",
            Symbol: "CURE",
            CoinName: "Curecoin",
        },
        GMC: {
            ImageUrl: "/media/20299/gmc.png",
            Symbol: "GMC",
            CoinName: "Gridmaster",
        },
        MMC: {
            ImageUrl: "/media/19795/mmc.png",
            Symbol: "MMC",
            CoinName: "MemoryCoin",
        },
        XBC: {
            ImageUrl: "/media/20488/xbc.png",
            Symbol: "XBC",
            CoinName: "BitcoinPlus",
        },
        CYC: {
            ImageUrl: "/media/19671/cyc.png",
            Symbol: "CYC",
            CoinName: "ConSpiracy Coin ",
        },
        OCTO: {
            ImageUrl: "/media/19983/888.png",
            Symbol: "OCTO",
            CoinName: "OctoCoin",
        },
        MSC: {
            ImageUrl: "/media/19814/mst.png",
            Symbol: "MSC",
            CoinName: "MasterCoin",
        },
        EGG: {
            ImageUrl: "/media/20269/egg.png",
            Symbol: "EGG",
            CoinName: "EggCoin",
        },
        C2: {
            ImageUrl: "/media/19640/c2.png",
            Symbol: "C2",
            CoinName: "Coin.2",
        },
        GSX: {
            ImageUrl: "/media/20314/gsxjpeg.png",
            Symbol: "GSX",
            CoinName: "GlowShares",
        },
        CAM: {
            ImageUrl: "/media/20220/cam.png",
            Symbol: "CAM",
            CoinName: "Camcoin",
        },
        RBR: {
            ImageUrl: "/media/20408/rbr.png",
            Symbol: "RBR",
            CoinName: "Ribbit Rewards",
        },
        XQN: {
            ImageUrl: "/media/12318067/xqn.png",
            Symbol: "XQN",
            CoinName: "Quotient",
        },
        ICASH: {
            ImageUrl: "/media/20319/icash.png",
            Symbol: "ICASH",
            CoinName: "ICASH",
        },
        NODE: {
            ImageUrl: "/media/20373/node.png",
            Symbol: "NODE",
            CoinName: "Node",
        },
        SOON: {
            ImageUrl: "/media/20436/soon.png",
            Symbol: "SOON",
            CoinName: "SoonCoin",
        },
        BTMI: {
            ImageUrl: "/media/20213/btmi.png",
            Symbol: "BTMI",
            CoinName: "BitMiles",
        },
        EVENT: {
            ImageUrl: "/media/20277/event.png",
            Symbol: "EVENT",
            CoinName: "Event Token",
        },
        "1CR": {
            ImageUrl: "/media/20175/1cr.png",
            Symbol: "1CR",
            CoinName: "1Credit",
        },
        VIOR: {
            ImageUrl: "/media/20469/viorjpeg.png",
            Symbol: "VIOR",
            CoinName: "ViorCoin",
        },
        XCO: {
            ImageUrl: "/media/20486/xco.png",
            Symbol: "XCO",
            CoinName: "XCoin",
        },
        VMC: {
            ImageUrl: "/media/19943/vmc.png",
            Symbol: "VMC",
            CoinName: "VirtualMining Coin",
        },
        MRS: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "MRS",
            CoinName: "MarsCoin",
        },
        VIRAL: {
            ImageUrl: "/media/20472/viral.png",
            Symbol: "VIRAL",
            CoinName: "Viral Coin",
        },
        EQM: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "EQM",
            CoinName: "Equilibrium Coin",
        },
        ISL: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "ISL",
            CoinName: "IslaCoin",
        },
        QSLV: {
            ImageUrl: "/media/20404/qslv.png",
            Symbol: "QSLV",
            CoinName: "Quicksilver coin",
        },
        XWT: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "XWT",
            CoinName: "World Trade Funds",
        },
        XNA: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "XNA",
            CoinName: "DeOxyRibose",
        },
        RDN: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "RDN",
            CoinName: "RadonPay",
        },
        SKB: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "SKB",
            CoinName: "SkullBuzz",
        },
        BSTY: {
            ImageUrl: "/media/27010496/bsty.png",
            Symbol: "BSTY",
            CoinName: "GlobalBoost",
        },
        FCS: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "FCS",
            CoinName: "CryptoFocus",
        },
        GAM: {
            ImageUrl: "/media/20293/gam.png",
            Symbol: "GAM",
            CoinName: "Gambit coin",
        },
        NXS: {
            ImageUrl: "/media/1382387/nexus.jpg",
            Symbol: "NXS",
            CoinName: "Nexus",
        },
        CESC: {
            ImageUrl: "/media/350786/cesc.png",
            Symbol: "CESC",
            CoinName: "Crypto Escudo",
        },
        TWLV: {
            ImageUrl: "/media/20472/viral.png",
            Symbol: "TWLV",
            CoinName: "Twelve Coin",
        },
        EAGS: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "EAGS",
            CoinName: "EagsCoin",
        },
        MWC: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "MWC",
            CoinName: "MultiWallet Coin",
        },
        ADC: {
            ImageUrl: "/media/350880/adc.png",
            Symbol: "ADC",
            CoinName: "AudioCoin",
        },
        MARS: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "MARS",
            CoinName: "MarsCoin ",
        },
        XMS: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "XMS",
            CoinName: "Megastake",
        },
        SPHR: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "SPHR",
            CoinName: "Sphere Coin",
        },
        SIGU: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "SIGU",
            CoinName: "Singular",
        },
        "BTX*": {
            ImageUrl: "/media/20215/btx.png",
            Symbol: "BTX*",
            CoinName: "BitcoinTX",
        },
        DCC: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "DCC",
            CoinName: "DarkCrave",
        },
        M1: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "M1",
            CoinName: "SupplyShock",
        },
        DB: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "DB",
            CoinName: "DarkBit",
        },
        CTO: {
            ImageUrl: "/media/19808/mrs.png",
            Symbol: "CTO",
            CoinName: "Crypto",
        },
        EDGE: {
            ImageUrl: "/media/20556/edge.png",
            Symbol: "EDGE",
            CoinName: "EdgeCoin",
        },
        "LUX*": {
            ImageUrl: "/media/20557/lux.png",
            Symbol: "LUX*",
            CoinName: "BitLux",
        },
        FUTC: {
            ImageUrl: "/media/20558/futc.png",
            Symbol: "FUTC",
            CoinName: "FutCoin",
        },
        GLOBE: {
            ImageUrl: "/media/20564/globe.png",
            Symbol: "GLOBE",
            CoinName: "Global",
        },
        TAM: {
            ImageUrl: "/media/20565/tam.png",
            Symbol: "TAM",
            CoinName: "TamaGucci",
        },
        MRP: {
            ImageUrl: "/media/20357/mrp.png",
            Symbol: "MRP",
            CoinName: "MorpheusCoin",
        },
        CREVA: {
            ImageUrl: "/media/20571/creva.png",
            Symbol: "CREVA",
            CoinName: "Creva Coin",
        },
        XFC: {
            ImageUrl: "/media/20574/xfc.png",
            Symbol: "XFC",
            CoinName: "Forever Coin",
        },
        NANAS: {
            ImageUrl: "/media/20575/nanas.png",
            Symbol: "NANAS",
            CoinName: "BananaBits",
        },
        LOG: {
            ImageUrl: "/media/20335/log.png",
            Symbol: "LOG",
            CoinName: "Wood Coin",
        },
        XCE: {
            ImageUrl: "/media/20573/xce.png",
            Symbol: "XCE",
            CoinName: "Cerium",
        },
        ACP: {
            ImageUrl: "/media/351019/acp.png",
            Symbol: "ACP",
            CoinName: "Anarchists Prime",
        },
        DRZ: {
            ImageUrl: "/media/20605/drz.png",
            Symbol: "DRZ",
            CoinName: "Droidz",
        },
        "BUCKS*": {
            ImageUrl: "/media/20602/bucks.png",
            Symbol: "BUCKS*",
            CoinName: "GorillaBucks",
        },
        BSC: {
            ImageUrl: "/media/20601/bsc.png",
            Symbol: "BSC",
            CoinName: "BowsCoin",
        },
        DRKT: {
            ImageUrl: "/media/20604/drkt.png",
            Symbol: "DRKT",
            CoinName: "DarkTron",
        },
        CIRC: {
            ImageUrl: "/media/20603/circ.png",
            Symbol: "CIRC",
            CoinName: "CryptoCircuits",
        },
        NKA: {
            ImageUrl: "/media/20367/nka.png",
            Symbol: "NKA",
            CoinName: "IncaKoin",
        },
        VERSA: {
            ImageUrl: "/media/20629/versa.png",
            Symbol: "VERSA",
            CoinName: "Versa Token",
        },
        EPY: {
            ImageUrl: "/media/20628/epy.png",
            Symbol: "EPY",
            CoinName: "Empyrean",
        },
        SQL: {
            ImageUrl: "/media/20441/sql.png",
            Symbol: "SQL",
            CoinName: "Squall Coin",
        },
        POLY: {
            ImageUrl: "/media/20631/poly.png",
            Symbol: "POLY",
            CoinName: "PolyBit",
        },
        PIGGY: {
            ImageUrl: "/media/19854/piggy.png",
            Symbol: "PIGGY",
            CoinName: "Piggy Coin",
        },
        CHA: {
            ImageUrl: "/media/19986/a3c.png",
            Symbol: "CHA",
            CoinName: "Charity Coin",
        },
        MIL: {
            ImageUrl: "/media/20354/mil.png",
            Symbol: "MIL",
            CoinName: "Milllionaire Coin",
        },
        CRW: {
            ImageUrl: "/media/1383378/crw1.png",
            Symbol: "CRW",
            CoinName: "Crown Coin",
        },
        GEN: {
            ImageUrl: "/media/20640/gen.png",
            Symbol: "GEN",
            CoinName: "Genstake",
        },
        XPH: {
            ImageUrl: "/media/20641/xph.png",
            Symbol: "XPH",
            CoinName: "PharmaCoin",
        },
        GRM: {
            ImageUrl: "/media/20642/grm.png",
            Symbol: "GRM",
            CoinName: "GridMaster",
        },
        QTZ: {
            ImageUrl: "/media/20643/qtz.png",
            Symbol: "QTZ",
            CoinName: "Quartz",
        },
        ARB: {
            ImageUrl: "/media/20645/arb.png",
            Symbol: "ARB",
            CoinName: "Arbit Coin",
        },
        LTS: {
            ImageUrl: "/media/20644/lts.png",
            Symbol: "LTS",
            CoinName: "Litestar Coin",
        },
        SPC: {
            ImageUrl: "/media/20655/spc.png",
            Symbol: "SPC",
            CoinName: "SpinCoin",
        },
        GP: {
            ImageUrl: "/media/20656/gp.png",
            Symbol: "GP",
            CoinName: "GoldPieces",
        },
        BITZ: {
            ImageUrl: "/media/20654/bitz.png",
            Symbol: "BITZ",
            CoinName: "Bitz Coin",
        },
        DUB: {
            ImageUrl: "/media/19986/a3c.png",
            Symbol: "DUB",
            CoinName: "DubCoin",
        },
        GRAV: {
            ImageUrl: "/media/20659/grav.png",
            Symbol: "GRAV",
            CoinName: "Graviton",
        },
        "BOB*": {
            ImageUrl: "/media/20207/bob.png",
            Symbol: "BOB*",
            CoinName: "Bob Coin",
        },
        MCN: {
            ImageUrl: "/media/20346/mcn.png",
            Symbol: "MCN",
            CoinName: "MonetaVerde",
        },
        QCN: {
            ImageUrl: "/media/19877/qcn.png",
            Symbol: "QCN",
            CoinName: "Quazar Coin",
        },
        HEDG: {
            ImageUrl: "/media/20663/hedg.png",
            Symbol: "HEDG",
            CoinName: "Hedgecoin",
        },
        SONG: {
            ImageUrl: "/media/20432/song.png",
            Symbol: "SONG",
            CoinName: "Song Coin",
        },
        XSEED: {
            ImageUrl: "/media/20163/xseed.png",
            Symbol: "XSEED",
            CoinName: "BitSeeds",
        },
        CRE: {
            ImageUrl: "/media/20683/cre.png",
            Symbol: "CRE",
            CoinName: "Credits",
        },
        AXIOM: {
            ImageUrl: "/media/20686/axiom.png",
            Symbol: "AXIOM",
            CoinName: "Axiom Coin",
        },
        SMLY: {
            ImageUrl: "/media/20429/smly.png",
            Symbol: "SMLY",
            CoinName: "SmileyCoin",
        },
        RBT: {
            ImageUrl: "/media/20407/rbt.png",
            Symbol: "RBT",
            CoinName: "Rimbit",
        },
        CHIP: {
            ImageUrl: "/media/20685/chip.png",
            Symbol: "CHIP",
            CoinName: "Chip",
        },
        SPEC: {
            ImageUrl: "/media/20689/spec.png",
            Symbol: "SPEC",
            CoinName: "SpecCoin",
        },
        UNC: {
            ImageUrl: "/media/20693/unc.png",
            Symbol: "UNC",
            CoinName: "UnCoin",
        },
        SPRTS: {
            ImageUrl: "/media/20692/sprts.png",
            Symbol: "SPRTS",
            CoinName: "Sprouts",
        },
        ZNY: {
            ImageUrl: "/media/20691/zny.png",
            Symbol: "ZNY",
            CoinName: "BitZeny",
        },
        BTQ: {
            ImageUrl: "/media/19638/btq.png",
            Symbol: "BTQ",
            CoinName: "BitQuark",
        },
        PKB: {
            ImageUrl: "/media/20694/pkb.png",
            Symbol: "PKB",
            CoinName: "ParkByte",
        },
        "STR*": {
            ImageUrl: "/media/19920/str.png",
            Symbol: "STR*",
            CoinName: "StarCoin",
        },
        SNRG: {
            ImageUrl: "/media/20700/snrg.png",
            Symbol: "SNRG",
            CoinName: "Synergy",
        },
        GHOUL: {
            ImageUrl: "/media/20701/ghoul.png",
            Symbol: "GHOUL",
            CoinName: "Ghoul Coin",
        },
        HNC: {
            ImageUrl: "/media/20702/hnc.png",
            Symbol: "HNC",
            CoinName: "Hellenic Coin",
        },
        DIGS: {
            ImageUrl: "/media/20706/digs.png",
            Symbol: "DIGS",
            CoinName: "Diggits",
        },
        EXP: {
            ImageUrl: "/media/20707/exp.png",
            Symbol: "EXP",
            CoinName: "Expanse",
        },
        GCR: {
            ImageUrl: "/media/20708/gcr.png",
            Symbol: "GCR",
            CoinName: "Global Currency Reserve",
        },
        MAPC: {
            ImageUrl: "/media/20710/mapc.png",
            Symbol: "MAPC",
            CoinName: "MapCoin",
        },
        MI: {
            ImageUrl: "/media/20711/mi.png",
            Symbol: "MI",
            CoinName: "XiaoMiCoin",
        },
        CON: {
            ImageUrl: "/media/20717/con_.png",
            Symbol: "CON",
            CoinName: "Paycon",
        },
        "NEU*": {
            ImageUrl: "/media/20721/neu.png",
            Symbol: "NEU*",
            CoinName: "NeuCoin",
        },
        TX: {
            ImageUrl: "/media/20722/tx.png",
            Symbol: "TX",
            CoinName: "Transfer",
        },
        GRS: {
            ImageUrl: "/media/20780736/grs.png",
            Symbol: "GRS",
            CoinName: "Groestlcoin ",
        },
        SC: {
            ImageUrl: "/media/20726/siacon-logo.png",
            Symbol: "SC",
            CoinName: "Siacoin",
        },
        CLV: {
            ImageUrl: "/media/20727/clv.png",
            Symbol: "CLV",
            CoinName: "CleverCoin",
        },
        FCT: {
            ImageUrl: "/media/1382863/fct1.png",
            Symbol: "FCT",
            CoinName: "Factoids",
        },
        LYB: {
            ImageUrl: "/media/20339/lyb.png",
            Symbol: "LYB",
            CoinName: "LyraBar",
        },
        BST: {
            ImageUrl: "/media/350558/bst.png",
            Symbol: "BST",
            CoinName: "BitStone",
        },
        PXI: {
            ImageUrl: "/media/350559/pxi.png",
            Symbol: "PXI",
            CoinName: "Prime-X1",
        },
        CPC: {
            ImageUrl: "/media/350560/cpc.png",
            Symbol: "CPC",
            CoinName: "CapriCoin",
        },
        AMS: {
            ImageUrl: "/media/350562/ams.png",
            Symbol: "AMS",
            CoinName: "Amsterdam Coin",
        },
        OBITS: {
            ImageUrl: "/media/350565/obits.png",
            Symbol: "OBITS",
            CoinName: "Obits Coin",
        },
        CLUB: {
            ImageUrl: "/media/350609/club.png",
            Symbol: "CLUB",
            CoinName: " ClubCoin",
        },
        RADS: {
            ImageUrl: "/media/350610/rads.png",
            Symbol: "RADS",
            CoinName: "Radium",
        },
        EMC: {
            ImageUrl: "/media/350611/emc.png",
            Symbol: "EMC",
            CoinName: "Emercoin",
        },
        BLITZ: {
            ImageUrl: "/media/350612/blitz.png",
            Symbol: "BLITZ",
            CoinName: "BlitzCoin",
        },
        "HIRE*": {
            ImageUrl: "/media/350613/hire.png",
            Symbol: "HIRE*",
            CoinName: "BitHIRE",
        },
        EGC: {
            ImageUrl: "/media/350614/egc.png",
            Symbol: "EGC",
            CoinName: "EverGreenCoin",
        },
        MND: {
            ImageUrl: "/media/350616/mnd.png",
            Symbol: "MND",
            CoinName: "MindCoin",
        },
        I0C: {
            ImageUrl: "/media/350691/i0c.png",
            Symbol: "I0C",
            CoinName: "I0coin",
        },
        BTA: {
            ImageUrl: "/media/1383113/bta1.png",
            Symbol: "BTA",
            CoinName: "Bata",
        },
        KARMA: {
            ImageUrl: "/media/350693/karm.png",
            Symbol: "KARMA",
            CoinName: "Karmacoin",
        },
        DCR: {
            ImageUrl: "/media/1382607/decred.png",
            Symbol: "DCR",
            CoinName: "Decred",
        },
        NAS2: {
            ImageUrl: "/media/350776/nas2.png",
            Symbol: "NAS2",
            CoinName: "Nas2Coin",
        },
        PAK: {
            ImageUrl: "/media/350788/pak.png",
            Symbol: "PAK",
            CoinName: "Pakcoin",
        },
        CRB: {
            ImageUrl: "/media/1382904/crbit1.png",
            Symbol: "CRB",
            CoinName: "Creditbit ",
        },
        DOGED: {
            ImageUrl: "/media/20029/doged.png",
            Symbol: "DOGED",
            CoinName: "DogeCoinDark",
        },
        OK: {
            ImageUrl: "/media/350819/ok.png",
            Symbol: "OK",
            CoinName: "OKCash",
        },
        RVR: {
            ImageUrl: "/media/30002218/rvr.jpg",
            Symbol: "RVR",
            CoinName: "Revolution VR",
        },
        AMP: {
            ImageUrl: "/media/350825/amp.png",
            Symbol: "AMP",
            CoinName: "Synereo",
        },
        HODL: {
            ImageUrl: "/media/350840/hodl.png",
            Symbol: "HODL",
            CoinName: "HOdlcoin",
        },
        EDRC: {
            ImageUrl: "/media/350858/edrc.jpg",
            Symbol: "EDRC",
            CoinName: "EDRCoin",
        },
        LSK: {
            ImageUrl: "/media/27011060/lsk.png",
            Symbol: "LSK",
            CoinName: "Lisk",
        },
        WAVES: {
            ImageUrl: "/media/27010639/waves2.png",
            Symbol: "WAVES",
            CoinName: "Waves",
        },
        HTC: {
            ImageUrl: "/media/350888/htc.png",
            Symbol: "HTC",
            CoinName: "Hitcoin",
        },
        GAME: {
            ImageUrl: "/media/350887/game.png",
            Symbol: "GAME",
            CoinName: "Gamecredits",
        },
        DSH: {
            ImageUrl: "/media/20026/dash.png",
            Symbol: "DSH",
            CoinName: "Dashcoin",
        },
        DBIC: {
            ImageUrl: "/media/350891/dbic.png",
            Symbol: "DBIC",
            CoinName: "DubaiCoin",
        },
        XHI: {
            ImageUrl: "/media/350892/xhi.png",
            Symbol: "XHI",
            CoinName: "HiCoin",
        },
        SPOTS: {
            ImageUrl: "/media/350893/spots.png",
            Symbol: "SPOTS",
            CoinName: "Spots",
        },
        BIOS: {
            ImageUrl: "/media/350894/bios.png",
            Symbol: "BIOS",
            CoinName: "BiosCrypto",
        },
        CAB: {
            ImageUrl: "/media/350896/cab.png",
            Symbol: "CAB",
            CoinName: "CabbageUnit",
        },
        DIEM: {
            ImageUrl: "/media/20260/diem_1.png",
            Symbol: "DIEM",
            CoinName: "CarpeDiemCoin",
        },
        GBT: {
            ImageUrl: "/media/350897/gbt.png",
            Symbol: "GBT",
            CoinName: "GameBetCoin",
        },
        "SAR*": {
            ImageUrl: "/media/350901/sar.png",
            Symbol: "SAR*",
            CoinName: "SARCoin",
        },
        RCX: {
            ImageUrl: "/media/350902/rcx.png",
            Symbol: "RCX",
            CoinName: "RedCrowCoin",
        },
        PWR: {
            ImageUrl: "/media/30002119/pwr.png",
            Symbol: "PWR",
            CoinName: "PWR Coin",
        },
        TRUMP: {
            ImageUrl: "/media/350905/trump.png",
            Symbol: "TRUMP",
            CoinName: "TrumpCoin",
        },
        PRM: {
            ImageUrl: "/media/350906/prm.png",
            Symbol: "PRM",
            CoinName: "PrismChain",
        },
        BCY: {
            ImageUrl: "/media/350903/bcy.png",
            Symbol: "BCY",
            CoinName: "BitCrystals",
        },
        RBIES: {
            ImageUrl: "/media/350904/rbies.png",
            Symbol: "RBIES",
            CoinName: "Rubies",
        },
        STEEM: {
            ImageUrl: "/media/350907/steem.png",
            Symbol: "STEEM",
            CoinName: "Steem",
        },
        BLRY: {
            ImageUrl: "/media/350908/blry.png",
            Symbol: "BLRY",
            CoinName: "BillaryCoin",
        },
        XWC: {
            ImageUrl: "/media/33957374/xwc.png",
            Symbol: "XWC",
            CoinName: "WhiteCoin",
        },
        DOT: {
            ImageUrl: "/media/350909/dot.png",
            Symbol: "DOT",
            CoinName: "Dotcoin",
        },
        SCOT: {
            ImageUrl: "/media/20416/scot_1.png",
            Symbol: "SCOT",
            CoinName: "Scotcoin",
        },
        DNET: {
            ImageUrl: "/media/350912/dnet.png",
            Symbol: "DNET",
            CoinName: "Darknet",
        },
        BAC: {
            ImageUrl: "/media/350913/bac.png",
            Symbol: "BAC",
            CoinName: "BitalphaCoin",
        },
        "XID*": {
            ImageUrl: "/media/350916/xid.png",
            Symbol: "XID*",
            CoinName: "International Diamond Coin",
        },
        TCR: {
            ImageUrl: "/media/350918/tcr.png",
            Symbol: "TCR",
            CoinName: "Thecreed",
        },
        POST: {
            ImageUrl: "/media/350917/post.png",
            Symbol: "POST",
            CoinName: "PostCoin",
        },
        INFX: {
            ImageUrl: "/media/350919/infx.png",
            Symbol: "INFX",
            CoinName: "Influxcoin",
        },
        ETHS: {
            ImageUrl: "/media/350910/eths.png",
            Symbol: "ETHS",
            CoinName: "EthereumScrypt",
        },
        PXL: {
            ImageUrl: "/media/350930/pxl.png",
            Symbol: "PXL",
            CoinName: "Phalanx",
        },
        NUM: {
            ImageUrl: "/media/350932/num.png",
            Symbol: "NUM",
            CoinName: "NumbersCoin",
        },
        SOUL: {
            ImageUrl: "/media/350930/pxl.png",
            Symbol: "SOUL",
            CoinName: "SoulCoin",
        },
        ION: {
            ImageUrl: "/media/350933/ion.jpg",
            Symbol: "ION",
            CoinName: "Ionomy",
        },
        GROW: {
            ImageUrl: "/media/350934/grow.png",
            Symbol: "GROW",
            CoinName: "GrownCoin",
        },
        UNITY: {
            ImageUrl: "/media/350935/unity_1.png",
            Symbol: "UNITY",
            CoinName: "SuperNET",
        },
        OLDSF: {
            ImageUrl: "/media/350936/oldsf.png",
            Symbol: "OLDSF",
            CoinName: "OldSafeCoin",
        },
        SSTC: {
            ImageUrl: "/media/350937/ssc.png",
            Symbol: "SSTC",
            CoinName: "SunShotCoin",
        },
        NETC: {
            ImageUrl: "/media/350938/netc.png",
            Symbol: "NETC",
            CoinName: "NetworkCoin",
        },
        GPU: {
            ImageUrl: "/media/350939/gpu.png",
            Symbol: "GPU",
            CoinName: "GPU Coin",
        },
        TAGR: {
            ImageUrl: "/media/350940/tagr.png",
            Symbol: "TAGR",
            CoinName: "Think And Get Rich Coin",
        },
        HMP: {
            ImageUrl: "/media/350941/hmp.png",
            Symbol: "HMP",
            CoinName: "HempCoin",
        },
        ADZ: {
            ImageUrl: "/media/351424/adz1.jpg",
            Symbol: "ADZ",
            CoinName: "Adzcoin",
        },
        GAP: {
            ImageUrl: "/media/350943/gap.png",
            Symbol: "GAP",
            CoinName: "Gapcoin",
        },
        MYC: {
            ImageUrl: "/media/350947/myc.png",
            Symbol: "MYC",
            CoinName: "MayaCoin",
        },
        IVZ: {
            ImageUrl: "/media/350944/ivz.png",
            Symbol: "IVZ",
            CoinName: "InvisibleCoin",
        },
        VTA: {
            ImageUrl: "/media/350945/vta.png",
            Symbol: "VTA",
            CoinName: "VirtaCoin",
        },
        SLS: {
            ImageUrl: "/media/350946/sls.png",
            Symbol: "SLS",
            CoinName: "SaluS",
        },
        SOIL: {
            ImageUrl: "/media/350949/soil.png",
            Symbol: "SOIL",
            CoinName: "SoilCoin",
        },
        CUBE: {
            ImageUrl: "/media/350948/cube.png",
            Symbol: "CUBE",
            CoinName: "DigiCube",
        },
        YOC: {
            ImageUrl: "/media/350957/yoc.png",
            Symbol: "YOC",
            CoinName: "YoCoin",
        },
        "COIN*": {
            ImageUrl: "/media/350950/coin.png",
            Symbol: "COIN*",
            CoinName: "Coin",
        },
        VPRC: {
            ImageUrl: "/media/350951/vpc.png",
            Symbol: "VPRC",
            CoinName: "VapersCoin",
        },
        APC: {
            ImageUrl: "/media/350956/apc.png",
            Symbol: "APC",
            CoinName: "AlpaCoin",
        },
        STEPS: {
            ImageUrl: "/media/350952/steps.png",
            Symbol: "STEPS",
            CoinName: "Steps",
        },
        DBTC: {
            ImageUrl: "/media/350953/dbtc.png",
            Symbol: "DBTC",
            CoinName: "DebitCoin",
        },
        UNIT: {
            ImageUrl: "/media/350954/unit.png",
            Symbol: "UNIT",
            CoinName: "Universal Currency",
        },
        AEON: {
            ImageUrl: "/media/350955/aeon.png",
            Symbol: "AEON",
            CoinName: "AEON",
        },
        SIB: {
            ImageUrl: "/media/350958/sib.png",
            Symbol: "SIB",
            CoinName: "SibCoin",
        },
        ERC: {
            ImageUrl: "/media/350960/erc.png",
            Symbol: "ERC",
            CoinName: "EuropeCoin",
        },
        AIB: {
            ImageUrl: "/media/350971/aib.png",
            Symbol: "AIB",
            CoinName: "AdvancedInternetBlock",
        },
        PRIME: {
            ImageUrl: "/media/350979/prime.png",
            Symbol: "PRIME",
            CoinName: "PrimeChain",
        },
        BERN: {
            ImageUrl: "/media/350973/bern.png",
            Symbol: "BERN",
            CoinName: "BERNcash",
        },
        BIGUP: {
            ImageUrl: "/media/350980/bigup.png",
            Symbol: "BIGUP",
            CoinName: "BigUp",
        },
        KR: {
            ImageUrl: "/media/350974/kr.png",
            Symbol: "KR",
            CoinName: "Krypton",
        },
        XRE: {
            ImageUrl: "/media/33187859/xre.png",
            Symbol: "XRE",
            CoinName: "RevolverCoin",
        },
        "1337": {
            ImageUrl: "/media/350976/1337.png",
            Symbol: "1337",
            CoinName: "1337",
        },
        MEME: {
            ImageUrl: "/media/1383050/pepecoin-512.png",
            Symbol: "MEME",
            CoinName: "Pepe",
        },
        XDB: {
            ImageUrl: "/media/350977/xdb.png",
            Symbol: "XDB",
            CoinName: "DragonSphere",
        },
        ANTI: {
            ImageUrl: "/media/350972/anti.png",
            Symbol: "ANTI",
            CoinName: "Anti Bitcoin",
        },
        BRK: {
            ImageUrl: "/media/350981/brk.png",
            Symbol: "BRK",
            CoinName: "BreakoutCoin",
        },
        COLX: {
            ImageUrl: "/media/12318297/colx.png",
            Symbol: "COLX",
            CoinName: "ColossusCoinXT",
        },
        MNM: {
            ImageUrl: "/media/350982/mnm.png",
            Symbol: "MNM",
            CoinName: "Mineum",
        },
        ADCN: {
            ImageUrl: "/media/350983/adcn.png",
            Symbol: "ADCN",
            CoinName: "Asiadigicoin",
        },
        ZEIT: {
            ImageUrl: "/media/350984/zeit.png",
            Symbol: "ZEIT",
            CoinName: "ZeitCoin",
        },
        "611": {
            ImageUrl: "/media/350985/611.png",
            Symbol: "611",
            CoinName: "SixEleven",
        },
        "2GIVE": {
            ImageUrl: "/media/350986/2give.png",
            Symbol: "2GIVE",
            CoinName: "2GiveCoin",
        },
        CGA: {
            ImageUrl: "/media/350988/cga.png",
            Symbol: "CGA",
            CoinName: "Cryptographic Anomaly",
        },
        SWING: {
            ImageUrl: "/media/350987/swing.png",
            Symbol: "SWING",
            CoinName: "SwingCoin",
        },
        SAFEX: {
            ImageUrl: "/media/1383986/safex.png",
            Symbol: "SAFEX",
            CoinName: "SafeExchangeCoin",
        },
        NEBU: {
            ImageUrl: "/media/350990/nebu.png",
            Symbol: "NEBU",
            CoinName: "Nebuchadnezzar",
        },
        AEC: {
            ImageUrl: "/media/350991/aec.png",
            Symbol: "AEC",
            CoinName: "AcesCoin",
        },
        FRN: {
            ImageUrl: "/media/350992/frn.png",
            Symbol: "FRN",
            CoinName: "Francs",
        },
        ADN: {
            ImageUrl: "/media/350993/adn.png",
            Symbol: "ADN",
            CoinName: "Aiden",
        },
        PULSE: {
            ImageUrl: "/media/350994/pulse.jpg",
            Symbol: "PULSE",
            CoinName: "Pulse",
        },
        N7: {
            ImageUrl: "/media/350995/n7.jpg",
            Symbol: "N7",
            CoinName: "Number7",
        },
        CYG: {
            ImageUrl: "/media/350997/cygnus.png",
            Symbol: "CYG",
            CoinName: "Cygnus",
        },
        LGBTQ: {
            ImageUrl: "/media/350996/lgbtq.png",
            Symbol: "LGBTQ",
            CoinName: "LGBTQoin",
        },
        UTH: {
            ImageUrl: "/media/350998/uth.png",
            Symbol: "UTH",
            CoinName: "Uther",
        },
        MPRO: {
            ImageUrl: "/media/350999/mpro.jpg",
            Symbol: "MPRO",
            CoinName: "MediumProject",
        },
        KAT: {
            ImageUrl: "/media/351028/katz.png",
            Symbol: "KAT",
            CoinName: "KATZcoin",
        },
        "404": {
            ImageUrl: "/media/351001/404.png",
            Symbol: "404",
            CoinName: "404Coin",
        },
        SPM: {
            ImageUrl: "/media/351002/sup.png",
            Symbol: "SPM",
            CoinName: "Supreme",
        },
        MOJO: {
            ImageUrl: "/media/351003/mojo.png",
            Symbol: "MOJO",
            CoinName: "Mojocoin",
        },
        BELA: {
            ImageUrl: "/media/351933/bela.jpg",
            Symbol: "BELA",
            CoinName: "BelaCoin",
        },
        FLX: {
            ImageUrl: "/media/351007/flx.png",
            Symbol: "FLX",
            CoinName: "Flash",
        },
        BOLI: {
            ImageUrl: "/media/351008/boli.png",
            Symbol: "BOLI",
            CoinName: "BolivarCoin",
        },
        CLUD: {
            ImageUrl: "/media/351027/clud.png",
            Symbol: "CLUD",
            CoinName: "CludCoin",
        },
        DIME: {
            ImageUrl: "/media/25792612/dime.png",
            Symbol: "DIME",
            CoinName: "DimeCoin",
        },
        FLY: {
            ImageUrl: "/media/351013/fly.png",
            Symbol: "FLY",
            CoinName: "FlyCoin",
        },
        HVCO: {
            ImageUrl: "/media/351014/hvco.png",
            Symbol: "HVCO",
            CoinName: "High Voltage Coin",
        },
        GIZ: {
            ImageUrl: "/media/351015/giz.png",
            Symbol: "GIZ",
            CoinName: "GIZMOcoin",
        },
        GREXIT: {
            ImageUrl: "/media/351016/grexit.png",
            Symbol: "GREXIT",
            CoinName: "GrexitCoin",
        },
        CARBON: {
            ImageUrl: "/media/351017/carbon.png",
            Symbol: "CARBON",
            CoinName: "Carboncoin",
        },
        DEUR: {
            ImageUrl: "/media/351018/deur.png",
            Symbol: "DEUR",
            CoinName: "DigiEuro",
        },
        TUR: {
            ImageUrl: "/media/351020/tur.png",
            Symbol: "TUR",
            CoinName: "Turron",
        },
        LEMON: {
            ImageUrl: "/media/351021/lemon.png",
            Symbol: "LEMON",
            CoinName: "LemonCoin",
        },
        STS: {
            ImageUrl: "/media/351022/sts.png",
            Symbol: "STS",
            CoinName: "STRESScoin",
        },
        DISK: {
            ImageUrl: "/media/351023/disk.png",
            Symbol: "DISK",
            CoinName: "Dark Lisk",
        },
        NEVA: {
            ImageUrl: "/media/351026/neva.png",
            Symbol: "NEVA",
            CoinName: "NevaCoin",
        },
        CYT: {
            ImageUrl: "/media/351024/cyt.png",
            Symbol: "CYT",
            CoinName: "Cryptokenz",
        },
        FUZZ: {
            ImageUrl: "/media/351025/fuzz.png",
            Symbol: "FUZZ",
            CoinName: "Fuzzballs",
        },
        NKC: {
            ImageUrl: "/media/351041/nkc.png",
            Symbol: "NKC",
            CoinName: "Nukecoinz",
        },
        SCRT: {
            ImageUrl: "/media/351031/scrt.png",
            Symbol: "SCRT",
            CoinName: "SecretCoin",
        },
        XRA: {
            ImageUrl: "/media/351032/xra.png",
            Symbol: "XRA",
            CoinName: "Ratecoin",
        },
        XNX: {
            ImageUrl: "/media/351033/xnx.jpg",
            Symbol: "XNX",
            CoinName: "XanaxCoin",
        },
        "STAR*": {
            ImageUrl: "/media/351043/star.jpg",
            Symbol: "STAR*",
            CoinName: "StarCoin",
        },
        STHR: {
            ImageUrl: "/media/351042/sthr.png",
            Symbol: "STHR",
            CoinName: "Stakerush",
        },
        DBG: {
            ImageUrl: "/media/351047/dbg.png",
            Symbol: "DBG",
            CoinName: "Digital Bullion Gold",
        },
        BON: {
            ImageUrl: "/media/351045/bon_1.png",
            Symbol: "BON",
            CoinName: "BonesCoin",
        },
        WMC: {
            ImageUrl: "/media/351044/wmc.png",
            Symbol: "WMC",
            CoinName: "WMCoin",
        },
        GOTX: {
            ImageUrl: "/media/351071/gotx.png",
            Symbol: "GOTX",
            CoinName: "GothicCoin",
        },
        FLVR: {
            ImageUrl: "/media/351046/2flav.png",
            Symbol: "FLVR",
            CoinName: "FlavorCoin",
        },
        SHREK: {
            ImageUrl: "/media/351048/shrek.png",
            Symbol: "SHREK",
            CoinName: "ShrekCoin",
        },
        "STA*": {
            ImageUrl: "/media/351063/sta.png",
            Symbol: "STA*",
            CoinName: "Stakers",
        },
        RISE: {
            ImageUrl: "/media/351059/rise.png",
            Symbol: "RISE",
            CoinName: "Rise",
        },
        REV: {
            ImageUrl: "/media/351061/rev.png",
            Symbol: "REV",
            CoinName: "Revenu",
        },
        PBC: {
            ImageUrl: "/media/351062/pbc.png",
            Symbol: "PBC",
            CoinName: "PabyosiCoin",
        },
        OBS: {
            ImageUrl: "/media/351064/obs.png",
            Symbol: "OBS",
            CoinName: "Obscurebay",
        },
        EXIT: {
            ImageUrl: "/media/351065/exit.png",
            Symbol: "EXIT",
            CoinName: "ExitCoin",
        },
        EDC: {
            ImageUrl: "/media/351066/edc.png",
            Symbol: "EDC",
            CoinName: "EducoinV",
        },
        CLINT: {
            ImageUrl: "/media/351067/clint.png",
            Symbol: "CLINT",
            CoinName: "Clinton",
        },
        CKC: {
            ImageUrl: "/media/351068/ckc.png",
            Symbol: "CKC",
            CoinName: "Clockcoin",
        },
        VIP: {
            ImageUrl: "/media/351069/vip.png",
            Symbol: "VIP",
            CoinName: "VIP Tokens",
        },
        NXE: {
            ImageUrl: "/media/351070/nxe.png",
            Symbol: "NXE",
            CoinName: "NXEcoin",
        },
        ZOOM: {
            ImageUrl: "/media/351081/zoom.png",
            Symbol: "ZOOM",
            CoinName: "ZoomCoin",
        },
        DRACO: {
            ImageUrl: "/media/351390/dt-token.png",
            Symbol: "DRACO",
            CoinName: "DT Token",
        },
        YOVI: {
            ImageUrl: "/media/351073/yovi.png",
            Symbol: "YOVI",
            CoinName: "YobitVirtualCoin",
        },
        ORLY: {
            ImageUrl: "/media/351076/orly.png",
            Symbol: "ORLY",
            CoinName: "OrlyCoin",
        },
        KUBO: {
            ImageUrl: "/media/351077/kubo.png",
            Symbol: "KUBO",
            CoinName: "KubosCoin",
        },
        INCP: {
            ImageUrl: "/media/351078/incp.png",
            Symbol: "INCP",
            CoinName: "InceptionCoin",
        },
        SAK: {
            ImageUrl: "/media/351079/sak.png",
            Symbol: "SAK",
            CoinName: "SharkCoin",
        },
        EVIL: {
            ImageUrl: "/media/351080/evil.png",
            Symbol: "EVIL",
            CoinName: "EvilCoin",
        },
        OMA: {
            ImageUrl: "/media/20386/oma.png",
            Symbol: "OMA",
            CoinName: "OmegaCoin",
        },
        MUE: {
            ImageUrl: "/media/351084/mue.png",
            Symbol: "MUE",
            CoinName: "MonetaryUnit",
        },
        COX: {
            ImageUrl: "/media/351083/cox.png",
            Symbol: "COX",
            CoinName: "CobraCoin",
        },
        "BSD*": {
            ImageUrl: "/media/351086/bsd.png",
            Symbol: "BSD*",
            CoinName: "BitSend",
        },
        DES: {
            ImageUrl: "/media/351087/des.png",
            Symbol: "DES",
            CoinName: "Destiny",
        },
        BIT16: {
            ImageUrl: "/media/20181/16bit.png",
            Symbol: "BIT16",
            CoinName: "16BitCoin",
        },
        PDC: {
            ImageUrl: "/media/351088/pdc.png",
            Symbol: "PDC",
            CoinName: "Project Decorum",
        },
        CMT: {
            ImageUrl: "/media/351090/cmt.png",
            Symbol: "CMT",
            CoinName: "CometCoin",
        },
        CHESS: {
            ImageUrl: "/media/351094/chess.jpg",
            Symbol: "CHESS",
            CoinName: "ChessCoin",
        },
        SPACE: {
            ImageUrl: "/media/351095/space.png",
            Symbol: "SPACE",
            CoinName: "SpaceCoin",
        },
        REE: {
            ImageUrl: "/media/351096/ree.png",
            Symbol: "REE",
            CoinName: "ReeCoin",
        },
        LQD: {
            ImageUrl: "/media/351097/lqd.png",
            Symbol: "LQD",
            CoinName: "Liquid",
        },
        MARV: {
            ImageUrl: "/media/351099/marv.png",
            Symbol: "MARV",
            CoinName: "Marvelous",
        },
        XDE2: {
            ImageUrl: "/media/351100/xde2.png",
            Symbol: "XDE2",
            CoinName: "XDE II",
        },
        VEC2: {
            ImageUrl: "/media/351101/vec2.png",
            Symbol: "VEC2",
            CoinName: "VectorCoin 2.0 ",
        },
        OMNI: {
            ImageUrl: "/media/351102/omni.png",
            Symbol: "OMNI",
            CoinName: "Omni",
        },
        GSY: {
            ImageUrl: "/media/351103/gsy.png",
            Symbol: "GSY",
            CoinName: "GenesysCoin",
        },
        "TKN*": {
            ImageUrl: "/media/351104/tkn.png",
            Symbol: "TKN*",
            CoinName: "TrollTokens",
        },
        LIR: {
            ImageUrl: "/media/351208/lir.png",
            Symbol: "LIR",
            CoinName: "Let it Ride",
        },
        MMNXT: {
            ImageUrl: "/media/351209/nxtasset.png",
            Symbol: "MMNXT",
            CoinName: "MMNXT ",
        },
        SCRPT: {
            ImageUrl: "/media/351210/scrpt.png",
            Symbol: "SCRPT",
            CoinName: "ScryptCoin",
        },
        LBC: {
            ImageUrl: "/media/351211/lbc.png",
            Symbol: "LBC",
            CoinName: "LBRY Credits",
        },
        SPX: {
            ImageUrl: "/media/351212/spx.png",
            Symbol: "SPX",
            CoinName: "Specie",
        },
        "SBD*": {
            ImageUrl: "/media/350907/steem.png",
            Symbol: "SBD*",
            CoinName: "Steem Backed Dollars",
        },
        CJ: {
            ImageUrl: "/media/351234/cj.png",
            Symbol: "CJ",
            CoinName: "CryptoJacks",
        },
        PUT: {
            ImageUrl: "/media/1383668/put1.png",
            Symbol: "PUT",
            CoinName: "PutinCoin",
        },
        KRAK: {
            ImageUrl: "/media/351236/krak.png",
            Symbol: "KRAK",
            CoinName: "Kraken",
        },
        DLISK: {
            ImageUrl: "/media/351237/dlisk.png",
            Symbol: "DLISK",
            CoinName: "Dlisk",
        },
        IBANK: {
            ImageUrl: "/media/351238/ibank.png",
            Symbol: "IBANK",
            CoinName: "iBankCoin",
        },
        STRAT: {
            ImageUrl: "/media/351303/stratis-logo.png",
            Symbol: "STRAT",
            CoinName: "Stratis",
        },
        VOYA: {
            ImageUrl: "/media/351304/voya.png",
            Symbol: "VOYA",
            CoinName: "Voyacoin",
        },
        ENTER: {
            ImageUrl: "/media/351305/enter.png",
            Symbol: "ENTER",
            CoinName: "EnterCoin (ENTER)",
        },
        WGC: {
            ImageUrl: "/media/351310/wgc.png",
            Symbol: "WGC",
            CoinName: "World Gold Coin",
        },
        BM: {
            ImageUrl: "/media/351311/bm.png",
            Symbol: "BM",
            CoinName: "BitMoon",
        },
        FRWC: {
            ImageUrl: "/media/351361/frwc.png",
            Symbol: "FRWC",
            CoinName: "Frankywillcoin",
        },
        PSY: {
            ImageUrl: "/media/351362/psy.png",
            Symbol: "PSY",
            CoinName: "Psilocybin",
        },
        XT: {
            ImageUrl: "/media/351364/xt.png",
            Symbol: "XT",
            CoinName: "ExtremeCoin",
        },
        RUST: {
            ImageUrl: "/media/351365/rust.png",
            Symbol: "RUST",
            CoinName: "RustCoin",
        },
        NZC: {
            ImageUrl: "/media/351366/nzc.png",
            Symbol: "NZC",
            CoinName: "NewZealandCoin",
        },
        XAUR: {
            ImageUrl: "/media/351382/xaur.png",
            Symbol: "XAUR",
            CoinName: "Xaurum",
        },
        BFX: {
            ImageUrl: "/media/19554/bitfinex.png",
            Symbol: "BFX",
            CoinName: "BitFinex Tokens",
        },
        UNIQ: {
            ImageUrl: "/media/351387/uniq.png",
            Symbol: "UNIQ",
            CoinName: "Uniqredit",
        },
        CRX: {
            ImageUrl: "/media/351388/crx.png",
            Symbol: "CRX",
            CoinName: "ChronosCoin",
        },
        DCT: {
            ImageUrl: "/media/351389/dct.png",
            Symbol: "DCT",
            CoinName: "Decent",
        },
        XPOKE: {
            ImageUrl: "/media/351393/xpoke.png",
            Symbol: "XPOKE",
            CoinName: "PokeChain",
        },
        MUDRA: {
            ImageUrl: "/media/351394/mudra.png",
            Symbol: "MUDRA",
            CoinName: "MudraCoin",
        },
        WARP: {
            ImageUrl: "/media/351395/warp.png",
            Symbol: "WARP",
            CoinName: "WarpCoin",
        },
        CNMT: {
            ImageUrl: "/media/351396/cnmt.png",
            Symbol: "CNMT",
            CoinName: "Coinomat",
        },
        PIZZA: {
            ImageUrl: "/media/351397/pizza.png",
            Symbol: "PIZZA",
            CoinName: "PizzaCoin",
        },
        LC: {
            ImageUrl: "/media/351398/lc.png",
            Symbol: "LC",
            CoinName: "Lutetium Coin",
        },
        HEAT: {
            ImageUrl: "/media/351399/heat.png",
            Symbol: "HEAT",
            CoinName: "Heat Ledger",
        },
        EXB: {
            ImageUrl: "/media/351401/exb.png",
            Symbol: "EXB",
            CoinName: "ExaByte (EXB)",
        },
        "CDX*": {
            ImageUrl: "/media/351404/cdx.png",
            Symbol: "CDX*",
            CoinName: "Cryptodex",
        },
        RBIT: {
            ImageUrl: "/media/351405/rbit.png",
            Symbol: "RBIT",
            CoinName: "ReturnBit",
        },
        "DCS.": {
            ImageUrl: "/media/351407/cloud.png",
            Symbol: "DCS.",
            CoinName: "deCLOUDs",
        },
        KMD: {
            ImageUrl: "/media/351408/kmd.png",
            Symbol: "KMD",
            CoinName: "Komodo",
        },
        GB: {
            ImageUrl: "/media/351411/db.png",
            Symbol: "GB",
            CoinName: "GoldBlocks",
        },
        NEO: {
            ImageUrl: "/media/1383858/neo.jpg",
            Symbol: "NEO",
            CoinName: "NEO",
        },
        ANC: {
            ImageUrl: "/media/19598/anc.png",
            Symbol: "ANC",
            CoinName: "Anoncoin",
        },
        SYNX: {
            ImageUrl: "/media/15887426/synx.png",
            Symbol: "SYNX",
            CoinName: "Syndicate",
        },
        MC: {
            ImageUrl: "/media/351428/mc.png",
            Symbol: "MC",
            CoinName: "Mass Coin",
        },
        EDR: {
            ImageUrl: "/media/351430/edc.png",
            Symbol: "EDR",
            CoinName: "E-Dinar Coin",
        },
        JWL: {
            ImageUrl: "/media/351432/jwl.png",
            Symbol: "JWL",
            CoinName: "Jewels",
        },
        WAY: {
            ImageUrl: "/media/351433/way.png",
            Symbol: "WAY",
            CoinName: "WayCoin",
        },
        TAB: {
            ImageUrl: "/media/351488/tab.png",
            Symbol: "TAB",
            CoinName: "MollyCoin",
        },
        TRIG: {
            ImageUrl: "/media/351489/trg.png",
            Symbol: "TRIG",
            CoinName: "Trigger",
        },
        BITCNY: {
            ImageUrl: "/media/351490/bitcny.png",
            Symbol: "BITCNY",
            CoinName: "bitCNY",
        },
        BITUSD: {
            ImageUrl: "/media/351491/bitusd.png",
            Symbol: "BITUSD",
            CoinName: "bitUSD",
        },
        "ATM*": {
            ImageUrl: "/media/351492/atm.png",
            Symbol: "ATM*",
            CoinName: "Autumncoin",
        },
        STO: {
            ImageUrl: "/media/351493/sto.png",
            Symbol: "STO",
            CoinName: "Save The Ocean",
        },
        SNS: {
            ImageUrl: "/media/351494/sns.png",
            Symbol: "SNS",
            CoinName: "Sense",
        },
        "FSN*": {
            ImageUrl: "/media/351495/fsn.png",
            Symbol: "FSN*",
            CoinName: "Fusion",
        },
        CTC: {
            ImageUrl: "/media/351496/ctc.png",
            Symbol: "CTC",
            CoinName: "CarterCoin",
        },
        TOT: {
            Symbol: "TOT",
            CoinName: "TotCoin",
        },
        BTD: {
            ImageUrl: "/media/351498/btd.png",
            Symbol: "BTD",
            CoinName: "Bitcloud",
        },
        BOTS: {
            ImageUrl: "/media/351499/bot.png",
            Symbol: "BOTS",
            CoinName: "ArkDAO",
        },
        MDC: {
            ImageUrl: "/media/351500/mdc.png",
            Symbol: "MDC",
            CoinName: "MedicCoin",
        },
        FTP: {
            ImageUrl: "/media/351501/ftp.png",
            Symbol: "FTP",
            CoinName: "FuturePoints",
        },
        ZET2: {
            ImageUrl: "/media/351502/zet2.png",
            Symbol: "ZET2",
            CoinName: "Zeta2Coin",
        },
        "COV*": {
            ImageUrl: "/media/351503/cov.png",
            Symbol: "COV*",
            CoinName: "CovenCoin",
        },
        KRB: {
            ImageUrl: "/media/25792684/krb.png",
            Symbol: "KRB",
            CoinName: "Karbo",
        },
        TELL: {
            ImageUrl: "/media/351505/tell.png",
            Symbol: "TELL",
            CoinName: "Tellurion",
        },
        ENE: {
            ImageUrl: "/media/351506/ene.png",
            Symbol: "ENE",
            CoinName: "EneCoin",
        },
        TDFB: {
            ImageUrl: "/media/351507/tdfb.png",
            Symbol: "TDFB",
            CoinName: "TDFB",
        },
        BLOCKPAY: {
            ImageUrl: "/media/351508/blockpay.png",
            Symbol: "BLOCKPAY",
            CoinName: "BlockPay",
        },
        BXT: {
            ImageUrl: "/media/351509/bxt.png",
            Symbol: "BXT",
            CoinName: "BitTokens",
        },
        ZYD: {
            ImageUrl: "/media/351510/zyd.png",
            Symbol: "ZYD",
            CoinName: "ZayedCoin",
        },
        MST: {
            ImageUrl: "/media/351529/mst1.png",
            Symbol: "MST",
            CoinName: "MustangCoin",
        },
        GOON: {
            ImageUrl: "/media/351512/goon.png",
            Symbol: "GOON",
            CoinName: "Goonies",
        },
        "808": {
            ImageUrl: "/media/351513/808.png",
            Symbol: "808",
            CoinName: "808",
        },
        VLT: {
            ImageUrl: "/media/351514/vlt.png",
            Symbol: "VLT",
            CoinName: "Veltor",
        },
        ZNE: {
            ImageUrl: "/media/351515/zne.jpg",
            Symbol: "ZNE",
            CoinName: "ZoneCoin",
        },
        DCK: {
            ImageUrl: "/media/351516/dck.png",
            Symbol: "DCK",
            CoinName: "DickCoin",
        },
        COVAL: {
            ImageUrl: "/media/351519/coval.png",
            Symbol: "COVAL",
            CoinName: "Circuits of Value",
        },
        DGDC: {
            ImageUrl: "/media/351520/dgd.png",
            Symbol: "DGDC",
            CoinName: "DarkGold",
        },
        TODAY: {
            ImageUrl: "/media/351521/today.png",
            Symbol: "TODAY",
            CoinName: "TodayCoin",
        },
        VRM: {
            ImageUrl: "/media/351522/vrm.png",
            Symbol: "VRM",
            CoinName: "Verium",
        },
        ROOT: {
            ImageUrl: "/media/351523/root.png",
            Symbol: "ROOT",
            CoinName: "RootCoin",
        },
        GPL: {
            ImageUrl: "/media/351525/gpl.png",
            Symbol: "GPL",
            CoinName: "Gold Pressed Latinum",
        },
        DOPE: {
            ImageUrl: "/media/351526/dope.png",
            Symbol: "DOPE",
            CoinName: "DopeCoin",
        },
        B3: {
            ImageUrl: "/media/12318367/b3.png",
            Symbol: "B3",
            CoinName: "B3 Coin",
        },
        FX: {
            ImageUrl: "/media/351527/fx.png",
            Symbol: "FX",
            CoinName: "FCoin",
        },
        PIO: {
            ImageUrl: "/media/351528/pio.png",
            Symbol: "PIO",
            CoinName: "Pioneershares",
        },
        PROUD: {
            ImageUrl: "/media/32655932/proud.png",
            Symbol: "PROUD",
            CoinName: "PROUD Money",
        },
        SMSR: {
            ImageUrl: "/media/351543/smsr.png",
            Symbol: "SMSR",
            CoinName: "Samsara Coin",
        },
        UBIQ: {
            ImageUrl: "/media/351544/ubiq.png",
            Symbol: "UBIQ",
            CoinName: "Ubiqoin",
        },
        ARM: {
            ImageUrl: "/media/351545/arm.png",
            Symbol: "ARM",
            CoinName: "Armory Coin",
        },
        RING: {
            ImageUrl: "/media/351546/ring.png",
            Symbol: "RING",
            CoinName: "RingCoin",
        },
        ERB: {
            ImageUrl: "/media/351550/erb.png",
            Symbol: "ERB",
            CoinName: "ERBCoin",
        },
        LAZ: {
            ImageUrl: "/media/351552/laz.png",
            Symbol: "LAZ",
            CoinName: "Lazarus",
        },
        FONZ: {
            ImageUrl: "/media/351553/fonz.png",
            Symbol: "FONZ",
            CoinName: "FonzieCoin",
        },
        BTCR: {
            ImageUrl: "/media/351554/btr.png",
            Symbol: "BTCR",
            CoinName: "BitCurrency",
        },
        "DROP*": {
            ImageUrl: "/media/351555/drop.png",
            Symbol: "DROP*",
            CoinName: "FaucetCoin",
        },
        SANDG: {
            ImageUrl: "/media/351556/sandt.png",
            Symbol: "SANDG",
            CoinName: "Save and Gain",
        },
        PNK: {
            ImageUrl: "/media/351557/pnk.png",
            Symbol: "PNK",
            CoinName: "SteamPunk",
        },
        MOOND: {
            ImageUrl: "/media/351558/moond.png",
            Symbol: "MOOND",
            CoinName: "Dark Moon",
        },
        DLC: {
            ImageUrl: "/media/351559/dlc.png",
            Symbol: "DLC",
            CoinName: "DollarCoin",
        },
        SEN: {
            ImageUrl: "/media/351560/sen.png",
            Symbol: "SEN",
            CoinName: "Sentaro",
        },
        SCN: {
            ImageUrl: "/media/351563/scn.png",
            Symbol: "SCN",
            CoinName: "Swiscoin",
        },
        WEX: {
            ImageUrl: "/media/351564/wex.jpg",
            Symbol: "WEX",
            CoinName: "Wexcoin",
        },
        LTH: {
            ImageUrl: "/media/351565/lth.png",
            Symbol: "LTH",
            CoinName: "Lathaan",
        },
        BRONZ: {
            ImageUrl: "/media/33957382/bronz.png",
            Symbol: "BRONZ",
            CoinName: "BitBronze",
        },
        SH: {
            ImageUrl: "/media/351567/sh.png",
            Symbol: "SH",
            CoinName: "Shilling",
        },
        BUZZ: {
            ImageUrl: "/media/15887419/buzz.png",
            Symbol: "BUZZ",
            CoinName: "BuzzCoin",
        },
        MG: {
            ImageUrl: "/media/351588/mg.png",
            Symbol: "MG",
            CoinName: "Mind Gene",
        },
        PSI: {
            ImageUrl: "/media/351589/psi.png",
            Symbol: "PSI",
            CoinName: "PSIcoin",
        },
        XPO: {
            ImageUrl: "/media/351590/xpo.png",
            Symbol: "XPO",
            CoinName: "Opair",
        },
        NLC: {
            ImageUrl: "/media/351591/nlc.png",
            Symbol: "NLC",
            CoinName: "NoLimitCoin",
        },
        PSB: {
            ImageUrl: "/media/351594/psb.jpg",
            Symbol: "PSB",
            CoinName: "PesoBit",
        },
        XBTS: {
            ImageUrl: "/media/351617/beats.png",
            Symbol: "XBTS",
            CoinName: "Beats",
        },
        FIT: {
            ImageUrl: "/media/351618/fit.png",
            Symbol: "FIT",
            CoinName: "Fitcoin",
        },
        PINKX: {
            ImageUrl: "/media/351624/pinkx.png",
            Symbol: "PINKX",
            CoinName: "PantherCoin",
        },
        FIRE: {
            ImageUrl: "/media/351625/fire.png",
            Symbol: "FIRE",
            CoinName: "FireCoin",
        },
        UNF: {
            ImageUrl: "/media/351626/unf.png",
            Symbol: "UNF",
            CoinName: "Unfed Coin",
        },
        SPORT: {
            ImageUrl: "/media/351627/sports.png",
            Symbol: "SPORT",
            CoinName: "SportsCoin",
        },
        PPY: {
            ImageUrl: "/media/351630/peerplays.png",
            Symbol: "PPY",
            CoinName: "Peerplays",
        },
        NTC: {
            ImageUrl: "/media/351631/ntc.png",
            Symbol: "NTC",
            CoinName: "NineElevenTruthCoin",
        },
        EGO: {
            ImageUrl: "/media/351632/ego.png",
            Symbol: "EGO",
            CoinName: "EGOcoin",
        },
        "BTCL*": {
            ImageUrl: "/media/351633/btlc.png",
            Symbol: "BTCL*",
            CoinName: "BitluckCoin",
        },
        "RCN*": {
            ImageUrl: "/media/351634/rcn.png",
            Symbol: "RCN*",
            CoinName: "RCoin",
        },
        X2: {
            ImageUrl: "/media/351635/x2.png",
            Symbol: "X2",
            CoinName: "X2Coin",
        },
        MT: {
            ImageUrl: "/media/19453/mycelium.png",
            Symbol: "MT",
            CoinName: "Mycelium Token",
        },
        TIA: {
            ImageUrl: "/media/351636/tia.png",
            Symbol: "TIA",
            CoinName: "Tianhe",
        },
        GBRC: {
            ImageUrl: "/media/351637/gbrc.png",
            Symbol: "GBRC",
            CoinName: "GBR Coin",
        },
        XUP: {
            ImageUrl: "/media/351638/xup.png",
            Symbol: "XUP",
            CoinName: "UPcoin",
        },
        "888": {
            ImageUrl: "/media/351639/888.png",
            Symbol: "888",
            CoinName: "Octocoin",
        },
        HALLO: {
            ImageUrl: "/media/351657/hallo.png",
            Symbol: "HALLO",
            CoinName: "Halloween Coin",
        },
        BBCC: {
            ImageUrl: "/media/351658/bbcc.png",
            Symbol: "BBCC",
            CoinName: "BaseballCardCoin",
        },
        EMIGR: {
            ImageUrl: "/media/351659/emirg.png",
            Symbol: "EMIGR",
            CoinName: "EmiratesGoldCoin",
        },
        BHC: {
            ImageUrl: "/media/351660/bhc.png",
            Symbol: "BHC",
            CoinName: "BighanCoin",
        },
        CRAFT: {
            ImageUrl: "/media/351681/craft.png",
            Symbol: "CRAFT",
            CoinName: "Craftcoin",
        },
        INV: {
            ImageUrl: "/media/351682/inv.png",
            Symbol: "INV",
            CoinName: "Invictus",
        },
        OLYMP: {
            ImageUrl: "/media/351683/olymp.png",
            Symbol: "OLYMP",
            CoinName: "OlympCoin",
        },
        DPAY: {
            ImageUrl: "/media/351684/dpay.png",
            Symbol: "DPAY",
            CoinName: "DelightPay",
        },
        ATOM: {
            ImageUrl: "/media/351685/atom.png",
            Symbol: "ATOM",
            CoinName: "Atomic Coin",
        },
        HKG: {
            ImageUrl: "/media/351689/hkg.jpg",
            Symbol: "HKG",
            CoinName: "Hacker Gold",
        },
        ANTC: {
            ImageUrl: "/media/351690/antc.png",
            Symbol: "ANTC",
            CoinName: "AntiLitecoin",
        },
        JOBS: {
            ImageUrl: "/media/351691/jobs.png",
            Symbol: "JOBS",
            CoinName: "JobsCoin",
        },
        DGORE: {
            ImageUrl: "/media/351697/dgore.png",
            Symbol: "DGORE",
            CoinName: "DogeGoreCoin",
        },
        THC: {
            ImageUrl: "/media/351699/thc.png",
            Symbol: "THC",
            CoinName: "The Hempcoin",
        },
        TRA: {
            ImageUrl: "/media/351700/tra.png",
            Symbol: "TRA",
            CoinName: "Tetra",
        },
        RMS: {
            ImageUrl: "/media/351701/rms.png",
            Symbol: "RMS",
            CoinName: "Resumeo Shares",
        },
        FJC: {
            ImageUrl: "/media/27010498/fjc.png",
            Symbol: "FJC",
            CoinName: "FujiCoin",
        },
        VAPOR: {
            ImageUrl: "/media/351708/vapor.png",
            Symbol: "VAPOR",
            CoinName: "Vaporcoin",
        },
        SDP: {
            ImageUrl: "/media/351709/sdp.jpg",
            Symbol: "SDP",
            CoinName: "SydPakCoin",
        },
        RRT: {
            ImageUrl: "/media/19554/bitfinex.png",
            Symbol: "RRT",
            CoinName: "Recovery Right Tokens",
        },
        XZC: {
            ImageUrl: "/media/1382780/xzc1.png",
            Symbol: "XZC",
            CoinName: "ZCoin",
        },
        PRE: {
            ImageUrl: "/media/351711/pre.png",
            Symbol: "PRE",
            CoinName: "Premium",
        },
        CALC: {
            ImageUrl: "/media/351712/calc.png",
            Symbol: "CALC",
            CoinName: "CaliphCoin",
        },
        LEA: {
            ImageUrl: "/media/351729/lea.png",
            Symbol: "LEA",
            CoinName: "LeaCoin",
        },
        CF: {
            ImageUrl: "/media/351730/cf.png",
            Symbol: "CF",
            CoinName: "Californium",
        },
        CRNK: {
            ImageUrl: "/media/351731/crnk.png",
            Symbol: "CRNK",
            CoinName: "CrankCoin",
        },
        CFC: {
            ImageUrl: "/media/351732/cfc.png",
            Symbol: "CFC",
            CoinName: "CoffeeCoin",
        },
        VTY: {
            ImageUrl: "/media/351733/vty.png",
            Symbol: "VTY",
            CoinName: "Victoriouscoin",
        },
        SFE: {
            ImageUrl: "/media/351735/sfe.png",
            Symbol: "SFE",
            CoinName: "Safecoin",
        },
        ARDR: {
            ImageUrl: "/media/351736/ardr.png",
            Symbol: "ARDR",
            CoinName: "Ardor",
        },
        BS: {
            ImageUrl: "/media/351737/bs.png",
            Symbol: "BS",
            CoinName: "BlackShadowCoin",
        },
        JIF: {
            ImageUrl: "/media/351738/jif.png",
            Symbol: "JIF",
            CoinName: "JiffyCoin",
        },
        CRAB: {
            ImageUrl: "/media/351739/crab.png",
            Symbol: "CRAB",
            CoinName: "CrabCoin",
        },
        "AIR*": {
            ImageUrl: "/media/351740/air.png",
            Symbol: "AIR*",
            CoinName: "Aircoin",
        },
        HILL: {
            ImageUrl: "/media/351747/hill.png",
            Symbol: "HILL",
            CoinName: "President Clinton",
        },
        FOREX: {
            ImageUrl: "/media/351748/forex.png",
            Symbol: "FOREX",
            CoinName: "ForexCoin",
        },
        MONETA: {
            ImageUrl: "/media/351749/moneta.png",
            Symbol: "MONETA",
            CoinName: "Moneta",
        },
        EC: {
            ImageUrl: "/media/351750/ec.jpg",
            Symbol: "EC",
            CoinName: "Eclipse",
        },
        RUBIT: {
            ImageUrl: "/media/351751/rubit.png",
            Symbol: "RUBIT",
            CoinName: "Rublebit",
        },
        HCC: {
            ImageUrl: "/media/351752/hcc.png",
            Symbol: "HCC",
            CoinName: "HappyCreatorCoin ",
        },
        BRAIN: {
            ImageUrl: "/media/351753/brain.png",
            Symbol: "BRAIN",
            CoinName: "BrainCoin",
        },
        VTX: {
            ImageUrl: "/media/351754/vertex.png",
            Symbol: "VTX",
            CoinName: "Vertex",
        },
        KRC: {
            ImageUrl: "/media/351755/krc.png",
            Symbol: "KRC",
            CoinName: "KRCoin",
        },
        ROYAL: {
            ImageUrl: "/media/351756/royal.png",
            Symbol: "ROYAL",
            CoinName: "RoyalCoin",
        },
        LFC: {
            ImageUrl: "/media/351757/lfc.png",
            Symbol: "LFC",
            CoinName: "BigLifeCoin",
        },
        ZUR: {
            ImageUrl: "/media/351758/zur.png",
            Symbol: "ZUR",
            CoinName: "Zurcoin",
        },
        NUBIS: {
            ImageUrl: "/media/351759/nubis.png",
            Symbol: "NUBIS",
            CoinName: "NubisCoin",
        },
        TENNET: {
            ImageUrl: "/media/351760/tennet.png",
            Symbol: "TENNET",
            CoinName: "Tennet",
        },
        PEC: {
            ImageUrl: "/media/351761/pec.png",
            Symbol: "PEC",
            CoinName: "PeaceCoin",
        },
        GMX: {
            ImageUrl: "/media/351762/gmx.jpg",
            Symbol: "GMX",
            CoinName: "Goldmaxcoin",
        },
        "32BIT": {
            ImageUrl: "/media/351763/32bit.png",
            Symbol: "32BIT",
            CoinName: "32Bitcoin",
        },
        GNJ: {
            ImageUrl: "/media/351789/gnj.png",
            Symbol: "GNJ",
            CoinName: "GanjaCoin V2",
        },
        TEAM: {
            ImageUrl: "/media/351790/team.png",
            Symbol: "TEAM",
            CoinName: "TeamUP",
        },
        SCT: {
            ImageUrl: "/media/351791/sct.png",
            Symbol: "SCT",
            CoinName: "ScryptToken",
        },
        LANA: {
            ImageUrl: "/media/351792/lana.png",
            Symbol: "LANA",
            CoinName: "LanaCoin",
        },
        ELE: {
            ImageUrl: "/media/351793/ele.png",
            Symbol: "ELE",
            CoinName: "Elementrem",
        },
        GCC: {
            ImageUrl: "/media/351796/gcc.jpg",
            Symbol: "GCC",
            CoinName: "GuccioneCoin",
        },
        AND: {
            ImageUrl: "/media/351797/and.png",
            Symbol: "AND",
            CoinName: "AndromedaCoin",
        },
        GBYTE: {
            ImageUrl: "/media/351835/bytes.png",
            Symbol: "GBYTE",
            CoinName: "Byteball",
        },
        EQUAL: {
            ImageUrl: "/media/351867/equal.png",
            Symbol: "EQUAL",
            CoinName: "EqualCoin",
        },
        SWEET: {
            ImageUrl: "/media/351868/sweet.png",
            Symbol: "SWEET",
            CoinName: "SweetStake",
        },
        "2BACCO": {
            ImageUrl: "/media/351869/2bacco.png",
            Symbol: "2BACCO",
            CoinName: "2BACCO Coin",
        },
        DKC: {
            ImageUrl: "/media/351870/dkc.png",
            Symbol: "DKC",
            CoinName: "DarkKnightCoin",
        },
        COC: {
            ImageUrl: "/media/351872/coc.png",
            Symbol: "COC",
            CoinName: "Community Coin",
        },
        CHOOF: {
            ImageUrl: "/media/351876/choof.png",
            Symbol: "CHOOF",
            CoinName: "ChoofCoin",
        },
        CSH: {
            ImageUrl: "/media/351877/csh.png",
            Symbol: "CSH",
            CoinName: "CashOut",
        },
        ZCL: {
            ImageUrl: "/media/351926/zcl.png",
            Symbol: "ZCL",
            CoinName: "ZClassic",
        },
        RYCN: {
            ImageUrl: "/media/351756/royal.png",
            Symbol: "RYCN",
            CoinName: "RoyalCoin 2.0",
        },
        PCS: {
            ImageUrl: "/media/351927/pabyosi.png",
            Symbol: "PCS",
            CoinName: "Pabyosi Coin",
        },
        NBIT: {
            ImageUrl: "/media/351928/nbit.png",
            Symbol: "NBIT",
            CoinName: "NetBit",
        },
        WINE: {
            ImageUrl: "/media/351929/wine.png",
            Symbol: "WINE",
            CoinName: "WineCoin",
        },
        DAR: {
            ImageUrl: "/media/351930/dar.png",
            Symbol: "DAR",
            CoinName: "Darcrus",
        },
        ARK: {
            ImageUrl: "/media/351931/ark.png",
            Symbol: "ARK",
            CoinName: "ARK",
        },
        IFLT: {
            ImageUrl: "/media/351934/iflt.png",
            Symbol: "IFLT",
            CoinName: "InflationCoin",
        },
        ZECD: {
            ImageUrl: "/media/351935/zecd.png",
            Symbol: "ZECD",
            CoinName: "ZCashDarkCoin",
        },
        ZXT: {
            ImageUrl: "/media/351936/zxt.png",
            Symbol: "ZXT",
            CoinName: "Zcrypt",
        },
        WASH: {
            ImageUrl: "/media/351944/wash.png",
            Symbol: "WASH",
            CoinName: "WashingtonCoin",
        },
        TESLA: {
            ImageUrl: "/media/351945/tesla.png",
            Symbol: "TESLA",
            CoinName: "TeslaCoilCoin",
        },
        LUCKY: {
            ImageUrl: "/media/351946/lucky.png",
            Symbol: "LUCKY",
            CoinName: "LuckyBlocks",
        },
        VSL: {
            ImageUrl:
                "/media/352113/d5a4e4f0366d3ae8cdbc45ad097f664c2557a55f0c237c1710-pimgpsh_fullsize_distr.jpg",
            Symbol: "VSL",
            CoinName: "vSlice",
        },
        TPG: {
            ImageUrl: "/media/351948/tpg.png",
            Symbol: "TPG",
            CoinName: "Troll Payment",
        },
        LEO: {
            ImageUrl: "/media/351988/leo.png",
            Symbol: "LEO",
            CoinName: "LEOcoin",
        },
        MDT: {
            ImageUrl: "/media/351989/mdt.png",
            Symbol: "MDT",
            CoinName: "Midnight",
        },
        CBD: {
            ImageUrl: "/media/351990/cbd.png",
            Symbol: "CBD",
            CoinName: "CBD Crystals",
        },
        PEX: {
            ImageUrl: "/media/351992/pex.png",
            Symbol: "PEX",
            CoinName: "PosEx",
        },
        INSANE: {
            ImageUrl: "/media/351993/insane.png",
            Symbol: "INSANE",
            CoinName: "InsaneCoin",
        },
        "PEN*": {
            ImageUrl: "/media/20384/pen.png",
            Symbol: "PEN*",
            CoinName: "PenCoin",
        },
        BASH: {
            ImageUrl: "/media/352004/bash.png",
            Symbol: "BASH",
            CoinName: "LuckChain",
        },
        FAME: {
            ImageUrl: "/media/352006/fame.png",
            Symbol: "FAME",
            CoinName: "FameCoin",
        },
        LIV: {
            ImageUrl: "/media/352007/liv.png",
            Symbol: "LIV",
            CoinName: "LiviaCoin",
        },
        SP: {
            ImageUrl: "/media/352018/sp.png",
            Symbol: "SP",
            CoinName: "Sex Pistols",
        },
        MEGA: {
            ImageUrl: "/media/352020/mega.png",
            Symbol: "MEGA",
            CoinName: "MegaFlash",
        },
        VRS: {
            ImageUrl: "/media/352021/vrs.png",
            Symbol: "VRS",
            CoinName: "Veros",
        },
        ALC: {
            ImageUrl: "/media/352022/alc.png",
            Symbol: "ALC",
            CoinName: "Arab League Coin",
        },
        DOGETH: {
            ImageUrl: "/media/352023/dogeth-2.png",
            Symbol: "DOGETH",
            CoinName: "EtherDoge",
        },
        KLC: {
            ImageUrl: "/media/352024/klc.png",
            Symbol: "KLC",
            CoinName: "KiloCoin",
        },
        HUSH: {
            ImageUrl: "/media/1383138/thehush_300x300.png",
            Symbol: "HUSH",
            CoinName: "Hush",
        },
        BTLC: {
            ImageUrl: "/media/352054/btlc.png",
            Symbol: "BTLC",
            CoinName: "BitLuckCoin",
        },
        DRM8: {
            ImageUrl: "/media/352055/drm8.png",
            Symbol: "DRM8",
            CoinName: "Dream8Coin",
        },
        FIST: {
            ImageUrl: "/media/352056/fist.png",
            Symbol: "FIST",
            CoinName: "FistBump",
        },
        EBZ: {
            ImageUrl: "/media/352069/ebz.png",
            Symbol: "EBZ",
            CoinName: "Ebitz",
        },
        "365": {
            ImageUrl: "/media/352070/365.png",
            Symbol: "365",
            CoinName: "365Coin",
        },
        DRS: {
            ImageUrl: "/media/352072/drs.png",
            Symbol: "DRS",
            CoinName: "Digital Rupees",
        },
        FGZ: {
            ImageUrl: "/media/352082/fgz.png",
            Symbol: "FGZ",
            CoinName: "Free Game Zone",
        },
        BOSON: {
            ImageUrl: "/media/352083/boson.png",
            Symbol: "BOSON",
            CoinName: "BosonCoin",
        },
        ATX: {
            ImageUrl: "/media/352084/atx.png",
            Symbol: "ATX",
            CoinName: "ArtexCoin",
        },
        PNC: {
            ImageUrl: "/media/352085/pnc.png",
            Symbol: "PNC",
            CoinName: "PlatiniumCoin",
        },
        BRDD: {
            ImageUrl: "/media/352086/brdd.png",
            Symbol: "BRDD",
            CoinName: "BeardDollars",
        },
        TIME: {
            ImageUrl: "/media/352105/time.png",
            Symbol: "TIME",
            CoinName: "Time",
        },
        BIP: {
            ImageUrl: "/media/352108/bip.png",
            Symbol: "BIP",
            CoinName: "BipCoin",
        },
        XNC: {
            ImageUrl: "/media/352109/xnc.png",
            Symbol: "XNC",
            CoinName: "XenCoin",
        },
        EMB: {
            ImageUrl: "/media/352110/emb.png",
            Symbol: "EMB",
            CoinName: "EmberCoin",
        },
        BTTF: {
            ImageUrl: "/media/352111/bttf.png",
            Symbol: "BTTF",
            CoinName: "Coin to the Future",
        },
        DLR: {
            ImageUrl: "/media/352114/dollarcoin.png",
            Symbol: "DLR",
            CoinName: "DollarOnline",
        },
        CSMIC: {
            ImageUrl: "/media/352115/csmic.png",
            Symbol: "CSMIC",
            CoinName: "Cosmic",
        },
        FIRST: {
            ImageUrl: "/media/352116/first.png",
            Symbol: "FIRST",
            CoinName: "FirstCoin",
        },
        SCASH: {
            ImageUrl: "/media/352117/scash.png",
            Symbol: "SCASH",
            CoinName: "SpaceCash",
        },
        XEN: {
            ImageUrl: "/media/352119/xen.jpg",
            Symbol: "XEN",
            CoinName: "XenixCoin",
        },
        JIO: {
            ImageUrl: "/media/352120/jio.png",
            Symbol: "JIO",
            CoinName: "JIO Token",
        },
        IW: {
            ImageUrl: "/media/352121/iw.png",
            Symbol: "IW",
            CoinName: "iWallet",
        },
        JNS: {
            ImageUrl: "/media/352126/jns.png",
            Symbol: "JNS",
            CoinName: "Janus",
        },
        TRICK: {
            ImageUrl: "/media/352127/trick.png",
            Symbol: "TRICK",
            CoinName: "TrickyCoin",
        },
        DCRE: {
            ImageUrl: "/media/352128/dcre.png",
            Symbol: "DCRE",
            CoinName: "DeltaCredits",
        },
        FRE: {
            ImageUrl: "/media/352129/fre.png",
            Symbol: "FRE",
            CoinName: "FreeCoin",
        },
        NPC: {
            ImageUrl: "/media/352130/npc.png",
            Symbol: "NPC",
            CoinName: "NPCcoin",
        },
        PLNC: {
            ImageUrl: "/media/352131/plnc.png",
            Symbol: "PLNC",
            CoinName: "PLNCoin",
        },
        DGMS: {
            ImageUrl: "/media/352132/dgms.png",
            Symbol: "DGMS",
            CoinName: "Digigems",
        },
        ICOB: {
            ImageUrl: "/media/352133/icb.png",
            Symbol: "ICOB",
            CoinName: "Icobid",
        },
        ARCO: {
            ImageUrl: "/media/352134/arco.png",
            Symbol: "ARCO",
            CoinName: "AquariusCoin",
        },
        KURT: {
            ImageUrl: "/media/352155/kurt.png",
            Symbol: "KURT",
            CoinName: "Kurrent",
        },
        XCRE: {
            ImageUrl: "/media/352156/xcre.png",
            Symbol: "XCRE",
            CoinName: "Creatio",
        },
        ENT: {
            ImageUrl: "/media/352157/ent.jpg",
            Symbol: "ENT",
            CoinName: "Eternity",
        },
        UR: {
            ImageUrl: "/media/352182/ur.jpg",
            Symbol: "UR",
            CoinName: "UR",
        },
        MTLM3: {
            ImageUrl: "/media/352183/mtmc3.png",
            Symbol: "MTLM3",
            CoinName: "Metal Music v3",
        },
        ODNT: {
            ImageUrl: "/media/352186/odnt.png",
            Symbol: "ODNT",
            CoinName: "Old Dogs New Tricks",
        },
        EUC: {
            ImageUrl: "/media/1382471/euc.png",
            Symbol: "EUC",
            CoinName: "Eurocoin",
        },
        CCX: {
            ImageUrl: "/media/352188/ccx.png",
            Symbol: "CCX",
            CoinName: "CoolDarkCoin",
        },
        BCF: {
            ImageUrl: "/media/352189/btf.png",
            Symbol: "BCF",
            CoinName: "BitcoinFast",
        },
        SEEDS: {
            ImageUrl: "/media/352190/seeds.png",
            Symbol: "SEEDS",
            CoinName: "SeedShares",
        },
        XSN: {
            ImageUrl: "/media/30001960/xsn.jpg",
            Symbol: "XSN",
            CoinName: "Stakenet",
        },
        TKS: {
            ImageUrl: "/media/352207/tks.jpg",
            Symbol: "TKS",
            CoinName: "Tokes",
        },
        BCCOIN: {
            ImageUrl: "/media/9350709/bccoin1.png",
            Symbol: "BCCOIN",
            CoinName: "BitConnect Coin",
        },
        SHORTY: {
            ImageUrl: "/media/352222/shorty.png",
            Symbol: "SHORTY",
            CoinName: "ShortyCoin",
        },
        PCM: {
            ImageUrl: "/media/352223/pcm.png",
            Symbol: "PCM",
            CoinName: "Procom",
        },
        KC: {
            ImageUrl: "/media/352224/kc.png",
            Symbol: "KC",
            CoinName: "Kernalcoin",
        },
        CORAL: {
            ImageUrl: "/media/352225/coral.png",
            Symbol: "CORAL",
            CoinName: "CoralPay",
        },
        BamitCoin: {
            ImageUrl: "/media/352236/bam.png",
            Symbol: "BamitCoin",
            CoinName: "BAM",
        },
        NXC: {
            ImageUrl: "/media/352248/nxc.png",
            Symbol: "NXC",
            CoinName: "Nexium",
        },
        MONEY: {
            ImageUrl: "/media/352249/money.png",
            Symbol: "MONEY",
            CoinName: "MoneyCoin",
        },
        BSTAR: {
            ImageUrl: "/media/352250/bstar.png",
            Symbol: "BSTAR",
            CoinName: "Blackstar",
        },
        HSP: {
            ImageUrl: "/media/352251/hsp.png",
            Symbol: "HSP",
            CoinName: "Horse Power",
        },
        HZT: {
            ImageUrl: "/media/352291/hzt.png",
            Symbol: "HZT",
            CoinName: "HazMatCoin",
        },
        CS: {
            ImageUrl: "/media/352292/cs.png",
            Symbol: "CS",
            CoinName: "CryptoSpots",
        },
        XSP: {
            ImageUrl: "/media/352293/xsp.png",
            Symbol: "XSP",
            CoinName: "PoolStamp",
        },
        CCRB: {
            ImageUrl: "/media/27011026/ccrb.png",
            Symbol: "CCRB",
            CoinName: "CryptoCarbon",
        },
        BULLS: {
            ImageUrl: "/media/352295/bulls.png",
            Symbol: "BULLS",
            CoinName: "BullshitCoin",
        },
        INCNT: {
            ImageUrl: "/media/352296/incnt.png",
            Symbol: "INCNT",
            CoinName: "Incent",
        },
        ICON: {
            ImageUrl: "/media/352297/icon.png",
            Symbol: "ICON",
            CoinName: "Iconic",
        },
        NIC: {
            ImageUrl: "/media/352309/nic.png",
            Symbol: "NIC",
            CoinName: "NewInvestCoin",
        },
        ACN: {
            ImageUrl: "/media/352310/acn.png",
            Symbol: "ACN",
            CoinName: "AvonCoin",
        },
        XNG: {
            ImageUrl: "/media/352311/xng.png",
            Symbol: "XNG",
            CoinName: "Enigma",
        },
        XCI: {
            ImageUrl: "/media/352312/xci.png",
            Symbol: "XCI",
            CoinName: "Cannabis Industry Coin",
        },
        LOOK: {
            ImageUrl: "/media/1381970/look.png",
            Symbol: "LOOK",
            CoinName: "LookCoin",
        },
        LOC: {
            ImageUrl: "/media/1381971/loc.png",
            Symbol: "LOC",
            CoinName: "Loco",
        },
        MMXVI: {
            ImageUrl: "/media/1381972/mmxvi.png",
            Symbol: "MMXVI",
            CoinName: "MMXVI",
        },
        TRST: {
            ImageUrl: "/media/1381975/trst.png",
            Symbol: "TRST",
            CoinName: "TrustCoin",
        },
        MIS: {
            ImageUrl: "/media/1381981/mis.png",
            Symbol: "MIS",
            CoinName: "MIScoin",
        },
        WOP: {
            ImageUrl: "/media/1381982/wop.png",
            Symbol: "WOP",
            CoinName: "WorldPay",
        },
        CQST: {
            ImageUrl: "/media/1381983/cqst.png",
            Symbol: "CQST",
            CoinName: "ConquestCoin",
        },
        IMPS: {
            ImageUrl: "/media/1381984/imps.jpg",
            Symbol: "IMPS",
            CoinName: "Impulse Coin",
        },
        IN: {
            ImageUrl: "/media/1381987/in.png",
            Symbol: "IN",
            CoinName: "InCoin",
        },
        CHIEF: {
            ImageUrl: "/media/1381988/chief.png",
            Symbol: "CHIEF",
            CoinName: "TheChiefCoin",
        },
        GOAT: {
            ImageUrl: "/media/1381990/goat.png",
            Symbol: "GOAT",
            CoinName: "Goat",
        },
        ANAL: {
            ImageUrl: "/media/1381991/anal.jpg",
            Symbol: "ANAL",
            CoinName: "AnalCoin",
        },
        RC: {
            ImageUrl: "/media/1381992/rc.png",
            Symbol: "RC",
            CoinName: "Russiacoin",
        },
        PND: {
            ImageUrl: "/media/12318184/pnd.png",
            Symbol: "PND",
            CoinName: "PandaCoin",
        },
        PX: {
            ImageUrl: "/media/1381994/px.png",
            Symbol: "PX",
            CoinName: "PXcoin",
        },
        "CND*": {
            ImageUrl: "/media/1381997/cnd.png",
            Symbol: "CND*",
            CoinName: "Canada eCoin",
        },
        OPTION: {
            ImageUrl: "/media/1381998/option.png",
            Symbol: "OPTION",
            CoinName: "OptionCoin",
        },
        AV: {
            ImageUrl: "/media/1382048/av.png",
            Symbol: "AV",
            CoinName: "Avatar Coin",
        },
        LTD: {
            ImageUrl: "/media/1382049/ltd.png",
            Symbol: "LTD",
            CoinName: "Limited Coin",
        },
        UNITS: {
            ImageUrl: "/media/1382050/units.png",
            Symbol: "UNITS",
            CoinName: "GameUnits",
        },
        HEEL: {
            ImageUrl: "/media/1382051/heel.png",
            Symbol: "HEEL",
            CoinName: "HeelCoin",
        },
        GAKH: {
            ImageUrl: "/media/1382090/gakh.png",
            Symbol: "GAKH",
            CoinName: "GAKHcoin",
        },
        SHIFT: {
            ImageUrl: "/media/1382125/shift.png",
            Symbol: "SHIFT",
            CoinName: "Shift",
        },
        S8C: {
            ImageUrl: "/media/1382093/s8c.png",
            Symbol: "S8C",
            CoinName: "S88 Coin",
        },
        LVG: {
            ImageUrl: "/media/1382094/lvg.png",
            Symbol: "LVG",
            CoinName: "Leverage Coin",
        },
        DRA: {
            ImageUrl: "/media/1382095/dra.png",
            Symbol: "DRA",
            CoinName: "DraculaCoin",
        },
        ASAFE2: {
            ImageUrl: "/media/1382096/allsafe.jpg",
            Symbol: "ASAFE2",
            CoinName: "Allsafe",
        },
        LTCR: {
            ImageUrl: "/media/1382097/ltcr.png",
            Symbol: "LTCR",
            CoinName: "LiteCreed",
        },
        QBC: {
            ImageUrl: "/media/19874/qbc.png",
            Symbol: "QBC",
            CoinName: "Quebecoin",
        },
        XPRO: {
            ImageUrl: "/media/1382098/xpro.png",
            Symbol: "XPRO",
            CoinName: "ProCoin",
        },
        "AST*": {
            ImageUrl: "/media/1382170/ast.png",
            Symbol: "AST*",
            CoinName: "Astral",
        },
        GIFT: {
            ImageUrl: "/media/1382171/gift.png",
            Symbol: "GIFT",
            CoinName: "GiftNet",
        },
        VIDZ: {
            ImageUrl: "/media/1382172/vidz.png",
            Symbol: "VIDZ",
            CoinName: "PureVidz",
        },
        INC: {
            ImageUrl: "/media/1382173/inc.png",
            Symbol: "INC",
            CoinName: "Incrementum",
        },
        PTA: {
            ImageUrl: "/media/1382236/pta.png",
            Symbol: "PTA",
            CoinName: "PentaCoin",
        },
        ACID: {
            ImageUrl: "/media/1382237/acid.png",
            Symbol: "ACID",
            CoinName: "AcidCoin",
        },
        ZLQ: {
            ImageUrl: "/media/1382238/zlq.png",
            Symbol: "ZLQ",
            CoinName: "ZLiteQubit",
        },
        RADI: {
            ImageUrl: "/media/1382239/rad.png",
            Symbol: "RADI",
            CoinName: "RadicalCoin",
        },
        RNC: {
            ImageUrl: "/media/1382240/rnc.png",
            Symbol: "RNC",
            CoinName: "ReturnCoin",
        },
        GOLOS: {
            ImageUrl: "/media/1382246/golos.png",
            Symbol: "GOLOS",
            CoinName: "Golos",
        },
        PASC: {
            ImageUrl: "/media/1382247/pasc.png",
            Symbol: "PASC",
            CoinName: "Pascal Coin",
        },
        TWIST: {
            ImageUrl: "/media/1382250/twist1.png",
            Symbol: "TWIST",
            CoinName: "TwisterCoin",
        },
        PAYP: {
            ImageUrl: "/media/1382251/payp.png",
            Symbol: "PAYP",
            CoinName: "PayPeer",
        },
        DETH: {
            ImageUrl: "/media/1382252/deth.png",
            Symbol: "DETH",
            CoinName: "DarkEther",
        },
        YAY: {
            ImageUrl: "/media/1382253/yay.png",
            Symbol: "YAY",
            CoinName: "YAYcoin",
        },
        YES: {
            ImageUrl: "/media/1382269/yes.png",
            Symbol: "YES",
            CoinName: "YesCoin",
        },
        LENIN: {
            ImageUrl: "/media/1382270/lenin.png",
            Symbol: "LENIN",
            CoinName: "LeninCoin",
        },
        MRSA: {
            ImageUrl: "/media/1382287/msra.png",
            Symbol: "MRSA",
            CoinName: "MrsaCoin",
        },
        OS76: {
            ImageUrl: "/media/1382288/os76.png",
            Symbol: "OS76",
            CoinName: "OsmiumCoin",
        },
        BOSS: {
            ImageUrl: "/media/1382289/boss.png",
            Symbol: "BOSS",
            CoinName: "BitBoss",
        },
        BIC: {
            ImageUrl: "/media/1382337/bic.png",
            Symbol: "BIC",
            CoinName: "Bikercoins",
        },
        CRPS: {
            ImageUrl: "/media/1382338/crps.png",
            Symbol: "CRPS",
            CoinName: "CryptoPennies",
        },
        MOTO: {
            ImageUrl: "/media/1382339/moto.png",
            Symbol: "MOTO",
            CoinName: "Motocoin",
        },
        NTCC: {
            ImageUrl: "/media/1382346/ntcc.png",
            Symbol: "NTCC",
            CoinName: "NeptuneClassic",
        },
        "XNC*": {
            ImageUrl: "/media/1382347/xnc.png",
            Symbol: "XNC*",
            CoinName: "Numismatic Collections",
        },
        HXX: {
            ImageUrl: "/media/1382348/hexx.jpg",
            Symbol: "HXX",
            CoinName: "HexxCoin",
        },
        SPKTR: {
            ImageUrl: "/media/1382349/spkr.png",
            Symbol: "SPKTR",
            CoinName: "Ghost Coin",
        },
        MAC: {
            ImageUrl: "/media/1382368/mac.png",
            Symbol: "MAC",
            CoinName: "MachineCoin",
        },
        SEL: {
            ImageUrl: "/media/1382369/sel.png",
            Symbol: "SEL",
            CoinName: "SelenCoin",
        },
        NOO: {
            ImageUrl: "/media/1382370/noo.png",
            Symbol: "NOO",
            CoinName: "Noocoin",
        },
        CHAO: {
            ImageUrl: "/media/1382371/chao.png",
            Symbol: "CHAO",
            CoinName: "23 Skidoo",
        },
        XGB: {
            ImageUrl: "/media/1382372/xgb.png",
            Symbol: "XGB",
            CoinName: "GoldenBird",
        },
        YMC: {
            ImageUrl: "/media/1382380/ymc.png",
            Symbol: "YMC",
            CoinName: "YamahaCoin",
        },
        JOK: {
            ImageUrl: "/media/1382381/jok.png",
            Symbol: "JOK",
            CoinName: "JokerCoin",
        },
        GBIT: {
            ImageUrl: "/media/1382382/gbit.jpg",
            Symbol: "GBIT",
            CoinName: "GravityBit",
        },
        TEC: {
            ImageUrl: "/media/1382383/tecoin.png",
            Symbol: "TEC",
            CoinName: "TeCoin",
        },
        BOMB: {
            ImageUrl: "/media/1382384/bomb.png",
            Symbol: "BOMB",
            CoinName: "BombCoin",
        },
        RIDE: {
            ImageUrl: "/media/1382388/ride.png",
            Symbol: "RIDE",
            CoinName: "Ride My Car",
        },
        PIVX: {
            ImageUrl: "/media/1382389/pivx.png",
            Symbol: "PIVX",
            CoinName: "Private Instant Verified Transaction",
        },
        KED: {
            ImageUrl: "/media/1382390/ked.png",
            Symbol: "KED",
            CoinName: "Klingon Empire Darsek",
        },
        CNO: {
            ImageUrl: "/media/1382391/coino.png",
            Symbol: "CNO",
            CoinName: "Coino",
        },
        WEALTH: {
            ImageUrl: "/media/1382392/wealth.png",
            Symbol: "WEALTH",
            CoinName: "WealthCoin",
        },
        IOP: {
            ImageUrl: "/media/12318262/iop.png",
            Symbol: "IOP",
            CoinName: "Internet of People",
        },
        XSPEC: {
            ImageUrl: "/media/1382395/xspec.png",
            Symbol: "XSPEC",
            CoinName: "Spectre",
        },
        PEPECASH: {
            ImageUrl: "/media/1382397/pepecash.png",
            Symbol: "PEPECASH",
            CoinName: "Pepe Cash",
        },
        CLICK: {
            ImageUrl: "/media/1382399/click.png",
            Symbol: "CLICK",
            CoinName: "Clickcoin",
        },
        ELS: {
            ImageUrl: "/media/1382400/els.png",
            Symbol: "ELS",
            CoinName: "Elysium",
        },
        KUSH: {
            ImageUrl: "/media/1382401/kush.png",
            Symbol: "KUSH",
            CoinName: "KushCoin",
        },
        ERY: {
            ImageUrl: "/media/1382403/ely2.png",
            Symbol: "ERY",
            CoinName: "Eryllium",
        },
        PLU: {
            ImageUrl: "/media/1382431/plu.png",
            Symbol: "PLU",
            CoinName: "Pluton",
        },
        PRES: {
            ImageUrl: "/media/1382432/pres.png",
            Symbol: "PRES",
            CoinName: "President Trump",
        },
        BTZ: {
            ImageUrl: "/media/1382433/btz.png",
            Symbol: "BTZ",
            CoinName: "BitzCoin",
        },
        OPES: {
            ImageUrl: "/media/1382434/opes.png",
            Symbol: "OPES",
            CoinName: "Opes",
        },
        WCT: {
            ImageUrl: "/media/350884/waves_1.png",
            Symbol: "WCT",
            CoinName: "Waves Community Token",
        },
        UBQ: {
            ImageUrl: "/media/1382441/ubq.png",
            Symbol: "UBQ",
            CoinName: "Ubiq",
        },
        RATIO: {
            ImageUrl: "/media/1382442/ratio.png",
            Symbol: "RATIO",
            CoinName: "Ratio",
        },
        BAN: {
            ImageUrl: "/media/1382466/ban.png",
            Symbol: "BAN",
            CoinName: "Babes and Nerds",
        },
        NICE: {
            ImageUrl: "/media/1382467/nice.png",
            Symbol: "NICE",
            CoinName: "NiceCoin",
        },
        SMF: {
            ImageUrl: "/media/1382468/xmf.png",
            Symbol: "SMF",
            CoinName: "SmurfCoin",
        },
        CWXT: {
            ImageUrl: "/media/1382470/cwxt.png",
            Symbol: "CWXT",
            CoinName: "CryptoWorldXToken",
        },
        TECH: {
            ImageUrl: "/media/1382505/tech.png",
            Symbol: "TECH",
            CoinName: "TechCoin",
        },
        CIR: {
            ImageUrl: "/media/1382506/cir.png",
            Symbol: "CIR",
            CoinName: "CircuitCoin",
        },
        LEPEN: {
            ImageUrl: "/media/1382507/lepen.png",
            Symbol: "LEPEN",
            CoinName: "LePenCoin",
        },
        ROUND: {
            ImageUrl: "/media/1382508/round.png",
            Symbol: "ROUND",
            CoinName: "RoundCoin",
        },
        MAR: {
            ImageUrl: "/media/1382577/mar.png",
            Symbol: "MAR",
            CoinName: "MarijuanaCoin",
        },
        MARX: {
            ImageUrl: "/media/1382578/marx.png",
            Symbol: "MARX",
            CoinName: "MarxCoin",
        },
        TAT: {
            ImageUrl: "/media/1382594/tat.png",
            Symbol: "TAT",
            CoinName: "Tatiana Coin",
        },
        HAZE: {
            ImageUrl: "/media/1382595/haze.png",
            Symbol: "HAZE",
            CoinName: "HazeCoin",
        },
        PRX: {
            ImageUrl: "/media/1382603/prx.png",
            Symbol: "PRX",
            CoinName: "Printerium",
        },
        NRC: {
            ImageUrl: "/media/1382604/nrc.png",
            Symbol: "NRC",
            CoinName: "Neurocoin",
        },
        PAC: {
            ImageUrl: "/media/30002082/pac.png",
            Symbol: "PAC",
            CoinName: "PacCoin",
        },
        IMPCH: {
            ImageUrl: "/media/1382606/impch.png",
            Symbol: "IMPCH",
            CoinName: "Impeach",
        },
        ERR: {
            ImageUrl: "/media/1382624/err.png",
            Symbol: "ERR",
            CoinName: "ErrorCoin",
        },
        TIC: {
            ImageUrl: "/media/1382625/tic.png",
            Symbol: "TIC",
            CoinName: "TrueInvestmentCoin",
        },
        NUKE: {
            ImageUrl: "/media/1382626/nuke.png",
            Symbol: "NUKE",
            CoinName: "NukeCoin",
        },
        EOC: {
            ImageUrl: "/media/1382628/eoc.png",
            Symbol: "EOC",
            CoinName: "EveryonesCoin",
        },
        SFC: {
            ImageUrl: "/media/1382639/sfc.png",
            Symbol: "SFC",
            CoinName: "Solarflarecoin",
        },
        JANE: {
            ImageUrl: "/media/1382640/jane.png",
            Symbol: "JANE",
            CoinName: "JaneCoin",
        },
        PARA: {
            ImageUrl: "/media/1382641/para.png",
            Symbol: "PARA",
            CoinName: "ParanoiaCoin",
        },
        MM: {
            ImageUrl: "/media/1382642/mm.jpg",
            Symbol: "MM",
            CoinName: "MasterMint",
        },
        BXC: {
            ImageUrl: "/media/1382649/bxc.jpg",
            Symbol: "BXC",
            CoinName: "Bitcedi",
        },
        NDOGE: {
            ImageUrl: "/media/1382650/ndoge.png",
            Symbol: "NDOGE",
            CoinName: "NinjaDoge",
        },
        ZBC: {
            ImageUrl: "/media/1382652/zbc.png",
            Symbol: "ZBC",
            CoinName: "Zilbercoin",
        },
        FRST: {
            ImageUrl: "/media/1382654/first.png",
            Symbol: "FRST",
            CoinName: "FirstCoin",
        },
        PAY: {
            ImageUrl: "/media/1383687/pay.png",
            Symbol: "PAY",
            CoinName: "TenX",
        },
        ORO: {
            ImageUrl: "/media/1382656/oro.png",
            Symbol: "ORO",
            CoinName: "OroCoin",
        },
        ALEX: {
            ImageUrl: "/media/1382657/alex.png",
            Symbol: "ALEX",
            CoinName: "Alexandrite",
        },
        TBCX: {
            ImageUrl: "/media/1382658/tbcx.png",
            Symbol: "TBCX",
            CoinName: "TrashBurn",
        },
        MCAR: {
            ImageUrl: "/media/1382659/mcar.png",
            Symbol: "MCAR",
            CoinName: "MasterCar",
        },
        THS: {
            ImageUrl: "/media/1382660/ths.png",
            Symbol: "THS",
            CoinName: "TechShares",
        },
        ACES: {
            ImageUrl: "/media/1382661/aces.png",
            Symbol: "ACES",
            CoinName: "AcesCoin",
        },
        UAEC: {
            ImageUrl: "/media/1382684/uaec.png",
            Symbol: "UAEC",
            CoinName: "United Arab Emirates Coin",
        },
        EA: {
            ImageUrl: "/media/1382685/ea.png",
            Symbol: "EA",
            CoinName: "EagleCoin",
        },
        PIE: {
            ImageUrl: "/media/1382686/pie.png",
            Symbol: "PIE",
            CoinName: "Persistent Information Exchange",
        },
        CREA: {
            ImageUrl: "/media/1382709/crea.png",
            Symbol: "CREA",
            CoinName: "CreativeChain",
        },
        WISC: {
            ImageUrl: "/media/1382710/wisc.jpg",
            Symbol: "WISC",
            CoinName: "WisdomCoin",
        },
        BVC: {
            ImageUrl: "/media/1382711/bvc.png",
            Symbol: "BVC",
            CoinName: "BeaverCoin",
        },
        FIND: {
            ImageUrl: "/media/1382713/find.png",
            Symbol: "FIND",
            CoinName: "FindCoin",
        },
        MLITE: {
            ImageUrl: "/media/1382725/mlite.png",
            Symbol: "MLITE",
            CoinName: "MeLite",
        },
        STALIN: {
            ImageUrl: "/media/1382726/stalin.png",
            Symbol: "STALIN",
            CoinName: "StalinCoin",
        },
        TSE: {
            ImageUrl: "/media/1382790/tato1.png",
            Symbol: "TSE",
            CoinName: "TattooCoin",
        },
        VLTC: {
            ImageUrl: "/media/1382738/vltc.png",
            Symbol: "VLTC",
            CoinName: "VaultCoin",
        },
        BIOB: {
            ImageUrl: "/media/1382739/biob.png",
            Symbol: "BIOB",
            CoinName: "BioBar",
        },
        SWT: {
            ImageUrl: "/media/1382740/swt.jpg",
            Symbol: "SWT",
            CoinName: "Swarm City Token",
        },
        PASL: {
            ImageUrl: "/media/1382741/pasl.png",
            Symbol: "PASL",
            CoinName: "Pascal Lite",
        },
        ZER: {
            ImageUrl: "/media/34155612/zer.png",
            Symbol: "ZER",
            CoinName: "Zero",
        },
        CHAT: {
            ImageUrl: "/media/1382762/chat.png",
            Symbol: "CHAT",
            CoinName: "ChatCoin",
        },
        CDN: {
            ImageUrl: "/media/1382763/cdn.png",
            Symbol: "CDN",
            CoinName: "Canada eCoin",
        },
        TPAY: {
            ImageUrl: "/media/1382770/tpay.png",
            Symbol: "TPAY",
            CoinName: "TrollPlay",
        },
        NETKO: {
            ImageUrl: "/media/1382771/netko.png",
            Symbol: "NETKO",
            CoinName: "Netko",
        },
        ZOI: {
            ImageUrl: "/media/27011018/zoi.png",
            Symbol: "ZOI",
            CoinName: "Zoin",
        },
        HONEY: {
            ImageUrl: "/media/1382937/honey1.png",
            Symbol: "HONEY",
            CoinName: "Honey",
        },
        MXT: {
            ImageUrl: "/media/1382782/mxt.jpg",
            Symbol: "MXT",
            CoinName: "MartexCoin",
        },
        MUSIC: {
            ImageUrl: "/media/1382783/music.png",
            Symbol: "MUSIC",
            CoinName: "Musicoin",
        },
        DTB: {
            ImageUrl: "/media/1382791/dtb.png",
            Symbol: "DTB",
            CoinName: "Databits",
        },
        VEG: {
            ImageUrl: "/media/1382792/veg.png",
            Symbol: "VEG",
            CoinName: "BitVegan",
        },
        MBIT: {
            ImageUrl: "/media/1382793/mbit.png",
            Symbol: "MBIT",
            CoinName: "Mbitbooks",
        },
        VOLT: {
            ImageUrl: "/media/1382794/volt.png",
            Symbol: "VOLT",
            CoinName: "BitVolt",
        },
        "B@": {
            ImageUrl: "/media/1382804/b.png",
            Symbol: "B@",
            CoinName: "BankCoin",
        },
        BEST: {
            ImageUrl: "/media/1382805/best.jpg",
            Symbol: "BEST",
            CoinName: "BestChain",
        },
        CHC: {
            ImageUrl: "/media/33842945/chc.jpg",
            Symbol: "CHC",
            CoinName: "ChainCoin",
        },
        ZENI: {
            ImageUrl: "/media/1382807/zen.png",
            Symbol: "ZENI",
            CoinName: "Zennies",
        },
        PLANET: {
            ImageUrl: "/media/1382851/planet.png",
            Symbol: "PLANET",
            CoinName: "PlanetCoin",
        },
        DUCK: {
            ImageUrl: "/media/1382852/duckduckcoin.png",
            Symbol: "DUCK",
            CoinName: "DuckDuckCoin",
        },
        BNX: {
            ImageUrl: "/media/1382853/bnx.png",
            Symbol: "BNX",
            CoinName: "BnrtxCoin",
        },
        BSTK: {
            ImageUrl: "/media/1382858/bstk.png",
            Symbol: "BSTK",
            CoinName: "BattleStake",
        },
        RNS: {
            ImageUrl: "/media/1382859/rns.png",
            Symbol: "RNS",
            CoinName: "RenosCoin",
        },
        DBIX: {
            ImageUrl: "/media/1382860/dbix.png",
            Symbol: "DBIX",
            CoinName: "DubaiCoin",
        },
        AMIS: {
            ImageUrl: "/media/1382862/amis.png",
            Symbol: "AMIS",
            CoinName: "AMIS",
        },
        KAYI: {
            ImageUrl: "/media/1382863/kayi.png",
            Symbol: "KAYI",
            CoinName: "Kayı",
        },
        XVP: {
            ImageUrl: "/media/1382865/xvp.png",
            Symbol: "XVP",
            CoinName: "VirtacoinPlus",
        },
        BOAT: {
            ImageUrl: "/media/1382866/boat.png",
            Symbol: "BOAT",
            CoinName: "Doubloon",
        },
        TAJ: {
            ImageUrl: "/media/1382867/taj.png",
            Symbol: "TAJ",
            CoinName: "TajCoin",
        },
        IMX: {
            ImageUrl: "/media/1382891/imx.png",
            Symbol: "IMX",
            CoinName: "Impact",
        },
        CJC: {
            ImageUrl: "/media/1382887/cjc.png",
            Symbol: "CJC",
            CoinName: "CryptoJournal",
        },
        AMY: {
            ImageUrl: "/media/1382935/amy.jpg",
            Symbol: "AMY",
            CoinName: "Amygws",
        },
        QBT: {
            ImageUrl: "/media/1382936/qbt.png",
            Symbol: "QBT",
            CoinName: "Cubits",
        },
        "SRC*": {
            ImageUrl: "/media/1382936/src1.png",
            Symbol: "SRC*",
            CoinName: "StarCredits",
        },
        EB3: {
            ImageUrl: "/media/1382938/eb3.png",
            Symbol: "EB3",
            CoinName: "EB3coin",
        },
        XVE: {
            ImageUrl: "/media/1382937/xve.png",
            Symbol: "XVE",
            CoinName: "The Vegan Initiative",
        },
        FAZZ: {
            ImageUrl: "/media/1382944/fazz.png",
            Symbol: "FAZZ",
            CoinName: "FazzCoin",
        },
        APT: {
            ImageUrl: "/media/1382945/apt.png",
            Symbol: "APT",
            CoinName: "Aptcoin",
        },
        BLAZR: {
            ImageUrl: "/media/1382946/blazr.png",
            Symbol: "BLAZR",
            CoinName: "BlazerCoin",
        },
        ARPA: {
            ImageUrl: "/media/1382966/arpa.png",
            Symbol: "ARPA",
            CoinName: "ArpaCoin",
        },
        "BNB*": {
            ImageUrl: "/media/1382967/bnb.png",
            Symbol: "BNB*",
            CoinName: "Boats and Bitches",
        },
        UNI: {
            ImageUrl: "/media/1382968/uni.png",
            Symbol: "UNI",
            CoinName: "Universe",
        },
        ECO: {
            ImageUrl: "/media/1382993/eco.png",
            Symbol: "ECO",
            CoinName: "ECOcoin",
        },
        XLR: {
            ImageUrl: "/media/1382994/xlr.png",
            Symbol: "XLR",
            CoinName: "Solaris",
        },
        DARK: {
            ImageUrl: "/media/1382995/dark.png",
            Symbol: "DARK",
            CoinName: "Dark",
        },
        DON: {
            ImageUrl: "/media/1382995/don.png",
            Symbol: "DON",
            CoinName: "DonationCoin",
        },
        MER: {
            ImageUrl: "/media/14913628/mer.png",
            Symbol: "MER",
            CoinName: "Mercury",
        },
        WGO: {
            ImageUrl: "/media/1382998/wgo.png",
            Symbol: "WGO",
            CoinName: "WavesGO",
        },
        ATMOS: {
            ImageUrl: "/media/30002333/atmos.jpg",
            Symbol: "ATMOS",
            CoinName: "Atmos",
        },
        INPAY: {
            ImageUrl: "/media/1383004/inpay.png",
            Symbol: "INPAY",
            CoinName: "InPay",
        },
        ETT: {
            ImageUrl: "/media/1383046/ett.png",
            Symbol: "ETT",
            CoinName: "EncryptoTel",
        },
        "WBTC*": {
            ImageUrl: "/media/1383045/wbtc.png",
            Symbol: "WBTC*",
            CoinName: "wBTC",
        },
        VISIO: {
            ImageUrl: "/media/1383047/visio.png",
            Symbol: "VISIO",
            CoinName: "Visio",
        },
        HPC: {
            ImageUrl: "/media/1383046/hpc.png",
            Symbol: "HPC",
            CoinName: "HappyCoin",
        },
        GOT: {
            ImageUrl: "/media/1383079/got.png",
            Symbol: "GOT",
            CoinName: "Giotto Coin",
        },
        CXT: {
            ImageUrl: "/media/1383080/cxt.png",
            Symbol: "CXT",
            CoinName: "Coinonat",
        },
        EMPC: {
            ImageUrl: "/media/1383081/empc.png",
            Symbol: "EMPC",
            CoinName: "EmporiumCoin",
        },
        LGD: {
            ImageUrl: "/media/1383085/lgd.png",
            Symbol: "LGD",
            CoinName: "Legends Cryptocurrency",
        },
        BUCKS: {
            ImageUrl: "/media/1383089/bucks.png",
            Symbol: "BUCKS",
            CoinName: "SwagBucks",
        },
        XBY: {
            ImageUrl: "/media/20780760/xby.png",
            Symbol: "XBY",
            CoinName: "XtraBYtes",
        },
        GUP: {
            ImageUrl: "/media/1383107/gup.png",
            Symbol: "GUP",
            CoinName: "Guppy",
        },
        MCRN: {
            ImageUrl: "/media/1383111/mcrn.png",
            Symbol: "MCRN",
            CoinName: "MacronCoin",
        },
        RAIN: {
            ImageUrl: "/media/1383114/rain.png",
            Symbol: "RAIN",
            CoinName: "Condensate",
        },
        WSX: {
            ImageUrl: "/media/1383144/wsx.png",
            Symbol: "WSX",
            CoinName: "WeAreSatoshi",
        },
        IEC: {
            ImageUrl: "/media/1383144/wsx.png",
            Symbol: "IEC",
            CoinName: "IvugeoEvolutionCoin",
        },
        IMS: {
            ImageUrl: "/media/1383145/ims.png",
            Symbol: "IMS",
            CoinName: "Independent Money System",
        },
        ARGUS: {
            ImageUrl: "/media/1383149/argus.png",
            Symbol: "ARGUS",
            CoinName: "ArgusCoin",
        },
        CNT: {
            ImageUrl: "/media/1383150/cnt.png",
            Symbol: "CNT",
            CoinName: "Centurion",
        },
        LMC: {
            ImageUrl: "/media/1383139/lmc.png",
            Symbol: "LMC",
            CoinName: "LomoCoin",
        },
        BTCS: {
            ImageUrl: "/media/1383158/btcs.png",
            Symbol: "BTCS",
            CoinName: "Bitcoin Scrypt",
        },
        PROC: {
            ImageUrl: "/media/1383159/proc.png",
            Symbol: "PROC",
            CoinName: "ProCurrency",
        },
        XGR: {
            ImageUrl: "/media/1383161/xgr.png",
            Symbol: "XGR",
            CoinName: "GoldReserve",
        },
        "WRC*": {
            ImageUrl: "/media/1383162/wrc.png",
            Symbol: "WRC*",
            CoinName: "WarCoin",
        },
        BENJI: {
            ImageUrl: "/media/1383163/benji.png",
            Symbol: "BENJI",
            CoinName: "BenjiRolls",
        },
        DUO: {
            ImageUrl: "/media/1383196/duo.png",
            Symbol: "DUO",
            CoinName: "ParallelCoin",
        },
        RBX: {
            ImageUrl: "/media/1383197/rbx.png",
            Symbol: "RBX",
            CoinName: "RiptoBuX",
        },
        GRW: {
            ImageUrl: "/media/1383234/grw.png",
            Symbol: "GRW",
            CoinName: "GrowthCoin",
        },
        APX: {
            ImageUrl: "/media/1383235/apx.png",
            Symbol: "APX",
            CoinName: "Apx",
        },
        MILO: {
            ImageUrl: "/media/1383236/milo.png",
            Symbol: "MILO",
            CoinName: "MiloCoin",
        },
        OLV: {
            ImageUrl: "/media/1383239/xvs.png",
            Symbol: "OLV",
            CoinName: "OldV",
        },
        ILC: {
            ImageUrl: "/media/1383238/ilc.png",
            Symbol: "ILC",
            CoinName: "ILCoin",
        },
        MRT: {
            ImageUrl: "/media/350884/waves_1.png",
            Symbol: "MRT",
            CoinName: "MinersReward",
        },
        IOU: {
            ImageUrl: "/media/1383241/iou1.png",
            Symbol: "IOU",
            CoinName: "IOU1",
        },
        PZM: {
            ImageUrl: "/media/1383242/pzm.jpg",
            Symbol: "PZM",
            CoinName: "Prizm",
        },
        PHR: {
            ImageUrl: "/media/1383243/phr.jpg",
            Symbol: "PHR",
            CoinName: "Phreak",
        },
        PUPA: {
            ImageUrl: "/media/1383245/pupa.png",
            Symbol: "PUPA",
            CoinName: "PupaCoin",
        },
        RICE: {
            ImageUrl: "/media/1383245/rice.png",
            Symbol: "RICE",
            CoinName: "RiceCoin",
        },
        XCT: {
            ImageUrl: "/media/1383246/xct.png",
            Symbol: "XCT",
            CoinName: "C-Bits",
        },
        DEA: {
            ImageUrl: "/media/1383264/dea.png",
            Symbol: "DEA",
            CoinName: "Degas Coin",
        },
        RED: {
            ImageUrl: "/media/1383265/red.png",
            Symbol: "RED",
            CoinName: "Redcoin",
        },
        ZSE: {
            ImageUrl: "/media/1383266/zse.png",
            Symbol: "ZSE",
            CoinName: "ZSEcoin",
        },
        CTIC: {
            ImageUrl: "/media/1383267/ctic.png",
            Symbol: "CTIC",
            CoinName: "Coinmatic",
        },
        TAP: {
            ImageUrl: "/media/1383283/tap.png",
            Symbol: "TAP",
            CoinName: "TappingCoin",
        },
        BITOK: {
            ImageUrl: "/media/1383282/bitok.jpg",
            Symbol: "BITOK",
            CoinName: "BitOKX",
        },
        PBT: {
            ImageUrl: "/media/1383324/pbt.png",
            Symbol: "PBT",
            CoinName: "Primalbase",
        },
        MUU: {
            ImageUrl: "/media/1383325/muu.png",
            Symbol: "MUU",
            CoinName: "MilkCoin",
        },
        INF8: {
            ImageUrl: "/media/1383326/inf8.png",
            Symbol: "INF8",
            CoinName: "Infinium-8",
        },
        HTML5: {
            ImageUrl: "/media/1383327/html5.png",
            Symbol: "HTML5",
            CoinName: "HTML5 Coin",
        },
        MNE: {
            ImageUrl: "/media/1383328/mne.png",
            Symbol: "MNE",
            CoinName: "Minereum",
        },
        DICE: {
            ImageUrl: "/media/1383361/dice.png",
            Symbol: "DICE",
            CoinName: "Etheroll",
        },
        "SUB*": {
            ImageUrl: "/media/1383362/sub.png",
            Symbol: "SUB*",
            CoinName: "Subscriptio",
        },
        USC: {
            ImageUrl: "/media/1383363/usc.png",
            Symbol: "USC",
            CoinName: "Ultimate Secure Cash",
        },
        DUX: {
            ImageUrl: "/media/1383364/dux.png",
            Symbol: "DUX",
            CoinName: "DuxCoin",
        },
        XPS: {
            ImageUrl: "/media/1383365/xps.png",
            Symbol: "XPS",
            CoinName: "PoisonIvyCoin",
        },
        EQT: {
            ImageUrl: "/media/1383366/eqt.png",
            Symbol: "EQT",
            CoinName: "EquiTrader",
        },
        INSN: {
            ImageUrl: "/media/1383366/insn.png",
            Symbol: "INSN",
            CoinName: "Insane Coin",
        },
        "MAT*": {
            ImageUrl: "/media/1383371/mat.png",
            Symbol: "MAT*",
            CoinName: "Manet Coin",
        },
        F16: {
            ImageUrl: "/media/1383372/f16.png",
            Symbol: "F16",
            CoinName: "F16Coin",
        },
        HAMS: {
            ImageUrl: "/media/1383381/hams.png",
            Symbol: "HAMS",
            CoinName: "HamsterCoin",
        },
        QTUM: {
            ImageUrl: "/media/1383382/qtum.png",
            Symbol: "QTUM",
            CoinName: "QTUM",
        },
        NEF: {
            ImageUrl: "/media/1383383/nef.png",
            Symbol: "NEF",
            CoinName: "NefariousCoin",
        },
        ZEN: {
            ImageUrl: "/media/25792624/zen.png",
            Symbol: "ZEN",
            CoinName: "ZenCash",
        },
        BOS: {
            ImageUrl: "/media/1383521/bos.png",
            Symbol: "BOS",
            CoinName: "BOScoin",
        },
        QWARK: {
            ImageUrl: "/media/1383522/qwark.png",
            Symbol: "QWARK",
            CoinName: "Qwark",
        },
        IOT: {
            ImageUrl: "/media/1383540/iota_logo.png",
            Symbol: "IOT",
            CoinName: "IOTA",
        },
        ADL: {
            ImageUrl: "/media/1383544/adl.png",
            Symbol: "ADL",
            CoinName: "Adelphoi",
        },
        "ECC*": {
            ImageUrl: "/media/1383546/ecc.png",
            Symbol: "ECC*",
            CoinName: "E-CurrencyCoin",
        },
        PTOY: {
            ImageUrl: "/media/1383547/ptoy.png",
            Symbol: "PTOY",
            CoinName: "Patientory",
        },
        ZRC: {
            ImageUrl: "/media/1383548/xzc.png",
            Symbol: "ZRC",
            CoinName: "ZrCoin",
        },
        LKK: {
            ImageUrl: "/media/1383553/lkk.png",
            Symbol: "LKK",
            CoinName: "Lykke",
        },
        ESP: {
            ImageUrl: "/media/14761907/esp.png",
            Symbol: "ESP",
            CoinName: "Espers",
        },
        "MAD*": {
            ImageUrl: "/media/1383556/mad.png",
            Symbol: "MAD*",
            CoinName: "SatoshiMadness",
        },
        DYN: {
            ImageUrl: "/media/1383557/dyn.png",
            Symbol: "DYN",
            CoinName: "Dynamic",
        },
        SEQ: {
            ImageUrl: "/media/1383558/seq.png",
            Symbol: "SEQ",
            CoinName: "Sequence",
        },
        MCAP: {
            ImageUrl: "/media/1383559/mcap.png",
            Symbol: "MCAP",
            CoinName: "MCAP",
        },
        MYST: {
            ImageUrl: "/media/1383561/myst.png",
            Symbol: "MYST",
            CoinName: "Mysterium",
        },
        SKY: {
            ImageUrl: "/media/30001806/sky.png",
            Symbol: "SKY",
            CoinName: "Skycoin",
        },
        CFI: {
            ImageUrl: "/media/1383567/cfi.png",
            Symbol: "CFI",
            CoinName: "Cofound.it",
        },
        AVT: {
            ImageUrl: "/media/1383599/avt.png",
            Symbol: "AVT",
            CoinName: "AventCoin",
        },
        IXT: {
            ImageUrl: "/media/1383612/ixt.png",
            Symbol: "IXT",
            CoinName: "iXledger",
        },
        STA: {
            ImageUrl: "/media/1383620/crs.png",
            Symbol: "STA",
            CoinName: "Starta",
        },
        TFL: {
            ImageUrl: "/media/1383621/tfl.png",
            Symbol: "TFL",
            CoinName: "True Flip Lottery",
        },
        EFYT: {
            ImageUrl: "/media/1383646/efyt.png",
            Symbol: "EFYT",
            CoinName: "Ergo",
        },
        XTZ: {
            ImageUrl: "/media/1383651/xbt.png",
            Symbol: "XTZ",
            CoinName: "Tezos",
        },
        QAU: {
            ImageUrl: "/media/1383669/qau.png",
            Symbol: "QAU",
            CoinName: "Quantum",
        },
        ECOB: {
            ImageUrl: "/media/1383670/ecob.png",
            Symbol: "ECOB",
            CoinName: "EcoBit",
        },
        USDT: {
            ImageUrl: "/media/1383672/usdt.png",
            Symbol: "USDT",
            CoinName: "Tether",
        },
        NANO: {
            ImageUrl: "/media/30001997/untitled-1.png",
            Symbol: "NANO",
            CoinName: "Nano",
        },
        AHT: {
            ImageUrl: "/media/1383688/ahc.png",
            Symbol: "AHT",
            CoinName: "Ahoolee",
        },
        ATB: {
            ImageUrl: "/media/1383689/atb.png",
            Symbol: "ATB",
            CoinName: "ATB coin",
        },
        CHAN: {
            ImageUrl: "/media/1383831/chan2.png",
            Symbol: "CHAN",
            CoinName: "ChanCoin",
        },
        CMP: {
            ImageUrl: "/media/1383692/compcoin.png",
            Symbol: "CMP",
            CoinName: "Compcoin",
        },
        RVT: {
            ImageUrl: "/media/1383694/rvt.png",
            Symbol: "RVT",
            CoinName: "Rivetz",
        },
        HRB: {
            ImageUrl: "/media/1383695/hrb.png",
            Symbol: "HRB",
            CoinName: "Harbour DAO",
        },
        "NET*": {
            ImageUrl: "/media/1383697/net1.png",
            Symbol: "NET*",
            CoinName: "Nimiq Exchange Token",
        },
        "8BT": {
            ImageUrl: "/media/1383698/8bt.png",
            Symbol: "8BT",
            CoinName: "8 Circuit Studios",
        },
        ACT: {
            ImageUrl: "/media/1383700/act.png",
            Symbol: "ACT",
            CoinName: "ACT",
        },
        SUR: {
            ImageUrl: "/media/1383696/sur.png",
            Symbol: "SUR",
            CoinName: "Suretly",
        },
        PING: {
            ImageUrl: "/media/1383706/ping1.png",
            Symbol: "PING",
            CoinName: "CryptoPing",
        },
        MIV: {
            ImageUrl: "/media/1383728/miv.png",
            Symbol: "MIV",
            CoinName: "MakeItViral",
        },
        "BET*": {
            ImageUrl: "/media/1383729/bet.png",
            Symbol: "BET*",
            CoinName: "DAO.casino",
        },
        WGR: {
            ImageUrl: "/media/1383736/wgr.png",
            Symbol: "WGR",
            CoinName: "Wagerr",
        },
        XEL: {
            ImageUrl: "/media/1383737/xel.png",
            Symbol: "XEL",
            CoinName: "Elastic",
        },
        NVST: {
            ImageUrl: "/media/1383732/nvst.png",
            Symbol: "NVST",
            CoinName: "NVO",
        },
        FUNC: {
            ImageUrl: "/media/1383739/func.png",
            Symbol: "FUNC",
            CoinName: "FunCoin",
        },
        PQT: {
            ImageUrl: "/media/1383741/pqt.png",
            Symbol: "PQT",
            CoinName: "PAquarium",
        },
        WTT: {
            ImageUrl: "/media/1383742/wtt.png",
            Symbol: "WTT",
            CoinName: "Giga Watt",
        },
        SNC: {
            ImageUrl: "/media/1383748/snc.png",
            Symbol: "SNC",
            CoinName: "SunContract",
        },
        STAR: {
            ImageUrl: "/media/1383750/star1.png",
            Symbol: "STAR",
            CoinName: "Starbase",
        },
        COR: {
            ImageUrl: "/media/1383753/cor.png",
            Symbol: "COR",
            CoinName: "Corion",
        },
        OROC: {
            ImageUrl: "/media/1383755/oroc.png",
            Symbol: "OROC",
            CoinName: "Orocrypt",
        },
        OAX: {
            ImageUrl: "/media/1383756/oax.png",
            Symbol: "OAX",
            CoinName: "OpenANX",
        },
        MBI: {
            ImageUrl: "/media/1383759/mbi.png",
            Symbol: "MBI",
            CoinName: "Monster Byte Inc",
        },
        DDF: {
            ImageUrl: "/media/1383760/ddf.png",
            Symbol: "DDF",
            CoinName: "Digital Developers Fund",
        },
        DIM: {
            ImageUrl: "/media/1383761/dim.png",
            Symbol: "DIM",
            CoinName: "DIMCOIN",
        },
        GGS: {
            ImageUrl: "/media/1383762/ggs.png",
            Symbol: "GGS",
            CoinName: "Gilgam",
        },
        DNA: {
            ImageUrl: "/media/34155600/encrypgen-exchange-icon-300.png",
            Symbol: "DNA",
            CoinName: "EncrypGen",
        },
        FYN: {
            ImageUrl: "/media/1383764/fyn.png",
            Symbol: "FYN",
            CoinName: "FundYourselfNow",
        },
        FND: {
            ImageUrl: "/media/1383765/fnd.png",
            Symbol: "FND",
            CoinName: "FundRequest",
        },
        DCY: {
            ImageUrl: "/media/1383767/dcy.png",
            Symbol: "DCY",
            CoinName: "Dinastycoin",
        },
        CFT: {
            ImageUrl: "/media/1383769/cft.png",
            Symbol: "CFT",
            CoinName: "CryptoForecast",
        },
        DNR: {
            ImageUrl: "/media/1383770/dnr.png",
            Symbol: "DNR",
            CoinName: "Denarius",
        },
        DP: {
            ImageUrl: "/media/1383772/dp.png",
            Symbol: "DP",
            CoinName: "DigitalPrice",
        },
        VUC: {
            ImageUrl: "/media/1383773/vuc.png",
            Symbol: "VUC",
            CoinName: "Virta Unique Coin",
        },
        BTPL: {
            ImageUrl: "/media/1383774/btpl.png",
            Symbol: "BTPL",
            CoinName: "Bitcoin Planet",
        },
        UNIFY: {
            ImageUrl: "/media/1383775/unify.png",
            Symbol: "UNIFY",
            CoinName: "Unify",
        },
        IPC: {
            ImageUrl: "/media/1383776/ipc.png",
            Symbol: "IPC",
            CoinName: "ImperialCoin",
        },
        BRIT: {
            ImageUrl: "/media/1383777/brit.png",
            Symbol: "BRIT",
            CoinName: "BritCoin",
        },
        AMMO: {
            ImageUrl: "/media/1383778/ammo.png",
            Symbol: "AMMO",
            CoinName: "Ammo Rewards",
        },
        SOCC: {
            ImageUrl: "/media/1383779/socc.png",
            Symbol: "SOCC",
            CoinName: "SocialCoin",
        },
        MASS: {
            ImageUrl: "/media/1383781/mass.png",
            Symbol: "MASS",
            CoinName: "Mass.Cloud",
        },
        LA: {
            ImageUrl: "/media/27010681/latoken.png",
            Symbol: "LA",
            CoinName: "LATOKEN",
        },
        IML: {
            ImageUrl: "/media/1383783/iml.png",
            Symbol: "IML",
            CoinName: "IMMLA",
        },
        XUC: {
            ImageUrl: "/media/1383784/xuc.png",
            Symbol: "XUC",
            CoinName: "Exchange Union",
        },
        STU: {
            ImageUrl: "/media/1383785/stu.png",
            Symbol: "STU",
            CoinName: "BitJob",
        },
        GUNS: {
            ImageUrl: "/media/1383789/guns.png",
            Symbol: "GUNS",
            CoinName: "GeoFunders",
        },
        IFT: {
            ImageUrl: "/media/12318127/ift.png",
            Symbol: "IFT",
            CoinName: "InvestFeed",
        },
        "CAT*": {
            ImageUrl: "/media/20780714/cat.png",
            Symbol: "CAT*",
            CoinName: "BitClave",
        },
        SYC: {
            ImageUrl: "/media/1383793/syc.png",
            Symbol: "SYC",
            CoinName: "SynchroCoin",
        },
        IND: {
            ImageUrl: "/media/1383794/ind.png",
            Symbol: "IND",
            CoinName: "Indorse",
        },
        "AHT*": {
            ImageUrl: "/media/1383796/aht.png",
            Symbol: "AHT*",
            CoinName: "Bowhead Health",
        },
        TRIBE: {
            ImageUrl: "/media/1383797/tribe.jpg",
            Symbol: "TRIBE",
            CoinName: "TribeToken",
        },
        NPX: {
            ImageUrl: "/media/12318066/npx.png",
            Symbol: "NPX",
            CoinName: "Napoleon X",
        },
        SCORE: {
            ImageUrl: "/media/1383813/score.png",
            Symbol: "SCORE",
            CoinName: "Scorecoin",
        },
        OTX: {
            ImageUrl: "/media/1383817/1qrfuod6_400x400.jpg",
            Symbol: "OTX",
            CoinName: "Octanox",
        },
        EQB: {
            ImageUrl: "/media/1383816/eqb.png",
            Symbol: "EQB",
            CoinName: "Equibit",
        },
        VOISE: {
            ImageUrl: "/media/12318263/voise.png",
            Symbol: "VOISE",
            CoinName: "Voise",
        },
        ETBS: {
            ImageUrl: "/media/12318348/etbs.png",
            Symbol: "ETBS",
            CoinName: "EthBits",
        },
        CVCOIN: {
            ImageUrl: "/media/1383821/cvcoin.png",
            Symbol: "CVCOIN",
            CoinName: "Crypviser",
        },
        DRP: {
            ImageUrl: "/media/1383822/drp.png",
            Symbol: "DRP",
            CoinName: "DCORP",
        },
        ARC: {
            ImageUrl: "/media/1383824/arc.png",
            Symbol: "ARC",
            CoinName: "ArcticCoin",
        },
        BOG: {
            ImageUrl: "/media/1383826/bog.png",
            Symbol: "BOG",
            CoinName: "Bogcoin",
        },
        NDC: {
            ImageUrl: "/media/1383827/ndc.png",
            Symbol: "NDC",
            CoinName: "NeverDie",
        },
        ADT: {
            ImageUrl: "/media/1383829/adt.png",
            Symbol: "ADT",
            CoinName: "AdToken",
        },
        UET: {
            ImageUrl: "/media/1383837/uet.png",
            Symbol: "UET",
            CoinName: "Useless Ethereum Token",
        },
        PART: {
            ImageUrl: "/media/1383838/part.png",
            Symbol: "PART",
            CoinName: "Particl",
        },
        AGRS: {
            ImageUrl: "/media/1383839/agrs.png",
            Symbol: "AGRS",
            CoinName: "Agoras Token",
        },
        SAND: {
            ImageUrl: "/media/1383825/beach.png",
            Symbol: "SAND",
            CoinName: "BeachCoin",
        },
        "XAI*": {
            ImageUrl: "/media/1383840/xai.png",
            Symbol: "XAI*",
            CoinName: "AICoin",
        },
        DMT: {
            ImageUrl: "/media/1383841/dmarket.png",
            Symbol: "DMT",
            CoinName: "DMarket",
        },
        DAS: {
            ImageUrl: "/media/14543970/das.png",
            Symbol: "DAS",
            CoinName: "DAS",
        },
        ADST: {
            ImageUrl: "/media/1383846/adst.png",
            Symbol: "ADST",
            CoinName: "Adshares",
        },
        CAT: {
            ImageUrl: "/media/1383848/bcat1.png",
            Symbol: "CAT",
            CoinName: "BlockCAT",
        },
        XCJ: {
            ImageUrl: "/media/1383849/xcj.png",
            Symbol: "XCJ",
            CoinName: "CoinJob",
        },
        RKC: {
            ImageUrl: "/media/1383852/rkc.png",
            Symbol: "RKC",
            CoinName: "Royal Kingdom Coin",
        },
        NLC2: {
            ImageUrl: "/media/16746543/nlc2.png",
            Symbol: "NLC2",
            CoinName: "NoLimitCoin",
        },
        LINDA: {
            ImageUrl: "/media/1383860/linda.png",
            Symbol: "LINDA",
            CoinName: "Linda",
        },
        SPN: {
            ImageUrl: "/media/1383861/spn.png",
            Symbol: "SPN",
            CoinName: "Spoon",
        },
        KING: {
            ImageUrl: "/media/1383862/king.png",
            Symbol: "KING",
            CoinName: "King93",
        },
        ANCP: {
            ImageUrl: "/media/1383863/ancp.png",
            Symbol: "ANCP",
            CoinName: "Anacrypt",
        },
        RCC: {
            ImageUrl: "/media/1383864/rcc.png",
            Symbol: "RCC",
            CoinName: "Reality Clash",
        },
        ROOTS: {
            ImageUrl: "/media/1383851/roots.png",
            Symbol: "ROOTS",
            CoinName: "RootProject",
        },
        SNK: {
            ImageUrl: "/media/1383865/snk.png",
            Symbol: "SNK",
            CoinName: "Sosnovkino",
        },
        CABS: {
            ImageUrl: "/media/1383869/cabs.png",
            Symbol: "CABS",
            CoinName: "CryptoABS",
        },
        OPT: {
            ImageUrl: "/media/1383873/opt.png",
            Symbol: "OPT",
            CoinName: "Opus",
        },
        ZNT: {
            ImageUrl: "/media/1383875/znt.png",
            Symbol: "ZNT",
            CoinName: "OpenZen",
        },
        BITSD: {
            ImageUrl: "/media/1383878/bitsd.png",
            Symbol: "BITSD",
            CoinName: "Bits Digit",
        },
        XLC: {
            ImageUrl: "/media/34333433/xlc.png",
            Symbol: "XLC",
            CoinName: "LeviarCoin",
        },
        SKIN: {
            ImageUrl: "/media/1383880/dsb_amky_400x400.jpg",
            Symbol: "SKIN",
            CoinName: "Skincoin",
        },
        HIRE: {
            ImageUrl: "/media/1383882/hite.png",
            Symbol: "HIRE",
            CoinName: "HireMatch",
        },
        "BBT*": {
            ImageUrl: "/media/1383883/bbt.png",
            Symbol: "BBT*",
            CoinName: "BrickBlock",
        },
        REAL: {
            ImageUrl: "/media/1383884/rise.png",
            Symbol: "REAL",
            CoinName: "REAL",
        },
        DFBT: {
            ImageUrl: "/media/1383890/dfbt.png",
            Symbol: "DFBT",
            CoinName: "DentalFix",
        },
        EQ: {
            ImageUrl: "/media/1383891/eq.png",
            Symbol: "EQ",
            CoinName: "EQUI",
        },
        WLK: {
            ImageUrl: "/media/1383892/wolk.png",
            Symbol: "WLK",
            CoinName: "Wolk",
        },
        VIB: {
            ImageUrl: "/media/1383893/vib.png",
            Symbol: "VIB",
            CoinName: "Viberate",
        },
        ONION: {
            ImageUrl: "/media/1383894/onion.png",
            Symbol: "ONION",
            CoinName: "DeepOnion",
        },
        BTX: {
            ImageUrl: "/media/1383895/btx.png",
            Symbol: "BTX",
            CoinName: "Bitcore",
        },
        ICE: {
            ImageUrl: "/media/1383896/46b-uaba_400x400.jpg",
            Symbol: "ICE",
            CoinName: "iDice",
        },
        XID: {
            ImageUrl: "/media/1383898/xid.jpg",
            Symbol: "XID",
            CoinName: "Sphre AIR",
        },
        GCN: {
            ImageUrl: "/media/1383899/gcn.png",
            Symbol: "GCN",
            CoinName: "gCn Coin",
        },
        "ATOM*": {
            ImageUrl: "/media/1383901/cosmos.jpg",
            Symbol: "ATOM*",
            CoinName: "Cosmos",
        },
        ICOO: {
            ImageUrl: "/media/1383904/icoo.jpg",
            Symbol: "ICOO",
            CoinName: "ICO OpenLedger",
        },
        TME: {
            ImageUrl: "/media/1383905/tme.png",
            Symbol: "TME",
            CoinName: "Timereum",
        },
        SIGT: {
            ImageUrl: "/media/9350710/sigt.png",
            Symbol: "SIGT",
            CoinName: "Signatum",
        },
        ONX: {
            ImageUrl: "/media/1383910/onx.png",
            Symbol: "ONX",
            CoinName: "Onix",
        },
        COE: {
            ImageUrl: "/media/1383911/coe.png",
            Symbol: "COE",
            CoinName: "CoEval",
        },
        ARENA: {
            ImageUrl: "/media/12318225/arena.png",
            Symbol: "ARENA",
            CoinName: "Arena",
        },
        WINK: {
            ImageUrl: "/media/1383913/wink.png",
            Symbol: "WINK",
            CoinName: "Wink",
        },
        CRM: {
            ImageUrl: "/media/1383915/cream.png",
            Symbol: "CRM",
            CoinName: "Cream",
        },
        BCH: {
            ImageUrl:
                "/media/1383919/12-bitcoin-cash-square-crop-small-grn.png",
            Symbol: "BCH",
            CoinName: "Bitcoin Cash",
        },
        DGPT: {
            ImageUrl: "/media/1383920/dgt.png",
            Symbol: "DGPT",
            CoinName: "DigiPulse",
        },
        MOBI: {
            ImageUrl: "/media/1383921/mobi.png",
            Symbol: "MOBI",
            CoinName: "Mobius",
        },
        CSNO: {
            ImageUrl: "/media/1383922/csno.png",
            Symbol: "CSNO",
            CoinName: "BitDice",
        },
        SDAO: {
            ImageUrl: "/media/1383933/sdao.png",
            Symbol: "SDAO",
            CoinName: "Solar DAO",
        },
        CORE: {
            ImageUrl: "/media/1383950/core.png",
            Symbol: "CORE",
            CoinName: "Core Group Asset",
        },
        KEN: {
            ImageUrl: "/media/1383953/kencoin.png",
            Symbol: "KEN",
            CoinName: "Kencoin",
        },
        QVT: {
            ImageUrl: "/media/1383954/qvt.png",
            Symbol: "QVT",
            CoinName: "Qvolta",
        },
        TIE: {
            ImageUrl: "/media/1383955/tie.png",
            Symbol: "TIE",
            CoinName: "Ties Network",
        },
        AUT: {
            ImageUrl: "/media/1383956/aut.png",
            Symbol: "AUT",
            CoinName: "Autoria",
        },
        CTT: {
            ImageUrl: "/media/1383957/ctt.png",
            Symbol: "CTT",
            CoinName: "CodeTract",
        },
        GRWI: {
            ImageUrl: "/media/1383971/grwi.png",
            Symbol: "GRWI",
            CoinName: "Growers International",
        },
        MNY: {
            ImageUrl: "/media/1383973/mny.png",
            Symbol: "MNY",
            CoinName: "Monkey",
        },
        MTH: {
            ImageUrl: "/media/1383976/mth.png",
            Symbol: "MTH",
            CoinName: "Monetha",
        },
        CCC: {
            ImageUrl: "/media/1383980/ccc.png",
            Symbol: "CCC",
            CoinName: "CCCoin",
        },
        UMC: {
            ImageUrl: "/media/1383983/umb.png",
            Symbol: "UMC",
            CoinName: "Umbrella Coin",
        },
        BMXT: {
            ImageUrl: "/media/1383984/bmxt.png",
            Symbol: "BMXT",
            CoinName: "Bitmxittz",
        },
        GAS: {
            ImageUrl: "/media/1383858/neo.jpg",
            Symbol: "GAS",
            CoinName: "Gas",
        },
        FIL: {
            ImageUrl: "/media/1383987/fil.png",
            Symbol: "FIL",
            CoinName: "FileCoin",
        },
        OCL: {
            ImageUrl: "/media/1383989/ocl.png",
            Symbol: "OCL",
            CoinName: "Oceanlab",
        },
        BNC: {
            ImageUrl: "/media/1383991/bnc.png",
            Symbol: "BNC",
            CoinName: "Benjacoin",
        },
        TOM: {
            ImageUrl: "/media/1383992/tom.png",
            Symbol: "TOM",
            CoinName: "Tomahawkcoin",
        },
        XAS: {
            ImageUrl: "/media/1383997/xas.png",
            Symbol: "XAS",
            CoinName: "Asch",
        },
        SMNX: {
            ImageUrl: "/media/1383998/sx.png",
            Symbol: "SMNX",
            CoinName: "SMNX",
        },
        DLT: {
            ImageUrl: "/media/1384001/delta.png",
            Symbol: "DLT",
            CoinName: "Agrello Delta",
        },
        MRV: {
            ImageUrl: "/media/1384009/mrv.png",
            Symbol: "MRV",
            CoinName: "Macroverse",
        },
        MBRS: {
            ImageUrl: "/media/1384010/mbrs.png",
            Symbol: "MBRS",
            CoinName: "Embers",
        },
        MET: {
            ImageUrl: "/media/1384013/met1.png",
            Symbol: "MET",
            CoinName: "Memessenger",
        },
        NEBL: {
            ImageUrl: "/media/1384016/nebl.png",
            Symbol: "NEBL",
            CoinName: "Neblio",
        },
        PGL: {
            ImageUrl: "/media/1384018/pgl.png",
            Symbol: "PGL",
            CoinName: "Prospectors",
        },
        XMCC: {
            ImageUrl: "/media/27010930/xmcc.jpg",
            Symbol: "XMCC",
            CoinName: "Monoeci",
        },
        AUN: {
            ImageUrl: "/media/1384019/auth.png",
            Symbol: "AUN",
            CoinName: "Authoreon",
        },
        "CASH*": {
            ImageUrl: "/media/1384020/cpp.png",
            Symbol: "CASH*",
            CoinName: "Cash Poker Pro",
        },
        CMPCO: {
            ImageUrl: "/media/1384036/cmpo.png",
            Symbol: "CMPCO",
            CoinName: "CampusCoin",
        },
        DTCT: {
            ImageUrl: "/media/1384025/dtct.png",
            Symbol: "DTCT",
            CoinName: "DetectorToken",
        },
        CTR: {
            ImageUrl: "/media/1384029/ctr.png",
            Symbol: "CTR",
            CoinName: "Centra",
        },
        WNET: {
            ImageUrl: "/media/1383982/wnet1.png",
            Symbol: "WNET",
            CoinName: "Wavesnode.net",
        },
        THNX: {
            ImageUrl: "/media/1384039/thnx.jpg",
            Symbol: "THNX",
            CoinName: "ThankYou",
        },
        WORM: {
            ImageUrl: "/media/1384040/worm.png",
            Symbol: "WORM",
            CoinName: "HealthyWorm",
        },
        FUCK: {
            ImageUrl: "/media/1384043/fuck.png",
            Symbol: "FUCK",
            CoinName: "Fuck Token",
        },
        VNT: {
            ImageUrl: "/media/1384015/vent.png",
            Symbol: "VNT",
            CoinName: "Veredictum",
        },
        SIFT: {
            ImageUrl: "/media/1384045/sift.jpg",
            Symbol: "SIFT",
            CoinName: "Smart Investment Fund Token",
        },
        IGNIS: {
            ImageUrl: "/media/1384046/ignis.png",
            Symbol: "IGNIS",
            CoinName: "Ignis",
        },
        IWT: {
            ImageUrl: "/media/1384048/iwt.png",
            Symbol: "IWT",
            CoinName: "IwToken",
        },
        JDC: {
            ImageUrl: "/media/1384049/jdc.png",
            Symbol: "JDC",
            CoinName: "JustDatingSite",
        },
        ITT: {
            ImageUrl: "/media/33957371/itt_new.jpg",
            Symbol: "ITT",
            CoinName: "Intelligent Trading",
        },
        LNC: {
            ImageUrl: "/media/20780792/lnc.png",
            Symbol: "LNC",
            CoinName: "BlockLancer",
        },
        AIX: {
            ImageUrl: "/media/1383807/aig.png",
            Symbol: "AIX",
            CoinName: "Aigang",
        },
        XEC: {
            ImageUrl: "/media/30001868/xec.png",
            Symbol: "XEC",
            CoinName: "Eternal Coin",
        },
        ENTRP: {
            ImageUrl: "/media/1383969/ent.png",
            Symbol: "ENTRP",
            CoinName: "Entropy Token",
        },
        ICOS: {
            ImageUrl: "/media/1383968/icos1.png",
            Symbol: "ICOS",
            CoinName: "ICOBox",
        },
        PIX: {
            ImageUrl: "/media/1384024/pix.png",
            Symbol: "PIX",
            CoinName: "Lampix",
        },
        MEDI: {
            ImageUrl: "/media/1384051/medi.png",
            Symbol: "MEDI",
            CoinName: "MediBond",
        },
        HGT: {
            ImageUrl: "/media/9350692/hgt.jpg",
            Symbol: "HGT",
            CoinName: "Hello Gold",
        },
        LTA: {
            ImageUrl: "/media/9350693/lta.png",
            Symbol: "LTA",
            CoinName: "Litra",
        },
        NIMFA: {
            ImageUrl: "/media/9350694/nimfa.jpg",
            Symbol: "NIMFA",
            CoinName: "Nimfamoney",
        },
        SCOR: {
            ImageUrl: "/media/9350695/scor.jpg",
            Symbol: "SCOR",
            CoinName: "Scorista",
        },
        MLS: {
            ImageUrl: "/media/9350696/mls.png",
            Symbol: "MLS",
            CoinName: "CPROP",
        },
        KEX: {
            ImageUrl: "/media/9350699/kex.png",
            Symbol: "KEX",
            CoinName: "KexCoin",
        },
        BRO: {
            ImageUrl: "/media/9350701/bro.png",
            Symbol: "BRO",
            CoinName: "Bitradio",
        },
        MINEX: {
            ImageUrl: "/media/9350702/minex.png",
            Symbol: "MINEX",
            CoinName: "Minex",
        },
        ATL: {
            ImageUrl: "/media/9350703/atlant.png",
            Symbol: "ATL",
            CoinName: "ATLANT",
        },
        "MAG*": {
            ImageUrl: "/media/9350711/mag.png",
            Symbol: "MAG*",
            CoinName: "Magos",
        },
        DFT: {
            ImageUrl: "/media/9350712/dft.png",
            Symbol: "DFT",
            CoinName: "Draftcoin",
        },
        LAT: {
            ImageUrl: "/media/9350724/lat.png",
            Symbol: "LAT",
            CoinName: "Latium",
        },
        SOJ: {
            ImageUrl: "/media/9350725/soj.png",
            Symbol: "SOJ",
            CoinName: "Sojourn Coin",
        },
        HDG: {
            ImageUrl: "/media/9350726/hdg.png",
            Symbol: "HDG",
            CoinName: "Hedge Token",
        },
        STCN: {
            ImageUrl: "/media/9350727/stcn.png",
            Symbol: "STCN",
            CoinName: "Stakecoin",
        },
        SQP: {
            ImageUrl: "/media/9350728/sqp.png",
            Symbol: "SQP",
            CoinName: "SqPay",
        },
        RIYA: {
            ImageUrl: "/media/9350737/riya.png",
            Symbol: "RIYA",
            CoinName: "Etheriya",
        },
        LNK: {
            ImageUrl: "/media/9350738/lnk.png",
            Symbol: "LNK",
            CoinName: "Ethereum.Link",
        },
        WAN: {
            ImageUrl: "/media/9350742/wan.jpg",
            Symbol: "WAN",
            CoinName: "Wanchain",
        },
        MNTP: {
            ImageUrl: "/media/9350745/mntp.png",
            Symbol: "MNTP",
            CoinName: "GoldMint",
        },
        ALTOCAR: {
            ImageUrl: "/media/9350746/altc.png",
            Symbol: "ALTOCAR",
            CoinName: "AltoCar",
        },
        "CFT*": {
            ImageUrl: "/media/9350747/credo.jpg",
            Symbol: "CFT*",
            CoinName: "Credo",
        },
        BLX: {
            ImageUrl: "/media/9350748/blx.png",
            Symbol: "BLX",
            CoinName: "Blockchain Index",
        },
        BOU: {
            ImageUrl: "/media/9350750/bou.jpg",
            Symbol: "BOU",
            CoinName: "Boulle",
        },
        OXY: {
            ImageUrl: "/media/9350753/oxy.png",
            Symbol: "OXY",
            CoinName: "Oxycoin",
        },
        TTT: {
            ImageUrl: "/media/9350755/ttt.jpg",
            Symbol: "TTT",
            CoinName: "Tap Project",
        },
        AMT: {
            ImageUrl: "/media/9350756/amt.jpg",
            Symbol: "AMT",
            CoinName: "Acumen",
        },
        GIM: {
            ImageUrl: "/media/27010507/gim.png",
            Symbol: "GIM",
            CoinName: "Gimli",
        },
        NYC: {
            ImageUrl: "/media/30001653/nyc.png",
            Symbol: "NYC",
            CoinName: "NewYorkCoin",
        },
        LBTC: {
            ImageUrl: "/media/9350763/lbtc.png",
            Symbol: "LBTC",
            CoinName: "LiteBitcoin",
        },
        FRAZ: {
            ImageUrl: "/media/9350764/fraz.png",
            Symbol: "FRAZ",
            CoinName: "FrazCoin",
        },
        EMT: {
            ImageUrl: "/media/9350765/emt.png",
            Symbol: "EMT",
            CoinName: "EasyMine",
        },
        GXC: {
            ImageUrl: "/media/9350766/gxc.png",
            Symbol: "GXC",
            CoinName: "Gx Coin",
        },
        KRONE: {
            ImageUrl: "/media/9350770/krone.png",
            Symbol: "KRONE",
            CoinName: "Kronecoin",
        },
        SRT: {
            ImageUrl: "/media/9350771/srt.png",
            Symbol: "SRT",
            CoinName: "Scrypto",
        },
        AVA: {
            ImageUrl: "/media/9350772/ava.png",
            Symbol: "AVA",
            CoinName: "Avalon",
        },
        BT: {
            ImageUrl: "/media/9350775/bt.png",
            Symbol: "BT",
            CoinName: "BuildTeam",
        },
        ACC: {
            ImageUrl: "/media/9350776/acc.jpg",
            Symbol: "ACC",
            CoinName: "AdCoin",
        },
        "AR*": {
            ImageUrl: "/media/14913465/ar.png",
            Symbol: "AR*",
            CoinName: "Ar.cash",
        },
        Z2: {
            ImageUrl: "/media/9350780/z2.png",
            Symbol: "Z2",
            CoinName: "Z2 Coin",
        },
        LINX: {
            ImageUrl: "/media/9350783/linx.png",
            Symbol: "LINX",
            CoinName: "Linx",
        },
        XCXT: {
            ImageUrl: "/media/9350784/xcxt.png",
            Symbol: "XCXT",
            CoinName: "CoinonatX",
        },
        BLAS: {
            ImageUrl: "/media/25792611/blas.png",
            Symbol: "BLAS",
            CoinName: "BlakeStar",
        },
        GOOD: {
            ImageUrl: "/media/9350786/good.png",
            Symbol: "GOOD",
            CoinName: "GoodCoin",
        },
        SCL: {
            ImageUrl: "/media/27010947/untitled-1.png",
            Symbol: "SCL",
            CoinName: "Sociall",
        },
        TRV: {
            ImageUrl: "/media/9350789/trv.png",
            Symbol: "TRV",
            CoinName: "Travel Coin",
        },
        CRTM: {
            ImageUrl: "/media/9350790/crtm.jpg",
            Symbol: "CRTM",
            CoinName: "Cryptum",
        },
        EON: {
            ImageUrl: "/media/9350791/eon.jpg",
            Symbol: "EON",
            CoinName: "Exscudo",
        },
        MTX: {
            ImageUrl: "/media/9350793/mtx.png",
            Symbol: "MTX",
            CoinName: "Matryx",
        },
        PRIX: {
            ImageUrl: "/media/33842954/300x300_logo_blue.png",
            Symbol: "PRIX",
            CoinName: "Privatix",
        },
        CTX: {
            ImageUrl: "/media/12318075/ctx1.png",
            Symbol: "CTX",
            CoinName: "CarTaxi",
        },
        CNX: {
            ImageUrl: "/media/11417632/cnx.png",
            Symbol: "CNX",
            CoinName: "Cryptonex",
        },
        DRC: {
            ImageUrl: "/media/11417638/drp.png",
            Symbol: "DRC",
            CoinName: "Dropcoin",
        },
        ACE: {
            ImageUrl: "/media/11999076/ace.png",
            Symbol: "ACE",
            CoinName: "TokenStars",
        },
        WRC: {
            ImageUrl: "/media/11999078/wrc.png",
            Symbol: "WRC",
            CoinName: "Worldcore",
        },
        BRX: {
            ImageUrl: "/media/12317960/brx.png",
            Symbol: "BRX",
            CoinName: "Breakout Stake",
        },
        UCASH: {
            ImageUrl: "/media/12317962/xuc.png",
            Symbol: "UCASH",
            CoinName: "U.CASH",
        },
        WRT: {
            ImageUrl: "/media/12317963/wrt.png",
            Symbol: "WRT",
            CoinName: "WRTcoin",
        },
        ORME: {
            ImageUrl: "/media/12317975/omes.png",
            Symbol: "ORME",
            CoinName: "Ormeus Coin",
        },
        DEEP: {
            ImageUrl: "/media/12317976/deep.png",
            Symbol: "DEEP",
            CoinName: "Deep Gold",
        },
        "TMT*": {
            ImageUrl: "/media/12317977/tmt.png",
            Symbol: "TMT*",
            CoinName: "ToTheMoon",
        },
        CCT: {
            ImageUrl: "/media/12317979/cct1.png",
            Symbol: "CCT",
            CoinName: "Crystal Clear Token ",
        },
        WSH: {
            ImageUrl: "/media/12317980/wish.png",
            Symbol: "WSH",
            CoinName: "Wish Finance",
        },
        "ARNA*": {
            ImageUrl: "/media/12318005/arna.png",
            Symbol: "ARNA*",
            CoinName: "ARNA Panacea",
        },
        ABC: {
            ImageUrl: "/media/12318006/bac.png",
            Symbol: "ABC",
            CoinName: "AB-Chain",
        },
        PRP: {
            ImageUrl: "/media/12318007/prp.png",
            Symbol: "PRP",
            CoinName: "Papyrus",
        },
        "SKR*": {
            ImageUrl: "/media/12318013/skr.png",
            Symbol: "SKR*",
            CoinName: "Skrilla Token",
        },
        "3DES": {
            ImageUrl: "/media/12318014/3des.png",
            Symbol: "3DES",
            CoinName: "3DES",
        },
        PYN: {
            ImageUrl: "/media/12318033/pyn.png",
            Symbol: "PYN",
            CoinName: "Paycentos",
        },
        KAPU: {
            ImageUrl: "/media/12318035/logo_500x500.png",
            Symbol: "KAPU",
            CoinName: "Kapu",
        },
        CAPP: {
            ImageUrl: "/media/15887416/capp.png",
            Symbol: "CAPP",
            CoinName: "Cappasity",
        },
        FC: {
            ImageUrl: "/media/12318045/fc.png",
            Symbol: "FC",
            CoinName: "Facecoin",
        },
        RCN: {
            ImageUrl: "/media/12318046/rnc.png",
            Symbol: "RCN",
            CoinName: "Ripio",
        },
        NRN: {
            ImageUrl: "/media/12318047/nrn.png",
            Symbol: "NRN",
            CoinName: "Doc.ai Neuron",
        },
        EVC: {
            ImageUrl: "/media/12318064/evc.png",
            Symbol: "EVC",
            CoinName: "Eventchain",
        },
        WIZ: {
            ImageUrl: "/media/12318081/wiz.png",
            Symbol: "WIZ",
            CoinName: "Crowdwiz",
        },
        ATKN: {
            ImageUrl: "/media/12318083/atkn.png",
            Symbol: "ATKN",
            CoinName: "A-Token",
        },
        RUSTBITS: {
            ImageUrl: "/media/12318085/rustbits.png",
            Symbol: "RUSTBITS",
            CoinName: "Rustbits",
        },
        ETHD: {
            ImageUrl: "/media/12318087/ethd.png",
            Symbol: "ETHD",
            CoinName: "Ethereum Dark",
        },
        SUMO: {
            ImageUrl: "/media/27010696/sumo.png",
            Symbol: "SUMO",
            CoinName: "Sumokoin",
        },
        H2O: {
            ImageUrl: "/media/12318092/h2o.png",
            Symbol: "H2O",
            CoinName: "Hydrominer",
        },
        TKT: {
            ImageUrl: "/media/12318093/tkt.png",
            Symbol: "TKT",
            CoinName: "Crypto Tickets",
        },
        RHEA: {
            ImageUrl: "/media/12318096/rhea.png",
            Symbol: "RHEA",
            CoinName: "Rhea",
        },
        DRT: {
            ImageUrl: "/media/12318099/drt.png",
            Symbol: "DRT",
            CoinName: "DomRaider",
        },
        SNOV: {
            ImageUrl: "/media/12318100/snov.png",
            Symbol: "SNOV",
            CoinName: "Snovio",
        },
        MTN: {
            ImageUrl: "/media/12318118/mtn.png",
            Symbol: "MTN",
            CoinName: "TrackNetToken",
        },
        STOCKBET: {
            ImageUrl: "/media/12318119/stockbet.png",
            Symbol: "STOCKBET",
            CoinName: "StockBet",
        },
        PLM: {
            ImageUrl: "/media/12318124/plm.jpg",
            Symbol: "PLM",
            CoinName: "Algo.Land",
        },
        SND: {
            ImageUrl: "/media/12318128/snd.png",
            Symbol: "SND",
            CoinName: "Sandcoin",
        },
        XP: {
            ImageUrl: "/media/12318134/xp.png",
            Symbol: "XP",
            CoinName: "Experience Points",
        },
        HSR: {
            ImageUrl: "/media/12318137/hsr.png",
            Symbol: "HSR",
            CoinName: "Hshare",
        },
        GLA: {
            ImageUrl: "/media/12318141/gla.png",
            Symbol: "GLA",
            CoinName: "Gladius",
        },
        ZNA: {
            ImageUrl: "/media/12318142/zna.png",
            Symbol: "ZNA",
            CoinName: "Zenome",
        },
        EZM: {
            ImageUrl: "/media/12318143/ezm.png",
            Symbol: "EZM",
            CoinName: "EZMarket",
        },
        ODN: {
            ImageUrl: "/media/12318145/odn.png",
            Symbol: "ODN",
            CoinName: "Obsidian",
        },
        POLL: {
            ImageUrl: "/media/12318144/poll.png",
            Symbol: "POLL",
            CoinName: "ClearPoll",
        },
        MTK: {
            ImageUrl: "/media/12318149/mtk.png",
            Symbol: "MTK",
            CoinName: "Moya Token",
        },
        CAS: {
            ImageUrl: "/media/12318148/cas.png",
            Symbol: "CAS",
            CoinName: "Cashaa",
        },
        MAT: {
            ImageUrl: "/media/12318162/mat.png",
            Symbol: "MAT",
            CoinName: "MiniApps",
        },
        GJC: {
            ImageUrl: "/media/12318166/gjc.png",
            Symbol: "GJC",
            CoinName: "Global Jobcoin",
        },
        WIC: {
            ImageUrl: "/media/12318168/ocfkmb0t_400x400.jpg",
            Symbol: "WIC",
            CoinName: "Wi Coin",
        },
        WAND: {
            ImageUrl: "/media/12318182/wandxlogo_new1.png",
            Symbol: "WAND",
            CoinName: "WandX",
        },
        ELIX: {
            ImageUrl: "/media/12318172/elix.png",
            Symbol: "ELIX",
            CoinName: "Elixir",
        },
        EBTC: {
            ImageUrl: "/media/12318175/ebtc.png",
            Symbol: "EBTC",
            CoinName: "eBitcoin",
        },
        ADA: {
            ImageUrl: "/media/12318177/ada.png",
            Symbol: "ADA",
            CoinName: "Cardano",
        },
        REC: {
            ImageUrl: "/media/12318179/rec.png",
            Symbol: "REC",
            CoinName: "Regalcoin",
        },
        BIS: {
            ImageUrl: "/media/12318191/bis.png",
            Symbol: "BIS",
            CoinName: "Bismuth",
        },
        OPP: {
            ImageUrl: "/media/12318188/opp.png",
            Symbol: "OPP",
            CoinName: "Opporty",
        },
        ROCK: {
            ImageUrl: "/media/12318189/rock.png",
            Symbol: "ROCK",
            CoinName: "Ice Rock Mining",
        },
        EARTH: {
            ImageUrl: "/media/12318190/earth.png",
            Symbol: "EARTH",
            CoinName: "Earth Token",
        },
        VSX: {
            ImageUrl: "/media/12318194/vsx.png",
            Symbol: "VSX",
            CoinName: "Vsync",
        },
        "WISH*": {
            ImageUrl: "/media/12317980/wish.png",
            Symbol: "WISH*",
            CoinName: "WishFinance",
        },
        FLASH: {
            ImageUrl: "/media/12318206/flash.png",
            Symbol: "FLASH",
            CoinName: "FLASH coin",
        },
        GRFT: {
            ImageUrl: "/media/12318208/grf.png",
            Symbol: "GRFT",
            CoinName: "Graft Blockchain",
        },
        BTCZ: {
            ImageUrl: "/media/12318408/btcz.png",
            Symbol: "BTCZ",
            CoinName: "BitcoinZ",
        },
        CZC: {
            ImageUrl: "/media/12318215/czc.png",
            Symbol: "CZC",
            CoinName: "Crazy Coin",
        },
        GUESS: {
            ImageUrl: "/media/12318217/guess.png",
            Symbol: "GUESS",
            CoinName: "Peerguess",
        },
        CAN: {
            ImageUrl: "/media/12318218/canya.png",
            Symbol: "CAN",
            CoinName: "CanYaCoin",
        },
        ETP: {
            ImageUrl: "/media/12318223/etp.png",
            Symbol: "ETP",
            CoinName: "Metaverse",
        },
        CIX: {
            ImageUrl: "/media/12318222/cnxasterisco.png",
            Symbol: "CIX",
            CoinName: "Cryptonetix",
        },
        ERT: {
            ImageUrl: "/media/12318226/ert.png",
            Symbol: "ERT",
            CoinName: "Esports.com",
        },
        "BAC*": {
            ImageUrl: "/media/12318229/bac.png",
            Symbol: "BAC*",
            CoinName: "LakeBanker",
        },
        FLIK: {
            ImageUrl: "/media/12318230/flik.png",
            Symbol: "FLIK",
            CoinName: "FLiK",
        },
        MBT: {
            ImageUrl: "/media/12318238/mbt.png",
            Symbol: "MBT",
            CoinName: "Multibot",
        },
        JVY: {
            ImageUrl: "/media/12318244/jvy.png",
            Symbol: "JVY",
            CoinName: "Javvy",
        },
        ALIS: {
            ImageUrl: "/media/12318247/alis.png",
            Symbol: "ALIS",
            CoinName: "ALISmedia",
        },
        LEV: {
            ImageUrl: "/media/12318249/lev.png",
            Symbol: "LEV",
            CoinName: "Leverj",
        },
        ARBI: {
            ImageUrl: "/media/12318258/arbi.png",
            Symbol: "ARBI",
            CoinName: "Arbi",
        },
        ELT: {
            ImageUrl: "/media/12318259/elt.png",
            Symbol: "ELT",
            CoinName: "Eloplay",
        },
        ARN: {
            ImageUrl: "/media/12318261/arn.png",
            Symbol: "ARN",
            CoinName: "Aeron",
        },
        ROK: {
            ImageUrl: "/media/12318268/rok.png",
            Symbol: "ROK",
            CoinName: "Rockchain",
        },
        XRED: {
            ImageUrl: "/media/12318269/xred.png",
            Symbol: "XRED",
            CoinName: "X Real Estate Development",
        },
        DAY: {
            ImageUrl: "/media/12318271/day.png",
            Symbol: "DAY",
            CoinName: "Chronologic",
        },
        FLP: {
            ImageUrl: "/media/12318280/flip.png",
            Symbol: "FLP",
            CoinName: "Gameflip",
        },
        HXT: {
            ImageUrl: "/media/12318282/hxt.png",
            Symbol: "HXT",
            CoinName: "HextraCoin",
        },
        "VRP*": {
            ImageUrl: "/media/12318284/vrt.png",
            Symbol: "VRP*",
            CoinName: "Prosense.tv",
        },
        NTM: {
            ImageUrl: "/media/12318286/ntm.png",
            Symbol: "NTM",
            CoinName: "NetM",
        },
        TZC: {
            ImageUrl: "/media/12318285/tzc.png",
            Symbol: "TZC",
            CoinName: "TrezarCoin",
        },
        MCI: {
            ImageUrl: "/media/12318289/mci.png",
            Symbol: "MCI",
            CoinName: "Musiconomi",
        },
        AIR: {
            ImageUrl: "/media/12318291/air.png",
            Symbol: "AIR",
            CoinName: "AirToken",
        },
        NTO: {
            ImageUrl: "/media/12318293/nto.png",
            Symbol: "NTO",
            CoinName: "Fujinto",
        },
        ATCC: {
            ImageUrl: "/media/12318294/atcc.png",
            Symbol: "ATCC",
            CoinName: "ATC Coin",
        },
        KOLION: {
            ImageUrl: "/media/12318295/kolion.png",
            Symbol: "KOLION",
            CoinName: "Kolion",
        },
        WILD: {
            ImageUrl: "/media/12318298/wild.png",
            Symbol: "WILD",
            CoinName: "Wild Crypto",
        },
        ELTC2: {
            ImageUrl: "/media/12318300/eltc2.png",
            Symbol: "ELTC2",
            CoinName: "eLTC",
        },
        ILCT: {
            ImageUrl: "/media/12318299/ilct.png",
            Symbol: "ILCT",
            CoinName: "ILCoin Token",
        },
        RYZ: {
            ImageUrl: "/media/12318305/ryz.png",
            Symbol: "RYZ",
            CoinName: "Anryze",
        },
        "GXC*": {
            ImageUrl: "/media/12318306/gxc.png",
            Symbol: "GXC*",
            CoinName: "GenXCoin",
        },
        ELM: {
            ImageUrl: "/media/12318308/elm.png",
            Symbol: "ELM",
            CoinName: "Elements",
        },
        TER: {
            ImageUrl: "/media/12318324/ter.png",
            Symbol: "TER",
            CoinName: "TerraNovaCoin",
        },
        XCS: {
            ImageUrl: "/media/12318323/xcs.png",
            Symbol: "XCS",
            CoinName: "CybCSec Coin",
        },
        BQ: {
            ImageUrl: "/media/12318325/bq.png",
            Symbol: "BQ",
            CoinName: "Bitqy",
        },
        CAV: {
            ImageUrl: "/media/12318328/cav.png",
            Symbol: "CAV",
            CoinName: "Caviar",
        },
        CLOUT: {
            ImageUrl: "/media/12318329/clout.png",
            Symbol: "CLOUT",
            CoinName: "Clout",
        },
        "PTC*": {
            ImageUrl: "/media/14913426/ptc.png",
            Symbol: "PTC*",
            CoinName: "Propthereum",
        },
        EVR: {
            ImageUrl: "/media/12318332/evr.png",
            Symbol: "EVR",
            CoinName: "Everus",
        },
        TOA: {
            ImageUrl: "/media/12318334/toacoin.png",
            Symbol: "TOA",
            CoinName: "TOA Coin",
        },
        MNZ: {
            ImageUrl: "/media/12318336/mnz.png",
            Symbol: "MNZ",
            CoinName: "Monaize",
        },
        VIVO: {
            ImageUrl: "/media/12318337/vivo.png",
            Symbol: "VIVO",
            CoinName: "VIVO Coin",
        },
        RPX: {
            ImageUrl: "/media/12318339/rpx.png",
            Symbol: "RPX",
            CoinName: "Red Pulse",
        },
        AURS: {
            ImageUrl: "/media/12318345/aurs.png",
            Symbol: "AURS",
            CoinName: "Aureus",
        },
        CAG: {
            ImageUrl: "/media/25792623/cag1.png",
            Symbol: "CAG",
            CoinName: "Change",
        },
        PKT: {
            ImageUrl: "/media/12318349/playkey.png",
            Symbol: "PKT",
            CoinName: "Playkey",
        },
        ECHT: {
            ImageUrl: "/media/12318352/echt.png",
            Symbol: "ECHT",
            CoinName: "e-Chat",
        },
        INXT: {
            ImageUrl: "/media/12318355/inxt.png",
            Symbol: "INXT",
            CoinName: "Internxt",
        },
        ATS: {
            ImageUrl: "/media/12318356/ats.png",
            Symbol: "ATS",
            CoinName: "Authorship",
        },
        RGC: {
            ImageUrl: "/media/12318357/rgc.png",
            Symbol: "RGC",
            CoinName: "RG Coin",
        },
        EBET: {
            ImageUrl: "/media/12318358/ebet.png",
            Symbol: "EBET",
            CoinName: "EthBet",
        },
        "BM*": {
            ImageUrl: "/media/12318361/bm.png",
            Symbol: "BM*",
            CoinName: "Bitcomo",
        },
        CPAY: {
            ImageUrl: "/media/12318303/cpay.png",
            Symbol: "CPAY",
            CoinName: "CryptoPay",
        },
        "BON*": {
            ImageUrl: "/media/12318368/bon.png",
            Symbol: "BON*",
            CoinName: "Bonpay",
        },
        WHL: {
            ImageUrl: "/media/12318372/whl.png",
            Symbol: "WHL",
            CoinName: "WhaleCoin",
        },
        BTG: {
            ImageUrl: "/media/27011062/btg.png",
            Symbol: "BTG",
            CoinName: "Bitcoin Gold",
        },
        ETG: {
            ImageUrl: "/media/12318378/etg.png",
            Symbol: "ETG",
            CoinName: "Ethereum Gold",
        },
        WOMEN: {
            ImageUrl: "/media/12318379/women.png",
            Symbol: "WOMEN",
            CoinName: "WomenCoin",
        },
        MAY: {
            ImageUrl: "/media/12318380/may.png",
            Symbol: "MAY",
            CoinName: "Theresa May Coin",
        },
        RNDR: {
            ImageUrl: "/media/12318381/rndr.png",
            Symbol: "RNDR",
            CoinName: "Render Token",
        },
        EDDIE: {
            ImageUrl: "/media/12318382/eddie.png",
            Symbol: "EDDIE",
            CoinName: "Eddie coin",
        },
        "SCT*": {
            ImageUrl: "/media/12318383/sct.png",
            Symbol: "SCT*",
            CoinName: "Soma",
        },
        NAMO: {
            ImageUrl: "/media/12318384/namo.png",
            Symbol: "NAMO",
            CoinName: "NamoCoin",
        },
        KCS: {
            ImageUrl: "/media/12318389/kcs.png",
            Symbol: "KCS",
            CoinName: "Kucoin",
        },
        GAT: {
            ImageUrl: "/media/12318390/gat.png",
            Symbol: "GAT",
            CoinName: "GATCOIN",
        },
        BLUE: {
            ImageUrl: "/media/12318407/blue.png",
            Symbol: "BLUE",
            CoinName: "Ethereum Blue",
        },
        FLLW: {
            ImageUrl: "/media/12318412/fllw.png",
            Symbol: "FLLW",
            CoinName: "Follow Coin",
        },
        WYR: {
            ImageUrl: "/media/12318413/wyr.png",
            Symbol: "WYR",
            CoinName: "Wyrify",
        },
        VZT: {
            ImageUrl: "/media/12318414/vzt.png",
            Symbol: "VZT",
            CoinName: "Vezt",
        },
        INDI: {
            ImageUrl: "/media/12318419/indi.png",
            Symbol: "INDI",
            CoinName: "IndiCoin",
        },
        LUX: {
            ImageUrl: "/media/12318422/lux.png",
            Symbol: "LUX",
            CoinName: "LUXCoin",
        },
        BAR: {
            ImageUrl: "/media/14543951/bar.png",
            Symbol: "BAR",
            CoinName: "TBIS token",
        },
        PIRL: {
            ImageUrl: "/media/34155614/pirl.png",
            Symbol: "PIRL",
            CoinName: "Pirl",
        },
        ECASH: {
            ImageUrl: "/media/14543971/ecash.png",
            Symbol: "ECASH",
            CoinName: "Ethereum Cash",
        },
        ODMC: {
            ImageUrl: "/media/14761889/odmcoin.png",
            Symbol: "ODMC",
            CoinName: "ODMCoin",
        },
        "CABS*": {
            ImageUrl: "/media/14761917/ctst.png",
            Symbol: "CABS*",
            CoinName: "CyberTrust",
        },
        BRAT: {
            ImageUrl: "/media/25792621/brat.png",
            Symbol: "BRAT",
            CoinName: "BROTHER",
        },
        DTR: {
            ImageUrl: "/media/14761903/dtr.png",
            Symbol: "DTR",
            CoinName: "Dynamic Trading Rights",
        },
        TKR: {
            ImageUrl: "/media/14761909/tkr.png",
            Symbol: "TKR",
            CoinName: "CryptoInsight",
        },
        ELITE: {
            ImageUrl: "/media/14761914/elite.png",
            Symbol: "ELITE",
            CoinName: "EthereumLite",
        },
        XIOS: {
            ImageUrl: "/media/14761915/xios.png",
            Symbol: "XIOS",
            CoinName: "Xios",
        },
        DOVU: {
            ImageUrl: "/media/14761916/dovu.png",
            Symbol: "DOVU",
            CoinName: "DOVU",
        },
        ETN: {
            ImageUrl: "/media/14761932/electroneum.png",
            Symbol: "ETN",
            CoinName: "Electroneum",
        },
        REA: {
            ImageUrl: "/media/14761934/rea.png",
            Symbol: "REA",
            CoinName: "Realisto",
        },
        AVE: {
            ImageUrl: "/media/14761937/ave.png",
            Symbol: "AVE",
            CoinName: "Avesta",
        },
        XNN: {
            ImageUrl: "/media/14761938/xnn.png",
            Symbol: "XNN",
            CoinName: "Xenon",
        },
        BTDX: {
            ImageUrl: "/media/14761939/btdx.png",
            Symbol: "BTDX",
            CoinName: "Bitcloud 2.0",
        },
        "LOAN*": {
            ImageUrl: "/media/14761940/loan.png",
            Symbol: "LOAN*",
            CoinName: "Lendoit",
        },
        "DTT*": {
            ImageUrl: "/media/14761941/dtt1.png",
            Symbol: "DTT*",
            CoinName: "Data Trading",
        },
        ZAB: {
            ImageUrl: "/media/14761946/zab.png",
            Symbol: "ZAB",
            CoinName: "ZABERcoin",
        },
        "MDL*": {
            ImageUrl: "/media/14913435/mdl-ico.png",
            Symbol: "MDL*",
            CoinName: "Modulum",
        },
        BT1: {
            ImageUrl: "/media/19633/btc.png",
            Symbol: "BT1",
            CoinName: "Bitfinex Bitcoin Future",
        },
        BT2: {
            ImageUrl: "/media/19633/btc.png",
            Symbol: "BT2",
            CoinName: "Bitcoin SegWit2X",
        },
        "SHP*": {
            ImageUrl: "/media/14761950/shp.png",
            Symbol: "SHP*",
            CoinName: "Sharpe Capital",
        },
        JCR: {
            ImageUrl: "/media/14761952/jcr.png",
            Symbol: "JCR",
            CoinName: "Jincor",
        },
        XSB: {
            ImageUrl: "/media/14761953/xbs.png",
            Symbol: "XSB",
            CoinName: "Extreme Sportsbook",
        },
        ATM: {
            ImageUrl: "/media/14913430/atm.png",
            Symbol: "ATM",
            CoinName: "ATMChain",
        },
        EBST: {
            ImageUrl: "/media/14913431/ebst.png",
            Symbol: "EBST",
            CoinName: "eBoost",
        },
        KEK: {
            ImageUrl: "/media/14913432/kek.png",
            Symbol: "KEK",
            CoinName: "KekCoin",
        },
        AID: {
            ImageUrl: "/media/14913433/aidcoin.png",
            Symbol: "AID",
            CoinName: "AidCoin",
        },
        "BHC*": {
            ImageUrl: "/media/14913434/bhc.png",
            Symbol: "BHC*",
            CoinName: "BlackholeCoin",
        },
        ALTCOM: {
            ImageUrl: "/media/14913436/altcom.png",
            Symbol: "ALTCOM",
            CoinName: "AltCommunity Coin",
        },
        OST: {
            ImageUrl: "/media/14913437/st.png",
            Symbol: "OST",
            CoinName: "Simple Token",
        },
        UGC: {
            ImageUrl: "/media/14913439/ugt.png",
            Symbol: "UGC",
            CoinName: "ugChain",
        },
        DTC: {
            ImageUrl: "/media/14913440/dtc.png",
            Symbol: "DTC",
            CoinName: "Datacoin",
        },
        PLAY: {
            ImageUrl: "/media/14913441/play.png",
            Symbol: "PLAY",
            CoinName: "HEROcoin",
        },
        PURE: {
            ImageUrl: "/media/14913451/pure.png",
            Symbol: "PURE",
            CoinName: "Pure",
        },
        CLD: {
            ImageUrl: "/media/14913452/cld.png",
            Symbol: "CLD",
            CoinName: "Cloud",
        },
        OTN: {
            ImageUrl: "/media/14913453/otn.png",
            Symbol: "OTN",
            CoinName: "Open Trading Network",
        },
        POS: {
            ImageUrl: "/media/14913455/pos.png",
            Symbol: "POS",
            CoinName: "PoSToken",
        },
        REBL: {
            ImageUrl: "/media/30001864/rebl1.jpg",
            Symbol: "REBL",
            CoinName: "REBL",
        },
        NEOG: {
            ImageUrl: "/media/14913457/neog.png",
            Symbol: "NEOG",
            CoinName: "NEO Gold",
        },
        EXN: {
            ImageUrl: "/media/14913459/exn.png",
            Symbol: "EXN",
            CoinName: "ExchangeN",
        },
        TRCT: {
            ImageUrl: "/media/14913462/trct.png",
            Symbol: "TRCT",
            CoinName: "Tracto",
        },
        BTCRED: {
            ImageUrl: "/media/14913463/btcred.png",
            Symbol: "BTCRED",
            CoinName: "Bitcoin Red",
        },
        EBCH: {
            ImageUrl: "/media/14913464/ebch.png",
            Symbol: "EBCH",
            CoinName: "eBitcoinCash",
        },
        JTX: {
            ImageUrl: "/media/14913466/jpc.png",
            Symbol: "JTX",
            CoinName: "Project J",
        },
        AXT: {
            ImageUrl: "/media/14913467/axt.png",
            Symbol: "AXT",
            CoinName: "AIX",
        },
        NEU: {
            ImageUrl: "/media/14913483/neu.png",
            Symbol: "NEU",
            CoinName: "Neumark",
        },
        RUPX: {
            ImageUrl: "/media/14913484/rupx.png",
            Symbol: "RUPX",
            CoinName: "Rupaya",
        },
        BDR: {
            ImageUrl: "/media/14913485/bdr.png",
            Symbol: "BDR",
            CoinName: "BlueDragon",
        },
        XIN: {
            ImageUrl: "/media/30002029/xin.png",
            Symbol: "XIN",
            CoinName: "Infinity Economics",
        },
        DUTCH: {
            ImageUrl: "/media/14913487/dutch.png",
            Symbol: "DUTCH",
            CoinName: "Dutch Coin",
        },
        "HNC*": {
            ImageUrl: "/media/14913529/hnc.png",
            Symbol: "HNC*",
            CoinName: "Huncoin",
        },
        "MDC*": {
            ImageUrl: "/media/14913531/mdc.png",
            Symbol: "MDC*",
            CoinName: "MadCoin",
        },
        PURA: {
            ImageUrl: "/media/14913533/pura.png",
            Symbol: "PURA",
            CoinName: "Pura",
        },
        INN: {
            ImageUrl: "/media/14913536/inn.png",
            Symbol: "INN",
            CoinName: "Innova",
        },
        BDL: {
            ImageUrl: "/media/14913539/bdl.png",
            Symbol: "BDL",
            CoinName: "Bitdeal",
        },
        CMS: {
            ImageUrl: "/media/14913540/comsa.png",
            Symbol: "CMS",
            CoinName: "COMSA",
        },
        XBL: {
            ImageUrl: "/media/14913541/xbl.png",
            Symbol: "XBL",
            CoinName: "Billionaire Token",
        },
        ZEPH: {
            ImageUrl: "/media/14913542/zeph.png",
            Symbol: "ZEPH",
            CoinName: "Project Zephyr",
        },
        ATFS: {
            ImageUrl: "/media/14913545/atfs.png",
            Symbol: "ATFS",
            CoinName: "ATFS Project",
        },
        GES: {
            ImageUrl: "/media/14913547/ges.png",
            Symbol: "GES",
            CoinName: "Galaxy eSolutions",
        },
        "PHR*": {
            ImageUrl: "/media/14913549/phr.png",
            Symbol: "PHR*",
            CoinName: "Phore",
        },
        LCASH: {
            ImageUrl: "/media/14913550/lcash.png",
            Symbol: "LCASH",
            CoinName: "LitecoinCash",
        },
        CFD: {
            ImageUrl: "/media/14913552/cfd.png",
            Symbol: "CFD",
            CoinName: "Confido",
        },
        PLC: {
            ImageUrl: "/media/14913555/plus.png",
            Symbol: "PLC",
            CoinName: "PlusCoin",
        },
        SRN: {
            ImageUrl: "/media/14913556/srn.png",
            Symbol: "SRN",
            CoinName: "SirinLabs",
        },
        WSC: {
            ImageUrl: "/media/14913560/wsc.png",
            Symbol: "WSC",
            CoinName: "WiserCoin",
        },
        XGOX: {
            ImageUrl: "/media/14913685/xgox.png",
            Symbol: "XGOX",
            CoinName: "Go!",
        },
        NEWB: {
            ImageUrl: "/media/14913564/newb.png",
            Symbol: "NEWB",
            CoinName: "Newbium",
        },
        LIFE: {
            ImageUrl: "/media/14913568/life.png",
            Symbol: "LIFE",
            CoinName: "LIFE",
        },
        RMC: {
            ImageUrl: "/media/14913570/rmc.png",
            Symbol: "RMC",
            CoinName: "Russian Mining Coin",
        },
        MSR: {
            ImageUrl: "/media/14913574/msr.png",
            Symbol: "MSR",
            CoinName: "Masari",
        },
        CJT: {
            ImageUrl: "/media/14913575/cjt.png",
            Symbol: "CJT",
            CoinName: "ConnectJob Token",
        },
        "ESC*": {
            ImageUrl: "/media/14913585/esc.png",
            Symbol: "ESC*",
            CoinName: "Ethersportcoin",
        },
        BNK: {
            ImageUrl: "/media/14913602/bnk.png",
            Symbol: "BNK",
            CoinName: "Bankera",
        },
        ELLA: {
            ImageUrl: "/media/14913603/ella.png",
            Symbol: "ELLA",
            CoinName: "Ellaism",
        },
        BPL: {
            ImageUrl: "/media/14913604/bpl.png",
            Symbol: "BPL",
            CoinName: "BlockPool",
        },
        DRXNE: {
            ImageUrl: "/media/14913608/drxne.png",
            Symbol: "DRXNE",
            CoinName: "Droxne",
        },
        SKR: {
            ImageUrl: "/media/14913631/skr.png",
            Symbol: "SKR",
            CoinName: "Sakuracoin",
        },
        XPTX: {
            ImageUrl: "/media/14913633/xptx.png",
            Symbol: "XPTX",
            CoinName: "PlatinumBAR",
        },
        GVT: {
            ImageUrl: "/media/14913634/gvt.png",
            Symbol: "GVT",
            CoinName: "Genesis Vision",
        },
        ASTRO: {
            ImageUrl: "/media/14913641/astro.png",
            Symbol: "ASTRO",
            CoinName: "Astronaut",
        },
        GMT: {
            ImageUrl: "/media/14913642/gmt.png",
            Symbol: "GMT",
            CoinName: "Mercury Protocol",
        },
        "EPY*": {
            ImageUrl: "/media/14913643/epy.png",
            Symbol: "EPY*",
            CoinName: "Emphy",
        },
        EXY: {
            ImageUrl: "/media/14913645/exy.png",
            Symbol: "EXY",
            CoinName: "Experty",
        },
        "HOLD*": {
            ImageUrl: "/media/14913647/hold.png",
            Symbol: "HOLD*",
            CoinName: "Interstellar Holdings",
        },
        MNX: {
            ImageUrl: "/media/14913648/mnx.png",
            Symbol: "MNX",
            CoinName: "MinexCoin",
        },
        CRDS: {
            ImageUrl: "/media/14913675/crds.png",
            Symbol: "CRDS",
            CoinName: "Credits",
        },
        VIU: {
            ImageUrl: "/media/14913680/viu.png",
            Symbol: "VIU",
            CoinName: "Viuly",
        },
        "SCR*": {
            ImageUrl: "/media/14913681/scr.png",
            Symbol: "SCR*",
            CoinName: "Scorum",
        },
        DBR: {
            ImageUrl: "/media/14913687/dbr.png",
            Symbol: "DBR",
            CoinName: "Düber",
        },
        ABT: {
            ImageUrl: "/media/14913683/abt.png",
            Symbol: "ABT",
            CoinName: "Advanced Browsing Token",
        },
        GFT: {
            ImageUrl: "/media/14913686/gft.jpg",
            Symbol: "GFT",
            CoinName: "Giftcoin",
        },
        STAC: {
            ImageUrl: "/media/27010495/stac1.png",
            Symbol: "STAC",
            CoinName: "STAC",
        },
        RIPT: {
            ImageUrl: "/media/15887409/ript.png",
            Symbol: "RIPT",
            CoinName: "RiptideCoin",
        },
        BBT: {
            ImageUrl: "/media/15887410/bbt.png",
            Symbol: "BBT",
            CoinName: "BitBoost",
        },
        GBX: {
            ImageUrl: "/media/15887411/gbx.png",
            Symbol: "GBX",
            CoinName: "GoByte",
        },
        CSTL: {
            ImageUrl: "/media/15887421/cstl.png",
            Symbol: "CSTL",
            CoinName: "Castle",
        },
        ICC: {
            ImageUrl: "/media/15887424/icc.png",
            Symbol: "ICC",
            CoinName: "Insta Cash Coin",
        },
        ALQO: {
            ImageUrl: "/media/16404849/alqo.png",
            Symbol: "ALQO",
            CoinName: "Alqo",
        },
        "KNC**": {
            ImageUrl: "/media/16404850/knc.png",
            Symbol: "KNC**",
            CoinName: "KingN Coin",
        },
        TRIA: {
            ImageUrl: "/media/16404852/tria.png",
            Symbol: "TRIA",
            CoinName: "Triaconta",
        },
        PBL: {
            ImageUrl: "/media/16404866/pbl.png",
            Symbol: "PBL",
            CoinName: "Publica",
        },
        MAG: {
            ImageUrl: "/media/16404853/mag.png",
            Symbol: "MAG",
            CoinName: "Magnet",
        },
        STEX: {
            ImageUrl: "/media/16404854/stex.png",
            Symbol: "STEX",
            CoinName: "STEX",
        },
        UFR: {
            ImageUrl: "/media/16404855/ufr.png",
            Symbol: "UFR",
            CoinName: "Upfiring",
        },
        LOCI: {
            ImageUrl: "/media/16404856/loci.png",
            Symbol: "LOCI",
            CoinName: "LociCoin",
        },
        TAU: {
            ImageUrl: "/media/16404857/lamden.png",
            Symbol: "TAU",
            CoinName: "Lamden Tau",
        },
        LAB: {
            ImageUrl: "/media/16404858/lab.png",
            Symbol: "LAB",
            CoinName: "Labrys",
        },
        DEB: {
            ImageUrl: "/media/16404861/deb.png",
            Symbol: "DEB",
            CoinName: "Debitum Token",
        },
        FLIXX: {
            ImageUrl: "/media/16404862/flixx.png",
            Symbol: "FLIXX",
            CoinName: "Flixxo",
        },
        FRD: {
            ImageUrl: "/media/16404865/frd.png",
            Symbol: "FRD",
            CoinName: "Farad",
        },
        PFR: {
            ImageUrl: "/media/30001682/pfr.png",
            Symbol: "PFR",
            CoinName: "PayFair",
        },
        ECA: {
            ImageUrl: "/media/16404869/eca.png",
            Symbol: "ECA",
            CoinName: "Electra",
        },
        LDM: {
            ImageUrl: "/media/16404870/ldm.png",
            Symbol: "LDM",
            CoinName: "Ludum token",
        },
        LTG: {
            ImageUrl: "/media/16404871/ltg.png",
            Symbol: "LTG",
            CoinName: "LiteCoin Gold",
        },
        BCD: {
            ImageUrl: "/media/16404872/bcd.png",
            Symbol: "BCD",
            CoinName: "Bitcoin Diamond",
        },
        STP: {
            ImageUrl: "/media/16404874/stp.png",
            Symbol: "STP",
            CoinName: "StashPay",
        },
        WISH: {
            ImageUrl: "/media/16404892/wish.png",
            Symbol: "WISH",
            CoinName: "MyWish",
        },
        AERM: {
            ImageUrl: "/media/16404893/aerm.png",
            Symbol: "AERM",
            CoinName: "Aerium",
        },
        PLX: {
            ImageUrl: "/media/16404895/plx.png",
            Symbol: "PLX",
            CoinName: "PlexCoin",
        },
        "NIO*": {
            ImageUrl: "/media/16404894/nio.png",
            Symbol: "NIO*",
            CoinName: "Autonio",
        },
        ETHB: {
            ImageUrl: "/media/16746424/ethb.png",
            Symbol: "ETHB",
            CoinName: "EtherBTC",
        },
        CDX: {
            ImageUrl: "/media/16746425/cdx.png",
            Symbol: "CDX",
            CoinName: "Commodity Ad Network",
        },
        FOOD: {
            ImageUrl: "/media/16746427/food.png",
            Symbol: "FOOD",
            CoinName: "FoodCoin",
        },
        VOT: {
            ImageUrl: "/media/16746442/vot.png",
            Symbol: "VOT",
            CoinName: "Votecoin",
        },
        SETH: {
            ImageUrl: "/media/16746447/seth.png",
            Symbol: "SETH",
            CoinName: "Sether",
        },
        "TIO*": {
            ImageUrl: "/media/16746450/tio.png",
            Symbol: "TIO*",
            CoinName: "Tio Tour Guides",
        },
        ABYSS: {
            ImageUrl: "/media/30002251/abyss1.png",
            Symbol: "ABYSS",
            CoinName: "The Abyss",
        },
        XSH: {
            ImageUrl: "/media/16746453/xsh.png",
            Symbol: "XSH",
            CoinName: "SHIELD",
        },
        "BCD*": {
            ImageUrl: "/media/16746454/bcd.png",
            Symbol: "BCD*",
            CoinName: "BitCAD",
        },
        GEA: {
            ImageUrl: "/media/16746475/gea.png",
            Symbol: "GEA",
            CoinName: "Goldea",
        },
        "BCO*": {
            ImageUrl: "/media/16746477/bco.png",
            Symbol: "BCO*",
            CoinName: "BridgeCoin",
        },
        DSR: {
            ImageUrl: "/media/16746481/dsr.png",
            Symbol: "DSR",
            CoinName: "Desire",
        },
        BDG: {
            ImageUrl: "/media/16746482/bdg.png",
            Symbol: "BDG",
            CoinName: "BitDegree",
        },
        ONG: {
            ImageUrl: "/media/16746484/ong.png",
            Symbol: "ONG",
            CoinName: "onG.social",
        },
        BTCM: {
            ImageUrl: "/media/16746489/btcm.png",
            Symbol: "BTCM",
            CoinName: "BTCMoon",
        },
        ETBT: {
            ImageUrl: "/media/16746535/etbt.png",
            Symbol: "ETBT",
            CoinName: "Ethereum Black",
        },
        ZCG: {
            ImageUrl: "/media/16746536/zcg.png",
            Symbol: "ZCG",
            CoinName: "ZCashGOLD",
        },
        MUT: {
            ImageUrl: "/media/16746537/mut.png",
            Symbol: "MUT",
            CoinName: "Mutual Coin",
        },
        MEOW: {
            ImageUrl: "/media/16746539/meow.png",
            Symbol: "MEOW",
            CoinName: "Kittehcoin",
        },
        DIVX: {
            ImageUrl: "/media/16746540/divx.png",
            Symbol: "DIVX",
            CoinName: "Divi",
        },
        CNBC: {
            ImageUrl: "/media/16746541/cnbc.png",
            Symbol: "CNBC",
            CoinName: "Cash & Back Coin",
        },
        "ARC*": {
            ImageUrl: "/media/16746547/arc.png",
            Symbol: "ARC*",
            CoinName: " Arcade City",
        },
        XUN: {
            ImageUrl: "/media/16746548/xun.png",
            Symbol: "XUN",
            CoinName: "UltraNote",
        },
        RFL: {
            ImageUrl: "/media/16746549/rfl.png",
            Symbol: "RFL",
            CoinName: "RAFL",
        },
        COFI: {
            ImageUrl: "/media/16746551/cofi.png",
            Symbol: "COFI",
            CoinName: "CoinFi",
        },
        ELTCOIN: {
            ImageUrl: "/media/16746556/eltcoin.png",
            Symbol: "ELTCOIN",
            CoinName: "ELTCOIN",
        },
        GRX: {
            ImageUrl: "/media/16746557/grx.png",
            Symbol: "GRX",
            CoinName: "Gold Reward Token",
        },
        NTK: {
            ImageUrl: "/media/16746560/ntk.png",
            Symbol: "NTK",
            CoinName: "Neurotoken",
        },
        ERO: {
            ImageUrl: "/media/16746561/ero.png",
            Symbol: "ERO",
            CoinName: "Eroscoin",
        },
        RLX: {
            ImageUrl: "/media/34155508/rlx.png",
            Symbol: "RLX",
            CoinName: "Relex",
        },
        MAN: {
            ImageUrl: "/media/16746574/man.png",
            Symbol: "MAN",
            CoinName: "People",
        },
        CWV: {
            ImageUrl: "/media/16746575/cwv.png",
            Symbol: "CWV",
            CoinName: "CryptoWave",
        },
        "ACT*": {
            ImageUrl: "/media/16746576/act.png",
            Symbol: "ACT*",
            CoinName: "Achain",
        },
        NRO: {
            ImageUrl: "/media/16746592/nro.png",
            Symbol: "NRO",
            CoinName: "Neuro",
        },
        SEND: {
            ImageUrl: "/media/16746577/send.png",
            Symbol: "SEND",
            CoinName: "Social Send",
        },
        GLT: {
            ImageUrl: "/media/16746578/glt.png",
            Symbol: "GLT",
            CoinName: "GlobalToken",
        },
        X8X: {
            ImageUrl: "/media/16746588/x8x.png",
            Symbol: "X8X",
            CoinName: "X8Currency",
        },
        COAL: {
            ImageUrl: "/media/16746586/coal.png",
            Symbol: "COAL",
            CoinName: "BitCoal",
        },
        DAXX: {
            ImageUrl: "/media/16746587/daxx.png",
            Symbol: "DAXX",
            CoinName: "DaxxCoin",
        },
        BWK: {
            ImageUrl: "/media/16746590/bwk.png",
            Symbol: "BWK",
            CoinName: "Bulwark",
        },
        FNTB: {
            ImageUrl: "/media/16746591/fnt.png",
            Symbol: "FNTB",
            CoinName: "FinTab",
        },
        XMRG: {
            ImageUrl: "/media/16746651/xmrg.png",
            Symbol: "XMRG",
            CoinName: "Monero Gold",
        },
        BTCE: {
            ImageUrl: "/media/16746600/btce.png",
            Symbol: "BTCE",
            CoinName: "EthereumBitcoin",
        },
        FYP: {
            ImageUrl: "/media/16746604/fyp.png",
            Symbol: "FYP",
            CoinName: "FlypMe",
        },
        BOXY: {
            ImageUrl: "/media/16746605/boxy.png",
            Symbol: "BOXY",
            CoinName: "BoxyCoin",
        },
        UTN: {
            ImageUrl: "/media/16746611/utn.png",
            Symbol: "UTN",
            CoinName: "Universa",
        },
        EGAS: {
            ImageUrl: "/media/16746616/egas.png",
            Symbol: "EGAS",
            CoinName: "ETHGAS",
        },
        DPP: {
            ImageUrl: "/media/16746618/dpp.png",
            Symbol: "DPP",
            CoinName: "Digital Assets Power Play",
        },
        ADB: {
            ImageUrl: "/media/16746619/adb.png",
            Symbol: "ADB",
            CoinName: "Adbank",
        },
        TGT: {
            ImageUrl: "/media/16746629/tgt.png",
            Symbol: "TGT",
            CoinName: "TargetCoin",
        },
        BMT: {
            ImageUrl: "/media/16746638/bmt.png",
            Symbol: "BMT",
            CoinName: "BMChain",
        },
        BIO: {
            ImageUrl: "/media/16746639/bio.png",
            Symbol: "BIO",
            CoinName: "Biocoin",
        },
        MTRC: {
            ImageUrl: "/media/16746642/mtrc.png",
            Symbol: "MTRC",
            CoinName: "ModulTrade",
        },
        BTCL: {
            ImageUrl: "/media/16746647/btcl.png",
            Symbol: "BTCL",
            CoinName: "BTC Lite",
        },
        PCN: {
            ImageUrl: "/media/16746648/pcn.png",
            Symbol: "PCN",
            CoinName: "PeepCoin",
        },
        PYP: {
            ImageUrl: "/media/16746650/pyp.png",
            Symbol: "PYP",
            CoinName: "PayPro",
        },
        RBTC: {
            ImageUrl: "/media/16746652/rbtc.png",
            Symbol: "RBTC",
            CoinName: "Bitcoin Revolution",
        },
        CRED: {
            ImageUrl: "/media/16746661/cred.png",
            Symbol: "CRED",
            CoinName: "Verify",
        },
        SBTC: {
            ImageUrl: "/media/16746666/sbtc.png",
            Symbol: "SBTC",
            CoinName: "Super Bitcoin",
        },
        KLKS: {
            ImageUrl: "/media/16746667/klk.png",
            Symbol: "KLKS",
            CoinName: "Kalkulus",
        },
        AC3: {
            ImageUrl: "/media/16746668/ac3.png",
            Symbol: "AC3",
            CoinName: "AC3",
        },
        GTO: {
            ImageUrl: "/media/16746671/gto.png",
            Symbol: "GTO",
            CoinName: "GIFTO",
        },
        TNB: {
            ImageUrl: "/media/16746672/tnb.png",
            Symbol: "TNB",
            CoinName: "Time New Bank",
        },
        "CHIPS*": {
            ImageUrl: "/media/16746673/chips.png",
            Symbol: "CHIPS*",
            CoinName: "CHIPS",
        },
        HKN: {
            ImageUrl: "/media/16746674/hkn.png",
            Symbol: "HKN",
            CoinName: "Hacken",
        },
        B2B: {
            ImageUrl: "/media/12318185/b2bx.png",
            Symbol: "B2B",
            CoinName: "B2BX",
        },
        "LOC*": {
            ImageUrl: "/media/30002305/loc.png",
            Symbol: "LOC*",
            CoinName: "LockTrip",
        },
        "MNT*": {
            ImageUrl: "/media/16746688/mnt.png",
            Symbol: "MNT*",
            CoinName: "Media Network Coin",
        },
        ITNS: {
            ImageUrl: "/media/16746694/itns.png",
            Symbol: "ITNS",
            CoinName: "IntenseCoin",
        },
        "SMT*": {
            ImageUrl: "/media/16746697/smt.png",
            Symbol: "SMT*",
            CoinName: "SmartMesh",
        },
        GER: {
            ImageUrl: "/media/16746741/ger.png",
            Symbol: "GER",
            CoinName: "GermanCoin",
        },
        LTCU: {
            ImageUrl: "/media/16746704/ltcu.png",
            Symbol: "LTCU",
            CoinName: "LiteCoin Ultra",
        },
        EMGO: {
            ImageUrl: "/media/16746703/emgo.png",
            Symbol: "EMGO",
            CoinName: "MobileGo",
        },
        BTCA: {
            ImageUrl: "/media/16746705/btca.png",
            Symbol: "BTCA",
            CoinName: "Bitair",
        },
        HQX: {
            ImageUrl: "/media/16746735/hqx.png",
            Symbol: "HQX",
            CoinName: "HOQU",
        },
        ETF: {
            ImageUrl: "/media/16746737/etf.png",
            Symbol: "ETF",
            CoinName: "EthereumFog",
        },
        "BCX*": {
            ImageUrl: "/media/16746738/bcx.png",
            Symbol: "BCX*",
            CoinName: "BitcoinX",
        },
        "LUX**": {
            ImageUrl: "/media/16746739/lux.png",
            Symbol: "LUX**",
            CoinName: "Luxmi Coin",
        },
        STAK: {
            ImageUrl: "/media/16746740/stak.png",
            Symbol: "STAK",
            CoinName: "Straks",
        },
        BCOIN: {
            ImageUrl: "/media/16746742/bcoin.png",
            Symbol: "BCOIN",
            CoinName: "BannerCoin",
        },
        "MED*": {
            ImageUrl: "/media/16746766/med.png",
            Symbol: "MED*",
            CoinName: "MediBloc",
        },
        CCOS: {
            ImageUrl: "/media/16746767/ccos.png",
            Symbol: "CCOS",
            CoinName: "CrowdCoinage",
        },
        BNTY: {
            ImageUrl: "/media/20780588/bnty.png",
            Symbol: "BNTY",
            CoinName: "Bounty0x",
        },
        HAT: {
            ImageUrl: "/media/20780593/hat.png",
            Symbol: "HAT",
            CoinName: "Hawala.Today",
        },
        VLR: {
            ImageUrl: "/media/20780606/vlr.png",
            Symbol: "VLR",
            CoinName: "Valorem",
        },
        CWX: {
            ImageUrl: "/media/20780607/cwx.png",
            Symbol: "CWX",
            CoinName: "Crypto-X",
        },
        DBC: {
            ImageUrl: "/media/20780608/dbc.png",
            Symbol: "DBC",
            CoinName: "DeepBrain Chain",
        },
        "ZEN*": {
            ImageUrl: "/media/20780609/zen.png",
            Symbol: "ZEN*",
            CoinName: "Zen Protocol",
        },
        POP: {
            ImageUrl: "/media/20780610/pop.png",
            Symbol: "POP",
            CoinName: "PopularCoin",
        },
        "CRC***": {
            ImageUrl: "/media/20780611/crc.png",
            Symbol: "CRC***",
            CoinName: "CrowdCoin",
        },
        PNX: {
            ImageUrl: "/media/20780612/pnx.png",
            Symbol: "PNX",
            CoinName: "PhantomX",
        },
        BAS: {
            ImageUrl: "/media/20780613/bas.png",
            Symbol: "BAS",
            CoinName: "BitAsean",
        },
        HBC: {
            ImageUrl: "/media/20780615/hbc.png",
            Symbol: "HBC",
            CoinName: "HomeBlockCoin",
        },
        AMM: {
            ImageUrl: "/media/20780616/amm.png",
            Symbol: "AMM",
            CoinName: "MicroMoney",
        },
        DAV: {
            ImageUrl: "/media/20780624/dav.png",
            Symbol: "DAV",
            CoinName: "DavorCoin",
        },
        XCPO: {
            ImageUrl: "/media/20780625/xcpo.png",
            Symbol: "XCPO",
            CoinName: "Copico",
        },
        GET: {
            ImageUrl: "/media/20780626/get.png",
            Symbol: "GET",
            CoinName: "Guaranteed Entrance Token",
        },
        GENE: {
            ImageUrl: "/media/20780630/gene.png",
            Symbol: "GENE",
            CoinName: "PARKGENE",
        },
        NMS: {
            ImageUrl: "/media/20780637/nms.png",
            Symbol: "NMS",
            CoinName: "Numus",
        },
        PHO: {
            ImageUrl: "/media/20780639/pho.png",
            Symbol: "PHO",
            CoinName: "Photon",
        },
        XTRA: {
            ImageUrl: "/media/20780641/extra-logo-white.png",
            Symbol: "XTRA",
            CoinName: "ExtraCredit",
        },
        HION: {
            ImageUrl: "/media/20780646/hion.png",
            Symbol: "HION",
            CoinName: "Handelion",
        },
        NTWK: {
            ImageUrl: "/media/20780649/ntwk.png",
            Symbol: "NTWK",
            CoinName: "Network Token",
        },
        SUCR: {
            ImageUrl: "/media/20780650/sucr.png",
            Symbol: "SUCR",
            CoinName: "Sucre",
        },
        "SMART*": {
            ImageUrl: "/media/20780651/smart.png",
            Symbol: "SMART*",
            CoinName: "SmartBillions",
        },
        ACCO: {
            ImageUrl: "/media/20780654/acco.png",
            Symbol: "ACCO",
            CoinName: "Accolade",
        },
        BTH: {
            ImageUrl: "/media/20780656/bth.png",
            Symbol: "BTH",
            CoinName: "Bytether ",
        },
        REM: {
            ImageUrl: "/media/20780658/rem.png",
            Symbol: "REM",
            CoinName: "REMME",
        },
        TOK: {
            ImageUrl: "/media/20780659/tok.png",
            Symbol: "TOK",
            CoinName: "TokugawaCoin",
        },
        EREAL: {
            ImageUrl: "/media/20780660/ereal.png",
            Symbol: "EREAL",
            CoinName: "eREAL",
        },
        CPN: {
            ImageUrl: "/media/20780661/cpn.png",
            Symbol: "CPN",
            CoinName: "CompuCoin",
        },
        XFT: {
            ImageUrl: "/media/30002339/xft.jpg",
            Symbol: "XFT",
            CoinName: "Fantasy Cash",
        },
        QLC: {
            ImageUrl: "/media/20780665/qlc.png",
            Symbol: "QLC",
            CoinName: "QLC Chain",
        },
        BTSE: {
            ImageUrl: "/media/20780666/bte.png",
            Symbol: "BTSE",
            CoinName: "BitSerial",
        },
        OMGC: {
            ImageUrl: "/media/20780667/omgc.png",
            Symbol: "OMGC",
            CoinName: "OmiseGO Classic",
        },
        Q2C: {
            ImageUrl: "/media/19872/q2c.jpg",
            Symbol: "Q2C",
            CoinName: "QubitCoin",
        },
        BLT: {
            ImageUrl: "/media/20780668/blt.png",
            Symbol: "BLT",
            CoinName: "Bloom Token",
        },
        TDS: {
            ImageUrl: "/media/20780673/tds.png",
            Symbol: "TDS",
            CoinName: "TokenDesk",
        },
        ORE: {
            ImageUrl: "/media/20780695/ore.png",
            Symbol: "ORE",
            CoinName: "Galactrum",
        },
        SPK: {
            ImageUrl: "/media/20780696/spk.png",
            Symbol: "SPK",
            CoinName: "Sparks",
        },
        GOA: {
            ImageUrl: "/media/20780698/goa.png",
            Symbol: "GOA",
            CoinName: "GoaCoin",
        },
        FLS: {
            ImageUrl: "/media/20780701/fuloos.png",
            Symbol: "FLS",
            CoinName: "Fuloos Coin",
        },
        WAGE: {
            ImageUrl: "/media/30002011/digiwage.jpg",
            Symbol: "WAGE",
            CoinName: "Digiwage",
        },
        GUN: {
            ImageUrl: "/media/20780703/gun.png",
            Symbol: "GUN",
            CoinName: "GunCoin",
        },
        DFS: {
            ImageUrl: "/media/20780715/dfs.png",
            Symbol: "DFS",
            CoinName: "DFSCoin",
        },
        POLIS: {
            ImageUrl: "/media/20780731/polis.png",
            Symbol: "POLIS",
            CoinName: "PolisPay",
        },
        WELL: {
            ImageUrl: "/media/20780732/well.png",
            Symbol: "WELL",
            CoinName: "Well",
        },
        FLOT: {
            ImageUrl: "/media/20780733/flot.png",
            Symbol: "FLOT",
            CoinName: "FireLotto",
        },
        CL: {
            ImageUrl: "/media/20780740/cl.png",
            Symbol: "CL",
            CoinName: "CoinLancer",
        },
        SHND: {
            ImageUrl: "/media/20780741/shnd.png",
            Symbol: "SHND",
            CoinName: "StrongHands",
        },
        AUA: {
            ImageUrl: "/media/20780743/aua.png",
            Symbol: "AUA",
            CoinName: "ArubaCoin",
        },
        SAGA: {
            ImageUrl: "/media/20780745/saga.png",
            Symbol: "SAGA",
            CoinName: "SagaCoin",
        },
        GXS: {
            ImageUrl: "/media/20780746/gxs.png",
            Symbol: "GXS",
            CoinName: "GXChain",
        },
        TSL: {
            ImageUrl: "/media/20780749/tsl.png",
            Symbol: "TSL",
            CoinName: "Energo",
        },
        IRL: {
            ImageUrl: "/media/20780751/irl.png",
            Symbol: "IRL",
            CoinName: "IrishCoin",
        },
        BOT: {
            ImageUrl: "/media/20780753/bot.png",
            Symbol: "BOT",
            CoinName: "Bodhi",
        },
        PMA: {
            ImageUrl: "/media/20780758/pma.png",
            Symbol: "PMA",
            CoinName: "PumaPay",
        },
        TROLL: {
            ImageUrl: "/media/20780762/troll.png",
            Symbol: "TROLL",
            CoinName: "Trollcoin",
        },
        FOR: {
            ImageUrl: "/media/20780764/for.png",
            Symbol: "FOR",
            CoinName: "Force Coin",
        },
        SGR: {
            ImageUrl: "/media/20780766/sgr.png",
            Symbol: "SGR",
            CoinName: "Sugar Exchange",
        },
        JET: {
            ImageUrl: "/media/20780772/jet.png",
            Symbol: "JET",
            CoinName: "Jetcoin",
        },
        MDS: {
            ImageUrl: "/media/20780773/ipnvhhke_400x400.jpg",
            Symbol: "MDS",
            CoinName: "MediShares",
        },
        LCP: {
            ImageUrl: "/media/20780774/lcp.png",
            Symbol: "LCP",
            CoinName: "Litecoin Plus",
        },
        IETH: {
            ImageUrl: "/media/20780777/ieth.png",
            Symbol: "IETH",
            CoinName: "iEthereum",
        },
        "GCC*": {
            ImageUrl: "/media/20780778/gcc.png",
            Symbol: "GCC*",
            CoinName: "TheGCCcoin",
        },
        INK: {
            ImageUrl: "/media/20780781/ink.png",
            Symbol: "INK",
            CoinName: "Ink",
        },
        KBR: {
            ImageUrl: "/media/20780782/kbr.png",
            Symbol: "KBR",
            CoinName: "Kubera Coin",
        },
        MONK: {
            ImageUrl: "/media/20780785/monk.png",
            Symbol: "MONK",
            CoinName: "Monkey Project",
        },
        JINN: {
            ImageUrl: "/media/20780786/jinn.png",
            Symbol: "JINN",
            CoinName: "Jinn",
        },
        SET: {
            ImageUrl: "/media/20780787/set.png",
            Symbol: "SET",
            CoinName: "Setcoin",
        },
        MGN: {
            ImageUrl: "/media/20780788/mgn.png",
            Symbol: "MGN",
            CoinName: "MagnaCoin",
        },
        KZC: {
            ImageUrl: "/media/20780789/kz.png",
            Symbol: "KZC",
            CoinName: "KZCash",
        },
        GNR: {
            ImageUrl: "/media/20780791/gnr.png",
            Symbol: "GNR",
            CoinName: "Gainer",
        },
        LWF: {
            ImageUrl: "/media/20780794/lwf.png",
            Symbol: "LWF",
            CoinName: "Local World Forwarders",
        },
        BRC: {
            ImageUrl: "/media/20780798/brc.png",
            Symbol: "BRC",
            CoinName: "BrightCoin",
        },
        WCG: {
            ImageUrl: "/media/20780799/wcg.png",
            Symbol: "WCG",
            CoinName: "World Crypto Gold",
        },
        HIVE: {
            ImageUrl: "/media/20780800/flat.png",
            Symbol: "HIVE",
            CoinName: "Hive",
        },
        GX: {
            ImageUrl: "/media/20780801/gamex.png",
            Symbol: "GX",
            CoinName: "GameX",
        },
        MFG: {
            ImageUrl: "/media/25792563/mfg.png",
            Symbol: "MFG",
            CoinName: "SyncFab",
        },
        ETL: {
            ImageUrl: "/media/25792566/etl.png",
            Symbol: "ETL",
            CoinName: "EtherLite",
        },
        DRG: {
            ImageUrl: "/media/25792571/drg.png",
            Symbol: "DRG",
            CoinName: "Dragon Coin",
        },
        "BRC*": {
            ImageUrl: "/media/25792572/brc.png",
            Symbol: "BRC*",
            CoinName: "BinaryCoin",
        },
        "SPX*": {
            ImageUrl: "/media/25792570/transferir-copiar.png",
            Symbol: "SPX*",
            CoinName: "Sp8de",
        },
        ONL: {
            ImageUrl: "/media/25792573/onl.png",
            Symbol: "ONL",
            CoinName: "On.Live",
        },
        ZAP: {
            ImageUrl: "/media/25792575/zap.png",
            Symbol: "ZAP",
            CoinName: "Zap",
        },
        AIDOC: {
            ImageUrl: "/media/25792577/aidoc.png",
            Symbol: "AIDOC",
            CoinName: "AI Doctor",
        },
        ECC: {
            ImageUrl: "/media/25792579/ecc.png",
            Symbol: "ECC",
            CoinName: "ECC",
        },
        ET4: {
            ImageUrl: "/media/25792581/et4.png",
            Symbol: "ET4",
            CoinName: "Eticket4",
        },
        LCT: {
            ImageUrl: "/media/25792592/lct.png",
            Symbol: "LCT",
            CoinName: "LendConnect",
        },
        VST: {
            ImageUrl: "/media/25792598/vst.png",
            Symbol: "VST",
            CoinName: "Vestarin",
        },
        CPY: {
            ImageUrl: "/media/25792602/cpy.png",
            Symbol: "CPY",
            CoinName: "COPYTRACK",
        },
        STN: {
            ImageUrl: "/media/25792600/stn.png",
            Symbol: "STN",
            CoinName: "Steneum Coin",
        },
        SFU: {
            ImageUrl: "/media/25792604/sfu.png",
            Symbol: "SFU",
            CoinName: "Saifu",
        },
        PCOIN: {
            ImageUrl: "/media/25792606/pcoin.png",
            Symbol: "PCOIN",
            CoinName: "Pioneer Coin",
        },
        "BLN*": {
            ImageUrl: "/media/25792607/bln.png",
            Symbol: "BLN*",
            CoinName: "Bolenum",
        },
        LUC: {
            ImageUrl: "/media/25792608/luc.png",
            Symbol: "LUC",
            CoinName: "Play 2 Live",
        },
        EDT: {
            ImageUrl: "/media/25792615/edt.png",
            Symbol: "EDT",
            CoinName: "EtherDelta",
        },
        CYDER: {
            ImageUrl: "/media/25792617/cyder.png",
            Symbol: "CYDER",
            CoinName: "Cyder Coin",
        },
        SRNT: {
            ImageUrl: "/media/25792618/srnt.png",
            Symbol: "SRNT",
            CoinName: "Serenity",
        },
        MLT: {
            ImageUrl: "/media/25792620/mlt.png",
            Symbol: "MLT",
            CoinName: "MultiGames",
        },
        EKO: {
            ImageUrl: "/media/25792625/eko.png",
            Symbol: "EKO",
            CoinName: "EchoLink",
        },
        UBTC: {
            ImageUrl: "/media/33187879/rsz_rc6d5pq9_400x400.jpg",
            Symbol: "UBTC",
            CoinName: "UnitedBitcoin",
        },
        BTO: {
            ImageUrl: "/media/25792636/bot.png",
            Symbol: "BTO",
            CoinName: "Bottos",
        },
        DOC: {
            ImageUrl: "/media/25792637/doc.png",
            Symbol: "DOC",
            CoinName: "Doc Coin",
        },
        ARCT: {
            ImageUrl: "/media/25792638/arct.png",
            Symbol: "ARCT",
            CoinName: "ArbitrageCT",
        },
        IDH: {
            ImageUrl: "/media/25792644/idh.png",
            Symbol: "IDH",
            CoinName: "IndaHash",
        },
        CBT: {
            ImageUrl: "/media/25792645/cbt.png",
            Symbol: "CBT",
            CoinName: "CommerceBlock Token",
        },
        ITZ: {
            ImageUrl: "/media/25792662/itz.png",
            Symbol: "ITZ",
            CoinName: "Interzone",
        },
        XBP: {
            ImageUrl: "/media/25792650/xbp.png",
            Symbol: "XBP",
            CoinName: "Black Pearl Coin",
        },
        EXRN: {
            ImageUrl: "/media/20780642/exrn.png",
            Symbol: "EXRN",
            CoinName: "EXRNchain",
        },
        BFT: {
            ImageUrl: "/media/25792654/bft.png",
            Symbol: "BFT",
            CoinName: "BF Token",
        },
        SGL: {
            ImageUrl: "/media/25792671/sgl.png",
            Symbol: "SGL",
            CoinName: "Sigil",
        },
        TNC: {
            ImageUrl: "/media/25792672/tnc.png",
            Symbol: "TNC",
            CoinName: "Trinity Network Credit",
        },
        CFTY: {
            ImageUrl: "/media/25792674/cfty.png",
            Symbol: "CFTY",
            CoinName: "Crafty",
        },
        DTA: {
            ImageUrl: "/media/25792680/dta.png",
            Symbol: "DTA",
            CoinName: "Data",
        },
        CV: {
            ImageUrl: "/media/27010446/cv.png",
            Symbol: "CV",
            CoinName: "CarVertical",
        },
        DTX: {
            ImageUrl: "/media/25792685/dtx.png",
            Symbol: "DTX",
            CoinName: "DataBroker DAO",
        },
        MCU: {
            ImageUrl: "/media/27010445/mcu.png",
            Symbol: "MCU",
            CoinName: "MediChain",
        },
        OCN: {
            ImageUrl: "/media/27010448/ocn.png",
            Symbol: "OCN",
            CoinName: "Odyssey",
        },
        "MDT*": {
            ImageUrl: "/media/27010451/mdt.png",
            Symbol: "MDT*",
            CoinName: "Measurable Data Token ",
        },
        PRPS: {
            ImageUrl: "/media/27010452/prps.png",
            Symbol: "PRPS",
            CoinName: "Purpose",
        },
        DUBI: {
            ImageUrl: "/media/27010453/dubi.png",
            Symbol: "DUBI",
            CoinName: "Decentralized Universal Basic Income",
        },
        BPT: {
            ImageUrl: "/media/27010454/bpt.png",
            Symbol: "BPT",
            CoinName: "Blockport",
        },
        SGN: {
            ImageUrl: "/media/27010455/sgn.png",
            Symbol: "SGN",
            CoinName: "Signals Network",
        },
        TCT: {
            ImageUrl: "/media/27010460/tct.png",
            Symbol: "TCT",
            CoinName: "TokenClub ",
        },
        ZIL: {
            ImageUrl: "/media/27010464/zil.png",
            Symbol: "ZIL",
            CoinName: "Zilliqa",
        },
        HORSE: {
            ImageUrl: "/media/27010465/horse.png",
            Symbol: "HORSE",
            CoinName: "Ethorse ",
        },
        "QBT*": {
            ImageUrl: "/media/27010469/oloethnw_400x400.jpg",
            Symbol: "QBT*",
            CoinName: "Qbao",
        },
        "ACC*": {
            ImageUrl: "/media/27010467/acc.png",
            Symbol: "ACC*",
            CoinName: "Accelerator Network",
        },
        SWFTC: {
            ImageUrl: "/media/27010472/swftc.png",
            Symbol: "SWFTC",
            CoinName: "SwftCoin",
        },
        IPL: {
            ImageUrl: "/media/27010470/ipl.png",
            Symbol: "IPL",
            CoinName: "InsurePal",
        },
        OPC: {
            ImageUrl: "/media/27010474/opc.png",
            Symbol: "OPC",
            CoinName: "OP Coin",
        },
        SAF: {
            ImageUrl: "/media/27010473/sfu.png",
            Symbol: "SAF",
            CoinName: "Safinus",
        },
        SHA: {
            ImageUrl: "/media/27010477/sha.png",
            Symbol: "SHA",
            CoinName: "Shacoin",
        },
        PYLNT: {
            ImageUrl: "/media/27010479/pylnt.png",
            Symbol: "PYLNT",
            CoinName: "Pylon Network",
        },
        GRLC: {
            ImageUrl: "/media/27010480/garlic.png",
            Symbol: "GRLC",
            CoinName: "Garlicoin",
        },
        EVE: {
            ImageUrl: "/media/27010481/eve.png",
            Symbol: "EVE",
            CoinName: "Devery",
        },
        REPUX: {
            ImageUrl: "/media/27010482/repux.png",
            Symbol: "REPUX",
            CoinName: "Repux",
        },
        JOY: {
            ImageUrl: "/media/27010484/joy.png",
            Symbol: "JOY",
            CoinName: "JoyToken",
        },
        "GTC*": {
            ImageUrl: "/media/27010485/gtcoin.png",
            Symbol: "GTC*",
            CoinName: "Global Tour Coin",
        },
        "XCD*": {
            ImageUrl: "/media/27010488/xcd.png",
            Symbol: "XCD*",
            CoinName: "Capdax",
        },
        BTW: {
            ImageUrl: "/media/27010490/btw.png",
            Symbol: "BTW",
            CoinName: "BitcoinWhite",
        },
        AXP: {
            ImageUrl: "/media/27010491/axp.png",
            Symbol: "AXP",
            CoinName: "aXpire",
        },
        FOTA: {
            ImageUrl: "/media/27010497/fota.png",
            Symbol: "FOTA",
            CoinName: "Fortuna",
        },
        DDD: {
            ImageUrl: "/media/27010499/ddd.png",
            Symbol: "DDD",
            CoinName: "Scry.info",
        },
        SPEND: {
            ImageUrl: "/media/27010502/spend.png",
            Symbol: "SPEND",
            CoinName: "Spend",
        },
        ZPT: {
            ImageUrl: "/media/27010506/zpt.png",
            Symbol: "ZPT",
            CoinName: "Zeepin",
        },
        CROAT: {
            ImageUrl: "/media/27010508/croat.png",
            Symbol: "CROAT",
            CoinName: "Croat",
        },
        REF: {
            ImageUrl: "/media/27010509/ref.png",
            Symbol: "REF",
            CoinName: "RefToken",
        },
        SXUT: {
            ImageUrl: "/media/27010511/sxdt.png",
            Symbol: "SXUT",
            CoinName: "SPECTRE Utility Token",
        },
        LDC: {
            ImageUrl: "/media/27010513/ldc.png",
            Symbol: "LDC",
            CoinName: "LeadCoin",
        },
        "FAIR*": {
            ImageUrl: "/media/27010514/fair.png",
            Symbol: "FAIR*",
            CoinName: "FairGame",
        },
        VAL: {
            ImageUrl: "/media/27010515/val.png",
            Symbol: "VAL",
            CoinName: "Valorbit",
        },
        BCDN: {
            ImageUrl: "/media/27010517/1.png",
            Symbol: "BCDN",
            CoinName: "BlockCDN ",
        },
        STK: {
            ImageUrl: "/media/27010524/stk.png",
            Symbol: "STK",
            CoinName: "STK Token",
        },
        MZX: {
            ImageUrl: "/media/27010540/mzx.png",
            Symbol: "MZX",
            CoinName: "Mosaic Network",
        },
        "CRC**": {
            ImageUrl: "/media/27010559/crc.png",
            Symbol: "CRC**",
            CoinName: "CryCash",
        },
        SPICE: {
            ImageUrl: "/media/27010560/vc.png",
            Symbol: "SPICE",
            CoinName: "SPiCE Venture Capital ",
        },
        Q1S: {
            ImageUrl: "/media/27010561/q1s.png",
            Symbol: "Q1S",
            CoinName: "Quantum1Net",
        },
        XTO: {
            ImageUrl: "/media/27010572/xto.png",
            Symbol: "XTO",
            CoinName: "Tao",
        },
        ELA: {
            ImageUrl: "/media/27010574/ela.png",
            Symbol: "ELA",
            CoinName: "Elastos",
        },
        "TPAY*": {
            ImageUrl: "/media/27010575/tpay.png",
            Symbol: "TPAY*",
            CoinName: "TokenPay",
        },
        CXO: {
            ImageUrl: "/media/27010577/cxo.png",
            Symbol: "CXO",
            CoinName: "CargoX",
        },
        WT: {
            ImageUrl: "/media/27010582/wt.png",
            Symbol: "WT",
            CoinName: "WeToken",
        },
        HGS: {
            ImageUrl: "/media/27010583/hgs.png",
            Symbol: "HGS",
            CoinName: "HashGains",
        },
        SISA: {
            ImageUrl: "/media/27010587/sisa.png",
            Symbol: "SISA",
            CoinName: "Strategic Investments in Significant Areas",
        },
        EBIT: {
            ImageUrl: "/media/27010588/ebit-logo.png",
            Symbol: "EBIT",
            CoinName: "eBit",
        },
        RCT: {
            ImageUrl: "/media/27010590/rct.png",
            Symbol: "RCT",
            CoinName: "RealChain",
        },
        CUZ: {
            ImageUrl: "/media/27010593/cuz.png",
            Symbol: "CUZ",
            CoinName: "Cool Cousin",
        },
        HLC: {
            ImageUrl: "/media/27010592/hlc_logo.png",
            Symbol: "HLC",
            CoinName: "Halal-Chain",
        },
        BETR: {
            ImageUrl: "/media/33842944/betr_new.png",
            Symbol: "BETR",
            CoinName: "BetterBetting",
        },
        "300": {
            ImageUrl: "/media/27010595/300.png",
            Symbol: "300",
            CoinName: "300 token",
        },
        GMR: {
            ImageUrl: "/media/27010596/gmr.png",
            Symbol: "GMR",
            CoinName: "Gimmer",
        },
        ING: {
            ImageUrl: "/media/27010597/ing_logo.png",
            Symbol: "ING",
            CoinName: "Iungo",
        },
        LHC: {
            ImageUrl: "/media/27010598/lhc.png",
            Symbol: "LHC",
            CoinName: "LHCoin",
        },
        BLZ: {
            ImageUrl: "/media/27010607/1.png",
            Symbol: "BLZ",
            CoinName: "Bluzelle",
        },
        HALAL: {
            ImageUrl: "/media/27010609/halal_logo.png",
            Symbol: "HALAL",
            CoinName: "Halal",
        },
        "CRAVE*": {
            ImageUrl: "/media/33434207/crave.png",
            Symbol: "CRAVE*",
            CoinName: "Crave-NG",
        },
        CHSB: {
            ImageUrl: "/media/27010612/chsb_logo.png",
            Symbol: "CHSB",
            CoinName: "SwissBorg",
        },
        EQUI: {
            ImageUrl: "/media/27010614/equi.png",
            Symbol: "EQUI",
            CoinName: "EQUI Token",
        },
        MCT: {
            ImageUrl: "/media/27010616/1717mct_logo.png",
            Symbol: "MCT",
            CoinName: "1717 Masonic Commemorative Token",
        },
        HHEM: {
            ImageUrl: "/media/27010617/hhem.png",
            Symbol: "HHEM",
            CoinName: "Healthureum",
        },
        CWIS: {
            ImageUrl: "/media/27010619/cwis_logo.png",
            Symbol: "CWIS",
            CoinName: "Crypto Wisdom Coin",
        },
        MBC: {
            ImageUrl: "/media/27010620/mbc_logo.png",
            Symbol: "MBC",
            CoinName: "My Big Coin",
        },
        GRO: {
            ImageUrl: "/media/27010628/gro.png",
            Symbol: "GRO",
            CoinName: "Gron Digital",
        },
        "MTN*": {
            ImageUrl: "/media/27010631/mtn_logo.png",
            Symbol: "MTN*",
            CoinName: "Medicalchain",
        },
        WBTC: {
            ImageUrl: "/media/27010633/wbtc.png",
            Symbol: "WBTC",
            CoinName: "WorldBTC",
        },
        DNO: {
            ImageUrl: "/media/27010637/dno.png",
            Symbol: "DNO",
            CoinName: "Denaro",
        },
        eFIC: {
            ImageUrl: "/media/30001623/efic.png",
            Symbol: "eFIC",
            CoinName: "FIC Network",
        },
        TKY: {
            ImageUrl: "/media/27010658/tky.png",
            Symbol: "TKY",
            CoinName: "THEKEY Token",
        },
        TRTL: {
            ImageUrl: "/media/27010966/untitled-1.png",
            Symbol: "TRTL",
            CoinName: "TurtleCoin",
        },
        HBZ: {
            ImageUrl: "/media/27010671/hbz.png",
            Symbol: "HBZ",
            CoinName: "Helbiz",
        },
        "CARE*": {
            ImageUrl: "/media/27010669/care.png",
            Symbol: "CARE*",
            CoinName: "Care Token",
        },
        DRPU: {
            ImageUrl: "/media/34077417/drup.png",
            Symbol: "DRPU",
            CoinName: "DRP Utility",
        },
        DOR: {
            ImageUrl: "/media/27010676/dor.png",
            Symbol: "DOR",
            CoinName: "Dorado",
        },
        PRFT: {
            ImageUrl: "/media/27010677/prft.png",
            Symbol: "PRFT",
            CoinName: "Proof Suite Token",
        },
        PARETO: {
            ImageUrl: "/media/27010679/pareto.png",
            Symbol: "PARETO",
            CoinName: "Pareto Network Token",
        },
        DTRC: {
            ImageUrl: "/media/27010691/dtr.png",
            Symbol: "DTRC",
            CoinName: "Datarius",
        },
        IQB: {
            ImageUrl: "/media/27010692/iqb.png",
            Symbol: "IQB",
            CoinName: "Intelligence Quotient Benefit",
        },
        "NDC*": {
            ImageUrl: "/media/27010697/ndc.png",
            Symbol: "NDC*",
            CoinName: "NeedleCoin",
        },
        BEE: {
            ImageUrl: "/media/27010700/bee.png",
            Symbol: "BEE",
            CoinName: "Bee Token",
        },
        MUN: {
            ImageUrl: "/media/27010701/mun.png",
            Symbol: "MUN",
            CoinName: "MUNcoin",
        },
        TIG: {
            ImageUrl: "/media/27010719/tig.png",
            Symbol: "TIG",
            CoinName: "Tigereum",
        },
        NYX: {
            ImageUrl: "/media/27010735/nyx.png",
            Symbol: "NYX",
            CoinName: "NYXCOIN",
        },
        SAT: {
            ImageUrl: "/media/27010744/sat.png",
            Symbol: "SAT",
            CoinName: "Satisfaction Token",
        },
        CRL: {
            ImageUrl: "/media/27010747/crl.png",
            Symbol: "CRL",
            CoinName: "Cryptelo Coin",
        },
        ORI: {
            ImageUrl: "/media/27010748/ori.png",
            Symbol: "ORI",
            CoinName: "Origami",
        },
        LYM: {
            ImageUrl: "/media/27010755/lym.png",
            Symbol: "LYM",
            CoinName: "Lympo",
        },
        USX: {
            ImageUrl: "/media/27010758/usx.png",
            Symbol: "USX",
            CoinName: "Unified Society USDEX",
        },
        LGR: {
            ImageUrl: "/media/27010759/lgr.jpg",
            Symbol: "LGR",
            CoinName: "Logarithm",
        },
        BCA: {
            ImageUrl: "/media/27010760/bca-2.jpg",
            Symbol: "BCA",
            CoinName: "Bitcoin Atom",
        },
        B2X: {
            ImageUrl: "/media/27010761/b2x.jpg",
            Symbol: "B2X",
            CoinName: "SegWit2x",
        },
        EXMR: {
            ImageUrl: "/media/27010763/exmr.jpg",
            Symbol: "EXMR",
            CoinName: "eXMR Monero",
        },
        UETL: {
            ImageUrl: "/media/27010771/uetl.jpg",
            Symbol: "UETL",
            CoinName: "Useless Eth Token Lite",
        },
        NBR: {
            ImageUrl: "/media/27010775/nbr.jpg",
            Symbol: "NBR",
            CoinName: "Niobio Cash",
        },
        ARY: {
            ImageUrl: "/media/27010774/ary.png",
            Symbol: "ARY",
            CoinName: "Block Array",
        },
        RAVE: {
            ImageUrl: "/media/27010776/rave.jpg",
            Symbol: "RAVE",
            CoinName: "Ravelous",
        },
        ILT: {
            ImageUrl: "/media/27010772/iqt.png",
            Symbol: "ILT",
            CoinName: "iOlite",
        },
        SCOOBY: {
            ImageUrl: "/media/27010777/scooby.jpg",
            Symbol: "SCOOBY",
            CoinName: "Scooby coin",
        },
        CEFS: {
            ImageUrl: "/media/27010779/cefs.png",
            Symbol: "CEFS",
            CoinName: "CryptopiaFeeShares",
        },
        "MINT*": {
            ImageUrl: "/media/27010780/mint2.png",
            Symbol: "MINT*",
            CoinName: "Mineable Token",
        },
        BUN: {
            ImageUrl: "/media/27010781/bun.png",
            Symbol: "BUN",
            CoinName: "BunnyCoin",
        },
        BSR: {
            ImageUrl: "/media/27010783/bsr.png",
            Symbol: "BSR",
            CoinName: "BitSoar Coin",
        },
        SKULL: {
            ImageUrl: "/media/27010785/skull.jpg",
            Symbol: "SKULL",
            CoinName: "Pirate Blocks",
        },
        TRDT: {
            ImageUrl: "/media/27010784/trdt.png",
            Symbol: "TRDT",
            CoinName: "Trident",
        },
        XBTY: {
            ImageUrl: "/media/27010787/xbty.jpg",
            Symbol: "XBTY",
            CoinName: "Bounty",
        },
        JC: {
            ImageUrl: "/media/27010790/jc.png",
            Symbol: "JC",
            CoinName: "JesusCoin",
        },
        BTCP: {
            ImageUrl: "/media/27010791/btcpjpg.png",
            Symbol: "BTCP",
            CoinName: "Bitcoin Private",
        },
        SKC: {
            ImageUrl: "/media/27010793/skc.jpg",
            Symbol: "SKC",
            CoinName: "Skeincoin",
        },
        MWAT: {
            ImageUrl: "/media/27010794/mwat.jpg",
            Symbol: "MWAT",
            CoinName: "RED MegaWatt",
        },
        JEW: {
            ImageUrl: "/media/27010795/untitled-1.png",
            Symbol: "JEW",
            CoinName: "Shekel",
        },
        "ERT*": {
            ImageUrl: "/media/20190/abc2.png",
            Symbol: "ERT*",
            CoinName: "Eristica",
        },
        KRM: {
            ImageUrl: "/media/27010812/krm.png",
            Symbol: "KRM",
            CoinName: "Karma",
        },
        CDY: {
            ImageUrl: "/media/27010814/bcy.jpg",
            Symbol: "CDY",
            CoinName: "Bitcoin Candy",
        },
        SSS: {
            ImageUrl: "/media/27010815/sss.png",
            Symbol: "SSS",
            CoinName: "ShareChain",
        },
        CRDNC: {
            ImageUrl: "/media/27010816/crdnc.png",
            Symbol: "CRDNC",
            CoinName: "Credence Coin",
        },
        BIFI: {
            ImageUrl: "/media/27010817/bifi.png",
            Symbol: "BIFI",
            CoinName: "BitcoinFile",
        },
        BTF: {
            ImageUrl: "/media/27010823/btf.jpg",
            Symbol: "BTF",
            CoinName: "Blockchain Traded Fund",
        },
        "IPC*": {
            ImageUrl: "/media/27010824/ipc.jpg",
            Symbol: "IPC*",
            CoinName: "IPChain",
        },
        SHOW: {
            ImageUrl: "/media/27010825/show.jpg",
            Symbol: "SHOW",
            CoinName: "ShowCoin",
        },
        STC: {
            ImageUrl: "/media/27010826/stc.jpg",
            Symbol: "STC",
            CoinName: "StarChain",
        },
        AIT: {
            ImageUrl: "/media/27010829/ait.jpg",
            Symbol: "AIT",
            CoinName: "AIChain Token",
        },
        ALT: {
            ImageUrl: "/media/27010831/alt.jpg",
            Symbol: "ALT",
            CoinName: "ALTcoin",
        },
        TRF: {
            ImageUrl: "/media/27010832/trf.png",
            Symbol: "TRF",
            CoinName: "Travelflex",
        },
        KB3: {
            ImageUrl: "/media/27010833/kb3.jpg",
            Symbol: "KB3",
            CoinName: "B3Coin",
        },
        FDX: {
            ImageUrl: "/media/27010834/fdx.jpg",
            Symbol: "FDX",
            CoinName: "fidentiaX",
        },
        KREDS: {
            ImageUrl: "/media/27010837/kreds.png",
            Symbol: "KREDS",
            CoinName: "KREDS",
        },
        EQL: {
            ImageUrl: "/media/27010838/eql.jpg",
            Symbol: "EQL",
            CoinName: "EQUAL",
        },
        GAI: {
            ImageUrl: "/media/27010839/gai.png",
            Symbol: "GAI",
            CoinName: "GraphGrail AI",
        },
        VULC: {
            ImageUrl: "/media/27010840/vulc.jpg",
            Symbol: "VULC",
            CoinName: "Vulcano",
        },
        "CRE*": {
            ImageUrl: "/media/27010928/creditcoin_logo.png",
            Symbol: "CRE*",
            CoinName: "Creditcoin",
        },
        "DTC**": {
            ImageUrl: "/media/27010849/dtc-1.png",
            Symbol: "DTC**",
            CoinName: "DivotyCoin",
        },
        DADI: {
            ImageUrl: "/media/27010852/dadi.png",
            Symbol: "DADI",
            CoinName: "DADI",
        },
        "MAG**": {
            ImageUrl: "/media/27010851/mag.png",
            Symbol: "MAG**",
            CoinName: "Maggie Token",
        },
        TOKC: {
            ImageUrl: "/media/27010853/tokc.jpg",
            Symbol: "TOKC",
            CoinName: "Tokyo Coin",
        },
        UNRC: {
            ImageUrl: "/media/27010854/unrc.png",
            Symbol: "UNRC",
            CoinName: "UniversalRoyalCoin",
        },
        BBP: {
            ImageUrl: "/media/27010855/bbp.png",
            Symbol: "BBP",
            CoinName: "BiblePay",
        },
        NOX: {
            ImageUrl: "/media/27010857/nox.jpg",
            Symbol: "NOX",
            CoinName: "NITRO",
        },
        HYS: {
            ImageUrl: "/media/27010859/hys.jpg",
            Symbol: "HYS",
            CoinName: "Heiss Shares",
        },
        LCWP: {
            ImageUrl: "/media/27010860/lcwp.jpg",
            Symbol: "LCWP",
            CoinName: "LiteCoinW Plus",
        },
        NAVI: {
            ImageUrl: "/media/27010863/navi.png",
            Symbol: "NAVI",
            CoinName: "NaviAddress",
        },
        "FLX*": {
            ImageUrl: "/media/27010870/flx.jpg",
            Symbol: "FLX*",
            CoinName: "BitFlux",
        },
        VVI: {
            ImageUrl: "/media/27010869/vvi.png",
            Symbol: "VVI",
            CoinName: "VV Coin",
        },
        ANK: {
            ImageUrl: "/media/27010873/ank.jpg",
            Symbol: "ANK",
            CoinName: "Ankorus Token",
        },
        HLP: {
            ImageUrl: "/media/27010877/hlp.png",
            Symbol: "HLP",
            CoinName: "Purpose Coin",
        },
        VIN: {
            ImageUrl: "/media/27010882/vin.jpg",
            Symbol: "VIN",
            CoinName: "VinChain",
        },
        SHPING: {
            ImageUrl: "/media/27010884/shping.png",
            Symbol: "SHPING",
            CoinName: "Shping Coin",
        },
        PTR: {
            ImageUrl: "/media/27010888/ptr.png",
            Symbol: "PTR",
            CoinName: "Petro",
        },
        LCC: {
            ImageUrl: "/media/27010889/lcc.png",
            Symbol: "LCC",
            CoinName: "LitecoinCash",
        },
        "BCR*": {
            ImageUrl: "/media/27010899/bcr.jpg",
            Symbol: "BCR*",
            CoinName: "Bitcoin Royal",
        },
        VANY: {
            ImageUrl: "/media/27010900/vany.png",
            Symbol: "VANY",
            CoinName: "Vanywhere",
        },
        NBX: {
            ImageUrl: "/media/27010901/nbx.png",
            Symbol: "NBX",
            CoinName: "Noxbox",
        },
        BAX: {
            ImageUrl: "/media/27010904/bax.jpg",
            Symbol: "BAX",
            CoinName: "BABB",
        },
        BERRY: {
            ImageUrl: "/media/27010907/berry.jpg",
            Symbol: "BERRY",
            CoinName: "Rentberry",
        },
        "SPC*": {
            ImageUrl: "/media/27010909/spc2.jpg",
            Symbol: "SPC*",
            CoinName: "SpaceChain",
        },
        FLIP: {
            ImageUrl: "/media/27010910/flip.png",
            Symbol: "FLIP",
            CoinName: "BitFlip",
        },
        CLIN: {
            ImageUrl: "/media/27010911/clin.png",
            Symbol: "CLIN",
            CoinName: "Clinicoin",
        },
        DHT: {
            ImageUrl: "/media/27010912/dht.png",
            Symbol: "DHT",
            CoinName: "DeHedge Token",
        },
        "GOOD*": {
            ImageUrl: "/media/27010913/good.jpg",
            Symbol: "GOOD*",
            CoinName: "Goodomy",
        },
        ALX: {
            ImageUrl: "/media/27010915/alx.jpg",
            Symbol: "ALX",
            CoinName: "ALAX",
        },
        DTH: {
            ImageUrl: "/media/27010917/dth.jpg",
            Symbol: "DTH",
            CoinName: "Dether",
        },
        SOC: {
            ImageUrl: "/media/27010918/soc.png",
            Symbol: "SOC",
            CoinName: "All Sports Coin",
        },
        TDX: {
            ImageUrl: "/media/27010919/tdx.png",
            Symbol: "TDX",
            CoinName: "Tidex Token",
        },
        LOT: {
            ImageUrl: "/media/27010925/lot.png",
            Symbol: "LOT",
            CoinName: "LottoCoin",
        },
        FUNK: {
            ImageUrl: "/media/27010926/funk.png",
            Symbol: "FUNK",
            CoinName: "Cypherfunks Coin",
        },
        LEAF: {
            ImageUrl: "/media/27010927/leaf.jpg",
            Symbol: "LEAF",
            CoinName: "LeafCoin",
        },
        COMP: {
            ImageUrl: "/media/27010929/comp.jpg",
            Symbol: "COMP",
            CoinName: "Compound Coin",
        },
        BITCAR: {
            ImageUrl: "/media/27010931/bitcar.png",
            Symbol: "BITCAR",
            CoinName: "BitCar",
        },
        CLN: {
            ImageUrl: "/media/27010933/cln.png",
            Symbol: "CLN",
            CoinName: "Colu Local Network",
        },
        NIHL: {
            ImageUrl: "/media/27010943/nihl.png",
            Symbol: "NIHL",
            CoinName: "Nihilo Coin",
        },
        BASHC: {
            ImageUrl: "/media/27010941/bashc.png",
            Symbol: "BASHC",
            CoinName: "BashCoin",
        },
        DIGIF: {
            ImageUrl: "/media/27010942/digif.png",
            Symbol: "DIGIF",
            CoinName: "DigiFel",
        },
        DGM: {
            ImageUrl: "/media/27010944/dgm.png",
            Symbol: "DGM",
            CoinName: "DigiMoney",
        },
        CBS: {
            ImageUrl: "/media/27010945/cbs.png",
            Symbol: "CBS",
            CoinName: "Cerberus",
        },
        TERN: {
            ImageUrl: "/media/27010948/tern.jpg",
            Symbol: "TERN",
            CoinName: "Ternio",
        },
        SVD: {
            ImageUrl: "/media/27010949/svd.png",
            Symbol: "SVD",
            CoinName: "Savedroid",
        },
        PF: {
            ImageUrl: "/media/27010950/untitled-1.png",
            Symbol: "PF",
            CoinName: "PROVER",
        },
        BTCH: {
            ImageUrl: "/media/27010951/btch.jpg",
            Symbol: "BTCH",
            CoinName: "Bitcoin Hush",
        },
        redBUX: {
            ImageUrl: "/media/30001743/redbux.png",
            Symbol: "redBUX",
            CoinName: "redBUX",
        },
        AUC: {
            ImageUrl: "/media/27010954/untitled-1.png",
            Symbol: "AUC",
            CoinName: "Auctus",
        },
        LIZ: {
            ImageUrl: "/media/27010957/liz.png",
            Symbol: "LIZ",
            CoinName: "Lizus Payment",
        },
        CIF: {
            ImageUrl: "/media/27010958/cif.png",
            Symbol: "CIF",
            CoinName: "Crypto Improvement Fund",
        },
        SPD: {
            ImageUrl: "/media/27010967/spd.png",
            Symbol: "SPD",
            CoinName: "Stipend",
        },
        "PUT*": {
            ImageUrl: "/media/27010989/untitled-1.png",
            Symbol: "PUT*",
            CoinName: "Robin8 Profile Utility Token",
        },
        FILL: {
            ImageUrl: "/media/27011029/fil1.png",
            Symbol: "FILL",
            CoinName: "Fillit",
        },
        POA: {
            ImageUrl: "/media/27010991/poa.png",
            Symbol: "POA",
            CoinName: "Poa Network",
        },
        RVN: {
            ImageUrl: "/media/27011010/rvn.jpg",
            Symbol: "RVN",
            CoinName: "Ravencoin",
        },
        XNK: {
            ImageUrl: "/media/27011011/xnk.jpg",
            Symbol: "XNK",
            CoinName: "Ink Protocol",
        },
        RFR: {
            ImageUrl: "/media/27011023/rfr.png",
            Symbol: "RFR",
            CoinName: "Refereum",
        },
        PROPS: {
            ImageUrl: "/media/27011028/props.jpg",
            Symbol: "PROPS",
            CoinName: "Props",
        },
        CEDEX: {
            ImageUrl: "/media/27011030/cedex.png",
            Symbol: "CEDEX",
            CoinName: "CEDEX Coin",
        },
        FUND: {
            ImageUrl: "/media/27011031/fund.png",
            Symbol: "FUND",
            CoinName: "Fund Platform",
        },
        CEL: {
            ImageUrl: "/media/27011043/cel.png",
            Symbol: "CEL",
            CoinName: "Celsius Network",
        },
        PUSHI: {
            ImageUrl: "/media/27011046/pushi.png",
            Symbol: "PUSHI",
            CoinName: "Pushi",
        },
        BINS: {
            ImageUrl: "/media/27011047/bins.jpg",
            Symbol: "BINS",
            CoinName: "Bitsense",
        },
        POKER: {
            ImageUrl: "/media/27011048/poker.jpg",
            Symbol: "POKER",
            CoinName: "PokerCoin",
        },
        AXYS: {
            ImageUrl: "/media/27011049/axys.jpg",
            Symbol: "AXYS",
            CoinName: "Axys",
        },
        "EVN*": {
            ImageUrl: "/media/27011050/evn1.png",
            Symbol: "EVN*",
            CoinName: "EvenCoin",
        },
        BOLD: {
            ImageUrl: "/media/27011051/bold.jpg",
            Symbol: "BOLD",
            CoinName: "Bold",
        },
        EXTN: {
            ImageUrl: "/media/27011052/extn.jpg",
            Symbol: "EXTN",
            CoinName: "Extensive Coin",
        },
        DIG: {
            ImageUrl: "/media/30001609/dig.png",
            Symbol: "DIG",
            CoinName: "Dignity",
        },
        ETS: {
            ImageUrl: "/media/27011054/ets.jpg",
            Symbol: "ETS",
            CoinName: "ETH Share",
        },
        LIPC: {
            ImageUrl: "/media/27011055/lipc.jpg",
            Symbol: "LIPC",
            CoinName: "LIpcoin",
        },
        HELL: {
            ImageUrl: "/media/30001610/hell.jpg",
            Symbol: "HELL",
            CoinName: "HELL COIN",
        },
        ELP: {
            ImageUrl: "/media/30001611/elp.png",
            Symbol: "ELP",
            CoinName: "Ellerium",
        },
        ACAT: {
            ImageUrl: "/media/30001613/acat.jpg",
            Symbol: "ACAT",
            CoinName: "Alphacat",
        },
        CO2: {
            ImageUrl: "/media/30001624/co2.png",
            Symbol: "CO2",
            CoinName: "CO2 Token",
        },
        INVOX: {
            ImageUrl: "/media/30001639/untitled-1.png",
            Symbol: "INVOX",
            CoinName: "Invox Finance",
        },
        VLX: {
            ImageUrl: "/media/30001652/vlx.png",
            Symbol: "VLX",
            CoinName: "Velox",
        },
        ACTN: {
            ImageUrl: "/media/30001655/actn.jpg",
            Symbol: "ACTN",
            CoinName: "Action Coin",
        },
        LTCH: {
            ImageUrl: "/media/30001658/ltch.png",
            Symbol: "LTCH",
            CoinName: "Litecoin Cash",
        },
        ZUP: {
            ImageUrl: "/media/30001659/zup.png",
            Symbol: "ZUP",
            CoinName: "Zupply Token",
        },
        HMT: {
            ImageUrl: "/media/30001660/hmt.jpg",
            Symbol: "HMT",
            CoinName: "Hamster Marketplace Token",
        },
        MDX: {
            ImageUrl: "/media/30001662/mdx.jpg",
            Symbol: "MDX",
            CoinName: "Midex",
        },
        ONT: {
            ImageUrl: "/media/30001663/ont.jpg",
            Symbol: "ONT",
            CoinName: "Ontology",
        },
        USDC: {
            ImageUrl: "/media/30001666/untitled-1.png",
            Symbol: "USDC",
            CoinName: "USCoin",
        },
        CBC: {
            ImageUrl: "/media/30001676/cbc.png",
            Symbol: "CBC",
            CoinName: "CashBagCoin",
        },
        KIND: {
            ImageUrl: "/media/30001674/untitled-1.png",
            Symbol: "KIND",
            CoinName: "Kind Ads",
        },
        BCT: {
            ImageUrl: "/media/30001675/bct.png",
            Symbol: "BCT",
            CoinName: "Blockchain Terminal",
        },
        CLO: {
            ImageUrl: "/media/30001680/clo.png",
            Symbol: "CLO",
            CoinName: "Callisto Network",
        },
        CRU: {
            ImageUrl: "/media/30001681/cru.png",
            Symbol: "CRU",
            CoinName: "Curium",
        },
        "ELI*": {
            ImageUrl: "/media/30001683/eli.png",
            Symbol: "ELI*",
            CoinName: "Elicoin",
        },
        MOAT: {
            ImageUrl: "/media/30001685/moat.jpg",
            Symbol: "MOAT",
            CoinName: "Mother Of All Tokens",
        },
        BBI: {
            ImageUrl: "/media/30001684/bbi.png",
            Symbol: "BBI",
            CoinName: "BelugaPay",
        },
        BEZ: {
            ImageUrl: "/media/30001686/bez.jpg",
            Symbol: "BEZ",
            CoinName: "Bezop",
        },
        ENTRC: {
            ImageUrl: "/media/30001688/entrc.jpg",
            Symbol: "ENTRC",
            CoinName: "ENTER COIN",
        },
        BTCGO: {
            ImageUrl: "/media/30001689/btcgo.jpg",
            Symbol: "BTCGO",
            CoinName: "BitcoinGo",
        },
        XTROPTIONS: {
            ImageUrl: "/media/30001691/xtroptions.jpg",
            Symbol: "XTROPTIONS",
            CoinName: "TROPTIONS",
        },
        KNW: {
            ImageUrl: "/media/30001710/knowledge-icon-stroke-3.png",
            Symbol: "KNW",
            CoinName: "Knowledge ",
        },
        PGC: {
            ImageUrl: "/media/30001697/pgc.jpg",
            Symbol: "PGC",
            CoinName: "Pegascoin",
        },
        BIT: {
            ImageUrl: "/media/30001705/bit.png",
            Symbol: "BIT",
            CoinName: "BitRewards",
        },
        "PTC**": {
            ImageUrl: "/media/30001713/ptc.jpg",
            Symbol: "PTC**",
            CoinName: "Plutocoin",
        },
        PKC: {
            ImageUrl: "/media/30001714/untitled-1.png",
            Symbol: "PKC",
            CoinName: "Pikciochain",
        },
        SQOIN: {
            ImageUrl: "/media/30001720/sqoin.jpg",
            Symbol: "SQOIN",
            CoinName: "StasyQ",
        },
        TBAR: {
            ImageUrl: "/media/30001724/tbar.png",
            Symbol: "TBAR",
            CoinName: "Titanium BAR",
        },
        TKLN: {
            ImageUrl: "/media/30001725/tkln.jpg",
            Symbol: "TKLN",
            CoinName: "Taklimakan",
        },
        CPL: {
            ImageUrl: "/media/30001726/cpl.png",
            Symbol: "CPL",
            CoinName: "CoinPlace Token",
        },
        TUBE: {
            ImageUrl: "/media/33842919/tube.png",
            Symbol: "TUBE",
            CoinName: "BitTube",
        },
        OMX: {
            ImageUrl: "/media/30001732/omx.jpg",
            Symbol: "OMX",
            CoinName: "Project Shivom",
        },
        TRCK: {
            ImageUrl: "/media/30001735/trck.jpg",
            Symbol: "TRCK",
            CoinName: "Truckcoin",
        },
        CHI: {
            ImageUrl: "/media/30001747/chi.png",
            Symbol: "CHI",
            CoinName: "Chimaera",
        },
        "CHF*": {
            ImageUrl: "/media/30001749/chf.jpg",
            Symbol: "CHF*",
            CoinName: "MobileBridge Momentum",
        },
        "INV*": {
            ImageUrl: "/media/30001757/invacio.png",
            Symbol: "INV*",
            CoinName: "Invacio",
        },
        W3C: {
            ImageUrl: "/media/30001759/untitled-1.png",
            Symbol: "W3C",
            CoinName: "W3Coin",
        },
        DIN: {
            ImageUrl: "/media/30001760/din.jpg",
            Symbol: "DIN",
            CoinName: "Dinero",
        },
        INSTAR: {
            ImageUrl: "/media/30001761/instar.png",
            Symbol: "INSTAR",
            CoinName: "Insights Network",
        },
        CHP: {
            ImageUrl: "/media/30001762/chp.jpg",
            Symbol: "CHP",
            CoinName: "CoinPoker Token",
        },
        PSD: {
            ImageUrl: "/media/30001764/psd.png",
            Symbol: "PSD",
            CoinName: "Poseidon",
        },
        J8T: {
            ImageUrl: "/media/30001765/jt8.png",
            Symbol: "J8T",
            CoinName: "JET8",
        },
        LELE: {
            ImageUrl: "/media/30001766/lele.png",
            Symbol: "LELE",
            CoinName: "Lelecoin",
        },
        DROP: {
            ImageUrl: "/media/30001849/drop.jpg",
            Symbol: "DROP",
            CoinName: "Dropil",
        },
        "VEGA*": {
            ImageUrl: "/media/30001786/vega.jpg",
            Symbol: "VEGA*",
            CoinName: "VEGA",
        },
        AKA: {
            ImageUrl: "/media/30001848/aka.jpg",
            Symbol: "AKA",
            CoinName: "Akroma",
        },
        SHIP: {
            ImageUrl: "/media/30001824/untitled-1.png",
            Symbol: "SHIP",
            CoinName: "ShipChain",
        },
        IHT: {
            ImageUrl: "/media/30001831/iht.jpg",
            Symbol: "IHT",
            CoinName: "I-House Token",
        },
        LCS: {
            ImageUrl: "/media/30001832/lcs.png",
            Symbol: "LCS",
            CoinName: "LocalCoinSwap",
        },
        LALA: {
            ImageUrl: "/media/30001833/lala.jpg",
            Symbol: "LALA",
            CoinName: "LaLa World",
        },
        LEDU: {
            ImageUrl: "/media/30001836/ledu.png",
            Symbol: "LEDU",
            CoinName: "Education Ecosystem",
        },
        FOXT: {
            ImageUrl: "/media/30001842/foxt.jpg",
            Symbol: "FOXT",
            CoinName: "Fox Trading",
        },
        ETKN: {
            ImageUrl: "/media/30001841/etnk.png",
            Symbol: "ETKN",
            CoinName: "EasyToken",
        },
        ROX: {
            ImageUrl: "/media/30001850/untitled-1.png",
            Symbol: "ROX",
            CoinName: "Robotina",
        },
        ADM: {
            ImageUrl: "/media/30001860/adm.png",
            Symbol: "ADM",
            CoinName: "Adamant",
        },
        AMBT: {
            ImageUrl: "/media/30001865/ambt.png",
            Symbol: "AMBT",
            CoinName: "AMBT Token",
        },
        "LVL*": {
            ImageUrl: "/media/30001870/lvl.png",
            Symbol: "LVL*",
            CoinName: "LevelNet Token",
        },
        MEE: {
            ImageUrl: "/media/30001874/mee.jpg",
            Symbol: "MEE",
            CoinName: "CoinMeet",
        },
        BTRM: {
            ImageUrl: "/media/30001875/btrm.png",
            Symbol: "BTRM",
            CoinName: "Betrium Token",
        },
        MANNA: {
            ImageUrl: "/media/30001876/manna.jpg",
            Symbol: "MANNA",
            CoinName: "Manna",
        },
        PROD: {
            ImageUrl: "/media/30001878/prod.jpg",
            Symbol: "PROD",
            CoinName: "Darenta",
        },
        ePRX: {
            ImageUrl: "/media/30001877/eprx.jpg",
            Symbol: "ePRX",
            CoinName: "eProxy",
        },
        HMC: {
            ImageUrl: "/media/30001881/hmc.jpg",
            Symbol: "HMC",
            CoinName: "Hi Mutual Society",
        },
        ZIX: {
            ImageUrl: "/media/30001884/zix.png",
            Symbol: "ZIX",
            CoinName: "ZIX Token",
        },
        ELEC: {
            ImageUrl: "/media/30001886/elec.jpg",
            Symbol: "ELEC",
            CoinName: "Electrify.Asia",
        },
        ORGT: {
            ImageUrl: "/media/30001887/e.png",
            Symbol: "ORGT",
            CoinName: "Organic Token",
        },
        PAN: {
            ImageUrl: "/media/30001898/untitled-1.png",
            Symbol: "PAN",
            CoinName: "Pantos",
        },
        BOTC: {
            ImageUrl: "/media/30001908/untitled-1.png",
            Symbol: "BOTC",
            CoinName: "BotChain",
        },
        GO: {
            ImageUrl: "/media/30001914/goc.png",
            Symbol: "GO",
            CoinName: "GoChain",
        },
        VIEW: {
            ImageUrl: "/media/30001915/untitled-1.png",
            Symbol: "VIEW",
            CoinName: "Viewly",
        },
        OKOIN: {
            ImageUrl: "/media/30001925/okoin.png",
            Symbol: "OKOIN",
            CoinName: "OKOIN",
        },
        ADK: {
            ImageUrl: "/media/30001930/adk.jpg",
            Symbol: "ADK",
            CoinName: "Aidos Kuneen",
        },
        GRAM: {
            ImageUrl: "/media/30001931/gram.png",
            Symbol: "GRAM",
            CoinName: "Telegram Open Network",
        },
        ESS: {
            ImageUrl: "/media/30001932/ess.png",
            Symbol: "ESS",
            CoinName: "Essentia",
        },
        VIT: {
            ImageUrl: "/media/30001936/untitled-1.png",
            Symbol: "VIT",
            CoinName: "Vice Industry Token",
        },
        SERA: {
            ImageUrl: "/media/30001937/untitled-1.png",
            Symbol: "SERA",
            CoinName: "Seraph",
        },
        AET: {
            ImageUrl: "/media/30001955/aet.jpg",
            Symbol: "AET",
            CoinName: "AfterEther",
        },
        CMOS: {
            ImageUrl: "/media/30001956/cmos.jpg",
            Symbol: "CMOS",
            CoinName: "Cosmo",
        },
        PGN: {
            ImageUrl: "/media/30001959/pgn.png",
            Symbol: "PGN",
            CoinName: "Pigeoncoin",
        },
        BMH: {
            ImageUrl: "/media/30001961/mesh.jpg",
            Symbol: "BMH",
            CoinName: "BlockMesh",
        },
        TT: {
            ImageUrl: "/media/30001962/tt.jpg",
            Symbol: "TT",
            CoinName: "TravelChain",
        },
        REDN: {
            ImageUrl: "/media/33187870/redn.png",
            Symbol: "REDN",
            CoinName: "Reden",
        },
        TLP: {
            ImageUrl: "/media/30001964/tlp.jpg",
            Symbol: "TLP",
            CoinName: "TulipCoin",
        },
        "GEN*": {
            ImageUrl: "/media/30001963/gen.png",
            Symbol: "GEN*",
            CoinName: "DAOstack",
        },
        BSX: {
            ImageUrl: "/media/30001965/bsx.jpg",
            Symbol: "BSX",
            CoinName: "Bitspace",
        },
        "BTCE*": {
            ImageUrl: "/media/30001967/btce.jpg",
            Symbol: "BTCE*",
            CoinName: "BitcoinEX",
        },
        BBN: {
            ImageUrl: "/media/30001968/bbn.jpg",
            Symbol: "BBN",
            CoinName: "BBNCOIN",
        },
        TDZ: {
            ImageUrl: "/media/30001966/tdz.png",
            Symbol: "TDZ",
            CoinName: "Tradelize",
        },
        LDN: {
            ImageUrl: "/media/30001976/ldn.jpg",
            Symbol: "LDN",
            CoinName: "Lydiancoin",
        },
        BUBO: {
            ImageUrl: "/media/30001978/bubo.jpg",
            Symbol: "BUBO",
            CoinName: "Budbo",
        },
        USOAMIC: {
            ImageUrl: "/media/30001984/usoamic.jpg",
            Symbol: "USOAMIC",
            CoinName: "USOAMIC",
        },
        FLUZ: {
            ImageUrl: "/media/30001985/fluz.jpg",
            Symbol: "FLUZ",
            CoinName: "FluzFluz",
        },
        IPSX: {
            ImageUrl: "/media/30001989/ipsx.png",
            Symbol: "IPSX",
            CoinName: "IP Exchange",
        },
        MIO: {
            ImageUrl: "/media/30001998/mio.png",
            Symbol: "MIO",
            CoinName: "Miner One token",
        },
        MITH: {
            ImageUrl: "/media/30002012/mith.jpg",
            Symbol: "MITH",
            CoinName: "Mithril",
        },
        "SPD*": {
            ImageUrl: "/media/30002014/spd.jpg",
            Symbol: "SPD*",
            CoinName: "Spindle",
        },
        FNO: {
            ImageUrl: "/media/30002174/fno.png",
            Symbol: "FNO",
            CoinName: "Fonero",
        },
        GST: {
            ImageUrl: "/media/30002016/gst.png",
            Symbol: "GST",
            CoinName: "Gostcoin",
        },
        PAS: {
            ImageUrl: "/media/30002018/pas.jpg",
            Symbol: "PAS",
            CoinName: "Passive Coin",
        },
        XSG: {
            ImageUrl: "/media/30002019/sng.jpg",
            Symbol: "XSG",
            CoinName: "Snowgem",
        },
        CVTC: {
            ImageUrl: "/media/30002020/cvtc.jpg",
            Symbol: "CVTC",
            CoinName: "CavatCoin",
        },
        FARM: {
            ImageUrl: "/media/30002023/faarm.jpg",
            Symbol: "FARM",
            CoinName: "FARM Coin",
        },
        NEXT: {
            ImageUrl: "/media/30002030/next.jpg",
            Symbol: "NEXT",
            CoinName: "Next.exchange Token",
        },
        XCLR: {
            ImageUrl: "/media/30002047/clr.png",
            Symbol: "XCLR",
            CoinName: "ClearCoin",
        },
        "XBP*": {
            ImageUrl: "/media/30002048/xbp.png",
            Symbol: "XBP*",
            CoinName: "BlitzPredict",
        },
        "DBC*": {
            ImageUrl: "/media/30002050/dbc.jpg",
            Symbol: "DBC*",
            CoinName: "Debit Coin",
        },
        SWTH: {
            ImageUrl: "/media/30002053/swh.jpg",
            Symbol: "SWTH",
            CoinName: "Switcheo",
        },
        FDZ: {
            ImageUrl: "/media/30002054/fdz.jpg",
            Symbol: "FDZ",
            CoinName: "Friendz",
        },
        VTN: {
            ImageUrl: "/media/30002055/vtn.jpg",
            Symbol: "VTN",
            CoinName: "Voltroon",
        },
        LION: {
            ImageUrl: "/media/30002060/lion.png",
            Symbol: "LION",
            CoinName: "CoinLion",
        },
        MASP: {
            ImageUrl: "/media/30002079/masp.png",
            Symbol: "MASP",
            CoinName: "Market.space",
        },
        XTL: {
            ImageUrl: "/media/30002080/xtl.jpg",
            Symbol: "XTL",
            CoinName: "Stellite",
        },
        UCN: {
            ImageUrl: "/media/30002081/ucn.jpg",
            Symbol: "UCN",
            CoinName: "UC Coin",
        },
        HUR: {
            ImageUrl: "/media/30002083/hur.png",
            Symbol: "HUR",
            CoinName: "Hurify",
        },
        BRIA: {
            ImageUrl: "/media/30002084/bria.jpg",
            Symbol: "BRIA",
            CoinName: "Briacoin",
        },
        IC: {
            ImageUrl: "/media/30002085/ic.png",
            Symbol: "IC",
            CoinName: "Ignition",
        },
        LATX: {
            ImageUrl: "/media/30002086/latx.jpg",
            Symbol: "LATX",
            CoinName: "LatiumX",
        },
        ROI: {
            ImageUrl: "/media/30002087/roi.jpg",
            Symbol: "ROI",
            CoinName: "ROIcoin",
        },
        ETHPR: {
            ImageUrl: "/media/30002088/ethpr.jpg",
            Symbol: "ETHPR",
            CoinName: "Ethereum Premium",
        },
        MNB: {
            ImageUrl: "/media/30002097/mnb.jpg",
            Symbol: "MNB",
            CoinName: "MoneyBag",
        },
        ACH: {
            ImageUrl: "/media/30002098/ach.jpg",
            Symbol: "ACH",
            CoinName: "AchieveCoin",
        },
        BTL: {
            ImageUrl: "/media/30002099/btl.jpg",
            Symbol: "BTL",
            CoinName: "Bitrolium",
        },
        GOAL: {
            ImageUrl: "/media/30002100/goal.jpg",
            Symbol: "GOAL",
            CoinName: "Goal Bonanza",
        },
        HOLD: {
            ImageUrl: "/media/30002104/hold.png",
            Symbol: "HOLD",
            CoinName: "HOLD",
        },
        EZT: {
            ImageUrl: "/media/30002107/ezt.jpg",
            Symbol: "EZT",
            CoinName: "EZToken",
        },
        SOL: {
            ImageUrl: "/media/30002110/sol.jpg",
            Symbol: "SOL",
            CoinName: "Sola",
        },
        VIC: {
            ImageUrl: "/media/30002111/vic.jpg",
            Symbol: "VIC",
            CoinName: "Victorium",
        },
        XCM: {
            ImageUrl: "/media/30002112/xcm1.jpg",
            Symbol: "XCM",
            CoinName: "CoinMetro",
        },
        NFN: {
            ImageUrl: "/media/30002113/nfn.jpg",
            Symbol: "NFN",
            CoinName: "Nafen",
        },
        WIIX: {
            ImageUrl: "/media/30002135/wiix.jpg",
            Symbol: "WIIX",
            CoinName: "Wiix",
        },
        EOSDAC: {
            ImageUrl: "/media/30002139/eosdac.png",
            Symbol: "EOSDAC",
            CoinName: "eosDAC",
        },
        BCI: {
            ImageUrl: "/media/30002141/bci.jpg",
            Symbol: "BCI",
            CoinName: "Bitcoin Interest",
        },
        MEDIC: {
            ImageUrl: "/media/30002146/medic.jpg",
            Symbol: "MEDIC",
            CoinName: "MedicCoin",
        },
        KWH: {
            ImageUrl: "/media/30002147/kwh.jpg",
            Symbol: "KWH",
            CoinName: "KWHCoin",
        },
        VLD: {
            ImageUrl: "/media/30002154/vld.jpg",
            Symbol: "VLD",
            CoinName: "Valid",
        },
        GSI: {
            ImageUrl: "/media/30002166/gsi.png",
            Symbol: "GSI",
            CoinName: "Globex SCI",
        },
        BDP: {
            ImageUrl: "/media/30002167/bdp.png",
            Symbol: "BDP",
            CoinName: "Bidipass",
        },
        FLM: {
            ImageUrl: "/media/30002171/flm.jpg",
            Symbol: "FLM",
            CoinName: "FOLM coin",
        },
        ALPS: {
            ImageUrl: "/media/30002172/alps.jpg",
            Symbol: "ALPS",
            CoinName: "Alpenschillling",
        },
        ZEL: {
            ImageUrl: "/media/30002173/zel.jpg",
            Symbol: "ZEL",
            CoinName: "Zelcash",
        },
        BKC: {
            ImageUrl: "/media/30002175/bkc.jpg",
            Symbol: "BKC",
            CoinName: "Balkancoin",
        },
        BITG: {
            ImageUrl: "/media/30002176/bitg.jpg",
            Symbol: "BITG",
            CoinName: "Bitcoin Green",
        },
        DEV: {
            ImageUrl: "/media/30002202/dev.jpg",
            Symbol: "DEV",
            CoinName: "Deviant Coin",
        },
        CHT: {
            ImageUrl: "/media/30002203/cht.png",
            Symbol: "CHT",
            CoinName: "Countinghouse Fund",
        },
        ABJ: {
            ImageUrl: "/media/30002209/abj.png",
            Symbol: "ABJ",
            CoinName: "Abjcoin",
        },
        FTW: {
            ImageUrl: "/media/30002211/ftw.jpg",
            Symbol: "FTW",
            CoinName: "FutureWorks",
        },
        RAP: {
            ImageUrl: "/media/30002214/rap.jpg",
            Symbol: "RAP",
            CoinName: "Rapture",
        },
        ARTE: {
            ImageUrl: "/media/30002216/arte.jpg",
            Symbol: "ARTE",
            CoinName: "Artemine",
        },
        ANI: {
            ImageUrl: "/media/30002219/ani.png",
            Symbol: "ANI",
            CoinName: "Animecoin",
        },
        PHC: {
            ImageUrl: "/media/30002220/phc.jpg",
            Symbol: "PHC",
            CoinName: "Profit Hunters Coin",
        },
        ETHM: {
            ImageUrl: "/media/30002221/ethm.jpg",
            Symbol: "ETHM",
            CoinName: "Ethereum Meta",
        },
        NOKU: {
            ImageUrl: "/media/30002224/noku.jpg",
            Symbol: "NOKU",
            CoinName: "NOKU Master token",
        },
        "RAC*": {
            ImageUrl: "/media/30002225/rac.png",
            Symbol: "RAC*",
            CoinName: "RoboAdvisorCoin",
        },
        SENC: {
            ImageUrl: "/media/30002228/senc.jpg",
            Symbol: "SENC",
            CoinName: "Sentinel Chain",
        },
        PAT: {
            ImageUrl: "/media/30002231/pat.png",
            Symbol: "PAT",
            CoinName: "PATRON",
        },
        CHFN: {
            ImageUrl: "/media/30002224/noku.jpg",
            Symbol: "CHFN",
            CoinName: "NOKU CHF",
        },
        EURN: {
            ImageUrl: "/media/30002224/noku.jpg",
            Symbol: "EURN",
            CoinName: "NOKU EUR",
        },
        LEU: {
            ImageUrl: "/media/30002242/leu.jpg",
            Symbol: "LEU",
            CoinName: "CryptoLEU",
        },
        SWC: {
            ImageUrl: "/media/30002243/swc.png",
            Symbol: "SWC",
            CoinName: "Scanetchain Token",
        },
        AVL: {
            ImageUrl: "/media/30002245/avl.jpg",
            Symbol: "AVL",
            CoinName: "Avalanche",
        },
        SEM: {
            ImageUrl: "/media/30002248/sem.jpg",
            Symbol: "SEM",
            CoinName: "Semux",
        },
        DARX: {
            ImageUrl: "/media/30002249/darx.png",
            Symbol: "DARX",
            CoinName: "Bitdaric",
        },
        BBK: {
            ImageUrl: "/media/30002250/bbk.jpg",
            Symbol: "BBK",
            CoinName: "BitBlocks",
        },
        "RAC**": {
            ImageUrl: "/media/30002261/logo_robet_256.png",
            Symbol: "RAC**",
            CoinName: "RoBET",
        },
        NCT: {
            ImageUrl: "/media/30002262/nct.png",
            Symbol: "NCT",
            CoinName: "PolySwarm",
        },
        UWC: {
            ImageUrl: "/media/30002269/uwc.jpg",
            Symbol: "UWC",
            CoinName: "Uwezocoin",
        },
        XHV: {
            ImageUrl: "/media/30002273/xhv.png",
            Symbol: "XHV",
            CoinName: "Haven Protocol",
        },
        CPX: {
            ImageUrl: "/media/30002271/cpx.jpg",
            Symbol: "CPX",
            CoinName: "Apex Token",
        },
        ADH: {
            ImageUrl: "/media/30002279/adh.jpg",
            Symbol: "ADH",
            CoinName: "Adhive",
        },
        LIF: {
            ImageUrl: "/media/30002281/lif.jpg",
            Symbol: "LIF",
            CoinName: "Winding Tree",
        },
        EFX: {
            ImageUrl: "/media/30002284/efx.jpg",
            Symbol: "EFX",
            CoinName: "The EFFECT Network",
        },
        LND: {
            ImageUrl: "/media/30002285/lnd.jpg",
            Symbol: "LND",
            CoinName: "Lendingblock",
        },
        "MRP*": {
            ImageUrl: "/media/30002287/mrp.png",
            Symbol: "MRP*",
            CoinName: "MoneyRebel",
        },
        FTO: {
            ImageUrl: "/media/30002288/fto.png",
            Symbol: "FTO",
            CoinName: "FuturoCoin",
        },
        SIG: {
            ImageUrl: "/media/30002295/sig.jpg",
            Symbol: "SIG",
            CoinName: "Signal",
        },
        CARE: {
            ImageUrl: "/media/30002297/care.jpg",
            Symbol: "CARE",
            CoinName: "Carebit",
        },
        NZL: {
            ImageUrl: "/media/30002301/nzl.jpg",
            Symbol: "NZL",
            CoinName: "Zealium",
        },
        TBT: {
            ImageUrl: "/media/30002303/tbt.jpg",
            Symbol: "TBT",
            CoinName: "T-BOT",
        },
        XMC: {
            ImageUrl: "/media/30002308/xmc.jpg",
            Symbol: "XMC",
            CoinName: "Monero Classic",
        },
        OAK: {
            ImageUrl: "/media/30002320/oak.png",
            Symbol: "OAK",
            CoinName: "Acorn Collective",
        },
        DML: {
            ImageUrl: "/media/30002332/dml.jpg",
            Symbol: "DML",
            CoinName: "Decentralized Machine Learning",
        },
        GEM: {
            ImageUrl: "/media/30002334/gem.jpg",
            Symbol: "GEM",
            CoinName: "Gems",
        },
        TIPS: {
            ImageUrl: "/media/30002340/tips.jpg",
            Symbol: "TIPS",
            CoinName: "FedoraCoin",
        },
        MOS: {
            ImageUrl: "/media/32655838/mos.jpg",
            Symbol: "MOS",
            CoinName: "MOS Coin",
        },
        TBX: {
            ImageUrl: "/media/32655844/tbx.jpg",
            Symbol: "TBX",
            CoinName: "Tokenbox",
        },
        PNT: {
            ImageUrl: "/media/32655845/pnt.jpg",
            Symbol: "PNT",
            CoinName: "Penta",
        },
        WIN: {
            ImageUrl: "/media/32655846/win.jpg",
            Symbol: "WIN",
            CoinName: "WCoin",
        },
        CHARM: {
            ImageUrl: "/media/32655849/charm.jpg",
            Symbol: "CHARM",
            CoinName: "Charm Coin",
        },
        PROTON: {
            ImageUrl: "/media/32655850/proton.jpg",
            Symbol: "PROTON",
            CoinName: "Proton",
        },
        CRS: {
            ImageUrl: "/media/32655853/crs.jpg",
            Symbol: "CRS",
            CoinName: "Cryptoreal",
        },
        DERO: {
            ImageUrl: "/media/32655854/dero.jpg",
            Symbol: "DERO",
            CoinName: "Dero",
        },
        DEAL: {
            ImageUrl: "/media/32655855/deal.jpg",
            Symbol: "DEAL",
            CoinName: "iDealCash",
        },
        JUMP: {
            ImageUrl: "/media/32655856/jump.jpg",
            Symbol: "JUMP",
            CoinName: "Jumpcoin",
        },
        KRL: {
            ImageUrl: "/media/32655864/krl.jpg",
            Symbol: "KRL",
            CoinName: "Kryll",
        },
        "TMT**": {
            ImageUrl: "/media/32655863/tmt.png",
            Symbol: "TMT**",
            CoinName: "Traxia Membership Token",
        },
        "SRC**": {
            ImageUrl: "/media/32655868/srccc.jpg",
            Symbol: "SRC**",
            CoinName: "SwissRealCoin",
        },
        SS: {
            ImageUrl: "/media/32655869/ss.png",
            Symbol: "SS",
            CoinName: "Sharder",
        },
        IFX: {
            ImageUrl: "/media/32655870/ifx.png",
            Symbol: "IFX",
            CoinName: "Infinex",
        },
        XMO: {
            ImageUrl: "/media/32655873/xmo.jpg",
            Symbol: "XMO",
            CoinName: "Monero Original",
        },
        EDU: {
            ImageUrl: "/media/32655888/edu.jpg",
            Symbol: "EDU",
            CoinName: "Educoin",
        },
        PCL: {
            ImageUrl: "/media/32655889/pcl.jpg",
            Symbol: "PCL",
            CoinName: "Peculium",
        },
        APH: {
            ImageUrl: "/media/32655904/aph.jpg",
            Symbol: "APH",
            CoinName: "Aphelion",
        },
        NBAI: {
            ImageUrl: "/media/32655907/nbai.jpg",
            Symbol: "NBAI",
            CoinName: "Nebula AI",
        },
        TUT: {
            ImageUrl: "/media/32655909/tut.png",
            Symbol: "TUT",
            CoinName: "Tutellus",
        },
        PAL: {
            ImageUrl: "/media/32655935/pal.png",
            Symbol: "PAL",
            CoinName: "PolicyPal Network",
        },
        ENU: {
            ImageUrl: "/media/32655936/enu.jpg",
            Symbol: "ENU",
            CoinName: "Enumivo",
        },
        BFDT: {
            ImageUrl: "/media/32655938/bfdt.jpg",
            Symbol: "BFDT",
            CoinName: "Befund",
        },
        YUM: {
            ImageUrl: "/media/32655952/yum.png",
            Symbol: "YUM",
            CoinName: "Yumerium",
        },
        GSC: {
            ImageUrl: "/media/33187819/gsc.png",
            Symbol: "GSC",
            CoinName: "Global Social Chain",
        },
        DESI: {
            ImageUrl: "/media/33187822/desi.png",
            Symbol: "DESI",
            CoinName: "DESI",
        },
        VLUX: {
            ImageUrl: "/media/33187827/vlux.png",
            Symbol: "VLUX",
            CoinName: "VLUX",
        },
        "MT*": {
            ImageUrl: "/media/33187831/mt.png",
            Symbol: "MT*",
            CoinName: "MyToken",
        },
        SSH: {
            ImageUrl: "/media/33187832/ssh.png",
            Symbol: "SSH",
            CoinName: "StreamSpace",
        },
        XBI: {
            ImageUrl: "/media/33187835/btci.jpg",
            Symbol: "XBI",
            CoinName: "Bitcoin Incognito",
        },
        VRA: {
            ImageUrl: "/media/33187836/vra.jpg",
            Symbol: "VRA",
            CoinName: "Verasity",
        },
        FRV: {
            ImageUrl: "/media/33187842/bcdw9qea_400x400.jpg",
            Symbol: "FRV",
            CoinName: "Fitrova",
        },
        WINS: {
            ImageUrl: "/media/33187846/wins.png",
            Symbol: "WINS",
            CoinName: "WinStars",
        },
        RTB: {
            ImageUrl: "/media/33187848/rsz_j0jj1fgb_400x400.jpg",
            Symbol: "RTB",
            CoinName: "AB-CHAIN",
        },
        FXT: {
            ImageUrl: "/media/33187853/bhbg2_sa_400x400.jpg",
            Symbol: "FXT",
            CoinName: "FuzeX",
        },
        DXC: {
            ImageUrl: "/media/33187858/rsz_po0xsyaa_400x400.jpg",
            Symbol: "DXC",
            CoinName: "DixiCoin",
        },
        CHBR: {
            ImageUrl: "/media/33187861/chbr.png",
            Symbol: "CHBR",
            CoinName: "CryptoHub",
        },
        OWD: {
            ImageUrl: "/media/33187865/owd.png",
            Symbol: "OWD",
            CoinName: "Owlstand",
        },
        ELLI: {
            ImageUrl: "/media/33187868/elli.jpg",
            Symbol: "ELLI",
            CoinName: "ElliotCoin",
        },
        AXS: {
            ImageUrl: "/media/33187869/axs.jpg",
            Symbol: "AXS",
            CoinName: "AXS",
        },
        DAN: {
            ImageUrl: "/media/33187871/dan.jpg",
            Symbol: "DAN",
            CoinName: "Daneel",
        },
        UBT: {
            ImageUrl: "/media/33187875/rsz_6fqeq4zg_400x400-1.jpg",
            Symbol: "UBT",
            CoinName: "UniBright",
        },
        LBA: {
            ImageUrl: "/media/33187882/rsz_b5olgqs7_400x400.jpg",
            Symbol: "LBA",
            CoinName: "Libra Credit",
        },
        LIVE: {
            ImageUrl: "/media/33187883/live.png",
            Symbol: "LIVE",
            CoinName: "Live Stars",
        },
        GBG: {
            ImageUrl: "/media/1382246/golos.png",
            Symbol: "GBG",
            CoinName: "Golos Gold",
        },
        CNN: {
            ImageUrl: "/media/33187884/cnn.png",
            Symbol: "CNN",
            CoinName: "Content Neutrality Network",
        },
        SHL: {
            ImageUrl: "/media/33187885/rsz_mhq4rzia_400x400.jpg",
            Symbol: "SHL",
            CoinName: "Oyster Shell",
        },
        ETZ: {
            ImageUrl: "/media/33187887/rsz_obc3pjkr_400x400.jpg",
            Symbol: "ETZ",
            CoinName: "EtherZero",
        },
        SKM: {
            ImageUrl: "/media/33187888/skm.jpg",
            Symbol: "SKM",
            CoinName: "Skrumble Network",
        },
        SHR: {
            ImageUrl: "/media/33187889/shr.png",
            Symbol: "SHR",
            CoinName: "ShareRing",
        },
        KEC: {
            ImageUrl: "/media/33187895/rsz_x8rkuu1o_400x400-1.png",
            Symbol: "KEC",
            CoinName: "KEYCO",
        },
        ODE: {
            ImageUrl: "/media/33187894/rsz_rliunpu-_400x400.jpg",
            Symbol: "ODE",
            CoinName: "ODEM ",
        },
        AMN: {
            ImageUrl: "/media/33187898/rsz_m8svzqju_400x400.jpg",
            Symbol: "AMN",
            CoinName: "Amon",
        },
        SABR: {
            ImageUrl: "/media/33187902/download.png",
            Symbol: "SABR",
            CoinName: "SABR Coin",
        },
        HWC: {
            ImageUrl: "/media/33187903/rsz_ti9rvys2_400x400.jpg",
            Symbol: "HWC",
            CoinName: "HollyWoodCoin",
        },
        BITGOLD: {
            ImageUrl: "/media/33187928/bitgold.png",
            Symbol: "BITGOLD",
            CoinName: "bitGold",
        },
        BITSILVER: {
            ImageUrl: "/media/33187928/bitgold.png",
            Symbol: "BITSILVER",
            CoinName: "bitSilver",
        },
        GIN: {
            ImageUrl: "/media/33187910/0suib6bl_400x400.jpg",
            Symbol: "GIN",
            CoinName: "GINcoin",
        },
        OPEN: {
            ImageUrl: "/media/33187913/ec78plgb_400x400.jpg",
            Symbol: "OPEN",
            CoinName: "Open Platform",
        },
        NLX: {
            ImageUrl: "/media/33187915/rsz_k6egfcvp_400x400.jpg",
            Symbol: "NLX",
            CoinName: "Nullex",
        },
        "LNC*": {
            ImageUrl: "/media/33187918/rsz_l4bqq9lb_400x400.jpg",
            Symbol: "LNC*",
            CoinName: "Linker Coin",
        },
        FACE: {
            ImageUrl: "/media/33187920/rsz_xey-vj3j_400x400.jpg",
            Symbol: "FACE",
            CoinName: "Faceter ",
        },
        IOTX: {
            ImageUrl: "/media/33187927/rsz_njsdvvpv_400x400.jpg",
            Symbol: "IOTX",
            CoinName: "IoTeX Network",
        },
        AITT: {
            ImageUrl: "/media/33434201/aitt.png",
            Symbol: "AITT",
            CoinName: "AITrading",
        },
        ITM: {
            ImageUrl: "/media/33434202/itm.jpg",
            Symbol: "ITM",
            CoinName: "intimate.io",
        },
        VID: {
            ImageUrl: "/media/33434208/vid.jpg",
            Symbol: "VID",
            CoinName: "VideoCoin",
        },
        UCT: {
            ImageUrl: "/media/33434210/uct.png",
            Symbol: "UCT",
            CoinName: "UCOT",
        },
        ZMR: {
            ImageUrl: "/media/33434221/zmr.jpg",
            Symbol: "ZMR",
            CoinName: "Monero 0",
        },
        XMV: {
            ImageUrl: "/media/33434222/xmv.jpg",
            Symbol: "XMV",
            CoinName: "MoneroV",
        },
        NKN: {
            ImageUrl: "/media/33434229/nkn.jpg",
            Symbol: "NKN",
            CoinName: "NKN",
        },
        ELY: {
            ImageUrl: "/media/33434230/ely.png",
            Symbol: "ELY",
            CoinName: "Elysian",
        },
        HER: {
            ImageUrl: "/media/33434231/her.jpg",
            Symbol: "HER",
            CoinName: "Hero Node",
        },
        PAR: {
            ImageUrl: "/media/33434252/par.jpg",
            Symbol: "PAR",
            CoinName: "Parlay",
        },
        LTCC: {
            ImageUrl: "/media/33434267/ltcc.jpg",
            Symbol: "LTCC",
            CoinName: "Listerclassic Coin",
        },
        RST: {
            ImageUrl: "/media/33434268/rst.jpg",
            Symbol: "RST",
            CoinName: "REGA Risk Sharing Token",
        },
        AMX: {
            ImageUrl: "/media/33434270/amx.jpg",
            Symbol: "AMX",
            CoinName: "Amero",
        },
        TFC: {
            ImageUrl: "/media/33434271/tfc.jpg",
            Symbol: "TFC",
            CoinName: "The Freedom Coin",
        },
        "MSC*": {
            ImageUrl: "/media/33434272/msc.jpg",
            Symbol: "MSC*",
            CoinName: "MaisCoin",
        },
        REPO: {
            ImageUrl: "/media/33434275/repo.jpg",
            Symbol: "REPO",
            CoinName: "Repo Coin",
        },
        IRC: {
            ImageUrl: "/media/33434281/irc.jpg",
            Symbol: "IRC",
            CoinName: "IRONCOIN",
        },
        "PLC*": {
            ImageUrl: "/media/33434283/plc.jpg",
            Symbol: "PLC*",
            CoinName: "PlatinCoin",
        },
        ANGL: {
            ImageUrl: "/media/33434286/angl.jpg",
            Symbol: "ANGL",
            CoinName: "Angel Token",
        },
        ANTS: {
            ImageUrl: "/media/33434287/ants.jpg",
            Symbol: "ANTS",
            CoinName: "ANTS Reloaded",
        },
        KNG: {
            ImageUrl: "/media/33434289/kng.jpg",
            Symbol: "KNG",
            CoinName: "BetKings",
        },
        "AUC*": {
            ImageUrl: "/media/33434290/auc.jpg",
            Symbol: "AUC*",
            CoinName: "AU-Coin",
        },
        CMM: {
            ImageUrl: "/media/33434296/commercium.jpg",
            Symbol: "CMM",
            CoinName: "Commercium ",
        },
        COG: {
            ImageUrl: "/media/33434304/cog.jpg",
            Symbol: "COG",
            CoinName: "Cognitio",
        },
        ZIPT: {
            ImageUrl: "/media/33434305/zipt.jpg",
            Symbol: "ZIPT",
            CoinName: "Zippie",
        },
        OSA: {
            ImageUrl: "/media/33434309/osa.jpg",
            Symbol: "OSA",
            CoinName: "Optimal Shelf Availability Token",
        },
        BEN: {
            ImageUrl: "/media/33752288/ben.jpg",
            Symbol: "BEN",
            CoinName: "BitCOEN",
        },
        BCIO: {
            ImageUrl: "/media/33752293/blockchainio.png",
            Symbol: "BCIO",
            CoinName: "Blockchain.io",
        },
        LYL: {
            ImageUrl: "/media/33842908/lyl.jpg",
            Symbol: "LYL",
            CoinName: "LoyalCoin",
        },
        FT: {
            ImageUrl: "/media/33842909/ft.jpg",
            Symbol: "FT",
            CoinName: "Fabric Token",
        },
        BMX: {
            ImageUrl: "/media/33842910/bmx.jpg",
            Symbol: "BMX",
            CoinName: "BitMart Coin",
        },
        PHI: {
            ImageUrl: "/media/33842914/phi.jpg",
            Symbol: "PHI",
            CoinName: "PHI Token",
        },
        PMNT: {
            ImageUrl: "/media/33842921/pmnt.jpg",
            Symbol: "PMNT",
            CoinName: "Paymon",
        },
        BNTN: {
            ImageUrl: "/media/33842923/bntn.png",
            Symbol: "BNTN",
            CoinName: "Blocnation",
        },
        "SOUL*": {
            ImageUrl: "/media/33842929/soul.jpg",
            Symbol: "SOUL*",
            CoinName: "Phantasma",
        },
        GRMD: {
            ImageUrl: "/media/33842930/grmd.jpg",
            Symbol: "GRMD",
            CoinName: "GreenMed",
        },
        SSC: {
            ImageUrl: "/media/33842931/ssc.jpg",
            Symbol: "SSC",
            CoinName: "SelfSell",
        },
        LOKI: {
            ImageUrl: "/media/33842932/loki.jpg",
            Symbol: "LOKI",
            CoinName: "Loki",
        },
        BTT: {
            ImageUrl: "/media/33842935/btt.jpg",
            Symbol: "BTT",
            CoinName: "Blocktrade token",
        },
        NCP: {
            ImageUrl: "/media/33842936/ncp.jpg",
            Symbol: "NCP",
            CoinName: "Newton Coin",
        },
        MPT: {
            ImageUrl: "/media/33842938/mpt1.png",
            Symbol: "MPT",
            CoinName: "Media Protocol Token",
        },
        STAX: {
            ImageUrl: "/media/33842943/stax.png",
            Symbol: "STAX",
            CoinName: "Staxcoin",
        },
        MRN: {
            ImageUrl: "/media/33842946/mrn.png",
            Symbol: "MRN",
            CoinName: "Mercoin",
        },
        FOPA: {
            ImageUrl: "/media/33842947/fopa.jpg",
            Symbol: "FOPA",
            CoinName: "Fopacoin",
        },
        "CBC*": {
            ImageUrl: "/media/33842951/cbc.jpg",
            Symbol: "CBC*",
            CoinName: "CashBet Coin",
        },
        OOT: {
            ImageUrl: "/media/33842952/oot.jpg",
            Symbol: "OOT",
            CoinName: "Utrum",
        },
        SIC: {
            ImageUrl: "/media/33842956/sic.jpg",
            Symbol: "SIC",
            CoinName: "Swisscoin",
        },
        ALG: {
            ImageUrl: "/media/33842957/alg.jpg",
            Symbol: "ALG",
            CoinName: "Algory",
        },
        EXCC: {
            ImageUrl: "/media/33842976/excc.jpg",
            Symbol: "EXCC",
            CoinName: "ExchangeCoin",
        },
        REL: {
            ImageUrl: "/media/33842979/rel.jpg",
            Symbol: "REL",
            CoinName: "Reliance",
        },
        BTCN: {
            ImageUrl: "/media/33957375/6g4_k7_o_400x400.jpg",
            Symbol: "BTCN",
            CoinName: "BitcoiNote",
        },
        HERO: {
            ImageUrl: "/media/33957376/hero.jpg",
            Symbol: "HERO",
            CoinName: "Hero",
        },
        EJAC: {
            ImageUrl: "/media/33957381/eja.jpg",
            Symbol: "EJAC",
            CoinName: "EJA Coin",
        },
        XT3: {
            ImageUrl: "/media/33957391/8exrkue2_400x400.jpg",
            Symbol: "XT3",
            CoinName: "Xt3ch",
        },
        MGD: {
            ImageUrl: "/media/33957397/obltargr_400x400.jpg",
            Symbol: "MGD",
            CoinName: "MassGrid",
        },
        VIG: {
            ImageUrl: "/media/33957398/vig.jpg",
            Symbol: "VIG",
            CoinName: "TheVig",
        },
        PLURA: {
            ImageUrl: "/media/33957400/plura.jpg",
            Symbol: "PLURA",
            CoinName: "PluraCoin",
        },
        SCX: {
            ImageUrl: "/media/33957403/scx.jpg",
            Symbol: "SCX",
            CoinName: "Swachhcoin",
        },
        NWCN: {
            ImageUrl: "/media/33957401/nwcn.jpg",
            Symbol: "NWCN",
            CoinName: "NowCoin",
        },
        "AEC*": {
            ImageUrl: "/media/34077383/aec.jpg",
            Symbol: "AEC*",
            CoinName: "EmaratCoin",
        },
        "BTF*": {
            ImageUrl: "/media/34077384/btf.jpg",
            Symbol: "BTF*",
            CoinName: "BitcoinFor",
        },
        "GMC*": {
            ImageUrl: "/media/34077385/gmc.png",
            Symbol: "GMC*",
            CoinName: "GMC Coin",
        },
        ICST: {
            ImageUrl: "/media/34077390/icst.png",
            Symbol: "ICST",
            CoinName: "ICST",
        },
        PURK: {
            ImageUrl: "/media/34155611/purk.png",
            Symbol: "PURK",
            CoinName: "Purk",
        },
        ROE: {
            ImageUrl: "/media/34077407/roe.jpg",
            Symbol: "ROE",
            CoinName: "Rover Coin",
        },
        DKD: {
            ImageUrl: "/media/34077409/dkd.png",
            Symbol: "DKD",
            CoinName: "Dekado",
        },
        LYNX: {
            ImageUrl: "/media/34077410/lynx.jpg",
            Symbol: "LYNX",
            CoinName: "Lynx",
        },
        POSQ: {
            ImageUrl: "/media/34077414/posq.jpg",
            Symbol: "POSQ",
            CoinName: "Poseidon Quark",
        },
        "OCTO*": {
            ImageUrl: "/media/34077418/octo.jpg",
            Symbol: "OCTO*",
            CoinName: "OctoBit Coin",
        },
        YCE: {
            ImageUrl: "/media/34077420/yce.jpg",
            Symbol: "YCE",
            CoinName: "MYCE",
        },
        OCC: {
            ImageUrl: "/media/34077421/occ.jpg",
            Symbol: "OCC",
            CoinName: "Original Crypto Coin",
        },
        ARO: {
            ImageUrl: "/media/34077423/aro.jpg",
            Symbol: "ARO",
            CoinName: "Arionum",
        },
        BWS: {
            ImageUrl: "/media/34077424/bws.jpg",
            Symbol: "BWS",
            CoinName: "BitcoinWSpectrum",
        },
        BTCC: {
            ImageUrl: "/media/34077427/btcc.png",
            Symbol: "BTCC",
            CoinName: "Bitcoin Core",
        },
        GOLF: {
            ImageUrl: "/media/34077431/tb5eoz00_400x400.jpeg",
            Symbol: "GOLF",
            CoinName: "GolfCoin",
        },
        MUSE: {
            ImageUrl: "/media/34077432/muse.jpg",
            Symbol: "MUSE",
            CoinName: "Muse",
        },
        OCT: {
            ImageUrl: "/media/34077434/oct.jpg",
            Symbol: "OCT",
            CoinName: "OracleChain",
        },
        XCEL: {
            ImageUrl: "/media/34077435/xcel.jpg",
            Symbol: "XCEL",
            CoinName: "XcelTrip",
        },
        ECH: {
            ImageUrl: "/media/34077437/ech.jpg",
            Symbol: "ECH",
            CoinName: "EthereCash",
        },
        XMN: {
            ImageUrl: "/media/34077439/xmn.jpg",
            Symbol: "XMN",
            CoinName: "Motion",
        },
        PLUS1: {
            ImageUrl: "/media/34077441/plus1.jpg",
            Symbol: "PLUS1",
            CoinName: "PlusOneCoin",
        },
        COI: {
            ImageUrl: "/media/34077446/coi.jpg",
            Symbol: "COI",
            CoinName: "Coinnec",
        },
        GMCN: {
            ImageUrl: "/media/34077455/gmcn.jpg",
            Symbol: "GMCN",
            CoinName: "GambleCoin",
        },
        TRVC: {
            ImageUrl: "/media/34077456/trvc.jpg",
            Symbol: "TRVC",
            CoinName: "Trivecoin",
        },
        KRX: {
            ImageUrl: "/media/34077460/krx.png",
            Symbol: "KRX",
            CoinName: "RAVN Korrax ",
        },
        "OPP*": {
            ImageUrl: "/media/34155496/opp.png",
            Symbol: "OPP*",
            CoinName: "OPP Open WiFi",
        },
        DTEM: {
            ImageUrl: "/media/34155499/dtem.jpg",
            Symbol: "DTEM",
            CoinName: "Dystem",
        },
        SOUND: {
            ImageUrl: "/media/34155506/sound.png",
            Symbol: "SOUND",
            CoinName: "Inmusik",
        },
        TRW: {
            ImageUrl: "/media/34155516/trw.png",
            Symbol: "TRW",
            CoinName: "Triwer",
        },
        GIC: {
            ImageUrl: "/media/34155569/gic.png",
            Symbol: "GIC",
            CoinName: "Giant",
        },
        EPIK: {
            ImageUrl: "/media/34155525/epik.png",
            Symbol: "EPIK",
            CoinName: "EPIK Token",
        },
        ZMN: {
            ImageUrl: "/media/34155539/zmine.jpg",
            Symbol: "ZMN",
            CoinName: "ZMINE",
        },
        PNY: {
            ImageUrl: "/media/34155540/pny.jpg",
            Symbol: "PNY",
            CoinName: "Peony Coin",
        },
        SAFE: {
            ImageUrl: "/media/34155541/safe.jpg",
            Symbol: "SAFE",
            CoinName: "SafeCoin",
        },
        COU: {
            ImageUrl: "/media/34155542/cou.jpg",
            Symbol: "COU",
            CoinName: "Couchain",
        },
        BID: {
            ImageUrl: "/media/34155544/bidcoin.jpg",
            Symbol: "BID",
            CoinName: "BidCoin",
        },
        ATH: {
            ImageUrl: "/media/34155545/ath.jpg",
            Symbol: "ATH",
            CoinName: "Atheios",
        },
        ABS: {
            ImageUrl: "/media/34155546/abs.jpg",
            Symbol: "ABS",
            CoinName: "Absolute Coin",
        },
        "JOY*": {
            ImageUrl: "/media/34155547/joy.jpg",
            Symbol: "JOY*",
            CoinName: "JOYSO",
        },
        VITAE: {
            ImageUrl: "/media/34155548/vitae.jpg",
            Symbol: "VITAE",
            CoinName: "Vitae",
        },
        "0xDIARY": {
            ImageUrl: "/media/34155551/0xdiary.jpg",
            Symbol: "0xDIARY",
            CoinName: "The 0xDiary Token",
        },
        "BTCS*": {
            ImageUrl: "/media/34155553/btcs.jpg",
            Symbol: "BTCS*",
            CoinName: "Bitcoin Supreme",
        },
        TDP: {
            ImageUrl: "/media/34155558/tdp.jpg",
            Symbol: "TDP",
            CoinName: "TrueDeck",
        },
        XGS: {
            ImageUrl: "/media/34155559/xgs.jpg",
            Symbol: "XGS",
            CoinName: "GenesisX",
        },
        XUEZ: {
            ImageUrl: "/media/34155560/xuez.jpg",
            Symbol: "XUEZ",
            CoinName: "XUEZ",
        },
        BIM: {
            ImageUrl: "/media/34155562/bim.png",
            Symbol: "BIM",
            CoinName: "BitminerCoin",
        },
        Dow: {
            ImageUrl: "/media/34155564/dow.jpg",
            Symbol: "Dow",
            CoinName: "DowCoin",
        },
        HEX: {
            ImageUrl: "/media/34155565/hex.png",
            Symbol: "HEX",
            CoinName: "HexCoin",
        },
        PYT: {
            ImageUrl: "/media/34155579/pyt.jpg",
            Symbol: "PYT",
            CoinName: "Payther",
        },
        DEI: {
            ImageUrl: "/media/34155582/dei.jpg",
            Symbol: "DEI",
            CoinName: "Deimos",
        },
        TPC: {
            ImageUrl: "/media/34155583/tpc.png",
            Symbol: "TPC",
            CoinName: "TPCash",
        },
        OYS: {
            ImageUrl: "/media/34155584/oys.jpg",
            Symbol: "OYS",
            CoinName: "Oyster Platform",
        },
        "WEB*": {
            ImageUrl: "/media/34155585/web.jpg",
            Symbol: "WEB*",
            CoinName: "Webchain",
        },
        VIDT: {
            ImageUrl: "/media/34155586/vid.png",
            Symbol: "VIDT",
            CoinName: "V-ID",
        },
        JEX: {
            ImageUrl: "/media/34155588/jex.jpg",
            Symbol: "JEX",
            CoinName: "JEX Token",
        },
        RYO: {
            ImageUrl: "/media/34155598/ryo.jpg",
            Symbol: "RYO",
            CoinName: "Ryo",
        },
        MUSD: {
            ImageUrl: "/media/34155599/musd.jpg",
            Symbol: "MUSD",
            CoinName: "MUSDcoin",
        },
        MIC: {
            ImageUrl: "/media/34155601/mic.jpg",
            Symbol: "MIC",
            CoinName: "Mindexcoin",
        },
        URALS: {
            ImageUrl: "/media/34155602/urals.jpg",
            Symbol: "URALS",
            CoinName: "Urals Coin",
        },
        QWC: {
            ImageUrl: "/media/34155603/qwc.jpg",
            Symbol: "QWC",
            CoinName: "Qwertycoin",
        },
        WAB: {
            ImageUrl: "/media/34155604/wab.jpg",
            Symbol: "WAB",
            CoinName: "WABnetwork",
        },
        BTN: {
            ImageUrl: "/media/34155606/btn.jpg",
            Symbol: "BTN",
            CoinName: "Bitcoin Nova",
        },
        ARE: {
            ImageUrl: "/media/34155607/are.jpg",
            Symbol: "ARE",
            CoinName: "ARENON",
        },
        DAC: {
            ImageUrl: "/media/34155608/dac.jpg",
            Symbol: "DAC",
            CoinName: "DACash",
        },
        EUNO: {
            ImageUrl: "/media/34155610/euno.jpg",
            Symbol: "EUNO",
            CoinName: "EUNO",
        },
        MMO: {
            ImageUrl: "/media/34333412/mmo.jpg",
            Symbol: "MMO",
            CoinName: "MMOCoin",
        },
        MVP: {
            ImageUrl: "/media/34333413/mvp.jpg",
            Symbol: "MVP",
            CoinName: "Merculet",
        },
        DASC: {
            ImageUrl: "/media/34333414/dasc.jpg",
            Symbol: "DASC",
            CoinName: "DasCoin",
        },
        EGT: {
            ImageUrl: "/media/34333415/egt.jpg",
            Symbol: "EGT",
            CoinName: "Egretia",
        },
        "MET*": {
            ImageUrl: "/media/34333416/met.jpg",
            Symbol: "MET*",
            CoinName: "Metronome",
        },
        PGT: {
            ImageUrl: "/media/34333417/pgt.png",
            Symbol: "PGT",
            CoinName: "Puregold token",
        },
        SLT: {
            ImageUrl: "/media/34333419/slt.jpg",
            Symbol: "SLT",
            CoinName: "SmartLands",
        },
        TGAME: {
            ImageUrl: "/media/34333421/tgame.jpg",
            Symbol: "TGAME",
            CoinName: "TrueGame",
        },
        "SPN*": {
            ImageUrl: "/media/34333422/spn.jpg",
            Symbol: "SPN*",
            CoinName: "Sapien Network",
        },
        ZINC: {
            ImageUrl: "/media/34333420/zinc.png",
            Symbol: "ZINC",
            CoinName: "ZINC",
        },
        KETAN: {
            ImageUrl: "/media/34333423/ketan.jpg",
            Symbol: "KETAN",
            CoinName: "Ketan",
        },
        INSUR: {
            ImageUrl: "/media/34333428/insur.jpg",
            Symbol: "INSUR",
            CoinName: "InsurChain Coin",
        },
        NIX: {
            ImageUrl: "/media/34333430/nix.jpg",
            Symbol: "NIX",
            CoinName: "NIX",
        },
        FIN: {
            ImageUrl: "/media/34333437/fin.jpg",
            Symbol: "FIN",
            CoinName: "Finom FIN Token",
        },
        RPM: {
            ImageUrl: "/media/34333441/rpm.jpg",
            Symbol: "RPM",
            CoinName: "Render Payment",
        },
        DGX: {
            ImageUrl: "/media/34477706/dgx.png",
            Symbol: "DGX",
            CoinName: "Digix Gold token",
        },
        ITA: {
            ImageUrl: "/media/34477708/ita.jpg",
            Symbol: "ITA",
            CoinName: "Italocoin",
        },
        NOM: {
            ImageUrl: "/media/34333437/fin.jpg",
            Symbol: "NOM",
            CoinName: "Finom NOM Token",
        },
        XSTC: {
            ImageUrl: "/media/34477716/xstc.png",
            Symbol: "XSTC",
            CoinName: "Safe Trade Coin",
        },
        EGCC: {
            ImageUrl: "/media/34477732/egcc.jpg",
            Symbol: "EGCC",
            CoinName: "Engine",
        },
        FREC: {
            ImageUrl: "/media/34477733/frec.jpg",
            Symbol: "FREC",
            CoinName: "Freyrchain",
        },
        AOA: {
            ImageUrl: "/media/34477737/aoa.jpg",
            Symbol: "AOA",
            CoinName: "Aurora ",
        },
        LET: {
            ImageUrl: "/media/34477738/let.jpg",
            Symbol: "LET",
            CoinName: "LinkEye",
        },
        MYB: {
            ImageUrl: "/media/33187817/myb.jpg",
            Symbol: "MYB",
            CoinName: "MyBit",
        },
        BNB: {
            ImageUrl: "/media/1383947/bnb.png",
            Symbol: "BNB",
            CoinName: "Binance Coin",
        },
        OMG: {
            ImageUrl: "/media/1383814/omisego.png",
            Symbol: "OMG",
            CoinName: "OmiseGo",
        },
        ICX: {
            ImageUrl: "/media/12318192/icx.png",
            Symbol: "ICX",
            CoinName: "ICON Project",
        },
        ZRX: {
            ImageUrl: "/media/1383799/zrx.png",
            Symbol: "ZRX",
            CoinName: "0x",
        },
        AE: {
            ImageUrl: "/media/1383836/ae.png",
            Symbol: "AE",
            CoinName: "Aeternity",
        },
        MKR: {
            ImageUrl: "/media/1382296/mkr.png",
            Symbol: "MKR",
            CoinName: "Maker",
        },
        REP: {
            ImageUrl: "/media/350815/augur-logo.png",
            Symbol: "REP",
            CoinName: "Augur",
        },
        "BTM*": {
            ImageUrl: "/media/1383996/btm.png",
            Symbol: "BTM*",
            CoinName: "Bytom",
        },
        NPXS: {
            ImageUrl: "/media/27010505/pxs.png",
            Symbol: "NPXS",
            CoinName: "Pundi X",
        },
        WTC: {
            ImageUrl: "/media/12317959/wtc.png",
            Symbol: "WTC",
            CoinName: "Waltonchain",
        },
        BAT: {
            ImageUrl: "/media/1383370/bat.png",
            Symbol: "BAT",
            CoinName: "Basic Attention Token",
        },
        GNT: {
            ImageUrl: "/media/351995/golem_logo.png",
            Symbol: "GNT",
            CoinName: "Golem Network Token",
        },
        RHOC: {
            ImageUrl: "/media/16746544/rhoc.png",
            Symbol: "RHOC",
            CoinName: "RChain",
        },
        SNT: {
            ImageUrl: "/media/1383568/snt.png",
            Symbol: "SNT",
            CoinName: "Status Network Token",
        },
        PPT: {
            ImageUrl: "/media/1383747/ppt.png",
            Symbol: "PPT",
            CoinName: "Populous",
        },
        DGD: {
            ImageUrl: "/media/350851/dgd.png",
            Symbol: "DGD",
            CoinName: "Digix DAO",
        },
        IOST: {
            ImageUrl: "/media/27010459/iost.png",
            Symbol: "IOST",
            CoinName: "IOS token",
        },
        HT: {
            ImageUrl: "/media/27010813/ht.png",
            Symbol: "HT",
            CoinName: "Huobi Token",
        },
        AION: {
            ImageUrl: "/media/16746538/aion.png",
            Symbol: "AION",
            CoinName: "Aion",
        },
        LRC: {
            ImageUrl: "/media/12318135/lrc.png",
            Symbol: "LRC",
            CoinName: "Loopring",
        },
        NAS: {
            ImageUrl: "/media/20780653/nas.png",
            Symbol: "NAS",
            CoinName: "Nebulas",
        },
        ELF: {
            ImageUrl: "/media/20780600/elf.png",
            Symbol: "ELF",
            CoinName: "aelf",
        },
        FUN: {
            ImageUrl: "/media/1383738/fun.png",
            Symbol: "FUN",
            CoinName: "FunFair",
        },
        KNC: {
            ImageUrl: "/media/12318084/knc.png",
            Symbol: "KNC",
            CoinName: "Kyber Network",
        },
        DCN: {
            ImageUrl: "/media/1383999/dcn.png",
            Symbol: "DCN",
            CoinName: "Dentacoin",
        },
        LOOM: {
            ImageUrl: "/media/30001890/untitled-1.png",
            Symbol: "LOOM",
            CoinName: "Loom Network",
        },
        MCO: {
            ImageUrl: "/media/1383653/mco.jpg",
            Symbol: "MCO",
            CoinName: "Monaco",
        },
        WAX: {
            ImageUrl: "/media/12318290/wax.png",
            Symbol: "WAX",
            CoinName: "Worldwide Asset eXchange",
        },
        BNT: {
            ImageUrl: "/media/1383549/bnt.jpg",
            Symbol: "BNT",
            CoinName: "Bancor Network Token",
        },
        KIN: {
            ImageUrl: "/media/1383731/kin.png",
            Symbol: "KIN",
            CoinName: "Kin",
        },
        BQX: {
            ImageUrl: "/media/16404851/ethos.png",
            Symbol: "BQX",
            CoinName: "Ethos",
        },
        POWR: {
            ImageUrl: "/media/12318301/powr.png",
            Symbol: "POWR",
            CoinName: "Power Ledger",
        },
        NULS: {
            ImageUrl: "/media/14913548/nuls.png",
            Symbol: "NULS",
            CoinName: "Nuls",
        },
        MANA: {
            ImageUrl: "/media/1383903/mana.png",
            Symbol: "MANA",
            CoinName: "Decentraland",
        },
        VERI: {
            ImageUrl: "/media/1383562/veri.png",
            Symbol: "VERI",
            CoinName: "Veritaseum",
        },
        "CMT*": {
            ImageUrl: "/media/30002257/cmt.png",
            Symbol: "CMT*",
            CoinName: "CyberMiles",
        },
        QASH: {
            ImageUrl: "/media/15887431/qash.png",
            Symbol: "QASH",
            CoinName: "Quoine Liquid",
        },
        "POLY*": {
            ImageUrl: "/media/27010571/poly.png",
            Symbol: "POLY*",
            CoinName: "Polymath Network",
        },
        ENG: {
            ImageUrl: "/media/12318287/eng.png",
            Symbol: "ENG",
            CoinName: "Enigma",
        },
        CTXC: {
            ImageUrl: "/media/30002149/ctxc.png",
            Symbol: "CTXC",
            CoinName: "Cortex",
        },
        NOAH: {
            ImageUrl: "/media/32655930/noah.jpg",
            Symbol: "NOAH",
            CoinName: "NOAHCOIN",
        },
        THETA: {
            ImageUrl: "/media/27010450/theta.png",
            Symbol: "THETA",
            CoinName: "Theta",
        },
        TUSD: {
            ImageUrl: "/media/30001972/tusd.png",
            Symbol: "TUSD",
            CoinName: "True USD",
        },
        SUB: {
            ImageUrl: "/media/1384011/sub1.png",
            Symbol: "SUB",
            CoinName: "Substratum Network",
        },
        BIX: {
            ImageUrl: "/media/27010664/bix.png",
            Symbol: "BIX",
            CoinName: "BiboxCoin",
        },
        "XIN*": {
            ImageUrl: "/media/34477741/xin.jpg",
            Symbol: "XIN*",
            CoinName: "Mixin",
        },
        "MTN**": {
            ImageUrl: "/media/34477739/mtn.png",
            Symbol: "MTN**",
            CoinName: "Motion",
        },
        "PAI*": {
            ImageUrl: "/media/34477740/pai.jpg",
            Symbol: "PAI*",
            CoinName: "Project Pai",
        },
        STORM: {
            ImageUrl: "/media/1383803/storm.jpg",
            Symbol: "STORM",
            CoinName: "Storm",
        },
        FSN: {
            ImageUrl: "/media/27010765/fsn.png",
            Symbol: "FSN",
            CoinName: "Fusion",
        },
        GTC: {
            ImageUrl: "/media/20780776/gtc.png",
            Symbol: "GTC",
            CoinName: "Game",
        },
        "MAN*": {
            ImageUrl: "/media/27010516/man.png",
            Symbol: "MAN*",
            CoinName: "Matrix AI Network",
        },
        DRGN: {
            ImageUrl: "/media/16746490/drgn.png",
            Symbol: "DRGN",
            CoinName: "Dragonchain",
        },
        HPB: {
            ImageUrl: "/media/20780783/hpb.png",
            Symbol: "HPB",
            CoinName: "High Performance Blockchain",
        },
        SALT: {
            ImageUrl: "/media/9350744/salt.jpg",
            Symbol: "SALT",
            CoinName: "Salt Lending",
        },
        CVC: {
            ImageUrl: "/media/1383611/cvc.png",
            Symbol: "CVC",
            CoinName: "Civic",
        },
        ICN: {
            ImageUrl: "/media/351400/icn.png",
            Symbol: "ICN",
            CoinName: "Iconomi",
        },
        AGI: {
            ImageUrl: "/media/25792653/agi.png",
            Symbol: "AGI",
            CoinName: "SingularityNET",
        },
        STORJ: {
            ImageUrl: "/media/20422/sjcx.png",
            Symbol: "STORJ",
            CoinName: "Storj",
        },
        SAN: {
            ImageUrl: "/media/1383730/san.png",
            Symbol: "SAN",
            CoinName: "Santiment",
        },
        RLC: {
            ImageUrl: "/media/12318418/rlc.png",
            Symbol: "RLC",
            CoinName: "iEx.ec",
        },
        CVT: {
            ImageUrl: "/media/32655908/cvt.jpg",
            Symbol: "CVT",
            CoinName: "CyberVein",
        },
        NCASH: {
            ImageUrl: "/media/27010960/untitled-1.png",
            Symbol: "NCASH",
            CoinName: "Nucleus Vision",
        },
        MEET: {
            ImageUrl: "/media/34477746/meet.jpg",
            Symbol: "MEET",
            CoinName: "CoinMeet",
        },
        BOE: {
            ImageUrl: "/media/34477747/boe.jpg",
            Symbol: "BOE",
            CoinName: "Bodhi ",
        },
        RTE: {
            ImageUrl: "/media/34477748/rte.jpg",
            Symbol: "RTE",
            CoinName: "Rate3 ",
        },
        UBC: {
            ImageUrl: "/media/30002222/ubc.jpg",
            Symbol: "UBC",
            CoinName: "Ubcoin",
        },
        HB: {
            ImageUrl: "/media/34155514/hb.jpg",
            Symbol: "HB",
            CoinName: "HeartBout",
        },
        XET: {
            ImageUrl: "/media/34155549/xet.jpg",
            Symbol: "XET",
            CoinName: "Eternal Token",
        },
        PCO: {
            ImageUrl: "/media/34477751/pco.jpg",
            Symbol: "PCO",
            CoinName: "Pecunio",
        },
        XPST: {
            ImageUrl: "/media/34477752/xpts.jpg",
            Symbol: "XPST",
            CoinName: "PokerSports",
        },
        NBC: {
            ImageUrl: "/media/33842955/nbc.jpg",
            Symbol: "NBC",
            CoinName: "Niobium",
        },
        CET: {
            ImageUrl: "/media/30002253/coinex.png",
            Symbol: "CET",
            CoinName: "CoinEx token",
        },
        HSC: {
            ImageUrl: "/media/34477757/hashfuture.png",
            Symbol: "HSC",
            CoinName: "HashCoin ",
        },
        CAR: {
            ImageUrl: "/media/34477749/car.jpg",
            Symbol: "CAR",
            CoinName: "CarBlock ",
        },
        REN: {
            ImageUrl: "/media/27010916/ren.jpg",
            Symbol: "REN",
            CoinName: "Republic Token",
        },
        KEP: {
            ImageUrl: "/media/32655941/kep.png",
            Symbol: "KEP",
            CoinName: "Kepler",
        },
        XPAT: {
            ImageUrl: "/media/34477766/xpat.jpg",
            Symbol: "XPAT",
            CoinName: "Bitnation Pangea",
        },
        SCRL: {
            ImageUrl: "/media/34477763/scrl.jpg",
            Symbol: "SCRL",
            CoinName: "Scroll",
        },
        LCK: {
            ImageUrl: "/media/20780805/luck.png",
            Symbol: "LCK",
            CoinName: "Luckbox",
        },
        MRPH: {
            ImageUrl: "/media/33187921/rsz_aqwlc_on_400x400.jpg",
            Symbol: "MRPH",
            CoinName: "Morpheus Network",
        },
        "0XBTC": {
            ImageUrl: "/media/32655871/0xbtc.jpg",
            Symbol: "0XBTC",
            CoinName: "0xBitcoin",
        },
        GNO: {
            ImageUrl: "/media/1383083/gnosis-logo.png",
            Symbol: "GNO",
            CoinName: "Gnosis",
        },
        R: {
            ImageUrl: "/media/12318360/r.png",
            Symbol: "R",
            CoinName: "Revain",
        },
        ELI: {
            ImageUrl: "/media/30001620/rpqn1zqi_400x400.jpg",
            Symbol: "ELI",
            CoinName: "Eligma",
        },
        "HOT*": {
            ImageUrl: "/media/30002313/hot.jpg",
            Symbol: "HOT*",
            CoinName: "Holo",
        },
        NEXO: {
            ImageUrl: "/media/32655865/nexo.jpg",
            Symbol: "NEXO",
            CoinName: "NEXO",
        },
        REQ: {
            ImageUrl: "/media/12318260/req.png",
            Symbol: "REQ",
            CoinName: "Request Network",
        },
        DATA: {
            ImageUrl: "/media/30002338/data.png",
            Symbol: "DATA",
            CoinName: "Streamr DATAcoin",
        },
        ANT: {
            ImageUrl: "/media/1383244/ant.png",
            Symbol: "ANT",
            CoinName: "Aragon",
        },
        ENJ: {
            ImageUrl: "/media/11417639/enjt.png",
            Symbol: "ENJ",
            CoinName: "Enjin Coin",
        },
        CND: {
            ImageUrl: "/media/12318283/cnd.png",
            Symbol: "CND",
            CoinName: "Cindicator",
        },
        RUFF: {
            ImageUrl: "/media/27010573/fqqzfp9_400x400.png",
            Symbol: "RUFF",
            CoinName: "Ruff",
        },
        QSP: {
            ImageUrl: "/media/15887408/qsp.png",
            Symbol: "QSP",
            CoinName: "Quantstamp",
        },
        SNM: {
            ImageUrl: "/media/1383564/snm.png",
            Symbol: "SNM",
            CoinName: "SONM",
        },
        KICK: {
            ImageUrl: "/media/1383929/kick.png",
            Symbol: "KICK",
            CoinName: "KickCoin",
        },
        AMB: {
            ImageUrl: "/media/9350739/amb.png",
            Symbol: "AMB",
            CoinName: "Ambrosus",
        },
        "ABT*": {
            ImageUrl: "/media/27010666/abt2.png",
            Symbol: "ABT*",
            CoinName: "ArcBlock",
        },
        DENT: {
            ImageUrl: "/media/1383613/dent.png",
            Symbol: "DENT",
            CoinName: "Dent",
        },
        "CS*": {
            ImageUrl: "/media/27011045/cs.png",
            Symbol: "CS*",
            CoinName: "Credits",
        },
        AUTO: {
            ImageUrl: "/media/30001729/untitled-1.png",
            Symbol: "AUTO",
            CoinName: "Cube",
        },
        MTC: {
            ImageUrl: "/media/33187828/mtc.png",
            Symbol: "MTC",
            CoinName: "DOCADEMIC",
        },
        POE: {
            ImageUrl: "/media/1383828/poe.png",
            Symbol: "POE",
            CoinName: "Po.et",
        },
        "RDN*": {
            ImageUrl: "/media/14913482/rdn.png",
            Symbol: "RDN*",
            CoinName: "Raiden Network",
        },
        EBC: {
            ImageUrl: "/media/25792622/ebc.png",
            Symbol: "EBC",
            CoinName: "EBCoin",
        },
        C20: {
            ImageUrl: "/media/12318302/c20.png",
            Symbol: "C20",
            CoinName: "Crypto20",
        },
        PLR: {
            ImageUrl: "/media/27010510/plr.png",
            Symbol: "PLR",
            CoinName: "Pillar",
        },
        BRD: {
            ImageUrl: "/media/20780589/brd.png",
            Symbol: "BRD",
            CoinName: "Bread token",
        },
        STQ: {
            ImageUrl: "/media/27010830/stq.jpg",
            Symbol: "STQ",
            CoinName: "Storiqa Token",
        },
        GNX: {
            ImageUrl: "/media/20780652/gnx.png",
            Symbol: "GNX",
            CoinName: "Genaro Network",
        },
        SPHTX: {
            ImageUrl: "/media/14913551/sphtx.png",
            Symbol: "SPHTX",
            CoinName: "SophiaTX",
        },
        MFT: {
            ImageUrl: "/media/34333427/mft.jpg",
            Symbol: "MFT",
            CoinName: "Mainframe",
        },
        VEE: {
            ImageUrl: "/media/12318044/vee.png",
            Symbol: "VEE",
            CoinName: "BLOCKv",
        },
        MTL: {
            ImageUrl: "/media/1383743/mtl.png",
            Symbol: "MTL",
            CoinName: "Metal",
        },
        JOINT: {
            ImageUrl: "/media/34477771/joint.jpg",
            Symbol: "JOINT",
            CoinName: "Joint Ventures",
        },
        TEL: {
            ImageUrl: "/media/25792569/tel.png",
            Symbol: "TEL",
            CoinName: "Telcoin",
        },
        TEN: {
            ImageUrl: "/media/27010864/ten.png",
            Symbol: "TEN",
            CoinName: "Tokenomy",
        },
        CONI: {
            ImageUrl: "/media/34477765/coni.png",
            Symbol: "CONI",
            CoinName: "CoinBene",
        },
        MBLC: {
            ImageUrl: "/media/34477768/montblanc.png",
            Symbol: "MBLC",
            CoinName: "Mont Blanc",
        },
        DIW: {
            ImageUrl: "/media/34477769/diw.png",
            Symbol: "DIW",
            CoinName: "DIWtoken",
        },
        EOS: {
            ImageUrl: "/media/1383652/eos_1.png",
            Symbol: "EOS",
            CoinName: "EOS",
        },
        XRP: {
            ImageUrl: "/media/34477776/xrp.png",
            Symbol: "XRP",
            CoinName: "XRP",
        },
        ATMI: {
            ImageUrl: "/media/34477778/atmi.jpg",
            Symbol: "ATMI",
            CoinName: "Atonomi",
        },
        IDXM: {
            ImageUrl: "/media/34477770/idxm.png",
            Symbol: "IDXM",
            CoinName: "IDEX Membership",
        },
        "ROCK*": {
            ImageUrl: "/media/14913607/rock1.png",
            Symbol: "ROCK*",
            CoinName: "RocketCoin ",
        },
        BITX: {
            ImageUrl: "/media/34155487/bitx.png",
            Symbol: "BITX",
            CoinName: "BitScreener",
        },
        CCO: {
            ImageUrl: "/media/34477773/cco.png",
            Symbol: "CCO",
            CoinName: "Ccore",
        },
        ETK: {
            ImageUrl: "/media/14913635/etk.png",
            Symbol: "ETK",
            CoinName: "Energi Token",
        },
        TKA: {
            ImageUrl: "/media/34477777/tka.png",
            Symbol: "TKA",
            CoinName: "Tokia",
        },
        "SMT**": {
            ImageUrl: "/media/12318350/smt.png",
            Symbol: "SMT**",
            CoinName: "Social Media Market",
        },
        FSBT: {
            ImageUrl: "/media/25792673/fsbt.png",
            Symbol: "FSBT",
            CoinName: "Forty Seven Bank",
        },
        OLT: {
            ImageUrl: "/media/34477783/olt.jpg",
            Symbol: "OLT",
            CoinName: "OneLedger",
        },
        RMT: {
            ImageUrl: "/media/34477782/rmt.jpg",
            Symbol: "RMT",
            CoinName: "SureRemit",
        },
        TIO: {
            ImageUrl: "/media/14913488/tio.png",
            Symbol: "TIO",
            CoinName: "Trade.io",
        },
        KEY: {
            ImageUrl: "/media/14761912/key.png",
            Symbol: "KEY",
            CoinName: "SelfKey",
        },
        EDG: {
            ImageUrl: "/media/1382799/edg.jpg",
            Symbol: "EDG",
            CoinName: "Edgeless",
        },
        LEND: {
            ImageUrl: "/media/16746444/lend.png",
            Symbol: "LEND",
            CoinName: "EthLend",
        },
        LGO: {
            ImageUrl: "/media/25792655/lgo.png",
            Symbol: "LGO",
            CoinName: "Legolas Exchange",
        },
        CRPT: {
            ImageUrl: "/media/25792665/crpt.png",
            Symbol: "CRPT",
            CoinName: "Crypterium",
        },
        SOAR: {
            ImageUrl: "/media/14913644/soar.png",
            Symbol: "SOAR",
            CoinName: "Soarcoin",
        },
        EDO: {
            ImageUrl: "/media/12318082/eiboo.png",
            Symbol: "EDO",
            CoinName: "Eidoo",
        },
        TRAC: {
            ImageUrl: "/media/27010463/trac.png",
            Symbol: "TRAC",
            CoinName: "OriginTrail",
        },
        "SKB*": {
            ImageUrl: "/media/34155491/skb.jpg",
            Symbol: "SKB*",
            CoinName: "Sakura Bloom",
        },
        QKC: {
            ImageUrl: "/media/33434307/qkc.jpg",
            Symbol: "QKC",
            CoinName: "QuarkChain",
        },
        ADX: {
            ImageUrl: "/media/1383667/adx1.png",
            Symbol: "ADX",
            CoinName: "AdEx",
        },
        HYDRO: {
            ImageUrl: "/media/33187856/rsz_prd5wxkj_400x400-1.jpg",
            Symbol: "HYDRO",
            CoinName: "Hydrogen",
        },
        "NEC*": {
            ImageUrl: "/media/27010828/nec.png",
            Symbol: "NEC*",
            CoinName: "Ethfinex Nectar Token",
        },
        ITC: {
            ImageUrl: "/media/20780628/itc.png",
            Symbol: "ITC",
            CoinName: "IoT Chain",
        },
        RKT: {
            ImageUrl: "/media/30001617/rkt.jpg",
            Symbol: "RKT",
            CoinName: "Rock Token",
        },
        SENSE: {
            ImageUrl: "/media/12318034/sense.png",
            Symbol: "SENSE",
            CoinName: "Sense Token",
        },
        BCAP: {
            ImageUrl: "/media/1383948/bcap1.png",
            Symbol: "BCAP",
            CoinName: "Blockchain Capital",
        },
        "EDR*": {
            ImageUrl: "/media/33752291/endor.png",
            Symbol: "EDR*",
            CoinName: "Endor Protocol Token ",
        },
        JNT: {
            ImageUrl: "/media/34155550/jnt.png",
            Symbol: "JNT",
            CoinName: "Jibrel Network Token",
        },
        INT: {
            ImageUrl: "/media/25792603/int.png",
            Symbol: "INT",
            CoinName: "Internet Node Token",
        },
        SNTR: {
            ImageUrl: "/media/33434220/rqwcvdzw_400x400.jpg",
            Symbol: "SNTR",
            CoinName: "Silent Notary",
        },
        COV: {
            ImageUrl: "/media/12318288/cov.png",
            Symbol: "COV",
            CoinName: "Covesting",
        },
        APIS: {
            ImageUrl: "/media/33957383/apis.png",
            Symbol: "APIS",
            CoinName: "APIS",
        },
        QRL: {
            ImageUrl: "/media/1383543/qrl.png",
            Symbol: "QRL",
            CoinName: "Quantum Resistant Ledger",
        },
        BEX: {
            ImageUrl: "/media/30002102/bex.png",
            Symbol: "BEX",
            CoinName: "BEX token",
        },
        LST: {
            ImageUrl: "/media/34477788/lst.jpg",
            Symbol: "LST",
            CoinName: "Lendroid Support Token",
        },
        DREAM: {
            ImageUrl: "/media/12318109/dtt.png",
            Symbol: "DREAM",
            CoinName: "DreamTeam Token",
        },
        EMV: {
            ImageUrl: "/media/34477791/emv.jpg",
            Symbol: "EMV",
            CoinName: "Ethereum Movie Venture",
        },
        MEDX: {
            ImageUrl: "/media/34333418/medx.jpg",
            Symbol: "MEDX",
            CoinName: "Mediblock",
        },
        GETX: {
            ImageUrl: "/media/34477784/getx.png",
            Symbol: "GETX",
            CoinName: "Guaranteed Ethurance Token Extra",
        },
        BWT: {
            ImageUrl: "/media/34477785/bwt.png",
            Symbol: "BWT",
            CoinName: "Bittwatt",
        },
        BTRN: {
            ImageUrl: "/media/34477795/btrn.png",
            Symbol: "BTRN",
            CoinName: "Biotron",
        },
        XMX: {
            ImageUrl: "/media/34477796/xmx.png",
            Symbol: "XMX",
            CoinName: "XMax",
        },
        FTX: {
            ImageUrl: "/media/30002155/ftx.jpg",
            Symbol: "FTX",
            CoinName: "FintruX",
        },
        MITX: {
            ImageUrl: "/media/32655903/mitx.jpg",
            Symbol: "MITX",
            CoinName: "Morpheus Infrastructure Token",
        },
        DNT: {
            ImageUrl: "/media/1383701/dnt.png",
            Symbol: "DNT",
            CoinName: "district0x",
        },
        SNGLS: {
            ImageUrl: "/media/351368/sngls.png",
            Symbol: "SNGLS",
            CoinName: "SingularDTV",
        },
        AST: {
            ImageUrl: "/media/12318279/ast.png",
            Symbol: "AST",
            CoinName: "AirSwap",
        },
        PAI: {
            ImageUrl: "/media/33842959/pai.png",
            Symbol: "PAI",
            CoinName: "PCHAIN",
        },
        TKN: {
            ImageUrl: "/media/1383157/tkn.png",
            Symbol: "TKN",
            CoinName: "TokenCard  ",
        },
        "DCC*": {
            ImageUrl: "/media/34477736/dcc.jpg",
            Symbol: "DCC*",
            CoinName: "Distributed Credit Chain",
        },
        UTK: {
            ImageUrl: "/media/9350717/utrust.png",
            Symbol: "UTK",
            CoinName: "Utrust",
        },
        DBET: {
            ImageUrl: "/media/14913561/dbet.png",
            Symbol: "DBET",
            CoinName: "Decent.bet",
        },
        UTT: {
            ImageUrl: "/media/20780614/utt.png",
            Symbol: "UTT",
            CoinName: "United Traders Token",
        },
        "CPC*": {
            ImageUrl: "/media/27010501/cpc.png",
            Symbol: "CPC*",
            CoinName: "CPChain",
        },
        SPANK: {
            ImageUrl: "/media/16404890/spank.png",
            Symbol: "SPANK",
            CoinName: "SpankChain",
        },
        HOT: {
            ImageUrl: "/media/33187896/hot.png",
            Symbol: "HOT",
            CoinName: "Hydro Protocol",
        },
        TRX: {
            ImageUrl: "/media/34477805/trx.jpg",
            Symbol: "TRX",
            CoinName: "Tronix",
        },
        CANDY: {
            ImageUrl: "/media/34077448/candy.jpg",
            Symbol: "CANDY",
            CoinName: "UnicornGo Candy",
        },
        APPC: {
            ImageUrl: "/media/12318370/app.png",
            Symbol: "APPC",
            CoinName: "AppCoins",
        },
        UTNP: {
            ImageUrl: "/media/33842948/utnp.jpg",
            Symbol: "UTNP",
            CoinName: "Universa Token",
        },
        PPP: {
            ImageUrl: "/media/12318216/ppp.png",
            Symbol: "PPP",
            CoinName: "PayPie",
        },
        INS: {
            ImageUrl: "/media/14913458/ins.png",
            Symbol: "INS",
            CoinName: "INS Ecosystem",
        },
        TOMO: {
            ImageUrl: "/media/30001748/tomo.jpg",
            Symbol: "TOMO",
            CoinName: "TomoChain",
        },
        WINGS: {
            ImageUrl: "/media/1382758/1wings.png",
            Symbol: "WINGS",
            CoinName: "Wings DAO",
        },
        WPR: {
            ImageUrl: "/media/14543969/wpr.png",
            Symbol: "WPR",
            CoinName: "WePower",
        },
        NGC: {
            ImageUrl: "/media/16746609/ngc.png",
            Symbol: "NGC",
            CoinName: "NagaCoin",
        },
        TNT: {
            ImageUrl: "/media/1383800/tnt.png",
            Symbol: "TNT",
            CoinName: "Tierion",
        },
        YEE: {
            ImageUrl: "/media/27010483/yee.png",
            Symbol: "YEE",
            CoinName: "Yee",
        },
        MOD: {
            ImageUrl: "/media/12318362/mod.png",
            Symbol: "MOD",
            CoinName: "Modum",
        },
        RNTB: {
            ImageUrl: "/media/30002046/rntb.jpg",
            Symbol: "RNTB",
            CoinName: "BitRent",
        },
        EXC: {
            ImageUrl: "/media/33434310/exc.jpg",
            Symbol: "EXC",
            CoinName: "Eximchain",
        },
        MSP: {
            ImageUrl: "/media/1383881/c9fobrlr_400x400.jpg",
            Symbol: "MSP",
            CoinName: "Mothership",
        },
        SXDT: {
            ImageUrl: "/media/27010511/sxdt.png",
            Symbol: "SXDT",
            CoinName: "SPECTRE Dividend Token",
        },
        DOCK: {
            ImageUrl: "/media/30002275/dock.png",
            Symbol: "DOCK",
            CoinName: "Dock.io",
        },
        COB: {
            ImageUrl: "/media/9350700/cobin.png",
            Symbol: "COB",
            CoinName: "Cobinhood",
        },
        ZXC: {
            ImageUrl: "/media/34477794/zxc.jpg",
            Symbol: "ZXC",
            CoinName: "Oxcert",
        },
        WABI: {
            ImageUrl: "/media/12318331/wabi.png",
            Symbol: "WABI",
            CoinName: "WaBi",
        },
        ESZ: {
            ImageUrl: "/media/34477790/esz.png",
            Symbol: "ESZ",
            CoinName: "EtherSportz",
        },
        IQ: {
            ImageUrl: "/media/34477786/iq.png",
            Symbol: "IQ",
            CoinName: "Everipedia",
        },
        LUN: {
            ImageUrl: "/media/1383112/lunyr-logo.png",
            Symbol: "LUN",
            CoinName: "Lunyr",
        },
        "PRE*": {
            ImageUrl: "/media/1383801/pst.png",
            Symbol: "PRE*",
            CoinName: "Presearch",
        },
        ZCO: {
            ImageUrl: "/media/32655858/zco.jpg",
            Symbol: "ZCO",
            CoinName: "Zebi Coin",
        },
        FUEL: {
            ImageUrl: "/media/11999072/fuel.png",
            Symbol: "FUEL",
            CoinName: "Etherparty",
        },
        PST: {
            ImageUrl: "/media/9350792/pst.jpg",
            Symbol: "PST",
            CoinName: "Primas",
        },
        SPF: {
            ImageUrl: "/media/20780669/spf.png",
            Symbol: "SPF",
            CoinName: "SportyCo",
        },
        CDT: {
            ImageUrl: "/media/1383699/cdt.png",
            Symbol: "CDT",
            CoinName: "Blox",
        },
        AURA: {
            ImageUrl: "/media/25792643/aura.png",
            Symbol: "AURA",
            CoinName: "Aurora",
        },
        YOYOW: {
            ImageUrl: "/media/12318178/yoyow.png",
            Symbol: "YOYOW",
            CoinName: "Yoyow",
        },
        VIBE: {
            ImageUrl: "/media/12318267/vibe.png",
            Symbol: "VIBE",
            CoinName: "VIBEHub",
        },
        TFD: {
            ImageUrl: "/media/27010902/tfood.jpg",
            Symbol: "TFD",
            CoinName: "TE-FOOD",
        },
        TAAS: {
            ImageUrl: "/media/1383085/taas.png",
            Symbol: "TAAS",
            CoinName: "Token as a Service",
        },
        HVN: {
            ImageUrl: "/media/1383745/hvt.png",
            Symbol: "HVN",
            CoinName: "Hive Project",
        },
        DAT: {
            ImageUrl: "/media/12318265/dat.png",
            Symbol: "DAT",
            CoinName: "Datum",
        },
        SENT: {
            ImageUrl: "/media/27010471/sent.png",
            Symbol: "SENT",
            CoinName: "Sentinel",
        },
        CPT: {
            ImageUrl: "/media/34477750/cpt.jpg",
            Symbol: "CPT",
            CoinName: "Cryptaur",
        },
        CMCT: {
            ImageUrl: "/media/27010977/cmct.png",
            Symbol: "CMCT",
            CoinName: "Crowd Machine",
        },
        CREDO: {
            ImageUrl: "/media/14913573/credo-1.png",
            Symbol: "CREDO",
            CoinName: "Credo",
        },
        SNIP: {
            ImageUrl: "/media/34477802/snip.png",
            Symbol: "SNIP",
            CoinName: "SnipCoin",
        },
        VME: {
            ImageUrl: "/media/34477799/vme.png",
            Symbol: "VME",
            CoinName: "VeriME",
        },
        TRAK: {
            ImageUrl: "/media/34477792/trak-2.jpg",
            Symbol: "TRAK",
            CoinName: "TrakInvest",
        },
        XDNA: {
            ImageUrl: "/media/34477810/xdna.png",
            Symbol: "XDNA",
            CoinName: "XDNA",
        },
        VITE: {
            ImageUrl: "/media/34477806/vite.jpg",
            Symbol: "VITE",
            CoinName: "VITE",
        },
        U42: {
            ImageUrl: "/media/34477718/u42.png",
            Symbol: "U42",
            CoinName: "You42",
        },
        SAL: {
            ImageUrl: "/media/34477811/sal.png",
            Symbol: "SAL",
            CoinName: "SalPay",
        },
        RNT: {
            ImageUrl: "/media/34477807/rnt.jpg",
            Symbol: "RNT",
            CoinName: "OneRoot Network",
        },
        BBO: {
            ImageUrl: "/media/34477808/bbo.jpg",
            Symbol: "BBO",
            CoinName: "Bigbom",
        },
        YUP: {
            ImageUrl: "/media/34477809/yup.jpg",
            Symbol: "YUP",
            CoinName: "Crowdholding",
        },
        REX: {
            ImageUrl: "/media/12318086/rex.png",
            Symbol: "REX",
            CoinName: "Imbrex",
        },
        DAI: {
            ImageUrl: "/media/27010778/dai.jpg",
            Symbol: "DAI",
            CoinName: "Dai",
        },
        NMR: {
            ImageUrl: "/media/1383655/nmr.png",
            Symbol: "NMR",
            CoinName: "Numeraire",
        },
        SKRP: {
            ImageUrl: "/media/34477820/skrp.jpg",
            Symbol: "SKRP",
            CoinName: "Skraps",
        },
        HBT: {
            ImageUrl: "/media/9350768/hbt.png",
            Symbol: "HBT",
            CoinName: "Hubii Network",
        },
        HALO: {
            ImageUrl: "/media/34477824/halo.jpg",
            Symbol: "HALO",
            CoinName: "Halo Platform",
        },
        PITCH: {
            ImageUrl: "/media/34477826/pitch.jpg",
            Symbol: "PITCH",
            CoinName: "PITCH",
        },
        CARD: {
            ImageUrl: "/media/34477813/card.png",
            Symbol: "CARD",
            CoinName: "Cardstack",
        },
        THRT: {
            ImageUrl: "/media/34477817/thrt.png",
            Symbol: "THRT",
            CoinName: "ThriveToken",
        },
        LIKE: {
            ImageUrl: "/media/34477816/like.png",
            Symbol: "LIKE",
            CoinName: "LikeCoin",
        },
        AVH: {
            ImageUrl: "/media/34477819/avh.png",
            Symbol: "AVH",
            CoinName: "Animation Vision Cash",
        },
        SCC: {
            ImageUrl: "/media/34477823/scc.png",
            Symbol: "SCC",
            CoinName: "StockChain Coin",
        },
        BSTN: {
            ImageUrl: "/media/34477825/bstn.png",
            Symbol: "BSTN",
            CoinName: "BitStation",
        },
        NANJ: {
            ImageUrl: "/media/34477827/nanj.png",
            Symbol: "NANJ",
            CoinName: "NANJCOIN",
        },
        PAXEX: {
            ImageUrl: "/media/34477830/paxex.png",
            Symbol: "PAXEX",
            CoinName: "PAXEX",
        },
        SLX: {
            ImageUrl: "/media/33434266/slx.jpg",
            Symbol: "SLX",
            CoinName: "Slate",
        },
        AMO: {
            ImageUrl: "/media/33187878/amo.jpg",
            Symbol: "AMO",
            CoinName: "Amo Coin",
        },
        HYT: {
            ImageUrl: "/media/33842924/hyt.jpg",
            Symbol: "HYT",
            CoinName: "HoryouToken",
        },
        STT: {
            ImageUrl: "/media/33434302/stt.jpg",
            Symbol: "STT",
            CoinName: "Staramba",
        },
        EMN: {
            ImageUrl: "/media/34155587/beepbeepnation_com-emn-vert-2.png",
            Symbol: "EMN",
            CoinName: "Eminent Token ",
        },
        DEC: {
            ImageUrl: "/media/16746438/drc.png",
            Symbol: "DEC",
            CoinName: "Darico",
        },
        HFT: {
            ImageUrl: "/media/34155494/hft.png",
            Symbol: "HFT",
            CoinName: "Hirefreehands",
        },
        GEX: {
            ImageUrl: "/media/30002206/gex.jpg",
            Symbol: "GEX",
            CoinName: "GreenX",
        },
        BETT: {
            ImageUrl: "/media/32655922/bett.jpg",
            Symbol: "BETT",
            CoinName: "Bettium",
        },
        AIC: {
            ImageUrl: "/media/32655843/aic.png",
            Symbol: "AIC",
            CoinName: "AI Crypto",
        },
        DIT: {
            ImageUrl: "/media/34477834/dit.jpg",
            Symbol: "DIT",
            CoinName: "Ditcoin",
        },
        ENK: {
            ImageUrl: "/media/27010914/enk.png",
            Symbol: "ENK",
            CoinName: "Enkidu",
        },
        TIP: {
            ImageUrl: "/media/34155504/tip.png",
            Symbol: "TIP",
            CoinName: "Tip Blockchain",
        },
        DNN: {
            ImageUrl: "/media/20780744/dnn.png",
            Symbol: "DNN",
            CoinName: "DNN Token",
        },
        MNTS: {
            ImageUrl: "/media/27010946/mnts.png",
            Symbol: "MNTS",
            CoinName: "Mint",
        },
        LYK: {
            ImageUrl: "/media/30002217/lykk.jpg",
            Symbol: "LYK",
            CoinName: "Loyakk Vega",
        },
        CTKN: {
            ImageUrl: "/media/32655945/ctkn.jpg",
            Symbol: "CTKN",
            CoinName: "Curaizon",
        },
        BGL: {
            ImageUrl: "/media/34155533/buglab-token-listing-transparent.png",
            Symbol: "BGL",
            CoinName: "Buglab",
        },
        PAVO: {
            ImageUrl: "/media/30001969/photo_2018-04-04_18-01-12.jpg",
            Symbol: "PAVO",
            CoinName: "Pavocoin",
        },
        HAC: {
            ImageUrl: "/media/12318176/hac.jpg",
            Symbol: "HAC",
            CoinName: "Hackspace Capital",
        },
        BMK: {
            ImageUrl: "/media/33752294/bmk.png",
            Symbol: "BMK",
            CoinName: "Benchmark",
        },
        PLMT: {
            ImageUrl: "/media/30002021/plmt.png",
            Symbol: "PLMT",
            CoinName: "Pallium",
        },
        AZART: {
            ImageUrl: "/media/34477835/azart.png",
            Symbol: "AZART",
            CoinName: "Azart",
        },
        RDC: {
            ImageUrl: "/media/34477836/rdc.jpg",
            Symbol: "RDC",
            CoinName: "Ordocoin",
        },
        FREE: {
            ImageUrl: "/media/34477838/free.jpg",
            Symbol: "FREE",
            CoinName: "FREE coin",
        },
        TRIP: {
            ImageUrl: "/media/12318231/trip.png",
            Symbol: "TRIP",
            CoinName: "Trippki",
        },
        IVY: {
            ImageUrl: "/media/33187893/rsz_udyc4sjg_400x400.jpg",
            Symbol: "IVY",
            CoinName: "IvyKoin",
        },
        AOP: {
            ImageUrl: "/media/34477839/aop.jpg",
            Symbol: "AOP",
            CoinName: "Averopay",
        },
        BTM: {
            ImageUrl: "/media/20084/btm.png",
            Symbol: "BTM",
            CoinName: "BitMark",
        },
        INTO: {
            ImageUrl: "/media/34477844/into.jpg",
            Symbol: "INTO",
            CoinName: "Influ Token",
        },
        SDRN: {
            ImageUrl: "/media/20780780/sdrn.png",
            Symbol: "SDRN",
            CoinName: "Senderon",
        },
        AIMS: {
            ImageUrl: "/media/34477845/aims.jpg",
            Symbol: "AIMS",
            CoinName: "HighCastle Token",
        },
        TSC: {
            ImageUrl: "/media/34477872/tsc.jpg",
            Symbol: "TSC",
            CoinName: "ThunderStake",
        },
        SPLB: {
            ImageUrl: "/media/34477873/splb.jpg",
            Symbol: "SPLB",
            CoinName: "SimpleBank",
        },
        CMZ: {
            ImageUrl: "/media/34477875/cmz.jpg",
            Symbol: "CMZ",
            CoinName: "CRYPTOMAGZ",
        },
        MOIN: {
            ImageUrl: "/media/350959/moin.png",
            Symbol: "MOIN",
            CoinName: "MoinCoin",
        },
        ERC20: {
            ImageUrl: "/media/34477717/erc20.png",
            Symbol: "ERC20",
            CoinName: "Index ERC20",
        },
        BANCA: {
            ImageUrl: "/media/27010659/banca.png",
            Symbol: "BANCA",
            CoinName: "BANCA",
        },
        TRN: {
            ImageUrl: "/media/34155510/trn.png",
            Symbol: "TRN",
            CoinName: "Ternion",
        },
        "ZSC*": {
            ImageUrl: "/media/30002000/zsc.png",
            Symbol: "ZSC*",
            CoinName: "ZeroState",
        },
        IMV: {
            ImageUrl: "/media/25792642/imv.png",
            Symbol: "IMV",
            CoinName: "ImmVRse",
        },
        SMART: {
            ImageUrl: "/media/34477887/smart2.png",
            Symbol: "SMART",
            CoinName: "SmartCash",
        },
        RUBY: {
            ImageUrl: "/media/32655942/ruby.png",
            Symbol: "RUBY",
            CoinName: "Rubius",
        },
        ILK: {
            ImageUrl: "/media/34155594/inlock.jpg",
            Symbol: "ILK",
            CoinName: "Inlock",
        },
        RAC: {
            ImageUrl: "/media/30002101/rac.jpg",
            Symbol: "RAC",
            CoinName: "RAcoin",
        },
        WYS: {
            ImageUrl: "/media/33434303/wys.jpg",
            Symbol: "WYS",
            CoinName: "Wysker",
        },
        LIGER: {
            ImageUrl: "/media/30002232/liger.jpg",
            Symbol: "LIGER",
            CoinName: "Ligercoin",
        },
        NLG: {
            ImageUrl: "/media/32655867/webpnet-resizeimage.png",
            Symbol: "NLG",
            CoinName: "Gulden",
        },
        LINK: {
            ImageUrl: "/media/12318078/link.png",
            Symbol: "LINK",
            CoinName: "ChainLink",
        },
        SFT: {
            ImageUrl: "/media/34477898/stf.png",
            Symbol: "SFT",
            CoinName: "SportsFix",
        },
        ITR: {
            ImageUrl: "/media/34477903/intro.png",
            Symbol: "ITR",
            CoinName: "INTRO",
        },
        HTML: {
            ImageUrl: "/media/34477916/html_newer.png",
            Symbol: "HTML",
            CoinName: "HTML Coin",
        },
        WORK: {
            ImageUrl: "/media/34477913/work.png",
            Symbol: "WORK",
            CoinName: "Aworker",
        },
        PHT: {
            ImageUrl: "/media/34477911/pht.png",
            Symbol: "PHT",
            CoinName: "Photochain",
        },
        SWM: {
            ImageUrl: "/media/27010630/swm_logo.png",
            Symbol: "SWM",
            CoinName: "Swarm Fund",
        },
        STX: {
            ImageUrl: "/media/1383946/stx.png",
            Symbol: "STX",
            CoinName: "Stox",
        },
        HMQ: {
            ImageUrl: "/media/1383174/hmq.png",
            Symbol: "HMQ",
            CoinName: "Humaniq",
        },
        EVN: {
            ImageUrl: "/media/14913587/env.png",
            Symbol: "EVN",
            CoinName: "Envion",
        },
        DATX: {
            ImageUrl: "/media/30001711/datx.jpg",
            Symbol: "DATX",
            CoinName: "DATx",
        },
        BMC: {
            ImageUrl: "/media/12318008/bmc.png",
            Symbol: "BMC",
            CoinName: "Blackmoon Crypto",
        },
        BKX: {
            ImageUrl: "/media/14913571/bkx.png",
            Symbol: "BKX",
            CoinName: "BANKEX",
        },
        GRID: {
            ImageUrl: "/media/14913632/grid.png",
            Symbol: "GRID",
            CoinName: "Grid+",
        },
        "1ST": {
            ImageUrl: "/media/351524/1st.png",
            Symbol: "1ST",
            CoinName: "FirstBlood",
        },
        PRO: {
            ImageUrl: "/media/1383792/pro.png",
            Symbol: "PRO",
            CoinName: "Propy",
        },
        UKG: {
            ImageUrl: "/media/14913456/ukg.png",
            Symbol: "UKG",
            CoinName: "UnikoinGold",
        },
        MDA: {
            ImageUrl: "/media/12318340/mda.png",
            Symbol: "MDA",
            CoinName: "Moeda",
        },
        BCPT: {
            ImageUrl: "/media/16746476/bcpt.png",
            Symbol: "BCPT",
            CoinName: "BlockMason Credit Protocol",
        },
        XYO: {
            ImageUrl: "/media/27011014/untitled-1.png",
            Symbol: "XYO",
            CoinName: "XY Oracle",
        },
        BBC: {
            ImageUrl: "/media/30002148/bbc.png",
            Symbol: "BBC",
            CoinName: "TraDove",
        },
        XDCE: {
            ImageUrl: "/media/16746634/xdc.png",
            Symbol: "XDCE",
            CoinName: "XinFin Coin",
        },
        PERU: {
            ImageUrl: "/media/34477804/peru.png",
            Symbol: "PERU",
            CoinName: "PeruCoin",
        },
        "DNET*": {
            ImageUrl: "/media/34477915/dnet.png",
            Symbol: "DNET*",
            CoinName: "DeNet",
        },
        FNP: {
            ImageUrl: "/media/33187823/fnp.jpg",
            Symbol: "FNP",
            CoinName: "FlipNpik",
        },
        RUP: {
            ImageUrl: "/media/30001935/rup.png",
            Symbol: "RUP",
            CoinName: "Rupee",
        },
        QUA: {
            ImageUrl: "/media/34477909/qua.png",
            Symbol: "QUA",
            CoinName: "Quasa",
        },
        SHPT: {
            ImageUrl: "/media/34477922/shpt.png",
            Symbol: "SHPT",
            CoinName: "Shipit",
        },
        FAN: {
            ImageUrl: "/media/34477946/fan.png",
            Symbol: "FAN",
            CoinName: "Fan360",
        },
        URB: {
            ImageUrl: "/media/34477891/urb.png",
            Symbol: "URB",
            CoinName: "Urbit Data",
        },
        KBC: {
            ImageUrl: "/media/34333424/kbc.png",
            Symbol: "KBC",
            CoinName: "Karatgold coin",
        },
        PRL: {
            ImageUrl: "/media/16746488/prl.png",
            Symbol: "PRL",
            CoinName: "Oyster Pearl",
        },
        COIN: {
            ImageUrl: "/media/14913606/coin.png",
            Symbol: "COIN",
            CoinName: "Coinvest",
        },
        JSE: {
            ImageUrl: "/media/34477889/jse.png",
            Symbol: "JSE",
            CoinName: "JSEcoin",
        },
        DBCCOIN: {
            ImageUrl: "/media/34477959/dbccoin.png",
            Symbol: "DBCCOIN",
            CoinName: "Datablockchain",
        },
        XAP: {
            ImageUrl: "/media/34477840/xap.png",
            Symbol: "XAP",
            CoinName: "Apollon",
        },
        BUD: {
            ImageUrl: "/media/34477958/bud.png",
            Symbol: "BUD",
            CoinName: "Buddy",
        },
        ORS: {
            ImageUrl: "/media/30002247/orst.png",
            Symbol: "ORS",
            CoinName: "ORS Group",
        },
        HMD: {
            ImageUrl: "/media/34477886/hmd.jpg",
            Symbol: "HMD",
            CoinName: "Homelend",
        },
        IMGZ: {
            ImageUrl: "/media/34477890/imgz.jpg",
            Symbol: "IMGZ",
            CoinName: "Imigize",
        },
        RSC: {
            ImageUrl: "/media/34477988/rsc1.png",
            Symbol: "RSC",
            CoinName: "Ronaldinho Soccer Coin",
        },
        ZAZA: {
            ImageUrl: "/media/34477917/zaza.png",
            Symbol: "ZAZA",
            CoinName: "ZAZA",
        },
        ORBS: {
            ImageUrl: "/media/34477897/orbs.jpg",
            Symbol: "ORBS",
            CoinName: "Orbis",
        },
        "CCC*": {
            ImageUrl: "/media/34477899/ccc.jpg",
            Symbol: "CCC*",
            CoinName: "Blockshipping",
        },
        MLN: {
            ImageUrl: "/media/1382653/mln.png",
            Symbol: "MLN",
            CoinName: "Melon",
        },
        ZCN: {
            ImageUrl: "/media/34333431/zcn.jpg",
            Symbol: "ZCN",
            CoinName: "0chain",
        },
        UUU: {
            ImageUrl: "/media/30002272/uuu.png",
            Symbol: "UUU",
            CoinName: "U Network",
        },
        ART: {
            ImageUrl: "/media/12318097/art.png",
            Symbol: "ART",
            CoinName: "Maecenas",
        },
        ZSC: {
            ImageUrl: "/media/12318341/zsc.png",
            Symbol: "ZSC",
            CoinName: "Zeusshield",
        },
        UP: {
            ImageUrl: "/media/12318374/up.png",
            Symbol: "UP",
            CoinName: "UpToken",
        },
        MRK: {
            ImageUrl: "/media/33187844/mrk.jpg",
            Symbol: "MRK",
            CoinName: "MARK.SPACE",
        },
        MOT: {
            ImageUrl: "/media/27010462/mot.png",
            Symbol: "MOT",
            CoinName: "Olympus Labs",
        },
        PLBT: {
            ImageUrl: "/media/1383671/polybius.png",
            Symbol: "PLBT",
            CoinName: "Polybius",
        },
        MGO: {
            ImageUrl: "/media/1382798/mgo.png",
            Symbol: "MGO",
            CoinName: "MobileGo",
        },
        CEEK: {
            ImageUrl: "/media/30002124/ceek.png",
            Symbol: "CEEK",
            CoinName: "CEEK Smart VR Token",
        },
        EVX: {
            ImageUrl: "/media/1383850/evx.png",
            Symbol: "EVX",
            CoinName: "Everex",
        },
        UPP: {
            ImageUrl: "/media/33957387/upp.jpg",
            Symbol: "UPP",
            CoinName: "Sentinel Protocol",
        },
        QUN: {
            ImageUrl: "/media/27010466/qun.png",
            Symbol: "QUN",
            CoinName: "QunQun",
        },
        DXT: {
            ImageUrl: "/media/27010739/dxt.png",
            Symbol: "DXT",
            CoinName: "DataWallet",
        },
        HST: {
            ImageUrl: "/media/34155557/horizonstate-logo_preview.png",
            Symbol: "HST",
            CoinName: "Decision Token",
        },
        BZNT: {
            ImageUrl: "/media/33842907/bznt.jpg",
            Symbol: "BZNT",
            CoinName: "Bezant",
        },
        XRL: {
            ImageUrl: "/media/1383754/xrl.png",
            Symbol: "XRL",
            CoinName: "Rialto.AI",
        },
        PRG: {
            ImageUrl: "/media/1384033/prg.png",
            Symbol: "PRG",
            CoinName: "Paragon",
        },
        UQC: {
            ImageUrl: "/media/16746443/uqc.png",
            Symbol: "UQC",
            CoinName: "Uquid Coin",
        },
        TIX: {
            ImageUrl: "/media/1383690/tix.png",
            Symbol: "TIX",
            CoinName: "Blocktix",
        },
        COSS: {
            ImageUrl: "/media/1383802/coss.png",
            Symbol: "COSS",
            CoinName: "COSS",
        },
        "BBN*": {
            ImageUrl: "/media/30002013/bbn2.jpg",
            Symbol: "BBN*",
            CoinName: "Banyan Network",
        },
        XES: {
            ImageUrl: "/media/33187847/rsz_fengcry6_400x400.jpg",
            Symbol: "XES",
            CoinName: "Proxeus",
        },
        "EMT*": {
            ImageUrl: "/media/34477908/emt.jpg",
            Symbol: "EMT*",
            CoinName: "Memority",
        },
        STRY: {
            ImageUrl: "/media/34477947/stry.jpg",
            Symbol: "STRY",
            CoinName: "STRYKZ",
        },
        MCV: {
            ImageUrl: "/media/34477762/mcv.png",
            Symbol: "MCV",
            CoinName: "MCV Token",
        },
        OIO: {
            ImageUrl: "/media/33434284/oio.png",
            Symbol: "OIO",
            CoinName: "Online",
        },
        HAV: {
            ImageUrl: "/media/30001740/hav.jpg",
            Symbol: "HAV",
            CoinName: "Havven",
        },
        HPAY: {
            ImageUrl: "/media/34478006/hpay.png",
            Symbol: "HPAY",
            CoinName: "HadePay",
        },
        UBEX: {
            ImageUrl: "/media/33187892/ubex.png",
            Symbol: "UBEX",
            CoinName: "Ubex",
        },
        KAAS: {
            ImageUrl: "/media/34333411/kaas.jpg",
            Symbol: "KAAS",
            CoinName: "KAASY.AI",
        },
    },
};
