import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import App from "./components/App";
import registerServiceWorker from "./registerServiceWorker";
import ReactGA from "react-ga";

const google_analytics = process.env.react_app_google_analytics;

ReactGA.initialize(google_analytics);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <Router>
        <Switch>
            <Route exact={true} path="/" component={App} />
            <Route
                exact={true}
                path="/address/:address/marked/:marked"
                component={App}
            />
            <Route exact={true} path="/address/:address" component={App} />
            <Route exact={true} path="/tx/:txhash" component={App} />
            <Route path="/:search" component={App} />
        </Switch>
    </Router>,
    document.getElementById("root"),
);
registerServiceWorker();
